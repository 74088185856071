import {transaction} from "@datorama/akita";
import companyActions, {CompanyActions} from "../company/CompanyActions";
import sectionActions, {SectionActions} from "../section/SectionActions";
import vehicleActions, {VehicleActions} from "../vehicle/VehicleActions";
import vehicleGroupActions, {VehicleGroupActions} from "../vehiclegroup/VehicleGroupActions";

export interface Dependencies {
    companyActions: CompanyActions;
    sectionActions: SectionActions;
    vehicleActions: VehicleActions;
    vehicleGroupActions: VehicleGroupActions;
}

export class VehicleSelectionActions {
    private readonly companyActions = companyActions;
    private readonly sectionActions = sectionActions;
    private readonly vehicleGroupActions = vehicleGroupActions;
    private readonly vehicleActions = vehicleActions;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public async navigateTo(section: string, vehicleGroup?: string, company?: string) {
        if (vehicleGroup && company) {
            await this.navigateToCompany(section, vehicleGroup, company);
        } else if (vehicleGroup) {
            await this.navigateToGroup(section, vehicleGroup);
        } else {
            await this.navigateToSection(section);
        }
    }

    private async navigateToCompany(section: string, vehicleGroup: string, company: string) {
        await Promise.all([
            this.sectionActions.select(section),
            this.vehicleActions.deselect(),
            this.vehicleGroupActions.loadSection(section),
            this.companyActions.loadGroup(vehicleGroup),
            this.vehicleActions.findAllByCompanyAndGroup(company, vehicleGroup)
        ]);

        this.vehicleGroupActions.select(vehicleGroup);
        this.companyActions.select(company);
    }

    private async navigateToGroup(section: string, vehicleGroup: string) {
        this.companyActions.deselect();
        const [companies] = await Promise.all([
            this.companyActions.loadGroup(vehicleGroup),
            this.sectionActions.select(section),
            this.vehicleActions.deselect(),
            this.vehicleGroupActions.loadSection(section)
        ]);
        this.vehicleGroupActions.select(vehicleGroup);

        if (companies.length === 1) {
            await this.navigateTo(section, vehicleGroup, companies[0]);
        }
    }

    private async navigateToSection(section: string) {
        this.companyActions.deselect();
        this.vehicleGroupActions.deselect();

        await Promise.all([
            this.sectionActions.select(section),
            this.vehicleActions.deselect(),
            this.vehicleGroupActions.loadSection(section)
        ]);
    }
}

export default new VehicleSelectionActions();
