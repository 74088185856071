import {Query} from "@datorama/akita";
import attributeValueStore, {AttributeValueState, AttributeValueStore} from "./AttributeValueStore";

export class AttributeValueQuery extends Query<AttributeValueState> {
    constructor(
        protected store: AttributeValueStore = attributeValueStore
    ) {
        super(store);
    }
}

export default new AttributeValueQuery();
