import SearchResult from "./SearchResult";
import {SearchResultType} from "./SearchResultType";

export interface VehicleSearchResult extends SearchResult {
    type: SearchResultType.VEHICLE;
    sectionSlug: string;
    chassisNumber: string;
}

export function isVehicleSearchResult(searchResult: SearchResult): searchResult is VehicleSearchResult {
    return searchResult.type === SearchResultType.VEHICLE;
}
