import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import React, {FC, Fragment, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import {isInvalidEmail} from "../app/common/validation";
import ContentHeader from "../app/component/ContentHeader";
import Config from "../app/model/Config";
import contactService from "../app/service/ContactService";
import userService from "../app/service/UserService";
import authQuery from "../app/state/auth/AuthQuery";
import configQuery from "../app/state/config/ConfigQuery";
import {CountryOptions} from "../i18n/country";
import {hasStoreLocator} from "../toggles";

export const Contact: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const config = configQuery.useValue();
    const {shopName, shopShortName, shopCountry, shopPhone, shopFax, shopEMail} = config;
    const {city, street} = getShopAddressParts(config);
    const {name: country} = CountryOptions.find(({key}) => key === shopCountry) || {name: shopCountry};

    const breadcrumbs: Breadcrumb[] = useMemo(() => [
        {
            text: t("contact-title"),
            to: "/kontakt.html",
        }
    ], [t]);

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("contact-title")}></ContentHeader>
            <Grid
                container
                spacing={4}
                className={classes.company}
            >
                <Grid item md={12}>
                    <Typography
                        variant="h4"
                        component="span"
                        children={shopName}
                    />

                </Grid>
                <Grid item md={6}>
                    <img
                        className={classes.logo}
                        alt={shopShortName}
                        src="/theme/logo.png"
                    />
                </Grid>
                <Grid item md={6}>
                    <Label text="contact-contact-label"/>
                    <address>
                        <div className={classes.text}>
                            {street}<br/>
                            {city}<br/>
                            {country}<br/>
                        </div>
                    </address>
                    <div>
                        <Label text="contact-phone-label"/>
                        <Link
                            color="secondary"
                            href={`tel:${shopPhone}`}
                            children={shopPhone}
                        />
                    </div>
                    <div>
                        <Label text="contact-fax-label"/>
                        {shopPhone}-{shopFax}
                    </div>
                    <div>
                        <Label text="contact-email-label"/>
                        <Link
                            color="secondary"
                            href={`mailto:${shopEMail}`}
                            children={shopEMail}
                        />
                    </div>
                </Grid>
            </Grid>
            <ContactForm/>
        </Fragment>
    );
};

const ContactForm: FC = () => {
    const {t} = useTranslation();
    const isLoggedIn = authQuery.useIsLoggedIn();
    const classes = useStyles(undefined);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const isInvalid = isInvalidEmail(email) || [name, email, subject, message].some(value => !value)

    useEffect(() => {
        if (isLoggedIn) {
            userService.getProfile()
                .then(({email, name}) => {
                    email && setEmail(email);
                    name && setName(name);
                })
                .catch(() => {
                })
        }
    }, [isLoggedIn])

    async function onSubmit() {
        setError(null);
        setSuccess(false);
        setLoading(true);
        try {
            await contactService.create({name, email, subject, message});
            setSuccess(true);
            setSubject("");
            setMessage("");
        } catch (err) {
            setError(err)
        } finally {
            setLoading(false);
        }
    }

    const link = hasStoreLocator() ? <Link color="secondary" component={RouterLink} to="/haendler/"/> : <span/>;

    return (
        <Fragment>
            <Alert severity="info" className={classes.note}>
                <div>
                    <Trans i18nKey="contact-note"
                           components={[<br/>, link]}
                    />
                </div>
            </Alert>
            <ErrorMessage error={error}/>
            {
                success &&
                <Alert onClose={() => setSuccess(false)} severity="success">
                    {t("contact-success-text")}
                </Alert>
            }
            <Grid
                container
                spacing={2}
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault();
                    onSubmit();
                }}
            >
                <Grid item container md={12}>
                    <Grid item md={7}>
                        <TextField
                            fullWidth
                            required
                            label={t("contact-mail-name-label")}
                            name="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item container md={12}>
                    <Grid item md={7}>
                        <TextField
                            fullWidth
                            required
                            label={t("contact-mail-from-label")}
                            type="email"
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item container md={12}>
                    <Grid item md={7}>
                        <TextField
                            fullWidth
                            required
                            label={t("contact-mail-subject-label")}
                            name="subject"
                            value={subject}
                            onChange={(event) => setSubject(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item container md={12}>
                    <Grid item md={7}>
                        <TextField
                            fullWidth
                            required
                            multiline
                            rows={4}
                            label={t("contact-mail-text-label")}
                            name="text"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item md={3}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="default"
                        disabled={loading || isInvalid}
                        disableElevation
                        data-cy="mail-send-action"
                    >
                        {t("contact-mail-send-action")}
                        {loading &&
                        <Box color="secondary.main" pl={1.5} display="flex">
                            <CircularProgress
                                size={24}
                                thickness={5}
                            />
                        </Box>}
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    );
};


const useStyles = makeStyles((theme) => ({
    company: {
        marginBottom: theme.spacing(4)
    },
    note: {
        marginBottom: theme.spacing(2)
    },
    logo: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(3),
        borderRadius: theme.spacing(3),
        width: "100%"
    },
    label: {
        "&:after": {
            content: "\":\""
        },
        marginRight: theme.spacing(1)
    },
    text: {
        paddingLeft: theme.spacing(2)
    }
}));

function getShopAddressParts({shopAddress}: Config): { street: string, city: string } {
    const [city, street] = (shopAddress || ",")
        .split(",")
        .map(part => part || "")
        .map(part => part.trim())
    ;
    return {street, city};
}

function Label({text}: { text: string }) {
    const {t} = useTranslation();
    const classes = useStyles(undefined);

    return (
        <Typography
            className={classes.label}
            variant="h5"
            component="span"
        >{t(text)}</Typography>
    );
}

function ErrorMessage({error}: any) {
    const {t} = useTranslation();
    if (!error) {
        return null;
    } else {
        return (
            <Alert severity="error">
                {t("error-500-heading")}<br/>
                {t("error-500-text1")}
            </Alert>
        );
    }
}

export default Contact;
