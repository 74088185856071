import {AttributeSlug} from "../../../../../model/attribute/Attribute";
import service, {AttributeFilter, AttributeService} from "../../../../../service/AttributeService";
import store, {AttributeValueStore} from "./AttributeValueStore";

export interface Dependencies {
    store: AttributeValueStore;
    service: AttributeService;
}

export class AttributeValueActions {
    private readonly store = store;
    private readonly service: AttributeService = service;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async load(attribute: AttributeSlug, filter: AttributeFilter): Promise<void> {
        const category = filter["category.slug"];
        try {
            const values = await this.service.find(attribute, filter);
            this.store.update({
                [this.createAttributePath(category, attribute)]: values
            });
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private createAttributePath(category: string, attribute: AttributeSlug) {
        return `${category}/${attribute}`;
    }

    private shouldSkipLoading(category: string, attribute: AttributeSlug): boolean {
        const element = this.store.getValue()[this.createAttributePath(category, attribute)];

        return !!element;
    }
}

export default new AttributeValueActions();
