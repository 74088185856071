import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, {FC, Fragment, useState} from "react";
import Vehicle from "../../../model/Vehicle";
import AppLink from "../../theme/AppLink";
import VehiclePopover from "./VehiclePopover";

export interface VehicleModelListProps {
    section: string;
    currentVehicle: Vehicle;
}

export const VehicleModelList: FC<VehicleModelListProps> = ({ section, currentVehicle }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [vehicle, setVehicle] = useState<Vehicle | null>(null);
    const open = !!anchorEl;

    function handlePopoverOpen(event: React.MouseEvent<HTMLElement, MouseEvent>, data: Vehicle) {
        setVehicle(data);
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    return (
        <Fragment>
            <ListItem>
                <AppLink to={`/${section}/fahrzeug/${currentVehicle.slug}.html`}
                         color="secondary">
                    <ListItemText data-cy="vehicle-title"
                                  primary={currentVehicle.name}
                                  onMouseEnter={(e) => handlePopoverOpen(e, currentVehicle)}
                                  onMouseLeave={handlePopoverClose}
                                  aria-owns={open ? "mouse-over-popover" : undefined}
                                  aria-haspopup="true"
                    />
                </AppLink>
            </ListItem>
            <VehiclePopover vehicle={vehicle} anchorElement={anchorEl}/>
        </Fragment>
    );
};
