import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, {FC, memo} from "react";
import {useTranslation} from "react-i18next";
import {otherCountries, preferredCountries} from "../../../i18n/country";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: "0.75vw",
        marginBottom: "0.75vw",
        fontSize: "0.875rem",
        "&::placeholder": {
            color: theme.palette.secondary.main
        }
    },
    label: {
        color: theme.palette.secondary.main,
    },
    labelContainer: {
        marginBottom: "0.5vw"
    }
}));

export const CountrySelection: FC<any> = memo((props) => {
    const classes = useStyles(props);
    return (
        <FormControl fullWidth>
            <div className={classes.labelContainer}>
                <InputLabel required={props.required} className={classes.label}>{props.label}</InputLabel>
            </div>
            <Select {...props}
                    data-cy="addressform-land-label"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className={classes.textField}>
                {preferredCountries.map(({key, name}) => (
                    <MenuItem key={key} value={key}>{name}</MenuItem>
                ))}
                <Divider/>
                {otherCountries.map(({key, name}) => (
                    <MenuItem key={key} value={key}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export default (props: any) => {
    const {t} = useTranslation();

    return (
        <CountrySelection
            label={t("address-country-label")}
            {...props}
        />
    );
};
