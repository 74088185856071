import {Order, QueryConfig} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import {HookQueryEntity} from "../HookQueryEntity";
import sectionStore, {Entity, SectionStore, State} from "./SectionStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class SectionQuery extends HookQueryEntity<State> {
    public readonly activeName = this.selectActive(section => section.name);

    constructor(
        protected store: SectionStore = sectionStore
    ) {
        super(store);
    }

    public isEmpty(): boolean {
        return this.getCount() === 0;
    }

    public useActiveName(): Entity['name'] | null {
        return useObservable(this.activeName, this.getActive()?.name) || null;
    }
}

export default new SectionQuery();
