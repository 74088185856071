import {useEffect, useState} from "react";
import {Observable} from "rxjs";

export function useObservable<T>(observable: Observable<T>): T | undefined;
export function useObservable<T>(observable: Observable<T>, initialState: T): T;

export function useObservable<T>(observable: Observable<T>, initialState?: T) {
    // @ts-ignore
    const [state, setState] = useState<T>(initialState);

    useEffect(() => {
        const sub = observable.subscribe(setState);

        return function cleanup() {
            return sub.unsubscribe();
        };
    }, [observable]);

    return state;
}
