import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import getItemPath from "../../common/route/getItemPath";
import Item from "../../model/item/Item";
import AppLink from "../theme/AppLink";

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: theme.spacing(2),
        backgroundColor: "white",
        borderRadius: '2px',
        border: '2px solid ' + theme.palette.primary.main  + '22',
        cursor: 'pointer',
        overflow: 'hidden',
        padding: theme.spacing(2),
        flexShrink: 0,
        minWidth: '400px',
        flexBasis: '48%',
        boxShadow: '0 2pt 5pt 0pt ' + theme.palette.secondary.main + 'dd',
        "&:hover": {
            background: "#efefef",
            textDecoration: 'none',
        }
    },
    image: {
        height: '100%',
        width: 'auto'
    },
    itemheader: {
        fontWeight: 'bold',
        fontSize: 'large',
    },
    description: {
        paddingTop: theme.spacing(2),
        fontSize: 'medium',
        fontWeight: 'normal',
    }
}));

export interface LatestItemProps {
    item: Item;
}

export const LatestItem: FC<LatestItemProps> = ({item}) => {
    const classes = useStyles(undefined);
    const {images = []} = item;

    return (
        <AppLink className={classes.grid} to={getItemPath(item.slug)}>
            <Grid
                container
                justify="center"
                alignItems="flex-start"
                direction='row'
                spacing={2}>

                <Grid item sm={6}>
                    {
                        images.length > 0 &&
                        <img className={classes.image}
                             src={images[0].locationSmall}
                             alt={images[0].description}/>
                    }
                </Grid>

                <Grid item sm={6}>
                    <Typography className={classes.itemheader} color="textPrimary" component="span"
                                variant="inherit"
                                data-cy="latest-item-number">{item.company && item.company.name + " "}</Typography>
                    <Typography className={classes.itemheader} color="primary" component="span"
                                variant="inherit" data-cy="latest-item-number">{item.itemNumber}</Typography>
                    <Typography className={classes.description} color="textPrimary" component="p"
                                variant="inherit" data-cy="latest-item-name">{item.name}</Typography>
                </Grid>

            </Grid>
        </AppLink>
    );
};
export default LatestItem;

