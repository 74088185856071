export enum RoleName {
    ROLE_ANONYMOUS = "ROLE_ANONYMOUS",
    ROLE_USER = "ROLE_USER",
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_STAFF = "ROLE_STAFF",

    ORDERS_READ = "ORDERS_READ",
    PRICE_LIST_1_READ = "PRICE_LIST_1_READ",
    PRICE_LIST_2_READ = "PRICE_LIST_2_READ",
    PRICE_LIST_3_READ = "PRICE_LIST_3_READ",
    PRICE_LIST_4_READ = "PRICE_LIST_4_READ",
    PRICE_LIST_8_READ = "PRICE_LIST_8_READ",
    PRICE_LIST_10_READ = "PRICE_LIST_10_READ",
    ITEM_OEM_NUMBER_READ = "ITEM_OEM_NUMBER_READ",
    ITEM_PRICE_READ = "ITEM_PRICE_READ",
    ITEM_NEWS_READ = "ITEM_NEWS_READ",
    ITEM_PREVIEW = "ITEM_PREVIEW"
}

export default RoleName;
