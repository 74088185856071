import React, {FC} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {CardMedia} from "@material-ui/core";

export interface IconCardProps {
    title: string;
    iconPath: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "220px",
        minWidth: "180px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: "center"
    },
    text: {
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        fontSize: "0.85rem"
    },
    iconRoot: {
        fontSize: "5rem",
        margin: "1.5rem"
    },
    imageIcon: {
        height: "120px",
        backgroundSize: "initial",
        marginBottom: "5px",
        marginTop: "30px"
    },
    content: {
        padding: "0",
        paddingBottom: "25px"
    }
}));

export const IconCard: FC<IconCardProps> = ({title, iconPath}) => {
    const classes = useStyles(undefined);

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia className={classes.imageIcon} image={iconPath}/>
                <CardContent className={classes.content}>
                    <Typography variant="body2" className={classes.text} component="p">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
export default IconCard;
