import {Store, StoreConfig} from "@datorama/akita";
import Address from "../../../model/checkout/Address";
import {AddressId} from "../../../model/checkout/AddressResponse";

export interface State {
    id?: AddressId;
    error?: any;
    address: Address;
    isCompanyAddress: boolean;
}

export function createInitialState(): State {
    return {
        address: {
            dealerLocator: false,
            billing: false,
            delivery: false,
            companyAddress: false,
            name: "",
            subName: "",
            street: "",
            zip: "",
            city: "",
            country: "",
            phone: "",
            mobile: "",
            fax: ""
        },
        isCompanyAddress: false
    };
}

@StoreConfig({
    name: "address.edit",
    resettable: true
})
export class EditAddressStore extends Store<State> {

    constructor() {
        super(createInitialState());
    }
}

export default new EditAddressStore();
