import ReturnForm from "../../model/returns/ReturnForm";
import {toDate} from "../../TransformDate";
import AuthorizedHttpService from "../AuthorizedHttpService";

export class ReturnFormService extends AuthorizedHttpService {
    public async list(): Promise<ReturnForm[]> {
        const items: any[] = await this.get("/returns/");

        return items.map(({sentAt, ...rest}) => ({
            sentAt: toDate(sentAt),
            ...rest
        }));
    }
}

export default new ReturnFormService();
