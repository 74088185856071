import ListItemText from "@material-ui/core/ListItemText";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {CategorySearchResult} from "../../model/search/CategorySearchResult";

export interface CategoryResultItemProps {
    value: CategorySearchResult;
}

export const CategoryResultItem: FC<CategoryResultItemProps> = ({value}) => {
    const {t} = useTranslation();
    const {name, section} = value;
    const linage = [...value.linage];
    linage.reverse();
    const [, , ...categories] = linage;
    categories.pop();
    categories.unshift(section);
    const breadcrumbs = categories.map(({name}) => name).join(" / ");

    return (
        <ListItemText
            data-cy="search-category-result"
            primary={name} secondary={t("result-category-name", {name: breadcrumbs})}/>
    )
};

export default CategoryResultItem;
