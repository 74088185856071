import sectionActions from "../../section/SectionActions"
import companyActions from "./company/CompanyActions"
import motorActions from "./motor/MotorActions"

export class MotorSelectionActions {
    private readonly sectionActions = sectionActions;
    private readonly companyActions = companyActions;
    private readonly motorActions = motorActions;

    public async navigateTo(section: string, company?: string): Promise<void> {
        this.selectCompany(company);
        await Promise.all([
            this.sectionActions.select(section),
            this.companyActions.load(section),
            this.loadMotors(company)
        ]);
    }

    private selectCompany(company?: string) {
        if (company) {
            this.companyActions.select(company);
        } else {
            this.companyActions.deselect();
        }
    }

    private loadMotors(company?: string) {
        return company ? this.motorActions.load(company) : Promise.resolve();
    }
}

export default new MotorSelectionActions();
