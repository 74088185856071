import {combineLatest} from "rxjs";
import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";
import {Breadcrumb} from "../../common/breadcrumb/Breadcrumb";
import companyQuery, {CompanyQuery} from "../company/CompanyQuery";
import sectionQuery, {SectionQuery} from "../section/SectionQuery";
import vehicleQuery, {VehicleQuery} from "../vehicle/VehicleQuery";
import vehicleGroupQuery, {VehicleGroupQuery} from "../vehiclegroup/VehicleGroupQuery";

export interface Dependencies {
    sectionQuery: SectionQuery;
    companyQuery: CompanyQuery;
    vehicleQuery: VehicleQuery;
    vehicleGroupQuery: VehicleGroupQuery;
}

export class VehicleSelectionQuery {
    private readonly companyQuery = companyQuery;
    private readonly sectionQuery = sectionQuery;
    private readonly vehicleGroupQuery = vehicleGroupQuery;
    private readonly vehicleQuery = vehicleQuery;
    public readonly error: Observable<Error | null>;
    public readonly breadcrumbs: Observable<Breadcrumb[]>;


    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);

        this.error = combineLatest([
            this.sectionQuery.error,
            this.vehicleGroupQuery.error,
            this.companyQuery.error,
            this.vehicleQuery.error
        ])
            .pipe(map(([
                           sectionError,
                           vehicleGroupError,
                           companyError,
                           vehicleError
                       ]) => sectionError || vehicleGroupError || companyError || vehicleError));

        this.breadcrumbs = combineLatest([
            this.sectionQuery.active,
            this.vehicleGroupQuery.active,
            this.companyQuery.active
        ])
            .pipe(map(([
                           section,
                           vehicleGroup,
                           company
                       ]) => {
                const breadcrumbs: Breadcrumb[] = [];

                if (section) {
                    breadcrumbs.push({
                        text: section.name,
                        to: `/${section.slug}/fahrzeuge/`
                    });

                    if (vehicleGroup) {
                        breadcrumbs.push({
                            text: vehicleGroup.name,
                            to: `/${section.slug}/fahrzeuge/${vehicleGroup.slug}`
                        });

                        if (company) {
                            breadcrumbs.push({
                                text: company.name,
                                to: `/${section.slug}/fahrzeuge/${vehicleGroup.slug}/${company.slug}`
                            });
                        }
                    }
                }

                return breadcrumbs;
            }));
    }
}

export default new VehicleSelectionQuery();
