import {FC, useEffect, useState} from "react";
import {useLocation} from "react-router-dom"
import windowAPI from "./hooks/windowAPI";

const ScrollToTop: FC = () => {
    const {pathname} = useLocation();
    const [prevPath, setPrevPath] = useState();

    useEffect(() => {
        if (prevPath !== pathname) {
            windowAPI.scrollToYForPage(pathname)
            //window.scrollTo(0, 0);
        }
        setPrevPath(prevPath);
    }, [pathname, prevPath]);

    return null;
}

export default ScrollToTop;