import HttpService from "./HttpService";
import {TenantSignupRequest} from "../../pages/signup/TenantSignupRequest";

export class TenantSignupService extends HttpService {
    private readonly path = "/signup/";

    public async create(data: TenantSignupRequest): Promise<void> {
        return this.post(this.path, undefined, data)
    }
}

export default new TenantSignupService();
