import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Reference from "../../model/Reference";
import AppLink from "../theme/AppLink";

export interface MotorsProps {
    motors: Reference[];
}

export const Motors: FC<MotorsProps> = ({motors}) => {
    const {t} = useTranslation();
    const heading = t("item-motors-heading");

    return (
        <Fragment>
            <Typography variant="h5" component="h3">{heading}</Typography>
            <List dense>
                {
                    motors.map(({name, slug}) => (
                        <ListItem key={name}>
                            <AppLink to={`/motor/${slug}/`}>
                                <ListItemText>{name}</ListItemText>
                            </AppLink>
                        </ListItem>
                    ))
                }
            </List>
        </Fragment>
    );
};

export default Motors;    
