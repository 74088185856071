import {Store, StoreConfig} from "@datorama/akita";
import {from, of, Subject, Subscription} from "rxjs";
import {catchError, filter, switchMap, tap} from "rxjs/operators";
import Category, {CategorySlug} from "../../model/Category";
import Item from "../../model/item/Item";
import {Page} from "../../model/user/OrderArchive";
import SaleCategory from "../../sale/model/SaleCategory";
import ItemSaleService from "../../service/ItemSaleService";

export enum SaleType {
    OFFER,
    CLEARANCE
}

export interface SaleState {
    type: SaleType
    error?: any;
    loading: boolean;
    salePage: Page<Item>;
    saleCategories: SaleCategory[];
    categories: Category[];
    categoryFilter: CategorySlug;
}

export const EMPTYPAGE: Page<Item> = {
    content: [],
    numberOfElements: 0,
    number: 0,
    totalElements: 0,
    size: 10,
    totalPages: 0
};


export function createInitialState(): SaleState {
    return {
        type: SaleType.OFFER,
        loading: false,
        salePage: EMPTYPAGE,
        saleCategories: [],
        categories: [],
        categoryFilter: '' as CategorySlug
    };
}

export interface GetPageAction {
    type?: SaleType,
    size?: number;
    offset?: number;
    categoryFilter?: CategorySlug;
}

@StoreConfig({name: "sale"})
export class SaleStore extends Store<SaleState> {

    private saleService = ItemSaleService;

    constructor() {
        super(createInitialState());
    }

    private readonly getSalePageAction$: Subject<GetPageAction> = new Subject<GetPageAction>()

    private readonly getSalePageEffect$ = this.getSalePageAction$.pipe(
        filter(action => action.type === SaleType.OFFER),
        switchMap(action => from(this.saleService.getAllItemsOnSale()).pipe(
            tap(result => this.update({type: action.type, saleCategories: result, loading: false})),
            catchError(error => {
                console.log(error)
                this.update({
                    type: SaleType.OFFER,
                    error: error,
                    saleCategories: [],
                    salePage: EMPTYPAGE,
                    loading: false
                })
                return of();
            })
            )
        )
    )

    private readonly getClearancePageEffect$ = this.getSalePageAction$.pipe(
        filter(action => action.type === SaleType.CLEARANCE),
        switchMap(action => {
                return from(this.saleService.getAllItemsOnClearance()).pipe(
                    tap(result => this.update({type: action.type, saleCategories: result, loading: false})),
                    catchError(error => {
                        console.log(error)
                        this.update({
                            type: SaleType.CLEARANCE,
                            error: error,
                            saleCategories: [],
                            salePage: EMPTYPAGE,
                            loading: false
                        })
                        return of();
                    })
                );
            }
        )
    )

    public activateEffects(): Subscription[] {
        return [
            this.getSalePageEffect$.subscribe(),
            this.getClearancePageEffect$.subscribe()
        ]
    }

    public getSalePage(action: GetPageAction): void {
        const {size, number} = this.getValue().salePage;
        this.update({categoryFilter: action.categoryFilter, salePage: EMPTYPAGE, loading: true});
        this.getSalePageAction$.next({type: this.getValue().type, size, offset: number, ...action});
    }
}

export default new SaleStore();
