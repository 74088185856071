import {transaction} from "@datorama/akita";

import itemStore, {ItemStore} from "./LatestItemsStore";
import itemService, {ItemService} from "../../service/ItemService";

export interface Dependencies {
    store: ItemStore;
    service: ItemService;
}

export class ItemActions {
    private readonly store = itemStore;
    private readonly service: ItemService = itemService;


    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public async setPage(newPage: number) {
        this.store.update({ page: newPage});
        await this.loadLatestItemsForCurrentPage();
    }

    @transaction()
    public async setSize(size: number) {
        this.store.update({ page: 0});
        this.store.update({ size: size});
        await this.loadLatestItemsForCurrentPage();
    }

    public async loadLatestItemsForCurrentPage() {
        this.store.update({
            loading: true,
            error: null
        });
        try {
            const result = await this.service.findLatestItems(this.getCurrentPage(), this.getCurrentSize());
            this.store.set(result.items);
            this.store.update({ totalItemsCount: result.count });
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private getCurrentPage() {
        return this.store.getValue().page;
    }

    private getCurrentSize() {
        return this.store.getValue().size;
    }
}

export default new ItemActions();
