function parseTireDimensionsToLinkParams(tireDimensions: string | null): string {
    if(!tireDimensions) {
        return "";
    }
    const [widthHeight, diameter] = tireDimensions.split('-');
    if(!widthHeight || !diameter) {
        return "";
    }
    const [width, height] = widthHeight.split('/');
    const params = {
        'reifenbreite': width,
        'reifenh-he-in': height,
        'durchmesser-in-zoll': diameter
    };
    if(!height) {
        delete params['reifenh-he-in'];
    }
    return "?" + new URLSearchParams(params).toString();
}

export function getTireSearchLinkFromTireDimensions(tireDimension: string | null): string {
    return '/produkte/k/reifen/' + parseTireDimensionsToLinkParams(tireDimension);
}