import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {TenantProfile} from "../../model/user/Profile";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import ProfileTextField from "./ProfileTextField";
import {isInvalidEmail} from "../../common/validation";

interface TenantProfileFieldsProps {
    profile: TenantProfile;
    isValid: boolean;
    onChange: <T extends keyof TenantProfile>(property: T, value: TenantProfile[T]) => void;
    onSubmit: () => void;
}

export const TenantProfileFields: React.FC<TenantProfileFieldsProps> = props => {
    const {t} = useTranslation();
    const {profile, isValid, onChange, onSubmit} = props;
    return (
        <Grid
            container
            spacing={4}
            component="form"
            onSubmit={(event: any) => {
                event.preventDefault();
                onSubmit();
            }}
        >
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("user-profile-tenant-core-data-legend")}</Typography>
                </Grid>
                <Grid item md={7}>
                    <ProfileTextField
                        property="firstName"
                        data={profile}
                        onChange={onChange}
                    />
                    <ProfileTextField
                        property="lastName"
                        data={profile}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item md={7}>
                    <ProfileTextField
                        property="email"
                        data={profile}
                        type="email"
                        error={isInvalidEmail(profile.email)}
                        helperText={isInvalidEmail(profile.email) ? t("form-email-invalid") : ""}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Grid item md={3}>
                <Button
                    type="submit"
                    variant="contained"
                    color="default"
                    disabled={!isValid}
                    disableElevation>{t("form-action-save")}</Button>
            </Grid>
        </Grid>
    );
}