import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, {FC} from "react";
import LoginDialog from "../app/component/auth/LoginDialog";
import ScrollTop from "../app/component/ScrollTop";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from "@material-ui/core/styles/useTheme";
import LiveChat from "../app/component/chat/LifeChat";

export const SCROLL_CONTAINER = 'main-scroll-container'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ffffff',
        minHeight: "100vh",
        width: '100%',
    },
    container: {
        overflowY: 'scroll',
        position: 'fixed',
        top: theme.spacing(12)*2,
        left: 0,
        height: "100%",
        width: '100%',
    },
    mainLarge: {
        minHeight: "calc(100vh - " + theme.spacing(12)*6 +'px)',
        width: '100%',
        "& > *": {
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(12)*4,
            paddingRight: theme.spacing(12)*4,
        },
    },
    mainSmall: {
        minHeight: "calc(100vh - " + theme.spacing(12)*6 +'px)',
        width: '100%',
        "& > *": {
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
    }
}));

export const Layout: FC = ({children}) => {
    const classes = useStyles(undefined);
    const theme = useTheme();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div className={classes.root} >
            <Header/>
            <Container id={SCROLL_CONTAINER} maxWidth={false} disableGutters={true} className={classes.container}>
                <LoginDialog/>
                <div className={isSmallLayout ? classes.mainSmall : classes.mainLarge}>
                    {children}

                </div>
                <Footer/>
            </Container>

            <LiveChat />
            <ScrollTop>
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon/>
                </Fab>
            </ScrollTop>
        </div>
    );
};

export default Layout;
