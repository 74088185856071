import React, {FC, memo} from "react";
import {useTranslation} from "react-i18next";
import SignupTextField from "./SignupTextField";
import FormSection from "./SignupFormSection";

export const DealershipGroup: FC = () => {
    const {t} = useTranslation();

    const properties = [
        "moped", "motorcycle", "mopedCar", "ecommerce", "farmEquipment", "cycles"
    ];

    return (
        <FormSection legend={t("form-dealership-legend")} includeSeparator={true}>
            {properties.map(property => (
                <SignupTextField
                    key={property}
                    property={property}
                    label={`form-dealership-${property}-label`}
                />
            ))
            }
        </FormSection>
    );
};

export default memo(DealershipGroup);
