import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Company, {CompanySlug} from "../../../model/Company";

export interface CompanyState extends EntityState<Company, CompanySlug>, ActiveState<CompanySlug> {
}

@StoreConfig({
    name: "vehicleselectionshortcut.company",
    idKey: "slug"
})
export class CompanyStore extends EntityStore<CompanyState> {
}

export default new CompanyStore();
