import CircularProgress from "@material-ui/core/CircularProgress";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import AddressForm from "../../app/component/address/AddressForm";
import ContentHeader from "../../app/component/ContentHeader";
import Address from "../../app/model/checkout/Address";
import NotFoundError from "../../app/service/error/NotFoundError";
import addressEditActions from "../../app/state/address/edit/EditAddressActions";
import addressEditQuery from "../../app/state/address/edit/EditAddressQuery";
import NotFoundErrorPage from "../../errors/404";
import InternalServerErrorPage from "../../errors/500";

interface Params {
    id: string;
}

export const AddressEditPage: FC = () => {
    const {id} = useParams<Params>();
    const error = addressEditQuery.useError();
    const address = addressEditQuery.useAddress();
    const isCompanyAddress = addressEditQuery.useIsCompanyAddress();
    const {t} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        addressEditActions.load(id).catch(error => console.log(error));
    }, [id]);

    function update<T extends keyof Address>(property: T, value: Address[T]) {
        addressEditActions.update({[property]: value});
    }

    function save() {
        addressEditActions.save()
            .then(history.goBack)
            .catch(error => console.log(error));
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("address-manage-breadcrumb"),
            to: "/benutzer/adresse/",
        },
        {
            text: t("address-edit-breadcrumb"),
            to: `/benutzer/adresse/${id}/edit`
        }
    ];

    if (error instanceof NotFoundError) {
        return (<NotFoundErrorPage/>);
    } else if (error) {
        return (<InternalServerErrorPage/>);
    } else if (address) {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("address-edit-title")}></ContentHeader>
                <AddressForm
                    address={address}
                    onSubmit={save}
                    onChange={update}
                    isCompanyAddress={isCompanyAddress}
                />
            </Fragment>
        );
    } else {
        return (<CircularProgress/>);
    }
};

export default AddressEditPage;
