import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import CategoryAttribute from "../../../../../model/CategoryAttribute";

export type Entity = CategoryAttribute;

export type ID = string;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    category: string;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        category: ""
    };
}

@StoreConfig({
    name: "filter.attribute.category",
    idKey: "slug"
})
export class CategoryAttributeStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new CategoryAttributeStore();
