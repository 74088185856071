import React, {FC} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Currency from "../component/Currency";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface SummaryRowProps extends RowProps{
    isSub?: boolean
    isSum?: boolean
    showBottomSeparator?: boolean
}

const useStyles = makeStyles(theme => ({
    warningLabel: {
        color: theme.palette.error.light
    },
    sum: {
        "& > *": {
            fontWeight: 'bold',
        },
    },
    value: {
        color: theme.palette.primary.main
    },
    leftPaddingCellsNoSeparator: {
        border: 0
    },
    leftPaddingCellsWithSeparator: {
        borderTop: 0
    }
}));

export const SummaryRow: FC<SummaryRowProps> = ({ cypressLabel, label, value, showBottomSeparator, warningText, isSum, isSub}) => {
    const classes = useStyles();

    return <TableRow className={isSum ? classes.sum : ''}>
        <TableCell
            className={showBottomSeparator ? classes.leftPaddingCellsWithSeparator : classes.leftPaddingCellsNoSeparator}
            align={"left"} colSpan={2}>
            {warningText ? <span data-cy={cypressLabel + '-label'} className={classes.warningLabel}>{warningText}</span> : null}
        </TableCell>
        <TableCell colSpan={3} align="right">{label}</TableCell>
        <TableCell align="right" className={classes.value} data-cy={cypressLabel + '-value'}>
            {!isSum ? (isSub ? '- ' : '+ ') : ''}<Currency value={value}/>
        </TableCell>
        <TableCell>
        </TableCell>
    </TableRow>
}

interface RowProps {
    cypressLabel?: string
    warningText?: string;
    label: string;
    value: number;
}

export const SubtractingRow: FC<RowProps> = (props) => {
    return <SummaryRow {...props} isSum={false} isSub={true} />
}

export const AddingRow: FC<RowProps> = (props) => {
    return <SummaryRow {...props} isSum={false} isSub={false} />
}

export const SumRow: FC<RowProps & {showBottomSeparator?: boolean}> = (props) => {
    return <SummaryRow {...props} isSum={true} />
}