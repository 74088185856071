import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Reference from "../../model/Reference";
import categoryQuery from "../../state/category/CategoryQuery";
import sectionQuery from "../../state/section/SectionQuery";
import vehicleQuery from "../../state/vehicle/VehicleQuery";
import ListItemLink from "../ListItemLink";

export const ProductCategoryList: FC = () => {
    const currentSectionSlug = sectionQuery.useActiveId();
    const currentVehicleSlug = vehicleQuery.useActiveId();
    const currentCategorySlug = categoryQuery.useActiveId();
    const categories = categoryQuery.useAll();
    const loading = categoryQuery.useIsLoading();
    const path = `/${currentSectionSlug}/fahrzeug/${currentVehicleSlug}`;
    const {t} = useTranslation();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <ProductCategoryListPure
                heading={t("vehicle-matching-product-categories")}
                activeSlug={currentCategorySlug}
                path={path}
                categories={categories}
            />
        );
    }
};

export default ProductCategoryList;

export interface ProductCategoryListProps {
    activeSlug?: string | null;
    path: string;
    categories: Reference[];
    hashLinks?: boolean;
    heading: string;
}

export const ProductCategoryListPure: FC<ProductCategoryListProps> = (props) => {
    const {
        activeSlug,
        categories,
        path
    } = props;

    return (

        <Fragment>
            <Typography variant="h6" component="h2">{props.heading}</Typography>
            <List>
                {categories.map(({name, slug}) => (
                    <ListItemLink
                        hashLink={!!props.hashLinks}
                        data-cy="vehicle-product-category-title"
                        key={slug}
                        primary={name}
                        path={props.hashLinks ? `${path}${slug}` : `${path}/${slug}/`}
                        disabled={slug === activeSlug}
                    />
                ))}
            </List>
        </Fragment>
    );
};
