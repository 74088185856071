import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppLink from "./theme/AppLink";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "200px",
        borderBottom: "3px solid #2bb6b7",
        position: "relative",
        textAlign: "center",
        "&:hover": {
            "& $image": {
                opacity: 0.50,
            },
            "& $middle": {
                opacity: 1,
            }
        }
    },
    image: {
        opacity: 0.3,
        display: "block",
        width: "100%",
        height: "150px",
        transition: ".5s ease",
        backfaceVisibility: "hidden",
    },
    imageSrc: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "10% 25%",
        width: "100%",
        height: "100%"
    },
    middle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    title: {
        backgroundColor: "transparent",
        color: "black",
        fontWeight: "bold",
        fontSize: "16px",
        padding: "16px 32px",
        textTransform: "uppercase"
    }
}));

export interface ImageLinkProps {
    path: string
    imageUrl: string
    name: string
}

export const ImageLink: FC<ImageLinkProps> = ({path, imageUrl, name}) => {
    const classes = useStyles(undefined);
    return <Paper className={classes.root}>
        <AppLink to={path}>
            <div className={classes.image}
            >
                <div
                    data-cy={'link-image'}
                    className={classes.imageSrc}
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                    }}
                />
            </div>
            <div className={classes.middle}>
                <Typography data-cy="image-link-title" className={classes.title}
                            variant="subtitle1">{name}</Typography>
            </div>

        </AppLink>
    </Paper>;
};