import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, Redirect} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import Backorders from "../app/component/checkout/Backorders";
import OrderCart from "../app/component/checkout/OrderCart";
import ContentHeader from "../app/component/ContentHeader";
import AppLink from "../app/component/theme/AppLink";
import ConfirmationCart from "../app/model/shoppingCart/ConfirmationCart";
import NotFoundError from "../app/service/error/NotFoundError";
import shoppingCartService, {CheckoutRequest} from "../app/service/ShoppingCartService";
import PageInternalServerError from "../errors/500";
import {loadStripe} from '@stripe/stripe-js';
import {StripePublishableApiKey} from "../app/config/Config";

const useStyles = makeStyles((theme) => ({
    grid: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    checkBoxText: {
        // maxWidth: "50%",
        paddingLeft: theme.spacing(3),
        fontStyle: "italic"
    },
    button: {
        paddingLeft: theme.spacing(2)
    },
    appLinkText: {
        color: theme.palette.secondary.dark
    },
    notesText: {
        fontStyle: "italic"
    },
    buttonProgress: {
        position: 'absolute'
    },
    circularProgress: {
        marginLeft: "50%",
        marginTop: theme.spacing(5)
    }
}));

export const CheckoutConfirmation: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const breadcrumbs: Breadcrumb[] = [];
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [comment, setComment] = useState("");
    const handleClick = () => setTermsAccepted(!termsAccepted);
    const [shopOrderSummary, setShopOrderSummary] = useState<ConfirmationCart>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        shoppingCartService.updateShippingDetails()
            .then((summary) => {
                setShopOrderSummary(summary);
                setTermsAccepted(summary.termsAccepted);
            })
            .catch(setError)
        ;
    }, []);

    breadcrumbs.push(
        {
            text: t("shopping-cart-breadcrumb-text"),
            to: "/shop/warenkorb.html"
        },
        {
            text: t("checkout-confirmation-breadcrumb-text"),
            to: "/shop/bestaetigen.html"
        }
    );

    const handleStripePayment = async (sessionId: string) => {
        const stripe = await loadStripe(StripePublishableApiKey);
        if (!stripe) {
            const message = 'Could not load stripe!';
            console.error(message);
            throw new Error(message);
        }
        const error = await stripe.redirectToCheckout({
            sessionId
        });
        if (error) {
            const message = 'Error redirecting to checkout';
            console.error(message, error);
            throw new Error(message);
        }
    }

    const onClickOrder = () => {
        setLoading(true);
        const successUrl: string = window.location.origin + "/shop/abgeschlossen.html";
        const abortUrl: string = window.location.origin + "/shop/abgebrochen.html";
        const checkoutRequest: CheckoutRequest = {comment, successUrl, abortUrl};

        shoppingCartService.checkout(checkoutRequest)
            .then(checkoutCart => {
                if (checkoutCart.stripeSessionId) {
                    handleStripePayment(checkoutCart.stripeSessionId).then();
                } else {
                    window.open(checkoutCart.redirectUrl, "_self")
                }
            })
            .catch(setError)
            .then(() => setLoading(false));
    }

    if (error instanceof NotFoundError) {
        return (<Redirect to="/shop/warenkorb.html"/>);
    } else if (loading) {
        return (<CircularProgress className={classes.circularProgress}/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("checkout-confirmation-heading")}></ContentHeader>
                {shopOrderSummary &&
                <OrderCart confirmationCart={shopOrderSummary}/>
                }
                {shopOrderSummary && (shopOrderSummary.unavailableItems.length > 0) &&
                <Backorders immediatePayment={shopOrderSummary.immediatePayment}
                            items={shopOrderSummary.unavailableItems}/>
                }
                <Paper className={classes.paper} variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <input data-cy="confirmation-checkbox" onChange={handleClick} checked={termsAccepted}
                                   type="checkbox"
                                   id="gtc-checkbox"
                                   required
                            />
                            <label htmlFor="gtc-checkbox" className={classes.checkBoxText}
                                   data-cy="confirmation-checkbox-text"
                            > {t("checkout-confirmation-gtc-text1")}
                                <AppLink to="/agb.html"
                                         className={classes.appLinkText}> {t("checkout-confirmation-gtc-textLink")}</AppLink>
                                {t("checkout-confirmation-gtc-text2")}</label>
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label="Anmerkungen zu Ihrer Bestellung"
                                multiline
                                fullWidth
                                rows={4}
                                onChange={e => setComment(e.target.value)}
                                value={comment}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <Box display="flex">
                    <Grid item md={2} sm={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            disableElevation
                            onClick={() => window.print()}
                        >
                            {t("print-order-button-text")}
                        </Button>
                    </Grid>
                    <Grid item md sm={false}/>
                    <Grid item md={2} sm={12}>
                        <Button data-cy="confirmation-back-button" variant="contained" color="default" fullWidth
                                disableElevation
                                component={RouterLink}
                                to="/shop/versand.html"
                        >
                            {t("back")}
                        </Button>
                    </Grid>
                    <Grid item md={4} sm={12} className={classes.button}>
                        {shopOrderSummary &&
                        <Button data-cy="confirmation-order-button" variant="contained" color="secondary" fullWidth
                                disableElevation disabled={!termsAccepted}
                                onClick={onClickOrder}>
                            {shopOrderSummary.immediatePayment ? t("checkout-confirmation-order-and-pay-button") : t("checkout-confirmation-order-button")}
                        </Button>}
                    </Grid>
                </Box>
            </Fragment>
        );
    }
};
export default CheckoutConfirmation;
