import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import itemQuery from "../../state/returns/items/ItemQuery";
import {isInvalidEmail} from "../../common/validation";
import Grid from "@material-ui/core/Grid";
import itemActions from "../../state/returns/items/ItemActions";
import TextField from "@material-ui/core/TextField";
import EmailField from "../form/EmailField";
import Button from "@material-ui/core/Button";

export const ReturnForm: FC = () => {
    const {t} = useTranslation();
    const hasItems = itemQuery.useAll().length;
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [pristine, setPristine] = useState(true);
    const invalid = !contact || isInvalidEmail(email);

    if (!hasItems) {
        return null;
    }

    return (
        <Grid container component="form"
              onSubmit={(event: any) => {
                  event.preventDefault();
                  itemActions.submitForm(contact, email);
              }}
        >
            <Grid item md={4}>
                <TextField
                    fullWidth
                    label={t("returns-contact-label")}
                    required
                    name="contact"
                    value={contact}
                    onChange={(event: any) => setContact(event.target.value)}
                />
            </Grid>
            <Grid item md={4}>
                <EmailField
                    fullWidth
                    required
                    label={t("returns-email-label")}
                    name="email"
                    value={email}
                    pristine={pristine}
                    onChange={(event: any) => {
                        setEmail(event.target.value);
                        setPristine(false);
                    }}
                />
            </Grid>
            <Grid item md={4}>
                <Button
                    type="submit"
                    fullWidth
                    disabled={invalid}
                    variant="contained"
                    color="primary"
                    children={t("returns-submit-form-button")}
                />
            </Grid>
        </Grid>

    );
}

export default ReturnForm;