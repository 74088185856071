import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Vehicle from "../../../model/Vehicle";

export interface VehiclePopoverProps {
    vehicle: Vehicle | null;
    anchorElement: HTMLElement | null;
}

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: "none"
    },
    paper: {
        padding: theme.spacing(1)

    }
}));

const VehiclePopover: FC<VehiclePopoverProps> = ({ vehicle, anchorElement }) => {
    const classes = useStyles(undefined);
    const { t } = useTranslation();
    const { chassisNumber, engineNumber } = vehicle || { chassisNumber: "", engineNumber: "" };

    const open = Boolean(anchorElement);
    if (chassisNumber || engineNumber) {

        return (
            <Popper
                id="mouse-over-popover"
                className={classes.popover}
                open={open}
                anchorEl={anchorElement}
                placement="left"
            >
                <Paper className={classes.paper}>
                    {
                        chassisNumber &&
                        <Typography data-cy="vehicle-popover-chassis-number"
                                    variant="body1">{t("vehicle-popover-chassis-number", { chassisNumber })}</Typography>
                    }
                    {
                        engineNumber &&
                        <Typography data-cy="vehicle-popover-engine-number"
                                    variant="body1">{t("vehicle-popover-engine-number", { engineNumber })}</Typography>
                    }
                </Paper>
            </Popper>
        );

    }
    return null;
};

export default VehiclePopover;
