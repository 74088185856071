import AuthorizedHttpService from "../AuthorizedHttpService";

export interface ReturnFile {
    id: number;
    name: string;
    addedAt: Date;
}

export interface AddReturnFileRequest {
    name: string;
    contentBase64: string;
}

export class ReturnFilesService extends AuthorizedHttpService {

    public async addFile(request: AddReturnFileRequest): Promise<void> {
        return this.post("/returns/files", undefined, request);
    }

    public async findCurrent(): Promise<ReturnFile[]> {
       return this.get("/returns/files");
    }

    async removeFile(id: number) {
        return this.delete(`/returns/files/${id}`);
    }
}

export default new ReturnFilesService();