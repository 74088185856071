import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {Autocomplete} from "@material-ui/lab";
import React, {FC, Fragment, RefObject, useRef, KeyboardEvent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import getVehiclePath from "../../common/route/getVehiclePath";
import useHistoryPushNew from "../../common/useHistoryPushNew";
import {GridDefaultSpacing} from "../../config/Config";
import Company, {CompanySlug} from "../../model/Company";
import Vehicle from "../../model/Vehicle";
import VehicleGroup, {VehicleGroupSlug} from "../../model/VehicleGroup";
import companyQuery from "../../state/company/CompanyQuery";
import selectionCompanyQuery from "../../state/vehicleselectionshortcut/company/CompanyQuery";
import shortcutActions from "../../state/vehicleselectionshortcut/ShortcutActions";
import vehicleQuery from "../../state/vehicleselectionshortcut/vehicle/VehicleQuery";
import vehicleGroupQuery from "../../state/vehicleselectionshortcut/vehiclegroup/VehicleGroupQuery";

export const VehicleSelectionShortcut: FC<{direction?: 'row' | 'column'}> = ({direction}) => {
    const loading = companyQuery.useIsLoading();
    const vehicleGroups = vehicleGroupQuery.useAll();
    const selectedVehicleGroup = vehicleGroupQuery.useCurrent();
    const companies = selectionCompanyQuery.useAll();
    const selectedCompany = selectionCompanyQuery.useCurrent();
    const vehicles = vehicleQuery.useAll();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <VehicleSelectionShortcutPure
                direction={direction}
                vehicleGroups={vehicleGroups}
                selectedVehicleGroup={selectedVehicleGroup}
                companies={companies}
                selectedCompany={selectedCompany}
                vehicles={vehicles}
            />
        );
    }
};

export default VehicleSelectionShortcut;

export interface VehicleSelectionProps {
    vehicleGroups: VehicleGroup[];
    selectedVehicleGroup?: VehicleGroup;
    companies: Company[];
    selectedCompany?: Company;
    vehicles: Vehicle[];
    direction?: 'row' | 'column'
}

export const VehicleSelectionShortcutPure: FC<VehicleSelectionProps> = ({
                                                                            vehicleGroups,
                                                                            selectedVehicleGroup,
                                                                            companies,
                                                                            selectedCompany,
                                                                            vehicles,
                                                                            direction,
                                                                            ...props
                                                                        }) => {
    const {t} = useTranslation();
    const historyPush = useHistoryPushNew();

    useEffect(() => {
        shortcutActions.changeCompany("" as CompanySlug)
    }, [])

    function updateVehicleGroup(value?: VehicleGroupSlug) {
        shortcutActions.changeGroup(value);
    }

    function updateCompany(value?: CompanySlug) {
        shortcutActions.changeCompany(value);
    }

    function jumpToVehiclePage(value?: Vehicle | null) {
        if (value && value.vehicleGroup.section) {
            const path = getVehiclePath(value.vehicleGroup.section.slug, value.slug);
            historyPush(path)
        }
    }

    const type = useRef(null);
    const manufacturer = useRef(null);
    const vehicle = useRef(null);

    const setCursorPosition = (ref: RefObject<any>, event: KeyboardEvent, position: number) => {
        event.preventDefault();
        event.stopPropagation();
        // @ts-ignore
        ref.current.selectionStart = ref.current.selectionEnd = position
    }

    const findNextSpaceIndex = (position: number, value: string) => {
        const nextSpace = value.indexOf(' ', position+1);
        return nextSpace === -1 ? value.length : nextSpace;
    }

    const findPrevSpaceIndex = (position: number, value: string) => {
        const nextSpace = value.split('').reverse().join('').indexOf(' ', value.length - position + 1);
        return nextSpace === -1 ? 0 : value.length - nextSpace;
    }

    const overrideNavigationButtons = (ref: RefObject<any>) => {
        return (event: any) => {
            if (event.key === 'Home') {
                setCursorPosition(ref, event, 0);
            }
            if (event.key === 'End') {
                setCursorPosition(ref, event, ref.current.value.length);
            }
            if (event.key === 'PageUp') {
                setCursorPosition(ref, event, findNextSpaceIndex(ref.current.selectionStart, event.target.value));
            }
            if (event.key === 'PageDown') {
                setCursorPosition(ref, event, findPrevSpaceIndex(ref.current.selectionStart, event.target.value));
            }
        }
    }

    return (<Fragment>
        <Typography variant="h5" component="h2" gutterBottom>{t("header-vehicle-quickfilter")}</Typography>
        <Grid container spacing={GridDefaultSpacing} direction={direction ?? 'column'} wrap={'wrap'} justify={'space-evenly'}>
            <Grid item xs={12} lg={direction === 'row' ? 4 : 12}>
                <Autocomplete
                    {...props}
                    autoHighlight={false}
                    autoSelect={false}
                    data-cy="shortcut-group"
                    value={selectedVehicleGroup || null}
                    options={vehicleGroups}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField inputRef={type}
                                                      onKeyDown={overrideNavigationButtons(type)}
                                                      {...params}
                                                      label={t("vehicle-selection-shortcut-type")}
                                                      variant="outlined"
                                                      fullWidth
                    />}
                    onChange={(event, value) => {
                        updateVehicleGroup(value?.slug);
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={direction === 'row' ? 4 : 12}>
                <Autocomplete
                    {...props}
                    data-cy="shortcut-company"
                    value={selectedCompany || null}
                    options={companies}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params}
                                                      inputRef={manufacturer}
                                                      onKeyDown={overrideNavigationButtons(manufacturer)}
                                                      label={t("vehicle-selection-shortcut-company")}
                                                      variant="outlined"
                                                      fullWidth

                    />}
                    onChange={(event, value) => {
                        updateCompany(value?.slug);
                    }}
                />
            </Grid>
            <Grid item  xs={12} lg={direction === 'row' ? 4 : 12}>
                <Autocomplete
                    {...props}
                    data-cy="shortcut-vehicle"
                    options={vehicles}
                    PopperComponent={FullWidthPopper}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params}
                                                      inputRef={vehicle}
                                                      onKeyDown={overrideNavigationButtons(vehicle)}
                                                      label={t("vehicle-selection-shortcut-vehicle")}
                                                      variant="outlined"
                                                      fullWidth
                    />}
                    onChange={(event, value) => {
                        jumpToVehiclePage(value);
                    }}
                />
            </Grid>
        </Grid>
    </Fragment>);
};

const FullWidthPopper: FC<any> = (props) => {
    return (<Popper {...props} style={{width: undefined}} placement="bottom-start"/>)
}