import {List} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {useObservable} from "../../common/useObservable";
import Reference from "../../model/Reference";
import subCategoriesQuery from "../../state/categoryPage/subcategory/SubCategoriesQuery";
import sectionQuery from "../../state/section/SectionQuery";
import ListItemLink from "../ListItemLink";

export const SubCategoryList: FC = () => {
    const categories = subCategoriesQuery.useAllSubCategories();
    const loading = useObservable(subCategoriesQuery.isLoading, false);
    const section = sectionQuery.useActiveId() || "" as any;

    if (loading) {
        return (<CircularProgress/>);
    } else if (categories.length) {
        return (
            <SubCategoryListPure
                section={section}
                categories={categories}
            />
        );
    } else {
        return null;
    }

};

export default SubCategoryList;

export interface SubCategoryListProps {
    section: string;
    categories: Reference[];
}

export const SubCategoryListPure: FC<SubCategoryListProps> = ({ categories, section }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Typography variant="h6">{t("subcategory-title")}</Typography>
            <List>
                {categories.map(({ name, slug }) => (
                    <ListItemLink
                        data-cy="sub-category-title"
                        key={slug}
                        disabled={false}
                        primary={name}
                        filled={true}
                        path={`/${section}/produkte/${slug}/`}
                    />
                ))}
            </List>
        </Grid>
    );
};

