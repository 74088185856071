import {Query} from "@datorama/akita";
import {Observable} from "rxjs";
import {distinctUntilChanged, throttleTime} from "rxjs/operators";
import {DefaultLoadingIndicatorThrottleTimeoutInMilliSeconds} from "../config/Config";

export function throttledLoading<T>(query: Query<T>): Observable<boolean> {
    return (
        query
            .selectLoading()
            .pipe(throttleTime(DefaultLoadingIndicatorThrottleTimeoutInMilliSeconds, undefined, {
                leading: false,
                trailing: true
            }))
            .pipe(distinctUntilChanged())
    );
}
