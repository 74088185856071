import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import useHistoryPushNew from "../common/useHistoryPushNew";
import {ShopAvailability} from "../model/shopprice/ShopAvailability";
import ItemSlug from "../model/item/ItemSlug";
import shoppingCartActions from "../state/shoppingCart/ShoppingCartActions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import getItemPath from "../common/route/getItemPath";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Currency from "../component/Currency";
import NumberField from "../component/form/NumberField";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Item from "../model/item/Item";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ShopPrice from "../model/shopprice/ShopPrice";

const useStyles = makeStyles(theme => ({
    image: {
        minWidth: theme.spacing(14),
        cursor: "pointer"
    },
    box: {
        minWidth: theme.spacing(54),
    },
    item: {
        cursor: "pointer"
    },
    discounted: {
        textDecoration: "line-through"
    },
    notAvailable: {
        color: theme.palette.text.disabled
    },
    deleteIcon: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    }
}));

interface ShoppingCartRowProps {
    item: Item;
    quantity: number;
    comment: string;
}

export const ShoppingCartRow: FC<ShoppingCartRowProps> = ({item, quantity, comment}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();
    const {price} = item;
    const offerPrice = price ? getItemPrice(price, quantity) : 0;
    const isNotAvailable = price && (price.shopAvailability !== ShopAvailability.IN_STOCK);

    function onChangingQuantity(slug: ItemSlug, quantity: number) {
        shoppingCartActions.setQuantity(slug, quantity).catch(error => console.log(error));
    }

    function onChangeComment(slug: ItemSlug, comment: string) {
        shoppingCartActions.setComment(slug, comment).catch(error => console.log(error));
    }

    function onClickRemoveFromShoppingCart(slug: ItemSlug) {
        shoppingCartActions.removeItem(slug).catch(error => console.log(error));
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           data-cy="shopping-cart-image" className={classes.image}>
                    {
                        item.images && item.images.length > 0 &&
                        <img src={item.images[0].locationSmall}
                             alt={item.images[0].description}/>
                    }
                </TableCell>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           className={classes.item} data-cy="shopping-cart-text">
                    <Box fontWeight="fontWeightBold" fontSize="h6.fontSize"
                         className={classes.box}>
                        {item.company && item.company.name + " "}
                        <Typography color="secondary" component="span"
                                    variant="inherit"
                                    data-cy="shopping-cart-item-number"
                                    onClick={() => push(getItemPath(item.slug))}>{item.itemNumber}</Typography>
                    </Box>
                    {item.name}
                </TableCell>
                <TableCell data-cy="shopping-cart-availability">
                    {price &&
                    <Typography color="primary" component="p"
                                variant="inherit"
                                data-cy="shopping-cart-item-availability">{t(`order-enum-shop-availability-${price.shopAvailability}`)}</Typography>
                    }
                </TableCell>
                <TableCell className={classes.price} data-cy="shopping-cart-price">
                    {price &&
                    <Fragment>
                        {offerPrice !== price.wholesalePrice &&
                        <Typography className={classes.discounted} variant="subtitle2" component="div">
                            <Currency value={price.wholesalePrice}/>
                        </Typography>
                        }
                        <Typography variant="subtitle2" component="div">
                            <Currency value={offerPrice}/>
                        </Typography>
                        {(price.volumeDiscounts || [])
                            .map(({price, quantity}) =>
                                <Typography key={quantity} variant="subtitle2" component="div">
                                    {t("item-price-volume-discount", {quantity})} <Currency value={price}/>
                                </Typography>)
                        }
                        {
                            price.rebatedPrice ? <Typography key={quantity} variant="subtitle2" component="div">
                                {t("Durch persönlichen Rabatt")} <Currency value={price.rebatedPrice}/>
                            </Typography> : null
                        }

                    </Fragment>
                    }
                </TableCell>
                <TableCell data-cy="shopping-cart-amount">
                    <NumberField
                        label={t("shopping-cart-amount")}
                        value={quantity}
                        onChange={(value: number) => onChangingQuantity(item.slug, value)}
                        inputProps={{min: "0"}}
                        style={{width: 50}}
                    />
                </TableCell>
                <TableCell className={classes.price} data-cy="shopping-cart-wholePrice"
                           align="right">
                    {price &&
                    <Typography variant="subtitle2" component="div"
                                className={isNotAvailable ? classes.notAvailable : undefined}>
                        <Currency
                            value={offerPrice * quantity}/>
                    </Typography>
                    }
                </TableCell>
                <TableCell data-cy="shopping-cart-delete-icon">
                    <DeleteIcon onClick={() => onClickRemoveFromShoppingCart(item.slug)}
                                className={classes.deleteIcon}/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2}/>
                <TableCell colSpan={4}>
                    <TextField
                        label={t("shopping-cart-comment")}
                        size="small"
                        fullWidth
                        onChange={(event) => onChangeComment(item.slug, event.target.value)}
                        value={comment}
                    />
                </TableCell>
                <TableCell/>
            </TableRow>
        </Fragment>
    );
}

export default ShoppingCartRow;

export function getItemPrice(price: ShopPrice, quantity: number) {
    const discounts = (price.volumeDiscounts || []);
    const special = discounts.filter((special) => quantity >= special.quantity).map(({price}) => price)[0];
    return price.rebatedPrice || special || price.offer || price.clearance || price.wholesalePrice;
}