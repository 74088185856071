import {Fade} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import RoleName from "../../app/model/auth/RoleName";
import authActions from "../../app/state/auth/AuthActions";
import authQuery from "../../app/state/auth/AuthQuery";

export const AccountAction: FC = () => {
    const {t} = useTranslation();
    const isLoggedIn = authQuery.useIsLoggedIn();
    const showAdminMenu = authQuery.useHasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_STAFF]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!isLoggedIn) {
            authActions.openLoginDialog();
        } else {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            handleClose();
        }
    }

    function handleLogout() {
        authActions.logout();
        handleClose();
    }

    return (
        <Fragment>
            <IconButton
                style={{color: 'white'}}
                color="inherit"
                onClick={handleClick}
                data-cy={"account-button"}
            >
                <AccountCircle/>
            </IconButton>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                role={undefined}
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Fade {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList onKeyDown={handleListKeyDown}>
                                    {showAdminMenu &&
                                    <MenuItem
                                        component={Link}
                                        href="/admin/"
                                        children={t("account-action-admin")}
                                    />
                                    }
                                    <MenuItem
                                        onClick={handleClose}
                                        component={(props) => <Link component={RouterLink}
                                                                    to="/benutzer/index.html" {...props} />}
                                        children={t("account-action-account")}
                                    />
                                    <MenuItem onClick={handleLogout}>{t("account-action-logout")}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Fragment>
    );
};

export default AccountAction;
