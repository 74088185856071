import TextField from "@material-ui/core/TextField";
import React, {memo, useMemo} from "react";
import {useTranslation} from "react-i18next";
import signupActions from "../../state/signup/SignupActions";
import signupQuery from "../../state/signup/SignupQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: "0.75vw",
        marginBottom: "0.75vw",
        fontSize: "0.875rem",
        "&::placeholder": {
            color: theme.palette.secondary.main
        }
    },
    label: {
        color: theme.palette.secondary.main
    }
}));

export interface SignupTextFieldProps {
    property: string;
    initialValue?: string;
    label: string;
    required?: boolean;
    multiline?: boolean;
    type?: string;
    error?: boolean;
    helperText?: string;
    rows?: number;
    maxLength?: number;
}

const SignupTextField: React.FC<SignupTextFieldProps> = ({initialValue, maxLength, property, label, ...props}) => {
    const {t} = useTranslation();
    const classes = useStyles(props);
    const value = signupQuery.useValue(property as any) || "";

    const transform = useMemo(() => {
        if (maxLength) {
            return (value: string) => (value || "").substr(0, maxLength)
        } else {
            return (value: string) => value
        }
    }, [maxLength])

    const onChange = (event: any) => {
        signupActions.update(property as any, transform(event.target.value));
    }

    return (
        <TextField
            {...props}
            fullWidth
            label={t(label)}
            name={`${property}`}
            value={value}
            onChange={onChange}
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.textField}
            InputLabelProps={{className: classes.label}}
        />
    );
}

export default memo(SignupTextField);
