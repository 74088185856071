import ExplodedView from "../model/ExplodedView";
import Reference from "../model/Reference";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class ExplodedViewService extends AuthorizedHttpService {
    public async findAll(): Promise<Reference[]> {
        return this.get("/exploded-views/");
    }

    public async findBySlug(slug: string): Promise<ExplodedView> {
        return this.get(`/exploded-views/${slug}`);
    }
}

export default new ExplodedViewService();
