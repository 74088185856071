import {Store, StoreConfig} from "@datorama/akita";
import {Order, OrderMetadata, Page} from "../../model/user/OrderArchive";
import {from, of, Subject, Subscription} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators"
import orderArchiveService from "../../service/OrderArchiveService"

export interface OderArchiveState {
    error?: any;
    loading: boolean;
    page: Page<OrderMetadata>;
    current?: Order;
}

export const EMPTYPAGE: Page<OrderMetadata> = {
    content: [],
    numberOfElements: 0,
    number: 0,
    totalElements: 0,
    size: 10,
    totalPages: 0
};

export function createInitialState(): OderArchiveState {
    return {
        loading: false,
        page: EMPTYPAGE,
    };
}

interface GetPageAction {
    size?: number;
    offset?: number;
}

interface GetOrderAction {
    transactionId: string
}

@StoreConfig({name: "orderArchive"})
export class OrderArchiveStore extends Store<OderArchiveState> {

    private orderArchiveService = orderArchiveService;

    constructor() {
        super(createInitialState());
    }

    private readonly getPageAction$: Subject<GetPageAction> = new Subject<GetPageAction>()
    private readonly getPageEffect$ = this.getPageAction$.pipe(
        switchMap(action => from(this.orderArchiveService.getPage(action.offset, action.size)).pipe(
            tap(result => this.update({page: result, loading: false})),
            catchError(error => {
                console.log(error)
                this.update({error: error, page: createInitialState().page, loading: false})
                return of();
            })
            )
        )
    )

    private readonly getOrderDetailsAction$: Subject<GetOrderAction> = new Subject<GetOrderAction>()
    private readonly getOrderDetailsEffect$ = this.getOrderDetailsAction$.pipe(
        switchMap(action => from(this.orderArchiveService.getOrder(action.transactionId)).pipe(
            tap(response => this.update({loading: false, current: response})),
            catchError(error => {
                console.log(error)
                this.update({error: error, current: undefined, loading: false})
                return of();
            })
            )
        )
    )

    public activateOrderDetailEffects(): Subscription[] {
        return [
            this.getOrderDetailsEffect$.subscribe(),
        ]
    }

    public activatePageEffects(): Subscription[] {
        return [
            this.getPageEffect$.subscribe()
        ]
    }

    public getPage(action: GetPageAction): void {
        const {size, number} = this.getValue().page;
        this.getPageAction$.next({ size, offset: number, ...action })
    }

    public getOrder(transactionId: string): void {
        this.getOrderDetailsAction$.next({transactionId})
    }
}

export default new OrderArchiveStore();
