import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, Redirect} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import getItemPath from "../../app/common/route/getItemPath";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";
import ContentHeader from "../../app/component/ContentHeader";
import Currency from "../../app/component/Currency";
import NumberField from "../../app/component/form/NumberField";
import ItemSlug from "../../app/model/item/ItemSlug";
import PreorderCartItem from "../../app/model/preorder/PreorderCartItem";
import {PreorderPrice} from "../../app/model/preorder/PreorderPrice";
import NotFoundError from "../../app/service/error/NotFoundError";
import cartActions from "../../app/state/preorder/cart/CartActions";
import cartQuery from "../../app/state/preorder/cart/CartQuery";
import PageInternalServerError from "../../errors/500";
import {AddingRow, SubtractingRow, SumRow} from "../../app/shoppingcart/SummaryRow";

export const CartPage: FC = () => {
    const {t} = useTranslation();
    const error = cartQuery.useError();
    const classes = useStyles(undefined);
    const isEmpty = cartQuery.useIsEmpty();
    const items = cartQuery.useAll();
    const netTotal = cartQuery.useNetTotal();
    const rebatedTotal = cartQuery.useRebatedTotal();
    const systemRebate = cartQuery.useSystemRebate();
    const vatPrice = cartQuery.useVatPrice();
    const totalPrice = cartQuery.useTotalPrice();
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("preorder-code-page-title"),
            to: "/vor-teile-wochen/code.html"
        }
    ];
    useEffect(() => {
        cartActions.load();
    }, [])

    if (error instanceof NotFoundError) {
        return (<Redirect to="/vor-teile-wochen/code.html"/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("preorder-cart-page-title")}></ContentHeader>
            <Grid container>
                {isEmpty &&
                <Grid item xs={12}>
                    <span>{t("shopping-cart-empty-text")}</span>
                </Grid>
                }
                {!isEmpty &&
                <Fragment>
                    <Grid item xs={12}>
                        <Note/>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                    <TableRow className={classes.header}>
                                        <TableCell colSpan={2}>{t("shopping-cart-header-cell-product")}</TableCell>
                                        <TableCell>{t("shopping-cart-header-cell-unit-price")}</TableCell>
                                        <TableCell>{t("shopping-cart-header-cell-amount")}</TableCell>
                                        <TableCell
                                            align="right">{t("shopping-cart-header-cell-total-price")}</TableCell>
                                        <TableCell/>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    {
                                        items.map(item => (
                                            <ShoppingCartRow
                                                key={item.slug}
                                                item={item}
                                            />
                                        ))
                                    }


                                    <SumRow label={t("shopping-cart-subtotal")}  value={netTotal} />


                                    {systemRebate?.discount ?
                                        <Fragment>
                                            <SubtractingRow label={t("shopping-cart-systemRebate", {discount: systemRebate.discount})} value={systemRebate.value} />
                                            <SumRow cypressLabel={'order-cart-rebated-total'} label={t("rebated-total")} value={rebatedTotal} />
                                        </Fragment> : null
                                    }


                                    { !!vatPrice ?
                                        <AddingRow cypressLabel={'order-cart-vat'} label={t("shopping-cart-ust")} value={vatPrice} /> : null
                                    }
                                    <SumRow cypressLabel={'order-cart-overall-sum'} showBottomSeparator={true} label={t("shopping-cart-overall-sum")} value={totalPrice} />

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item md={10}/>
                    <Grid item md={2}>
                        <Button variant="contained" color="secondary" fullWidth
                                disableElevation
                                component={RouterLink}
                                to="/vor-teile-wochen/bestaetigung.html"
                        >
                            {t("shopping-cart-order")}
                        </Button>
                    </Grid>
                </Fragment>
                }
            </Grid>
        </Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    item: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    },
    image: {
        minWidth: theme.spacing(14),
        cursor: "pointer"
    },
    box: {
        minWidth: theme.spacing(54),
    },
    discounted: {
        textDecoration: "line-through"
    }
}));

const Note: FC = () => {
    const {t} = useTranslation();
    const validTo = cartQuery.useValidTo();

    if (!validTo) {
        return null;
    }

    return (
        <Alert severity="info">
            {t("preorder-cart-note", {validTo: moment(validTo).format(t("date-format"))})}
        </Alert>
    );
}

interface ShoppingCartRowProps {
    item: PreorderCartItem;
}

const ShoppingCartRow: FC<ShoppingCartRowProps> = ({item}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();
    const {price, quantity} = item;
    const offerPrice = getItemPrice(price, quantity);

    function onChangingQuantity(slug: ItemSlug, quantity: number) {
        cartActions.setQuantity(slug, quantity).catch(error => console.log(error));
    }

    function onClickRemoveFromShoppingCart(slug: ItemSlug) {
        cartActions.removeItem(slug).catch(error => console.log(error));
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           data-cy="shopping-cart-image" className={classes.image}>
                    {
                        item.image &&
                        <img src={item.image.locationSmall}
                             alt={item.image.description}/>
                    }
                </TableCell>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           className={classes.item} data-cy="shopping-cart-text">
                    <Box fontWeight="fontWeightBold" fontSize="h6.fontSize"
                         className={classes.box}>
                        {item.company && item.company.name + " "}
                        <Typography color="secondary" component="span"
                                    variant="inherit"
                                    data-cy="shopping-cart-item-number"
                                    onClick={() => push(getItemPath(item.slug))}>{item.itemNumber}</Typography>
                    </Box>
                    {item.name}
                </TableCell>
                <TableCell className={classes.price} data-cy="shopping-cart-price">
                    <Fragment>
                        <Typography className={classes.discounted} variant="subtitle2" component="div">
                            <Currency value={price.originalPrice}/>
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                            <Currency value={offerPrice}/>
                        </Typography>
                        {(price.volumeDiscounts || [])
                            .map(({price, quantity}) =>
                                <Typography key={quantity} variant="subtitle2" component="div">
                                    {t("item-price-volume-discount", {quantity})} <Currency value={price}/>
                                </Typography>
                            )}
                    </Fragment>
                </TableCell>
                <TableCell data-cy="shopping-cart-amount">
                    <NumberField
                        label={t("shopping-cart-amount")}
                        value={quantity}
                        onChange={(value: number) => onChangingQuantity(item.slug, value)}
                        inputProps={{min: "0"}}
                        style={{width: 50}}
                    />
                </TableCell>
                <TableCell colSpan={2} className={classes.price} data-cy="shopping-cart-wholePrice"
                           align="right">
                    <Typography variant="subtitle2" component="div">
                        <Currency
                            value={offerPrice * quantity}/>
                    </Typography>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => onClickRemoveFromShoppingCart(item.slug)}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export function getItemPrice(price: PreorderPrice, quantity: number) {
    const special = (price.volumeDiscounts || [])
        .filter((special) => quantity >= special.quantity)
        .map(({price}) => price)[0]
    ;
    return special || price.reducedPrice;
}

export default CartPage;
