import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useMotorBreadcrumbs from "../app/common/breadcrumb/useMotorBreadcrumbs";
import ContentHeader from "../app/component/ContentHeader";
import MotorAttributes from "../app/component/motor/MotorAttributes";
import MotorVehicles from "../app/component/motor/MotorVehicles";
import ProductCategoryList from "../app/component/motor/ProductCategoryList";
import {GridDefaultSpacing} from "../app/config/Config";
import MotorModel from "../app/model/motor/Motor";
import NotFoundError from "../app/service/error/NotFoundError";
import motorService from "../app/service/MotorService";
import categoryActions from "../app/state/category/CategoryActions";
import motorActions from "../app/state/motor/MotorActions";
import sectionActions from "../app/state/section/SectionActions";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import ReferenceList from "../app/component/item/ReferenceList";
import {useTranslation} from "react-i18next";

interface Params {
    section?: string;
    slug: string;
}

export const MotorPage: FC = () => {
    const {slug} = useParams<Params>();
    const [motor, setMotor] = useState<MotorModel>();
    const [error, setError] = useState<any>();
    const breadcrumbs = useMotorBreadcrumbs(motor);
    const classes = useStyles(undefined);

    const {t} = useTranslation();
    useEffect(() => {
        motorActions.select(slug);
        motorService.read(slug)
            .then(setMotor)
            .catch(setError)
        ;
    }, [slug]);

    useEffect(() => {
        if (motor) {
            sectionActions.select(motor.section.slug).catch(error => console.log(error));
        }
    }, [motor]);

    useEffect(() => {
        if (motor) {
            categoryActions.loadForMotor(motor.section.slug, motor.slug)
                .catch(error => console.log(error));
        }
    }, [motor]);

    useEffect(() => {
        categoryActions.deselect();
    }, []);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (motor) {
        return (
            <Fragment>
                <ContentHeader
                    breadCrumbs={breadcrumbs}
                    data-cy="motor-title" header={motor.name}></ContentHeader>
                <Grid container spacing={GridDefaultSpacing}>
                    <Grid item md={3}>
                        <ProductCategoryList/>
                    </Grid>
                    <Grid item container md>
                        {
                            (motor.explodedViews?.length > 0) &&
                            <Grid className={classes.group} item sm={9}>
                                <ReferenceList
                                    heading={t("exploded-views-heading")}
                                    items={motor.explodedViews}
                                    to={(slug) => `/explosionszeichnung/${slug}.html`}/>
                            </Grid>
                        }
                        <Grid className={classes.group} item sm={9}><MotorAttributes motor={motor}/></Grid>
                        <Grid className={classes.group} item sm={9}><MotorVehicles motor={motor}/></Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    } else {
        return (<CircularProgress/>);
    }
};

const useStyles = makeStyles((theme) => ({
    group: {
        marginBottom: theme.spacing(1)
    }
}));

export default MotorPage;
