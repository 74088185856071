import {Store, StoreConfig} from "@datorama/akita";
import userService from "../../service/UserService";
import {from, of, Subject, Subscription} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {Profile} from "../../model/user/Profile";
import {Language} from "../language/UserLanguage";

export interface ProfileState {
    loading: boolean;
    error: string | undefined;
    profile: Profile;
}

export function createInitialState(): ProfileState {
    return {
        loading: false,
        error: undefined,
        profile: {
            newsletter: false,
            storeLocatorActive: false,
            displayRecommendedRetailPrice: false,
            displayBuyingPrice: false,
            displayPriceBox: false,
            locale: Language.NOT_SET
        },
    };
}

@StoreConfig({name: "sale"})
export class ProfileStore extends Store<ProfileState> {

    private service = userService;

    constructor() {
        super(createInitialState());
    }

    private readonly updateProfileAction$: Subject<Profile> = new Subject<Profile>()
    private readonly updateProfileEffect$ = this.updateProfileAction$.pipe(
        tap(() => this.update({ loading: true, error: undefined})),
        switchMap(action => from(this.service.updateProfile(action)).pipe(
            tap(result => this.update({profile: result, loading: false, error: undefined})),
            catchError(error => {
                console.log(error)
                this.update({error: error, profile: undefined, loading: false})
                return of();
            })
            )
        )
    )

    public activateProfileEffects(): Subscription[] {
        return [
            this.updateProfileEffect$.subscribe()
        ]
    }

    public updateProfile(profile: Profile): void {
        this.updateProfileAction$.next(profile);
    }

    public fetchProfile(): void {
        this.update({loading: true});
        this.service.getProfile()
            .then(it => this.update({profile: it, loading: false}))
            .catch(e => console.error('Could not load profile', e));
    }
}

export default new ProfileStore();
