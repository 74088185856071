import vehicleGroupService, {VehicleGroupService} from "../../service/VehicleGroupService";
import vehicleGroupStore, {FilterState, VehicleGroupStore} from "./VehicleGroupStore";

export interface Dependencies {
    vehicleGroupStore: VehicleGroupStore;
    vehicleGroupService: VehicleGroupService;
}

export class VehicleGroupActions {
    private readonly vehicleGroupStore = vehicleGroupStore;
    private readonly vehicleGroupService = vehicleGroupService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async loadSection(section: string) {
        const filter: FilterState = {
            section
        };
        if (this.isFilterEqual(filter)) {
            return;
        }

        try {
            this.vehicleGroupStore.set([]);
            this.vehicleGroupStore.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.vehicleGroupService.read(section);
            this.vehicleGroupStore.set(result);
        } catch (error) {
            this.vehicleGroupStore.setError(error);
        } finally {
            this.vehicleGroupStore.setLoading(false);
        }
    }

    private isFilterEqual(next: FilterState) {
        const current = this.vehicleGroupStore.getValue().filter;

        return next.section === current.section;
    }

    public select(slug: string) {
        this.vehicleGroupStore.setActive(slug);
    }

    public deselect() {
        this.vehicleGroupStore.setActive(null);
    }
}

export default new VehicleGroupActions();
