import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import AddressResponse, {AddressId} from "../../app/model/checkout/AddressResponse";
import addressService from "../../app/service/AddressService";
import NotFoundError from "../../app/service/error/NotFoundError";
import NotFoundErrorPage from "../../errors/404";
import InternalServerErrorPage from "../../errors/500";

const useStyles = makeStyles(theme => ({
    root: {},
    table: {}
}));

export const AddressIndexPage: FC = () => {
    const [error, setError] = useState<any>();
    const [items, setItems] = useState<AddressResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles(undefined);

    useEffect(() => {
        setLoading(true);
        addressService.findAll()
            .then(setItems)
            .catch(setError)
            .then(() => setLoading(false))
        ;
    }, []);

    function onRemove(id: AddressId) {
        setLoading(true);
        addressService.remove(id)
            .then(() => setItems(items.filter((item) => item.id !== id)))
            .catch(setError)
            .then(() => setLoading(false))
        ;
    }

    function getAddressType(item: AddressResponse) {
        let delivery: string = "";
        let billing: string = "";
        let company: string = "";
        let comma: string = "";

        if (item.delivery) {
            delivery = t("address-type-DELIVERY");
        }
        if (item.billing) {
            if (item.delivery) {
                comma = ", ";
            }
            billing = comma.concat(t("address-type-BILLING"));
        }
        if (item.companyAddress) {
            if (item.delivery || item.billing) {
                comma = ", ";
            }
            company = comma.concat(t("address-type-COMPANY"));
        }
        return delivery.concat(billing, company);
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("address-manage-breadcrumb"),
            to: "/benutzer/adresse/",
            disable: true
        }
    ];

    if (error instanceof NotFoundError) {
        return (<NotFoundErrorPage/>);
    } else if (error) {
        return (<InternalServerErrorPage/>);
    } else if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("address-manage-title")}></ContentHeader>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead data-cy="address-index-edit-table-head">
                            <TableRow>
                                <TableCell>{t("address-name-label")}</TableCell>
                                <TableCell>{t("address-street-label")}</TableCell>
                                <TableCell>{t("address-zip-label")}</TableCell>
                                <TableCell>{t("address-city-label")}</TableCell>
                                <TableCell>{t("address-country-label")}</TableCell>
                                <TableCell>{t("address-type-label")}</TableCell>
                                <TableCell>{t("address-action-label")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody data-cy="address-index-edit-table-body">
                            {items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.street}</TableCell>
                                    <TableCell>{item.zip}</TableCell>
                                    <TableCell>{item.city}</TableCell>
                                    <TableCell>{item.country}</TableCell>
                                    <TableCell>{getAddressType(item)}</TableCell>
                                    <TableCell>
                                        <IconButton data-cy="address-index-edit-button"
                                                    component={RouterLink}
                                                    to={`/benutzer/adresse/${item.id}/edit`}
                                        >
                                            <EditIcon/>
                                        </IconButton>

                                        {item.companyAddress !== true &&
                                            <IconButton
                                                data-cy="address-index-delete-button"
                                                onClick={() => onRemove(item.id)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <IconButton
                        data-cy="address-index-create-button"
                        component={RouterLink}
                        to={`/benutzer/adresse/create`}
                    >
                        <AddIcon/>
                    </IconButton>
                </TableContainer>
            </Fragment>
        );
    }
};

export default AddressIndexPage;
