import ListItemText from "@material-ui/core/ListItemText";
import React, {FC} from "react";
import {isCategorySearchResult} from "../../model/search/CategorySearchResult";
import {isItemSearchResult} from "../../model/search/ItemSearchResult";
import {isMotorSearchResult} from "../../model/search/MotorSearchResult";
import SearchResult from "../../model/search/SearchResult";
import {isVehicleSearchResult} from "../../model/search/VehicleSearchResult";
import CategoryResultItem from "./CategoryResultItem";
import ItemResultItem from "./ItemResultItem";
import MotorResultItem from "./MotorResultItem";
import VehicleResultItem from "./VehicleResultItem";
import {isCompanySearchResult} from "../../model/search/CompanySearchResult";
import CompanyResultItem from "./CompanyResultItem";

export interface ResultItemProps {
    value: SearchResult;
}

export const ResultItem: FC<ResultItemProps> = ({value}) => {
    const {name} = value;

    if (isVehicleSearchResult(value)) {
        return <VehicleResultItem value={value}/>
    } else if (isCategorySearchResult(value)) {
        return <CategoryResultItem value={value}/>
    } else if (isMotorSearchResult(value)) {
        return <MotorResultItem value={value}/>
    } else if (isItemSearchResult(value)) {
        return <ItemResultItem value={value}/>
    } else if (isCompanySearchResult(value)) {
        return <CompanyResultItem value={value}/>
    }


    return (
        <ListItemText
            data-cy="search-result"
            primary={name}
        />
    );
};

export default ResultItem;    
