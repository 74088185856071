import React, {FC, Fragment} from "react";
import authQuery from "../../app/state/auth/AuthQuery";
import configQuery from "../../app/state/config/ConfigQuery";
import cartQuery from "../../app/state/preorder/cart/CartQuery";
import {useTranslation} from "react-i18next";
import HeaderMenu from "./HeaderMenu";
import Badge from "@material-ui/core/Badge";
import NotForTenant from "../../app/component/tenant/NotForTenant";


export const MenuHeader: FC = () => {
    const isLoggedIn = authQuery.useIsLoggedIn();
    const {t} = useTranslation();

    const preorderEnabled = configQuery.useConfig("preorderEnabled");

    const showPreorder = isLoggedIn && preorderEnabled;
    const showSales = isLoggedIn && !preorderEnabled;

    const preorderCount = cartQuery.useCount();
    const hasError = !!cartQuery.useError();

    return <Fragment>
        <HeaderMenu
            title={t("header-menu-bicycle")}
            items={[
                {text: t("header-menu-vehicle-group"), path: "/Zweirad/fahrzeuge/"},
                {text: t("header-menu-categories"), path: "/Zweirad/produkte/"},
                {text: t("header-menu-motors"), path: "/Zweirad/motoren/"},
                {text: t("keyword-breadcrumb"), path: "/Zweirad/stichwortverzeichnis.html"}
            ]}/>

        <HeaderMenu
            title={t("header-menu-light-vehicle")}
            items={[
                {text: t("header-menu-vehicle-group"), path: "/Mopedauto/fahrzeuge/mopedauto/"},
                {text: t("header-menu-categories"), path: "/Mopedauto/produkte/"},
                {text: t("header-menu-motors"), path: "/Mopedauto/motoren/"},
                {text: t("keyword-breadcrumb"), path: "/Mopedauto/stichwortverzeichnis.html"}
            ]}/>

        {showPreorder &&
        <Badge badgeContent={preorderCount} color={hasError ? "error" : "secondary"}>
            <HeaderMenu
                title={t("preorder-header-menu")}
                items={[
                    {text: t("preorder-header-menu-Zweirad"), path: "/vor-teile-wochen/Zweirad.html"},
                    {text: t("preorder-header-menu-Mopedauto"), path: "/vor-teile-wochen/Mopedauto.html"},
                    {
                        text: (<Badge badgeContent={preorderCount} variant="dot"
                                      color={hasError ? "error" : "secondary"}>
                            {t("preorder-header-menu-cart")}
                        </Badge>),
                        path: "/vor-teile-wochen/warenkorb.html"
                    }
                ]}
            />
        </Badge>
        }

        {showSales &&
        <NotForTenant>
            <HeaderMenu
                title={t('sales_menu_title')} items={[
                {text: t('offer-sale-label'), path: "/aktionen/angebote.html"},
                {text: t('offer-clearance-label'), path: "/aktionen/abverkauf.html"}
            ]}/>
        </NotForTenant>
        }
    </Fragment>
}