import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, {FC, Fragment} from "react";
import {Link as RouterLink} from "react-router-dom";
import authQuery from "../../app/state/auth/AuthQuery";
import shoppingCartQuery from "../../app/state/shoppingCart/ShoppingCartQuery";

export const ShoppingCartAction: FC = () => {
    const isLoggedIn = authQuery.useIsLoggedIn();
    const count = shoppingCartQuery.useCount();
    const hasError = shoppingCartQuery.useHasError();

    if (!isLoggedIn) {
        return null;
    } else {
        return (
            <Fragment>
                <IconButton
                    data-cy="shopping-cart-icon"
                    style={{color: 'white'}}
                    color="inherit"
                    component={RouterLink}
                    to="/shop/warenkorb.html"
                >
                    <Badge badgeContent={count} color={hasError ? "error" : "default"}>
                        <ShoppingCartIcon/>
                    </Badge>
                </IconButton>
            </Fragment>
        );
    }
};

export default ShoppingCartAction;
