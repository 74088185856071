import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useVehicleBreadcrumbs from "../app/common/breadcrumb/useVehicleBreadcrumbs";
import ContentHeader from "../app/component/ContentHeader";
import ProductCategoryList from "../app/component/vehicle/ProductCategoryList";
import Vehicle from "../app/component/vehicle/Vehicle";
import {GridDefaultSpacing} from "../app/config/Config";
import VehicleModel from "../app/model/Vehicle";
import NotFoundError from "../app/service/error/NotFoundError";
import vehicleService from "../app/service/VehicleService";
import categoryActions from "../app/state/category/CategoryActions";
import sectionActions from "../app/state/section/SectionActions";
import vehicleActions from "../app/state/vehicle/VehicleActions";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

interface Params {
    section: string;
    slug: string;
}

export const VehiclePage: FC = () => {
    const {section, slug} = useParams<Params>();
    const [vehicle, setVehicle] = useState<VehicleModel>();
    const [error, setError] = useState<any>();
    const breadcrumbs = useVehicleBreadcrumbs(vehicle);

    useEffect(() => {
        vehicleActions.select(slug).catch(error => console.log(error));
        vehicleService.read(slug)
            .then(setVehicle)
            .catch(setError)
        ;
    }, [slug]);

    useEffect(() => {
        sectionActions.select(section).catch(error => console.log(error));
    }, [section]);

    useEffect(() => {
        categoryActions.loadForVehicle(section, slug).catch(error => console.log(error));
    }, [section, slug]);

    useEffect(() => {
        categoryActions.deselect();
    }, []);


    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (vehicle) {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="vehicle-title" header={vehicle.company.name + '' + vehicle.name}></ContentHeader>
                <Grid container spacing={GridDefaultSpacing}>
                    <Grid item md={3}>
                        <ProductCategoryList/>
                    </Grid>
                    <Grid item md>
                        <Vehicle vehicle={vehicle}/>
                    </Grid>
                </Grid>
            </Fragment>
        );
    } else {
        return (<CircularProgress/>);
    }
};

export default VehiclePage;
