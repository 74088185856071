import {Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import VehicleGroup from "../../../model/VehicleGroup";
import {throttledLoading} from "../../throttleLoading";
import vehicleGroupStore, {VehicleGroupState, VehicleGroupStore} from "./VehicleGroupStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class VehicleGroupQuery extends QueryEntity<VehicleGroupState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();
    public readonly current = this.selectActive();
    public readonly currentSlug = this.selectActiveId();

    constructor(
        protected store: VehicleGroupStore = vehicleGroupStore
    ) {
        super(store);
    }

    public useAll(): VehicleGroup[] {
        return useObservable(this.all, this.getAll());
    }

    public useCurrent(): VehicleGroup | undefined {
        return useObservable(this.current, this.getActive());
    }
}

export default new VehicleGroupQuery();
