import TableCell from "@material-ui/core/TableCell";
import getItemPath from "../../common/route/getItemPath";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Currency from "../Currency";
import {getItemPrice} from "../../shoppingcart/ShoppingCartRow";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import ShoppingCartItem from "../../model/shoppingCart/ShoppingCartItem";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    pointer: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    },
}));

interface OrderCartItemRowProps {
    item: ShoppingCartItem;
    onPushHistory: (path: string) => void;
}

export const OrderCartItemRow: React.FC<OrderCartItemRowProps> = props => {
    const classes = useStyles(props);
    const {item, quantity} = props.item;
    return <TableRow>
        <TableCell onClick={() => props.onPushHistory(getItemPath(item.slug))}
                   className={classes.pointer} data-cy="order-cart-text">
            <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                {item.company && item.company.name + " "}
                <Typography color="secondary" component="span"
                            variant="inherit" data-cy="order-cart-item-number"
                            onClick={() => props.onPushHistory(getItemPath(item.slug))}>{item.itemNumber}</Typography>
            </Box>
            {item.name}
        </TableCell>
        <TableCell className={classes.price} data-cy="order-cart-price" align="right">
            {item.price &&
            <Typography variant="subtitle2" component="div">
                <Currency
                    value={getItemPrice(item.price, quantity)}/>
            </Typography>
            }
        </TableCell>
        <TableCell data-cy="order-cart-amount" align="right">
            <Typography>
                {quantity}
            </Typography>
        </TableCell>
        <TableCell colSpan={3} className={classes.price} data-cy="order-cart-wholePrice"
                   align="right">
            {item.price &&
            <Typography variant="subtitle2" component="div">
                <Currency
                    value={getItemPrice(item.price, quantity) * quantity}/>
            </Typography>
            }
        </TableCell>
        <TableCell></TableCell>
    </TableRow>;
}

export default OrderCartItemRow;