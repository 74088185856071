import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Alert} from "@material-ui/lab";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import getItemPath from "../app/common/route/getItemPath";
import ContentHeader from "../app/component/ContentHeader";
import Item from "../app/component/item/Item";
import ItemSlug from "../app/model/item/ItemSlug";
import NotFoundError from "../app/service/error/NotFoundError";
import {useItem} from "../app/state/categoryPage/item/useItem";
import cartQuery from "../app/state/preorder/cart/CartQuery";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

interface Params {
    slug: string;
}

const useStyles = makeStyles(theme => ({
    infoMessage: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    availableFromMessage: {
        width: "100%",
        marginTop: 0,
        marginBottom: 0,
    }
}));

export const ProductPage: FC = () => {
    const {slug} = useParams<Params>();
    const [item, error] = useItem(slug);
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const isPreorderOpen = cartQuery.useIsOpen();

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (item) {
        const breadcrumbs: Breadcrumb[] = [
            {
                text: item.section.name,
                to: `/${item.section.slug}/fahrzeuge/`
            },
            {
                text: t("category-breadcrumb-text"),
                to: `/${item.section.slug}/produkte/`
            },
            {
                text: item.category.name,
                to: `/${item.section.slug}/produkte/${item.category.slug}/`
            },
            {
                text: item.itemNumber,
                to: getItemPath(slug as ItemSlug)
            }
        ];

        return <Fragment>
            {
                !item.notPublishedMessage &&
                <ContentHeader data-cy="page-title"
                               breadCrumbs={breadcrumbs}
                               header={(item.company ? item.company.name + ' ' : '') + item.itemNumber}
                               subheader={item.name}></ContentHeader>
            }
            {!!item.notPublishedMessage &&
            <Fragment>
                {!!item.availableFrom ?
                    <Alert data-cy="alert-available-from" className={classes.infoMessage} severity="warning" variant="filled">
                        <AlertTitle className={classes.availableFromMessage}>
                            {t("item-not-published-message-available-from-prefix")}{item.availableFrom}
                        </AlertTitle>
                    </Alert>
                    : <Alert
                        className={classes.infoMessage}
                        severity="warning"
                    >
                        <AlertTitle>{t("item-not-published-message")}</AlertTitle>
                        {t(`item-not-published-message-${item.notPublishedMessage}`)}
                    </Alert>
                }
            </Fragment>
            }

            <Item item={item} preorder={isPreorderOpen}/>
        </Fragment>;
    } else {
        return (<CircularProgress/>);
    }
};

export default ProductPage;
