import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Vehicle from "../../model/Vehicle";

export type FilterState = Partial<{
    company: string;
}>

export type ID = string;
export type Entity = Vehicle;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    filter: FilterState
}

export function createInitialState(): Partial<State> {
    return {
        filter: {}
    };
}

@StoreConfig({
    name: "motor",
    idKey: "slug"
})
export class MotorStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new MotorStore();
