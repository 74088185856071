import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import VehicleGroup from "../../model/VehicleGroup";

export interface FilterState {
    section: string | null;
}

export type ID = string;

export type Entity = VehicleGroup;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    filter: FilterState
}

export function createInitialFilterState(): FilterState {
    return {
        section: null
    };
}

@StoreConfig({
    name: "vehiclegroup",
    idKey: "slug"
})
export class VehicleGroupStore extends EntityStore<State> {
    constructor() {
        super({filter: createInitialFilterState(), loading: false});
    }
}

export default new VehicleGroupStore();
