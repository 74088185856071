import {TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import getItemPath from "../../app/common/route/getItemPath";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";
import ContentHeader from "../../app/component/ContentHeader";
import Currency from "../../app/component/Currency";
import AppLink from "../../app/component/theme/AppLink";
import PreorderCartItem from "../../app/model/preorder/PreorderCartItem";
import cartActions from "../../app/state/preorder/cart/CartActions";
import cartQuery from "../../app/state/preorder/cart/CartQuery";
import PageInternalServerError from "../../errors/500";

export const CheckoutPage: FC = () => {
    const {t} = useTranslation();
    const error = cartQuery.useError();
    const classes = useStyles(undefined);
    const items = cartQuery.useAll();
    const netTotal = cartQuery.useNetTotal();
    const vatPrice = cartQuery.useVatPrice();
    const totalPrice = cartQuery.useTotalPrice();
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("preorder-code-page-title"),
            to: "/vor-teile-wochen/code.html"
        }
    ];
    useEffect(() => {
        cartActions.load();
    }, [])

    if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("preorder-cart-checkout-page-title")}></ContentHeader>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow className={classes.header}>
                                    <TableCell colSpan={2}>{t("shopping-cart-header-cell-product")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-unit-price")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-amount")}</TableCell>
                                    <TableCell
                                        align="right">{t("shopping-cart-header-cell-total-price")}
                                    </TableCell>
                                    <TableCell/>
                                </TableRow>
                                {
                                    items.map(item => (
                                        <ShoppingCartRow
                                            key={item.slug}
                                            item={item}
                                        />
                                    ))
                                }
                                <TableRow>
                                    <TableCell rowSpan={3} colSpan={2}/>
                                    <TableCell colSpan={2} align="right">{t("shopping-cart-net-sum")}</TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2" component="div">
                                            <Currency value={netTotal}/>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {
                                    !!vatPrice &&
                                    <TableRow>
                                        <TableCell colSpan={2} align="right">{t("shopping-cart-ust")}</TableCell>
                                        <TableCell colSpan={1} data-cy="shopping-cart-vat" align="right">
                                            <Typography variant="subtitle2" component="div">
                                                <Currency value={vatPrice}/>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell colSpan={2} align="right">{t("order-cart-overall-sum")}</TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2" component="div">
                                            <Currency value={totalPrice}/>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Form/>
            </Grid>
        </Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    item: {
        cursor: "pointer"
    },
    image: {
        minWidth: theme.spacing(14),
        cursor: "pointer"
    },
    discounted: {
        textDecoration: "line-through"
    },

    grid: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2)
    },
    checkBoxText: {
        // maxWidth: "50%",
        paddingLeft: theme.spacing(3),
        fontStyle: "italic"
    },
    button: {
        paddingLeft: theme.spacing(2)
    },
    appLinkText: {
        color: theme.palette.secondary.dark
    },
    notesText: {
        fontStyle: "italic"
    },
    buttonProgress: {
        position: 'absolute'
    },
    circularProgress: {
        marginLeft: "50%",
        marginTop: theme.spacing(5)
    }
}));


interface ShoppingCartRowProps {
    item: PreorderCartItem;
}

const ShoppingCartRow: FC<ShoppingCartRowProps> = ({item}) => {
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();
    const {price, quantity} = item;
    const itemPrice = price.reducedPrice;

    return (
        <Fragment>
            <TableRow>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           data-cy="shopping-cart-image" className={classes.image}>
                    {
                        item.image &&
                        <img src={item.image.locationSmall}
                             alt={item.image.description}/>
                    }
                </TableCell>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           className={classes.item} data-cy="shopping-cart-text">
                    <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                        {item.company && item.company.name + " "}
                        <Typography color="secondary" component="span"
                                    variant="inherit"
                                    data-cy="shopping-cart-item-number"
                                    onClick={() => push(getItemPath(item.slug))}>{item.itemNumber}</Typography>
                    </Box>
                    {item.name}
                </TableCell>
                <TableCell>
                    <Currency value={itemPrice}/>
                </TableCell>
                <TableCell>
                    {quantity}
                </TableCell>
                <TableCell align="right">
                    <Currency value={itemPrice * quantity}/>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

const Form: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const [date, setDate] = useState("");
    const [comment, setComment] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const isValid = termsAccepted && !!date;
    const push = useHistoryPushNew();

    function submit() {
        cartActions
            .checkout(date, comment)
            .then(() => push("/vor-teile-wochen/fertig.html"))
            .catch(() => {
            })
        ;
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item md={12}>
                            <input data-cy="confirmation-checkbox"
                                   onChange={(event) => setTermsAccepted(event.target.checked)} checked={termsAccepted}
                                   type="checkbox"
                                   id="gtc-checkbox"
                                   required
                            />
                            <label htmlFor="gtc-checkbox" className={classes.checkBoxText}
                                   data-cy="confirmation-checkbox-text"
                            > {t("checkout-confirmation-gtc-text1")}
                                <AppLink to="/agb.html"
                                         className={classes.appLinkText}> {t("checkout-confirmation-gtc-textLink")}</AppLink>
                                {t("checkout-confirmation-gtc-text2")}</label>
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label="gewünschtes Lieferdatum"
                                fullWidth
                                onChange={e => setDate(e.target.value)}
                                value={date}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label="Anmerkungen zu Ihrer Bestellung"
                                multiline
                                fullWidth
                                rows={4}
                                onChange={e => setComment(e.target.value)}
                                value={comment}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={2}>
                <Button variant="contained" fullWidth
                        disableElevation
                        component={RouterLink}
                        to="/vor-teile-wochen/warenkorb.html"
                >
                    {t("back")}
                </Button>
            </Grid>
            <Grid item md={3}>
                <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    onClick={() => window.print()}
                >
                    {t("print-order-button-text")}
                </Button>
            </Grid>
            <Grid item md sm/>
            <Grid item md={3}>
                <Button variant="contained"
                        color="secondary"
                        fullWidth
                        disableElevation
                        disabled={!isValid}
                        onClick={() => submit()}
                >
                    {t("checkout-confirmation-order-button")}
                </Button>
            </Grid>
        </Fragment>
    );
}

export default CheckoutPage;
