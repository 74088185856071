import {ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import getVehiclePath from "../../common/route/getVehiclePath";
import Motor from "../../model/motor/Motor";
import AppLink from "../theme/AppLink";

export interface MotorVehiclesProps {
    motor: Motor;
}

export const MotorVehicles: FC<MotorVehiclesProps> = ({motor}) => {
    const {t} = useTranslation();
    const heading = t("motor-vehicles-heading");
    const vehicles = motor.vehicles;
    const section = motor.section;

    return (
        <Fragment>
            <Typography variant="h6" component="h3">{heading}</Typography>
            <List component="div" disablePadding>
                {
                    vehicles.map((vehicle) => (
                        <ListItem
                            component={AppLink}
                            key={vehicle.slug}
                            to={getVehiclePath(section.slug, vehicle.slug)}
                            color="secondary">
                            <ListItemText
                                data-cy="linked-vehicle-title"
                            >
                                <Typography color="textPrimary" variant="body1"
                                            component="span">{vehicle.company}</Typography>
                                {" "}
                                {vehicle.name}
                            </ListItemText>
                        </ListItem>
                    ))
                }
            </List>
        </Fragment>
    );
};

export default MotorVehicles;    
