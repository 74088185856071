import React, {FC} from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    separator: {
        marginTop: "0.75vw",
        marginBottom: "0.75vw",
        opacity: 0.5
    },
    requiredStar: {
        color: theme.palette.warning.main
    }
}));

export interface SignupFormSectionProps {
    legend: string;
    includeSeparator?: boolean;
    markRequired?: boolean;
    "data-cy"?: string;
}

const FormSection: FC<SignupFormSectionProps> = props => {
    const classes = useStyles(props);
    return (<Grid item container md={12} data-cy={props['data-cy']}>
        <Grid item md={3}>
            <b>{props.legend}{props.markRequired ? <b className={classes.requiredStar}>*</b> : null}</b>
        </Grid>
        <Grid item md={7}>
            {props.children}
            {props.includeSeparator ? <hr className={classes.separator}/> : null}
        </Grid>
    </Grid>);
}

export default FormSection;