import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import itemActions from "../../app/state/returns/items/ItemActions"
import returnFilesActions from "../../app/state/returns/files/ReturnFilesActions"
import itemQuery from "../../app/state/returns/items/ItemQuery";
import BadRequestError from "../../app/service/error/BadRequestError";
import ReturnItemTable from "../../app/component/returns/ReturnItemTable";
import AddReturnItemForm from "../../app/component/returns/AddReturnItemForm ";
import ReturnForm from "../../app/component/returns/ReturnForm";
import {AddReturnFilesForm} from "../../app/component/returns/AddReturnFilesForm";
import ReturnFileTable from "../../app/component/returns/ReturnFileTable";

const useStyles = makeStyles((theme) => ({
    noteList: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

export const AddReturnsPage: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const breadcrumbs: Breadcrumb[] = [];

    useEffect(() => {
        itemActions.load().catch(error => console.log(error));
        returnFilesActions.load().catch(error => console.log(error));
    }, []);

    breadcrumbs.push(
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("returns-add-page-title"),
            to: "/ruecksendungen/add"
        }
    );

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"
                           header={t("returns-add-page-title")}></ContentHeader>

            <Grid container>
                <Grid item md={12}>
                    <Alert severity="warning">
                        <AlertTitle>{t("returns-note-heading")}</AlertTitle>
                        <ul className={classes.noteList}>
                            <li>{t("returns-note-entry1")}</li>
                            <li>{t("returns-note-entry2")}</li>
                            <li>{t("returns-note-entry3")}</li>
                        </ul>
                    </Alert>
                </Grid>
                <ErrorAlert/>
                <Grid item md={12}>
                    <ReturnItemTable/>
                </Grid>
                <Grid item md={12}>
                    <ReturnFileTable />
                </Grid>
                <SuccessAlert/>
                <Grid item md={12}>
                    <ReturnForm/>
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h5" component="h3">{t("returns-add-item-heading")}</Typography>
                </Grid>
                <Grid item md={12}>
                    <AddReturnItemForm/>
                </Grid>
                <Grid item md={12}>
                    <Typography variant="h5" component="h3">{t("returns-add-files-heading")}</Typography>
                </Grid>
                <Grid item md={12}>
                    <AddReturnFilesForm />
                </Grid>
            </Grid>
        </Fragment>
    );
};

const ErrorAlert: FC = () => {
    const {t} = useTranslation();
    const error = itemQuery.useError();

    if (error instanceof BadRequestError) {
        return (
            <Grid item md={12}>
                <Alert severity="error">
                    {t("returns-error-text")}
                </Alert>
            </Grid>
        );
    } else if (!!error) {
        return (
            <Grid item md={12}>
                <Alert severity="error">
                    <AlertTitle>{t("error-500-heading")}</AlertTitle>
                    {t("error-500-text1")}
                </Alert>
            </Grid>
        );
    }
    return null;
}

const SuccessAlert: FC = () => {
    const {t} = useTranslation();
    const showSuccess = itemQuery.useValue("success");

    if (!showSuccess) {
        return null;
    }

    return (
        <Grid item md={12}>
            <Alert>
                {t("returns-items-success")}
            </Alert>
        </Grid>
    );
}

export default AddReturnsPage;
