import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import vehicleItemStore, {State, VehicleItemStore} from "./VehicleItemStore";

@QueryConfig({})
export class VehicleItemQuery extends HookQueryEntity<State> {
    constructor(
        protected store: VehicleItemStore = vehicleItemStore
    ) {
        super(store);
    }
}

export default new VehicleItemQuery();
