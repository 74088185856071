import {Store, StoreConfig} from "@datorama/akita";
import Config from "../../model/Config";
import {Language} from "./UserLanguage";

export interface State extends Config {
    language: Language;
}

export function createInitialState(): Partial<State> {
    return {
        language: Language.DEFAULT
    };
}

@StoreConfig({
    name: "language"
})
export class LanguageStore extends Store<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new LanguageStore();
