import vehicleService, {VehicleService} from "../../service/VehicleService";
import companyActions, {CompanyActions} from "../company/CompanyActions";
import vehicleStore, {FilterState, VehicleStore} from "./VehicleStore";

export interface Dependencies {
    vehicleStore: VehicleStore;
    vehicleService: VehicleService;
    companyActions: CompanyActions;
}

export class VehicleActions {
    private readonly vehicleStore = vehicleStore;
    private readonly vehicleService = vehicleService;
    private readonly companyActions = companyActions;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async findAllByCompanyAndGroup(company: string, group: string): Promise<void> {
        const filter: FilterState = {company, group};
        if (this.isFilterEqual(filter)) {
            return;
        }
        this.companyActions.select(company);
        try {
            this.vehicleStore.set([]);
            this.vehicleStore.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.vehicleService.findAllByCompanyAndGroup(filter);
            this.vehicleStore.set(result);
        } catch (error) {
            this.vehicleStore.setError(error);
            throw error;
        } finally {
            this.vehicleStore.setLoading(false);
        }
    }

    private isFilterEqual(next: FilterState) {
        const current = this.vehicleStore.getValue().filter;

        return next.company === current.company && next.group === current.group;
    }

    public async select(slug: string): Promise<void> {
        const current = this.vehicleStore.getValue();

        if (current.active !== slug) {
            this.vehicleStore.setActive(slug);
        }
    }

    public async deselect(): Promise<void> {
        this.vehicleStore.setActive(null);
    }
}

export default new VehicleActions();
