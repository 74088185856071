import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import NotFoundError from "../app/service/error/NotFoundError";
import shoppingCartActions from "../app/state/shoppingCart/ShoppingCartActions";
import PageInternalServerError from "../errors/500";
import ContentHeader from "../app/component/ContentHeader";

export const CheckoutCancellation: FC = () => {
    const {t} = useTranslation();
    const breadcrumbs: Breadcrumb[] = [];
    const search = new URLSearchParams(useLocation().search);
    const orderId = search.get("orderId") || "";
    const timestamp = search.get("timestamp") || "";
    const signature = search.get("signature") || "";
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (orderId && timestamp && signature) {
            setLoading(true);
            setError(null);
            shoppingCartActions.abort(orderId, timestamp, signature)
                .catch(setError)
                .then(() => setLoading(false))
            ;
        }
    }, [orderId, timestamp, signature]);

    breadcrumbs.push(
        {
            text: t("shopping-cart-breadcrumb-text"),
            to: `/shop/warenkorb.html`
        },
        {
            text: t("checkout-cancellation-breadcrumb-text"),
            to: `/shop/abgebrochen.html`
        }
    );

    if (error && !(error instanceof NotFoundError)) {
        return (<PageInternalServerError/>);
    } else if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <Fragment>
                <ContentHeader header={t("checkout-cancellation-heading")} breadCrumbs={breadcrumbs} />
                <Typography color="error" variant="h4" component="h1">{t("checkout-cancellation-heading")}</Typography>
            </Fragment>
        );
    }
};

export default CheckoutCancellation;
