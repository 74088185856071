import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import React, {FC, Fragment, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import getItemPath from "../../common/route/getItemPath";
import useHistoryPushNew from "../../common/useHistoryPushNew";
import PreorderItem from "../../model/item/PreorderItem";
import cartActions from "../../state/preorder/cart/CartActions";
import cartQuery from "../../state/preorder/cart/CartQuery";
import itemActions from "../../state/preorder/item/ItemActions";
import itemQuery from "../../state/preorder/item/ItemQuery";
import PaginationActions from "../category/PaginationActions"
import Currency from "../Currency";
import NumberField from "../form/NumberField";
import AppLink from "../theme/AppLink";

export const PreorderPagedItemList: FC = () => {
    const {t} = useTranslation();
    const loading = itemQuery.useIsLoading();
    const page = itemQuery.usePage();
    const size = itemQuery.useSize();
    const items = itemQuery.useAll();
    const count = itemQuery.useCount();

    if (loading) {
        return (<CircularProgress/>);
    } else if (count === 0) {
        return t("category-filter-page-empty");
    }

    return (
        <PagedItemListPure
            items={items}
            page={page}
            size={size}
            count={count}
        />
    );
};

export default PreorderPagedItemList;

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    image: {
        [theme.breakpoints.down("sm")]: {
            maxHeight: theme.spacing(10),
            maxWidth: theme.spacing(10)
        },
        [theme.breakpoints.up("md")]: {
            maxHeight: theme.spacing(15),
            maxWidth: theme.spacing(15)
        },
        [theme.breakpoints.up("lg")]: {
            maxHeight: theme.spacing(20),
            maxWidth: theme.spacing(20)
        }
    },
    itemCell: {
        cursor: "pointer"
    },
    company: {
        maxHeight: theme.spacing(5),
        maxWidth: theme.spacing(15),
        [theme.breakpoints.up("lg")]: {
            maxHeight: theme.spacing(10),
            maxWidth: theme.spacing(20)
        }
    },
    row: {},
    table: {
        marginTop: theme.spacing(1)
    },
    flags: {
        marginTop: 0
    },
    quantityCell: {
        width: theme.spacing(25)
    },
    price: {
        whiteSpace: "nowrap"
    },
    priceOriginal: {
        textDecoration: "line-through"
    },
    priceReduced: {
        fontWeight: "bold"
    }
}));

export interface PagedItemProps {
    items: PreorderItem[];
    page: number;
    size: number;
    count: number;
}

export const PagedItemListPure: FC<PagedItemProps> = ({items, page, size, count}) => {
    const classes = useStyles(undefined);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        itemActions.setPage(newPage).catch(error => console.log(error));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        itemActions.setSize(parseInt(event.target.value, 10)).catch(error => console.log(error));
    };

    return (
        <Fragment>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableBody>
                        {items.map(item => <ItemRow key={item.slug} item={item}/>)}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PaginationActions}
            />
        </Fragment>
    );
};

interface ItemRowProps {
    item: PreorderItem;
}

const ItemRow: FC<ItemRowProps> = ({item}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const [quantity, setQuantity] = useState(1);
    const push = useHistoryPushNew();
    const {image, orderCount} = item;
    const cartQuantity = cartQuery.useItemQuantity(item.slug);

    function addToCart() {
        cartActions.addItem(item, quantity);
    }

    return (
        <TableRow
            hover
            className={classes.row}
        >
            <TableCell align="center">
                {
                    image &&
                    <img className={classes.image}
                         src={image.locationSmall}
                         alt={image.description}/>
                }
            </TableCell>
            <TableCell component="th" scope="row" className={classes.itemCell}
                       onClick={() => push(getItemPath(item.slug))}
            >
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                    {item.company && item.company.name + " "}
                    <Typography color="secondary" component="span"
                                variant="inherit">{item.itemNumber}</Typography>
                </Box>
                <AppLink to={getItemPath(item.slug)}>{item.name}</AppLink>
            </TableCell>
            <TableCell align="right">
                <div className={classes.price}>
                    <Trans i18nKey="preorder-price-original"
                           components={[<Currency value={item.price.originalPrice} className={classes.priceOriginal}/>]}
                    />
                </div>
                <div className={classes.price}>
                    <Trans i18nKey="preorder-price-reduced"
                           values={item}
                           components={[<Currency value={item.price.reducedPrice} className={classes.priceReduced}/>]}
                    />
                </div>
                {orderCount > 0 &&
                <div>
                    Vorjahresbedarf: <strong>{orderCount}</strong>
                </div>
                }
            </TableCell>
            <TableCell className={classes.quantityCell}>
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <NumberField
                            value={quantity}
                            onChange={setQuantity}
                            size="small"
                            variant="outlined"
                            fullWidth
                            label={t("order-amount-input-label")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton
                            onClick={() => addToCart()}
                        >
                            <AddShoppingCartIcon/>
                        </IconButton>
                    </Grid>
                    {cartQuantity > 0 &&
                    <Grid item xs={12}>
                        <Fragment>
                            Bereits im Warenkorb: {cartQuantity}
                        </Fragment>
                    </Grid>
                    }
                </Grid>
            </TableCell>
            <TableCell>
            </TableCell>
        </TableRow>
    );
}
