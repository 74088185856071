import service, {AttributeService} from "../../../../../service/AttributeService";
import store, {CategoryAttributeStore} from "./CategoryAttributeStore";

export interface Dependencies {
    store: CategoryAttributeStore;
    service: AttributeService;
}

export class CategoryAttributeActions {
    private readonly store = store;
    private readonly service: AttributeService = service;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async load(category: string): Promise<void> {
        try {
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                category
            });
            const result = await this.service.findAllByCategory(category);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }
}

export default new CategoryAttributeActions();
