import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import ItemModel from "../../model/item/Item";
import profileQuery from "../../state/user/ProfileQuery";
import CompanyLogo from "../CompanyLogo";
import Attributes from "./Attributes";
import {Panel} from "./Panel";
import ComplementItems from "./ComplementItems";
import Description from "./Description";
import Files from "./Files";
import Images from "./Images";
import ItemReferenceList from "./ItemReferenceList";
import Motors from "./Motors";
import OrderPanel from "./OrderPanel";
import PreorderOrderPanel from "./PreorderOrderPanel";
import ReferenceList from "./ReferenceList";
import StringList from "./StringList";
import Vehicles from "./Vehicles";
import profileStore from "../../state/user/ProfileStore";
import Paper from "@material-ui/core/Paper";
import InternalNote from "./InternalNote";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import useHistoryPushNew from "../../common/useHistoryPushNew";

export interface ItemProps {
    item: ItemModel;
    preorder: boolean;
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles(theme => ({
    orderPanel: {
        position: 'relative',
        top: -40
    },
    content: {
        marginTop: theme.spacing(1)
    },
    align: {
        marginLeft: "16px",
    },
    active_tab: {
        color: '#2bb6b7',
    },
    default_tab: {
        color: 'gray',
    },
    company: {
        maxWidth: "100%",
        maxHeight: theme.spacing(25)
    },
    imageContainer: {

    },
    complementItemsDivider: {
        margin: theme.spacing(3)
    },
    root: {
        paddingBottom: theme.spacing(4)
    },
    paddingTop: {
        paddingTop: theme.spacing(6)
    }
}));

export const Item: FC<ItemProps> = ({item, preorder}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const userProfile = profileQuery.useProfile();
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useHistoryPushNew();

    useEffect(() => {
        profileStore.fetchProfile();
    }, [])
    const {
        attributes = [], itemNumber, description, company, images = [], price,
        alternatives = [],
        spareItems = [],
        spareForItems = [],
        complementItems = [],
        complementForItems = [],
        comparisonNumbers = [],
        oemNumbers = [],
        explodedViews = [],
        files = [],
        motors = [],
        vehicles = [],
        internalNote,
        variations,
    } = item;

    return (
        <Grid className={classes.root} container spacing={2} direction={"row"}>

            <Grid item container justify={'space-between'} direction={"column"} lg={3} xs={12}>
                <Grid container item>
                    {
                        images.length > 0 &&
                        <Grid item xs={12} >
                            <Paper variant="outlined" className={classes.imageContainer}>
                                <Images images={images}/>
                            </Paper>
                        </Grid>
                    }
                    {
                        isSmallLayout ? <React.Fragment>
                            <Grid item xs={12}>
                                {price &&
                                <Panel background={theme.palette.secondary} heading={"Artikelnummer " + itemNumber}><OrderPanel
                                    userProfile={userProfile} item={item}/></Panel>}
                            </Grid>

                            {preorder && !!price?.preorderPrice ?
                                <Grid item>
                                    <Panel heading={"Vor-Teile-Wochen"}><PreorderOrderPanel slug={item.slug}
                                                                                            price={price.preorderPrice}/></Panel>
                                </Grid>
                                : null
                            }
                        </React.Fragment> : null
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
                {
                    internalNote ? <InternalNote internalNote={internalNote}/> : null
                }
                <div>

                    {
                        description ?
                            <div className={classes.paddingTop}>
                                <Description>{description}</Description>
                                {
                                    shouldShowAttributes(attributes, company) &&
                                    <Attributes attributes={attributes} company={company}/>
                                }
                            </div>
                            :
                            <div className={classes.paddingTop}>
                                {
                                    shouldShowAttributes(attributes, company) &&
                                    <Attributes attributes={attributes} company={company}/>
                                }

                            </div>
                    }
                    {
                        <div className={classes.paddingTop}>
                            {
                                complementItems.length > 0 ?
                                    <div className={classes.paddingTop}>
                                        <ComplementItems items={complementItems} heading={t("item-complement-items-heading")} />
                                    </div> : null
                            }
                            {
                                complementForItems.length > 0 ?
                                    <div className={classes.paddingTop}>
                                        <ComplementItems
                                            items={complementForItems}
                                            heading={t("item-complement-for-items-heading", {itemNumber})}
                                        />
                                    </div> : null
                            }
                            {
                                alternatives.length > 0 ?
                                    <div className={classes.paddingTop}>
                                        <ItemReferenceList
                                            data-cy="alternative-item-list"
                                            title={t("item-alternative-items-heading", {itemNumber})}
                                            items={alternatives}
                                        />
                                    </div> : null
                            }
                            {
                                spareItems.length > 0 ?
                                    <div className={classes.paddingTop}>
                                        <ItemReferenceList
                                            data-cy="spare-item-list"
                                            title={t("item-spare-items-heading", {itemNumber})}
                                            items={spareItems}
                                        />
                                    </div> : null
                            }
                            {
                                spareForItems.length > 0 ?
                                    <div className={classes.paddingTop}>
                                        <ItemReferenceList
                                            data-cy="spare-for-item-list"
                                            title={t("item-spare-for-items-heading", {itemNumber})}
                                            items={spareForItems}
                                        />
                                    </div> : null
                            }
                            {
                                oemNumbers.length > 0 ?
                                    <div className={classes.paddingTop}>
                                        <StringList
                                            heading={t("item-oem-numbers-heading")}
                                            items={oemNumbers}
                                        />
                                    </div> : null
                            }
                        </div>
                    }
                    {
                        files.length > 0 ?
                            <div className={classes.paddingTop}>
                                <Files files={files}/>
                            </div> : null
                    }
                    {
                        vehicles.length > 0 ?
                            <div className={classes.paddingTop}>
                                <Vehicles vehicles={vehicles}/>
                            </div> : null
                    }
                </div>
                <div className={classes.align}>
                    {
                        comparisonNumbers.length > 0 &&
                        <div>
                            <StringList
                                heading={t("item-comparison-numbers-heading")}
                                items={comparisonNumbers}
                            />
                        </div>
                    }
                    {
                        explodedViews.length > 0 &&
                        <div>
                            <ReferenceList
                                heading={t("item-exploded-views-heading")}
                                items={explodedViews}
                                to={(slug) => `/explosionszeichnung/${slug}.html`}
                            />
                        </div>
                    }
                    {
                        motors.length > 0 &&
                        <div><Motors motors={motors}/></div>
                    }
                </div>
            </Grid>

            <Grid item lg={3} xs={12}>
                {company && <CompanyLogo className={classes.company} company={company}/>}
                {
                    !isSmallLayout ? <React.Fragment>
                        <Grid item xs={12}>
                            {price &&
                            <Panel background={theme.palette.secondary} heading={"Artikelnummer " + itemNumber}>
                                {variations?.length ?
                                    <div>
                                     Variante
                                <Select
                                    fullWidth={true}
                                    id={itemNumber}
                                    style={{backgroundColor: "white"}}
                                    defaultValue={itemNumber}
                                    value={itemNumber}
                                    variant="outlined"
                                    color="primary">
                                    {variations.map(v => <MenuItem onClick={() => navigate('/produkt/' + v.slug + '.html')} value={v.itemNumber}>{v.name}</MenuItem>)}
                                </Select>
                                    </div>: null}
                                <OrderPanel userProfile={userProfile} item={item}/>
                            </Panel>}
                        </Grid>

                        {preorder && !!price?.preorderPrice ?
                            <Grid item>
                                <Panel heading={"Vor-Teile-Wochen"}><PreorderOrderPanel slug={item.slug}
                                                                                        price={price.preorderPrice}/></Panel>
                            </Grid>
                            : null
                        }
                    </React.Fragment> : null
                }
            </Grid>

        </Grid>
    );
};

function shouldShowAttributes(attributes: ItemModel["attributes"], company: ItemModel["company"]): boolean {
    return (attributes && attributes.length > 0) || !!company;
}


export default Item;
