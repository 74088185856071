import SaleItem from "../sale/model/SaleItem";
import {SaleType} from "../state/sale/SaleStore";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import useHistoryPushNew from "./useHistoryPushNew";
import cartActions from "../state/shoppingCart/ShoppingCartActions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import getItemPath from "./route/getItemPath";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AppLink from "../component/theme/AppLink";
import Currency from "../component/Currency";
import Grid from "@material-ui/core/Grid";
import NumberField from "../component/form/NumberField";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CompanyLogo from "../component/CompanyLogo";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AttributeType from "../model/attribute/AttributeType";
import categoryAttributeQuery from "../state/categoryPage/filter/attribute/category/CategoryAttributeQuery";
import {Color} from "@material-ui/lab/Alert/Alert";
import {ShopAvailability} from "../model/shopprice/ShopAvailability";
import Alert from "@material-ui/lab/Alert";
import authQuery from "../state/auth/AuthQuery";
import RoleName from "../model/auth/RoleName";
import Flag from "../component/item/Flag";
import {ListItemText} from "@material-ui/core";
import useTenantSwitch from "./useTenantSwitch";
import profileQuery from "../state/user/ProfileQuery";

interface ItemRowProps {
    item: SaleItem;
    type?: SaleType;
    altLinkTarget?: string;
}

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    discountQuantity: {
        fontSize: "small"
    },
    image: {
        width: '100%',
    },
    discounted: {
        textDecoration: "line-through",
        fontWeight: 'bold'
    },
    offer: {
        fontSize: 'large',
        fontWeight: 'bold'
    },
    itemAttributes: {
        marginTop: 0,
        marginBottom: 0,
        listStyleType: "square",
        color: theme.palette.text.secondary
    },
    attributeItem: {
        color: theme.palette.text.primary
    },
    availabilityAlert: {
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
    },
    flags: {
        marginTop: 0
    },
    itemCell: {
        cursor: "pointer",
        flexBasis: "40%",
        flexGrow: 1,
    },
    imageCell: {
        flexBasis: "10%",
        flexGrow: 0,
    },
    priceCell: {
        flexBasis: "10%"
    },
    orderCell: {
        flexBasis: "20%"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap"
    }
}));

export const SeverityStyles: { [key: string]: Color } = {
    [ShopAvailability.IN_STOCK]: "success",
    [ShopAvailability.ON_REQUEST]: "info",
    [ShopAvailability.OUT_OF_STOCK]: "error"
};

export const ItemRow: FC<ItemRowProps> = ({item, altLinkTarget}) => {
    const {t} = useTranslation();
    const tenantSwitch = useTenantSwitch();
    const userProfile = profileQuery.useProfile();
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();
    const [quantity, setQuantity] = useState(1);
    const image = item.image;
    const volumeDiscounts = item.volumeDiscounts;
    const attributes = item.attributes || [];
    const flags = attributes.filter(({attributeType}) => attributeType === AttributeType.BOOLEAN);

    const displayBuyingPrice = tenantSwitch.selectForBaseOrTenant(userProfile.displayBuyingPrice, true, userProfile.displayBuyingPrice);

    const valueAttributes: { attributeName: string, value?: string, attributeMeasure?: string | null }[] =
        attributes.filter(({attributeType}) => attributeType !== AttributeType.BOOLEAN);
    const attributeNames = categoryAttributeQuery.useAllNames();
    const isAllowedPreview = authQuery.useHasRole(RoleName.ITEM_PREVIEW);

    function addToCart() {
        cartActions.addItemBySlug(item.slug, quantity)
            .then(() => cartActions.loadCart());
    }

    if (isAllowedPreview) {
        attributeNames.forEach(name => {
            if (valueAttributes.every(({attributeName}) => attributeName !== name)) {
                valueAttributes.push({attributeName: name});
            }
        });
    }

    return <TableRow data-cy="vehicle-list-item" data-cy2={`vehicle-list-item-${item.slug}`} className={classes.row} hover>
            <TableCell align="center" className={classes.imageCell}>
                {
                    image &&
                    <img className={classes.image}
                         loading={'lazy'}
                         src={image.locationSmall}
                         alt={image.description}/>
                }
            </TableCell>
            <TableCell data-cy={'itemrow-itemCell'} component="th" scope="row" className={classes.itemCell}
                       onAuxClick={(event) =>  {
                           if (event.button === 1) {
                               event.preventDefault();
                               window.open(altLinkTarget ? altLinkTarget : getItemPath(item.slug));
                           }
                       } }
                       onClick={() => push(altLinkTarget ? altLinkTarget : getItemPath(item.slug))}
            >
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">

                    {item.company && item.company.name + " "}
                    <Typography color="secondary" component="span"
                                variant="inherit">{item.name} </Typography>
                    <Typography color="primary" component="span"
                                variant="inherit">{item.itemNumber}</Typography>

                </Box>
                {
                    item.attributeName ? <ListItemText secondary={item.attributeName}/> : null
                }
                {
                    valueAttributes.length > 0 ?
                        <ul data-cy={'itemrow-attribute-list'} className={classes.itemAttributes}>
                            {valueAttributes.map(({attributeName, attributeMeasure, value}) =>
                                <li key={attributeName} data-cy={'attribute-list-item-' + attributeName}>
                                    {value
                                        ? <span
                                            className={classes.attributeItem}>{attributeName}: {value} {attributeMeasure}</span>
                                        : <Box component="span" color="error.main">{attributeName}</Box>
                                    }
                                </li>
                            )}
                        </ul> : null
                }
                {
                    flags.length > 0 ?
                        <Grid container className={classes.flags}>
                            {
                                flags.map(({attributeName}) => (
                                    <Grid key={attributeName} item xs={4}>
                                        <Flag>{attributeName}</Flag>
                                    </Grid>
                                ))
                            }
                        </Grid> : null
                }
                <AppLink to={altLinkTarget ? altLinkTarget : getItemPath(item.slug)}></AppLink>


            </TableCell>
            <TableCell className={classes.priceCell}>
                {
                    item.originalPrice ?
                        <Grid container direction={"column"}>
                            {
                                displayBuyingPrice ? <Grid item xs={12} data-cy={'itemrow-priceCell'}>

                                    <div className={item.discountedPrice ? classes.discounted : classes.offer}>
                                        {item.variations?.length ?
                                            <Typography component={'div'}>verschiedene ab</Typography> : null}
                                        <Currency data-cy={'itemrow-originalPrice'} value={item.originalPrice}/>
                                    </div>

                                    {item.discountedPrice &&
                                    <div className={classes.offer}>
                                        {item.variations?.length ? <Typography>verschiedene ab</Typography> : null}
                                        <Currency data-cy={'itemrow-discountedPrice'} value={item.discountedPrice}/>
                                    </div>}
                                    {volumeDiscounts.length > 0 && volumeDiscounts.map(({price, quantity}, index) => (
                                        <Typography
                                            key={index}
                                            className={classes.discountQuantity}
                                            component="div"
                                        >{t("item-price-volume-discount", {quantity})} <Currency
                                            className={classes.offer}
                                            value={price}/></Typography>
                                    ))}
                                </Grid> : null
                            }
                            {item?.availability ?
                                <Grid item xs={12} data-cy={'itemrow-availability-grid'}>
                                    <Alert data-cy="item-availability"
                                           className={classes.availabilityAlert}
                                           severity={SeverityStyles[item.availability]}>
                                        {t(`order-enum-shop-availability-${item.availability}`)}
                                    </Alert>
                                </Grid> : null
                            }
                        </Grid> : null
                }
            </TableCell>
            <TableCell className={classes.orderCell}>
                {
                    item.originalPrice ?
                        <Grid data-cy={'itemrow-orderCell'} alignItems={"flex-end"} container direction={"column"}>

                            <Grid item xs={6}>
                                {item.company &&
                                <CompanyLogo className={classes.image} company={item.company}/>}
                            </Grid>

                            <Grid xs={12} item>
                                {
                                    !item.variations?.length ?
                                        <Grid container direction={"row"} wrap={"wrap"} justify={"space-between"}
                                              alignItems={"center"}>
                                            <Grid style={{minWidth: '100px'}} item xs={6}>
                                                <NumberField
                                                    value={quantity}
                                                    onChange={setQuantity}
                                                    size="small"
                                                    variant="outlined"
                                                    label={t("order-amount-input-label")}
                                                />
                                            </Grid>
                                            <Grid style={{minWidth: '50px'}} item xs={4}>

                                                <IconButton onClick={() => addToCart()}>
                                                    <AddShoppingCartIcon/>
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </Grid>
                        : null
                }
            </TableCell>
        </TableRow>;
}

export default ItemRow;