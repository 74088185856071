import {transaction} from "@datorama/akita";
import {Observable} from "rxjs";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import PaymentType from "../../model/checkout/PaymentType";
import ShippingType from "../../model/checkout/ShippingType";
import Item from "../../model/item/Item";
import ShoppingCartItem from "../../model/shoppingCart/ShoppingCartItem";
import NotFoundError from "../../service/error/NotFoundError";
import shoppingCartService, {ApplyVoucherResponseCode, ShoppingCartService} from "../../service/ShoppingCartService";
import authQuery from "../auth/AuthQuery";
import shoppingCartQuery, {ShoppingCartQuery} from "./ShoppingCartQuery";
import shoppingCartStore, {ID, ShoppingCartStore} from "./ShoppingCartStore";
import ItemSlug from "../../model/item/ItemSlug";

export interface Dependencies {
    store: ShoppingCartStore;
    query: ShoppingCartQuery;
    service: ShoppingCartService;
    isLoggedIn: Observable<boolean>;
}

export class ShoppingCartActions {
    private readonly store = shoppingCartStore;
    private readonly query = shoppingCartQuery;
    private readonly service: ShoppingCartService = shoppingCartService;
    private readonly isLoggedIn = authQuery.isLoggedIn;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
        //this.createLoadRequests().subscribe();
    }

    public createLoadRequests(): Observable<Promise<void>> {
        return (
            this.isLoggedIn
                .pipe(distinctUntilChanged())
                .pipe(filter((isLoggedIn) => isLoggedIn))
                .pipe(map(() => this.loadCart()))
        );
    }

    @transaction()
    public async loadCart(): Promise<void> {
        this.store.update({
            loading: true,
            error: null
        });
        try {
            const result = await this.service.find();
            this.store.setShopOrder(result);
        } catch (error) {
            if (error instanceof NotFoundError) {
                this.store.set([]);
            } else {
                this.store.setError(error);
            }
        } finally {
            this.store.setLoading(false);
        }
    }

    public async addFormItems(items: { slug: ItemSlug, quantity: number }[]): Promise<(ItemSlug | undefined)[]> {
        //execute and await the first one to ensure that a shopping cart exists for the rest of them
        const first = await this.addItemBySlug(items[0].slug, items[0].quantity);
        const rest = await Promise.all(items.slice(1).map(item => this.addItemBySlug(item.slug, item.quantity)));
        return [first, ...rest];
    }

    public async addItemBySlug(slug: ItemSlug, quantity: number = 1): Promise<ItemSlug | undefined> {
        const timestamp = Date.now();
        this.store.update({
            loading: true,
            error: null,
            timestamp
        });
        try {
            await this.service.addItem({slug: slug, quantity});
        } catch (error) {
            console.log(error)
            return undefined;
        } finally {
            this.store.setLoading(false);
        }
        return slug
    }

    @transaction()
    public async addItem(item: Item, quantity: number = 1): Promise<void> {
        const slug = item.slug;
        const current = this.query.getEntity(slug);
        if (current) {
            this.store.update(slug, {quantity: quantity + current.quantity});
        } else {
            this.store.add({slug, item, quantity, comment: ""});
        }
        const timestamp = Date.now();
        this.store.update({
            loading: true,
            error: null,
            timestamp
        });
        try {
            const result = await this.service.addItem({slug: item.slug, quantity});
            if (timestamp >= this.currentTimestamp()) {
                this.store.setShopOrder(result);
            }
        } catch (error) {
            if (timestamp >= this.currentTimestamp()) {
                this.store.setError(error);
                throw error;
            }
        } finally {
            if (timestamp >= this.currentTimestamp()) {
                this.store.setLoading(false);
            }
        }
    }

    @transaction()
    public async setItem(item: Item, quantity: number = 1, comment: string = ""): Promise<void> {
        const slug = item.slug;
        const current = this.query.getEntity(slug);
        if (current) {
            this.store.update(slug, {quantity, comment});
        } else {
            this.store.add({slug, item, quantity, comment: ""});
        }
        const timestamp = Date.now();
        this.store.update({
            loading: true,
            error: null,
            timestamp
        });
        try {
            const result = await this.service.setItem({slug: item.slug, quantity});
            if (timestamp >= this.currentTimestamp()) {
                this.store.setShopOrder(result);
            }
        } catch (error) {
            if (timestamp >= this.currentTimestamp()) {
                this.store.setError(error);
                throw error;
            }
        } finally {
            if (timestamp >= this.currentTimestamp()) {
                this.store.setLoading(false);
            }
        }
    }

    @transaction()
    public async addSlug(slug: ID, quantity: number = 1): Promise<void> {
        // @ts-ignore
        await this.addItem({slug}, quantity);
    }

    private currentTimestamp(): number {
        const {timestamp} = this.store.getValue();

        return timestamp;
    }

    @transaction()
    public async removeItem(slug: ID): Promise<void> {
        if (this.query.hasEntity(slug)) {
            this.store.remove(slug);
            const timestamp = Date.now();
            this.store.update({
                loading: true,
                error: null,
                timestamp
            });
            try {
                const result = await this.service.removeItem(slug);
                if (timestamp >= this.currentTimestamp()) {
                    this.store.setShopOrder(result);
                }
            } catch (error) {
                if (timestamp >= this.currentTimestamp()) {
                    this.store.setError(error);
                    throw error;
                }
            } finally {
                if (timestamp >= this.currentTimestamp()) {
                    this.store.setLoading(false);
                }
            }
        }
    }

    @transaction()
    public async setQuantity(slug: ID, quantity: number): Promise<void> {
        await this.updateAt(slug, {quantity});
    }

    private async updateAt(slug: ID, newState: Partial<ShoppingCartItem>) {
        if (this.query.hasEntity(slug)) {
            this.store.update(slug, newState);
            const {quantity, comment} = this.query.getEntity(slug) || {quantity: 1, comment: ""};
            const timestamp = Date.now();
            this.store.update({
                loading: true,
                error: null,
                timestamp
            });
            try {
                const result = await this.service.setItem({slug, quantity, comment});
                if (timestamp >= this.currentTimestamp()) {
                    this.store.setShopOrder(result);
                }
            } catch (error) {
                if (timestamp >= this.currentTimestamp()) {
                    this.store.setError(error);
                    throw error;
                }
            } finally {
                if (timestamp >= this.currentTimestamp()) {
                    this.store.setLoading(false);
                }
            }
        }
    }

    @transaction()
    public async setComment(slug: ID, comment: string): Promise<void> {
        await this.updateAt(slug, {comment});
    }

    @transaction()
    public async setPaymentType(paymentType: PaymentType): Promise<void> {
        this.store.update({
            loading: true,
            error: null
        });
        try {
            await this.service.updateSelectedPaymentType(paymentType);
            this.store.update({paymentType: paymentType});
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    @transaction()
    public async setShippingType(shippingType: ShippingType): Promise<void> {
        this.store.update({
            loading: true,
            error: null
        });
        try {
            await this.service.updateSelectedShippingType(shippingType);
            this.store.update({shippingType: shippingType});
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    @transaction()
    public async abort(orderId: string, timestamp: string, signature: string): Promise<void> {
        try {
            const result = await this.service.abort(orderId, timestamp, signature);
            this.store.setShopOrder(result);
        } catch (error) {
            throw error;
        }
    }

    public setVoucherCode(voucherCode: string) {
        this.store.update({
            voucher: {
                ...this.store.getValue().voucher ?? {},
                code: voucherCode
            }
        });
    }

    @transaction()
    public async applyVoucherCode(voucherCode: string): Promise<void> {
        try {
            this.setVoucherCode(voucherCode);
            const response = await this.service.applyVoucher(voucherCode);
            this.store.update({
                applyVoucherResponse: response
            });
            if (response.responseCode === ApplyVoucherResponseCode.VALID) {
                await this.loadCart();
            }
        } catch (error) {
            throw error;
        }
    }

    @transaction()
    public async removeVoucherCode(): Promise<void> {
        this.setVoucherCode('');
        await this.service.removeVoucher();
        this.store.update({
            applyVoucherResponse: undefined
        });
        await this.loadCart();
    }

}

export default new ShoppingCartActions();
