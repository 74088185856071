import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import {CatalogueBaseUrl} from "../../app/config/Config";
import {KeyboardArrowRightRounded} from "@material-ui/icons";

export const CatalogPage: FC = () => {
    const {t} = useTranslation();
    const [group, setGroup] = useState("");
    const classes = useStyles(undefined);
    const breadcrumbs: Breadcrumb[] = [
        {text: t("catalog-breadcrumb"), to: "/pgo-malaguti.html"}
    ];
    const baseUrl = CatalogueBaseUrl

    useEffect(() => {
        setGroup(Catalogue[0].name)
    }, [])


    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("catalog-page-title")}>
                {
                    console.log('layoputing issue here.')
                }
                <img
                    className={classes.logo}
                    alt="malaguti"
                    src="/media/v1/standard/malaguti_logo.png"
                />
                <img
                    className={classes.logo}
                    alt="pgo"
                    src="/media/v1/standard/pgo_logo.jpg"
                />
            </ContentHeader>
            <Grid container>
                <Grid item md={3}>
                    <Typography variant="h5" component="h2">{t("catalog-page-title")}</Typography>
                    <List>
                        {Catalogue
                            .map(({name}) =>
                                <ListItem
                                    key={name}
                                    button
                                    selected={name === group}
                                    onClick={() => setGroup(name)}
                                >
                                    <ListItemText primary={
                                        <div className={classes.link}>
                                            {name === group && <KeyboardArrowRightRounded className={classes.centerVertically} color={"secondary"} />}
                                            <Typography className={classes.centerVertically} color="secondary" component="span">
                                                {name}
                                            </Typography>
                                        </div>
                                    } />
                                </ListItem>
                            )
                        }
                    </List>
                </Grid>
                <Grid item md={8}>
                    {Catalogue
                        .filter(({name}) => name === group)
                        .map(({name, files}) =>
                            <Fragment key={name}>
                                <Typography variant="h5" component="h2">{name}</Typography>
                                <List>
                                    {files.map(file =>
                                        <ListItem
                                            key={file}
                                            button
                                            component="a"
                                            href={`${baseUrl}/${name}/${file}.pdf`}
                                        >
                                            <ListItemText primary={<Typography color="secondary" component="span">{file}</Typography>}/>
                                        </ListItem>
                                    )}
                                </List>
                            </Fragment>
                        )}
                </Grid>
            </Grid>
        </Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    root: {},
    logo: {
        maxWidth: theme.spacing(32),
        verticalAlign: "middle",
        border: "none",
        paddingLeft: theme.spacing(2)
    },
    link: {
        height: '100%'
    },
    centerVertically: {
        verticalAlign: 'middle'
    }
}));

const Catalogue: Array<{ name: string, files: string[] }> = [
    {
        "name": "Dingo",
        "files": [
            "Dingo 50 2007-2011",
            "Dingo B9 2009-2011",
            "Dingo Exciting 50 2009-2011"
        ]
    },
    {
        "name": "Malaguti Enduro",
        "files": [
            "ATV 50 Grizzly Quad 2004-2010",
            "Drakon 50 2005-2008",
            "Grizzly RCX 10 1998-2001",
            "Grizzly RCX 10 2002-2007",
            "Grizzly RCX 10 2008-2010",
            "Grizzly RCX 12 1998-2001",
            "Grizzly RCX 12 2002-2007",
            "Grizzly RCX 12 2008-2010",
            "XSM 50 2003",
            "XSM 50 2004-2006",
            "XSM 50 2007",
            "XSM 50 2008-2009",
            "XSM 50 2010",
            "XTM 50 2003",
            "XTM 50 2004-2006",
            "XTM 50 2007",
            "XTM 50 2008-2009",
            "XTM 50 2008",
            "XTM 50 2010"
        ]
    },
    {
        "name": "Malaguti Roller",
        "files": [
            "Centro 125IE 2008-2011",
            "Centro 160IE 2009-2011",
            "Centro 50 2T 1999",
            "Centro 50 4T 2009-2011",
            "Ciak 50 2006",
            "Crosser 50 1996-1999",
            "F10 Jetline 1997-1999",
            "Firefox 50 AC 2004-2008",
            "Firefox 50 LC 1999-2000",
            "Firefox 50 LC 2001-2003",
            "Firefox 50 LC 2004-2007",
            "Firefox 50 LC 2006-2008",
            "Madison 125 2000-2003",
            "Madison 250 1999-2003",
            "Phantom F12 AC 1999-2000",
            "Phantom F12 AC 2001-2004",
            "Phantom F12 AC 2002-2003",
            "Phantom F12 AC 2004-2005",
            "Phantom F12 AC 2005-2007",
            "Phantom F12 LC 1999-2000",
            "Phantom F12 LC 2004-2007",
            "Phantom F12 R AC 2007-2008",
            "Phantom F12 R LC 2008-2011",
            "Spidermax GT 500 2008-2011",
            "Yesterday 1998-1999",
            "Yesterday 50 2000"
        ]
    },
    {
        "name": "PGO",
        "files": [
            "Big Max 50 1996-2003",
            "Big Max 50 2004-2011",
            "Big Max 50 Chromo 2001-2003",
            "Comet 50 1994-1997",
            "Comet 50 1998-2004",
            "G-Max 50 2005",
            "Galaxy 50 1994-1997",
            "Ligero RS 50 2005-2008",
            "Ligero ab 2010",
            "Mega 50 1998-2000",
            "PM-X 50 1998-2001",
            "PM-X 50 Sport 2000-2002",
            "Rodoshow 2000-2003",
            "Star 50 I 1990-1993",
            "Star 50 II 1994-1995",
            "Star 80 I 1990-1993",
            "T-Rex 125 2T 1999-2001",
            "T-Rex 125 4T 1999-2003",
            "T-Rex 150 4T 1999-2003",
            "T-Rex 50 1999-2003",
            "TR3-50 Trike 2003-2005"
        ]
    },
    {
        "name": "PGO Quads",
        "files": [
            "Bugrider 250 2005-2006",
            "Bugrider 50+150 2006",
            "Bugxter 150 2004-2005",
            "X-Rider 150 2004-2005",
            "X-Rider 50 2004-2005",
            "XL-Rider 150 2006",
            "XL-Rider 50 2006"
        ]
    }
]

export default CatalogPage;
