import explodedViewService from "../../service/ExplosionDrawingService";
import explodedViewQuery from "./ExplodedViewQuery";
import explodedViewStore, {ID} from "./ExplodedViewStore";

export class ExplodedViewActions {
    private readonly store = explodedViewStore;
    private readonly service = explodedViewService;
    private readonly query = explodedViewQuery;

    public async load(slug: string): Promise<void> {
        if (this.shouldSkipLoading(slug)) {
            return;
        }
        try {
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                slug
            });
            const {boxes, ...other} = await this.service.findBySlug(slug);
            this.store.set(boxes);
            this.store.update(other)
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(next: string) {
        const current = this.query.getSlug();

        return current === next && this.hasNoError();
    }

    private hasNoError(): boolean {
        const {error} = this.query.getValue();

        return !error;
    }

    public async highlight(id: ID): Promise<void> {
        const {highlight} = this.query.getValue();

        if (highlight !== id) {
            this.store.update({highlight: id});
        }
    }

    public async dehighlight(): Promise<void> {
        this.store.update({highlight: undefined});
    }

    public async select(id: ID): Promise<void> {
        const current = this.query.getActiveId();

        if (current !== id) {
            this.store.setActive(id);
        }
    }
}

export default new ExplodedViewActions();
