import {transaction} from "@datorama/akita";
import itemService, {ItemService} from "../../service/ItemService";
import vehicleStore, {FilterState, MotorItemStore} from "./MotorItemStore";

export interface Dependencies {
    store: MotorItemStore;
    service: ItemService;
}

export class MotorItemActions {
    private readonly store = vehicleStore;
    private readonly service = itemService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public async findAll(motor: string, category: string): Promise<void> {
        const filter: FilterState = {motor, category};
        if (this.isFilterEqual(filter)) {
            return;
        }
        try {
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.service.findAllByMotorAndCategory(motor, category);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private isFilterEqual(next: FilterState) {
        const current = this.store.getValue().filter;

        return next.motor === current.motor && next.category === current.category;
    }

    public async select(slug: string): Promise<void> {
        const current = this.store.getValue();

        if (current.active !== slug) {
            this.store.setActive(slug);
        }
    }

    public async deselect(): Promise<void> {
        this.store.setActive(null);
    }
}

export default new MotorItemActions();
