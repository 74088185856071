import service, {CompanyService} from "../../../../service/CompanyService";
import store, {CompanyStore} from "./CompanyStore";

export interface Dependencies {
    store: CompanyStore;
    service: CompanyService;
}

export class CompanyActions {
    private readonly store = store;
    private readonly service: CompanyService = service;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async load(category: string): Promise<void> {
        if (this.shouldSkipLoading(category)) {
            return;
        }

        try {
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                category
            });
            const result = await this.service.findByCategorySlug(category);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(next: string): boolean {
        return this.isCategoryEqual(next) && this.hasNoError();
    }

    private isCategoryEqual(next: string): boolean {
        const current = this.store.getValue().category;

        return current === next;
    }

    private hasNoError(): boolean {
        const { error } = this.store.getValue();

        return !error;
    }
}

export default new CompanyActions();
