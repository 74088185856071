import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import {useObservable} from "../app/common/useObservable";
import ContentHeader from "../app/component/ContentHeader";
import VehicleItemTree from "../app/component/vehicle/itemtree/VehicleItemTree";
import ProductCategoryList from "../app/component/vehicle/ProductCategoryList";
import {GridDefaultSpacing} from "../app/config/Config";
import Vehicle from "../app/model/Vehicle";
import VehicleItem from "../app/model/VehicleItem";
import NotFoundError from "../app/service/error/NotFoundError";
import vehicleService from "../app/service/VehicleService";
import categoryActions from "../app/state/category/CategoryActions";
import categoryQuery from "../app/state/category/CategoryQuery";
import sectionActions from "../app/state/section/SectionActions";
import vehicleActions from "../app/state/vehicle/VehicleActions";
import vehicleCategoryActions from "../app/state/vehiclecategory/VehicleCategoryActions"
import vehicleCategoryQuery from "../app/state/vehiclecategory/VehicleCategoryQuery"
import vehicleItemActions from "../app/state/vehicleitem/VehicleItemActions"
import vehicleItemQuery from "../app/state/vehicleitem/VehicleItemQuery"
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import {makeStyles} from "@material-ui/core/styles";
import VehicleItemsShortcutNavigation from "../app/component/vehicle/VehicleItemsShortcutNavigation";
import {getTireSearchLinkFromTireDimensions} from "../app/common/util/tireDimensions";
import {printPromiseError} from "../app/common/util/errorHelper";
import {useRememberScrollY} from "../app/component/hooks/RememberScrollY";

const FAKE_SLUG_TIRE_FRONT = 'fake-slug-tire-front';
const FAKE_SLUG_TIRE_BACK = 'fake-slug-tire-back';
const CATEGORY_NAME_TIRES = 'Reifen';

interface Params {
    section: string;
    vehicle: string;
    category: string;
}

const useStyles = makeStyles(theme => ({
    categoryName: {
        color: theme.palette.warning.main,
        fontWeight: "bold"
    }
}))

export const VehicleItemsPage: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const {section, vehicle, category} = useParams<Params>();
    const items = vehicleItemQuery.useAll();
    const categories = vehicleCategoryQuery.useAll();
    const loading = vehicleItemQuery.useIsLoading();
    const error = vehicleItemQuery.useError();
    const [vehicleData, setVehicleData] = useState<Vehicle | null>(null);
    const categoryData = useObservable(categoryQuery.current, null);
    const breadcrumbs: Breadcrumb[] = [];
    const isEmpty = items.length === 0;

    useRememberScrollY(!loading)


    useEffect(() => {
        if (vehicleData) {
            vehicleItemActions.findAll(vehicle, category)
                .then(result => {
                    if (result.some(value => value.categoryNames.includes(CATEGORY_NAME_TIRES)) && (vehicleData?.vehicleGroup?.name !== 'Mopedauto')) {
                        vehicleItemActions.set([...result,
                            pushDummyNodeForTireFilters('vorne', t('vehicle-items-general-selection-front-tire', {tireDimenions: vehicleData.tireFront}), FAKE_SLUG_TIRE_FRONT),
                            pushDummyNodeForTireFilters('hinten', t('vehicle-items-general-selection-back-tire', {tireDimenions: vehicleData.tireBack}), FAKE_SLUG_TIRE_BACK)
                        ])
                    }
                })
                .catch(printPromiseError);
            vehicleCategoryActions.findAll(vehicle, category)
                .catch(printPromiseError);
        }
    }, [vehicle, category, vehicleData, t]);

    useEffect(() => {
        vehicleService.read(vehicle)
            .then(setVehicleData)
            .catch(() => {
            })
        ;
    }, [vehicle]);

    useEffect(() => {
        categoryActions.loadForVehicle(section, vehicle).catch(error => console.log(error));
    }, [section, vehicle]);

    useEffect(() => {
        categoryActions.select(category);
    }, [category]);

    useEffect(() => {
        vehicleActions.select(vehicle).catch(error => console.log(error));
    }, [vehicle]);

    useEffect(() => {
        sectionActions.select(section).catch(error => console.log(error));
    }, [section]);

    // Tire links should not be displayed for moped cars.
    if (vehicleData && categoryData) {
        let sectionPath = "";
        const vehiclesPrefix = "fahrzeuge";
        const vehicleGroup = vehicleData.vehicleGroup;
        const section = vehicleGroup.section;
        if (section) {
            sectionPath = `/${section.slug}`;
            breadcrumbs.push({
                text: t("vehicle-group-breadcrumb-text"),
                to: `${sectionPath}/${vehiclesPrefix}/`
            })
        }
        breadcrumbs.push(
            {
                text: vehicleGroup.name,
                to: `${sectionPath}/${vehiclesPrefix}/${vehicleGroup.slug}/`
            },
            {
                text: vehicleData.company.name,
                to: `${sectionPath}/${vehiclesPrefix}/${vehicleGroup.slug}/${vehicleData.company.slug}`
            },
            {
                text: vehicleData.name,
                to: `${sectionPath}/fahrzeug/${vehicleData.slug}.html`
            },
            {
                text: categoryData.name,
                to: `${sectionPath}/fahrzeug/${vehicleData.slug}/${categoryData.slug}/`
            }
        );
    }

    const getItemLink = (item: VehicleItem) => {
        if (vehicleData && item.slug === FAKE_SLUG_TIRE_FRONT) {
            return getTireSearchLinkFromTireDimensions(vehicleData.tireFront)
        } else if (vehicleData && item.slug === FAKE_SLUG_TIRE_BACK) {
            return getTireSearchLinkFromTireDimensions(vehicleData.tireBack)
        } else {
            return `/${section}/fahrzeug/${vehicle}/${item.slug}.html`;
        }
    }


    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs}
                               data-cy="vehicle-title"
                               header={vehicleData ? `${vehicleData.company.name} ${vehicleData.name}` : ''}/>
                <Grid container spacing={GridDefaultSpacing}>
                    <Grid item md={3}>
                        <ProductCategoryList/>
                    </Grid>
                    <Grid item md>
                        <Typography
                            variant="h4"
                            component="h2"
                            className={classes.categoryName}
                            gutterBottom
                        >{categoryData?.name}</Typography>
                        {loading ? <CircularProgress/> :
                            <Fragment>
                                {isEmpty ?
                                    <span>{t("vehicle-items-list-empty")}</span>
                                    :
                                    <Fragment>
                                        <VehicleItemsShortcutNavigation items={items}/>
                                        <VehicleItemTree items={items} categories={categories}
                                                         getLink={getItemLink}/>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
};

function pushDummyNodeForTireFilters(attributeName: string, name: string, itemSlug: string) {
   return {
        attributeName: attributeName,
        categoryNames: [CATEGORY_NAME_TIRES],
        volumeDiscounts: [],
        itemNumber: name,
        companyName: '',
        name: name,
        slug: itemSlug as any
    };
}

export default VehicleItemsPage;
