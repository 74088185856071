import {useEffect} from "react";
import windowAPI from "./windowAPI";

export function useRememberScrollY(trigger: boolean, page?: string) {
    const target = page ? page : window.location.toString()
    useEffect(() => {
            return () => {
                windowAPI.storeY(target)
            }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (trigger) {
            windowAPI.scrollToYForPage(target)
        }
    }, [trigger, target])
}
