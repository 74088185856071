import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, {FC} from "react";
import {GridDefaultSpacing} from "../../../config/Config";
import Reference from "../../../model/Reference";
import {ImageLink} from "../../ImageLink";
import useCategorySelection from "./useCategorySelection";


export const CategoryImages: FC = () => {
    const {categories, loading} = useCategorySelection();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <CategorySelectionImages
                categories={categories}
            />
        );
    }
};


export interface CategoryImagesProps {
    categories: Reference[]
}

export const CategorySelectionImages: FC<CategoryImagesProps> = ({categories}) => {

    return (
        <Grid container spacing={GridDefaultSpacing}>
            {categories.map(({name, slug}) => (
                <Grid key={slug} item md={4}>
                    <ImageLink
                        path={slug}
                        name={name}
                        imageUrl={`/img/produkte/${slug}.jpg`}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default CategoryImages;
