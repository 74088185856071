import React, {FC, Fragment, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import shoppingCartActions from "../../state/shoppingCart/ShoppingCartActions";
import ItemSlug from "../../model/item/ItemSlug";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {
    AddingFormRow,
    BACKEND_ERROR_STATUS,
    EMPTY_SLUG,
    filterBySlugs,
    PARTIAL_SUCCESS_STATUS,
    SUCCESS_STATUS
} from "./ShoppingCartImport";
import {Color} from '@material-ui/lab/Alert';
import {StatusSnackbar} from "./StatusSnackbar";

const useStyles = makeStyles({
    padded: {
        padding: "5px"
    },
    rowContainer: {
        verticalAlign: 'middle'
    },
    error: {
        padding: "5px",
        display: 'inline-block',
        color: "red",
        transform: "translateY(50%)"
    },
    formRow: {
        display: "block"
    }
})


export const AddingForm: FC = () => {
    const classes = useStyles(undefined);
    const {t} = useTranslation();
    const [rows, setRows] = useState<AddingFormRow[]>([{
        slug: EMPTY_SLUG,
        quantity: 0
    }])

    const [importStatus, setImportStatus] = useState<Color | undefined>(undefined);

    const handleCloseSnackbar = () => {
        setImportStatus(undefined);
    };

    const submit = () => {
        handleCloseSnackbar();
        shoppingCartActions.addFormItems(rows)
            .then(
                result => {
                    const tmp = filterBySlugs(rows.filter(row => row.slug !== EMPTY_SLUG),
                        result.filter(it => !!it) as ItemSlug[]).map(it => ({...it, error: true}))
                    setRows(tmp.length > 0 ? tmp : [{slug: EMPTY_SLUG, quantity: 0}]);
                    return tmp;
                })
            .then((result) => setImportStatus( result.length === 0 ? SUCCESS_STATUS : PARTIAL_SUCCESS_STATUS))
            .then(() => shoppingCartActions.loadCart())
            .catch(error => {setImportStatus(BACKEND_ERROR_STATUS);})
    }

    const editRow = (key: number) => (slug: ItemSlug, quantity: number) => {
        const newrows = [...rows]
        newrows[key] = {slug, quantity: parseInt(('' + quantity))}
        setRows(newrows[newrows.length - 1].slug ? [...newrows, {slug: EMPTY_SLUG, quantity: 0}] : newrows)
    }

    return <Fragment>
        <StatusSnackbar closeDelay={3000} show={!!importStatus} severity={importStatus} closeCallback={handleCloseSnackbar} />
        <div className={classes.padded}>
            <div>
                {
                    rows.map((it, index) => <AddingFormField key={index} error={it.error} callback={editRow(index)}
                                                             quantity={it.quantity} slug={it.slug}/>)
                }
            </div>
            <Button variant="contained" onClick={submit}>{t("adding-form-order-button")}</Button>
        </div>
    </Fragment>
}

interface AddingFormFieldProps {
    callback: (slug: ItemSlug, quantity: number) => void
    slug: ItemSlug
    quantity: number
    error?: boolean;
}

export const AddingFormField: FC<AddingFormFieldProps> = (props: AddingFormFieldProps) => {
    const {t} = useTranslation();
    const classes = useStyles(props);
    const submitItemNumber = (arg: ItemSlug) => {
        props.callback(arg, props.quantity)
    }

    const submitAmount = (arg: string) => {
        if(isNaN(+arg)) {
            const prev = arg.substr(0, arg.length - 1)
            if (isNaN(+prev)) {
                props.callback(props.slug, 0);
                return;
            }
            props.callback(props.slug, +prev);
            return;
        }
        props.callback(props.slug, +arg)
    }

    return <div data-cy="adding-form-row" className={classes.formRow}>

        <TextField
            variant="outlined"
            margin="dense"
            type="text"
            data-cy="adding-form-row-itemnumber"
            value={props.slug}
            onChange={(evt) => submitItemNumber(evt.target.value as ItemSlug)}>
        </TextField>
        <TextField
            variant="outlined"
            data-cy="adding-form-row-quantity"
            margin="dense"
            type="text"
            value={props.quantity}
            onChange={(evt) => submitAmount(evt.target.value)}
        >
        </TextField>
        {
            props.error && <Typography component={"div"} className={classes.error}>{t('cart-form-invalid-input')}</Typography>
        }
    </div>
}

export default AddingForm;


