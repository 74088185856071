import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import FilteredItem from "../../../model/item/FilteredItem";
import {ItemFilter} from "../../../service/ItemService";
import pageSize from "../../common/PageSize";

export type Entity = FilteredItem;

export type ID = string;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    filter: ItemFilter;
    totalItemsCount: number;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        filter: {
            "category.slug": "",
            query: "",
            sort: "sort.name",
            page: 0,
            size: pageSize.load()
        },
        totalItemsCount: -1
    };
}

@StoreConfig({
    name: "item",
    resettable: true,
    idKey: "slug"
})
export class ItemStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }

    public updateFilterAttribute(attributeSlug: string, attributeValue: string | null | number) {
        const current = this.getValue().filter;
        const newFilter = {...current};
        if (attributeValue != null) {
            newFilter[attributeSlug] = attributeValue;
        } else {
            delete newFilter[attributeSlug];
        }

        this.update({
            filter: newFilter
        })
    }

    public resetFilter(): void {
        const current = this.getValue().filter;
        const newFilter = {
            query: current.query,
            page: 0,
            size: current.size,
            sort: current.sort,
            "category.slug": current["category.slug"]
        };

        this.update({
            filter: newFilter
        });
    }
}

export default new ItemStore();
