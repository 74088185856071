import ItemSlug from "../model/item/ItemSlug";
import Preorder from "../model/preorder/Preorder";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class PreorderService extends AuthorizedHttpService {
    public async findCurrent(): Promise<Preorder> {
        return this.get("/preorders/", {valid: true});
    }

    public async findOpen(): Promise<Preorder> {
        return this.get("/preorders/", {open: true});
    }

    public async create(code: string): Promise<void> {
        return this.post("/preorders/", undefined, {code});
    }

    public async checkout(deliveryDate: string, comment: string): Promise<void> {
        return this.put("/preorders/", undefined, {deliveryDate, comment});
    }

    public async addItem(slug: ItemSlug, quantity: number): Promise<Preorder> {
        return this.post("/preorders/item/", undefined, {slug, quantity});
    }

    public async setItem(slug: ItemSlug, quantity: number): Promise<Preorder> {
        return this.put("/preorders/item/", undefined, {slug, quantity});
    }

    public async removeItem(slug: ItemSlug): Promise<Preorder> {
        return this.delete(`/preorders/item/${slug}`);
    }
}

export default new PreorderService();
