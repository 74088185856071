import {CategorySlug} from "../model/Category";
import Reference from "../model/Reference";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class KeywordService extends AuthorizedHttpService {
    public async findAllBySection(slug: string): Promise<Reference<CategorySlug>[]> {
        return this.get(`/keywords/section/${slug}/`);
    }
}

export default new KeywordService();
