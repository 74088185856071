import {akitaDevtools} from "@datorama/akita";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {setupTranslations} from "./setupTranslations";
import {isDev} from "./toggles";
import {printPromiseError} from "./app/common/util/errorHelper";

setupTranslations().catch(printPromiseError);

if (isDev()) {
    akitaDevtools();
}

declare var cookieconsent: any;
document.addEventListener('DOMContentLoaded', function () {
    cookieconsent.run({
        "notice_banner_type": "simple",
        "consent_type": "express",
        "palette": "light",
        "language": "de",
        "page_load_consent_levels": ["strictly-necessary"],
        "notice_banner_reject_button_hide": false,
        "preferences_center_close_button_hide": false,
        "website_name": "Scootertech.at"
    });
});

if (isDev()) {
    // material ui transitions produce a deprecation warning
    ReactDOM.render(<React.StrictMode><App/></React.StrictMode>, document.getElementById("root"));
} else {
    ReactDOM.render(<App/>, document.getElementById("root"));
}
