import {Path} from "history";
import {useHistory} from "react-router-dom";

export function useHistoryPushNew(): (path: Path) => void {
    const history = useHistory();

    return function push(path: Path) {
        if (path !== history.location.pathname) {
            history.push(path);
        }
    };
}

export default useHistoryPushNew;
