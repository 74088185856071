import {transaction} from "@datorama/akita";
import sectionActions from "../section/SectionActions";
import cartActions from "./cart/CartActions";
import categoryActions from "./category/CategoryActions";
import itemActions from "./item/ItemActions";
import subCategoryActions from "./subcategory/SubCategoryActions";
import subSubCategoryActions from "./subsubcategory/SubCategoryActions";

export class PreorderActions {
    private readonly sectionActions = sectionActions;
    private readonly categoryActions = categoryActions;
    private readonly subCategoryActions = subCategoryActions;
    private readonly subSubCategoryActions = subSubCategoryActions;
    private readonly itemActions = itemActions;
    private readonly cartActions = cartActions;

    @transaction()
    public async navigateToSection(section: string): Promise<void> {
        await Promise.all([
            this.sectionActions.select(section),
            this.categoryActions.load(section),
            this.cartActions.load()
        ]);
    }

    @transaction()
    public async navigateToCategory(section: string, category: string): Promise<void> {
        this.sectionActions.select(section);
        this.categoryActions.load(section);
        this.categoryActions.select(category);
        const [subCategory] = await this.subCategoryActions.load(category);
        this.navigateToSubCategory(section, category, subCategory.slug);
        this.cartActions.load();
    }

    @transaction()
    public async navigateToSubCategory(section: string, category: string, subcategory: string): Promise<void> {
        this.sectionActions.select(section);
        this.categoryActions.load(section);
        this.categoryActions.select(category);
        this.subCategoryActions.load(category);
        this.subCategoryActions.select(subcategory);
        this.subSubCategoryActions.load(subcategory);
        this.itemActions.load(subcategory);
        this.cartActions.load();
    }
}

export default new PreorderActions();
