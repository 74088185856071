import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Section, {SectionSlug} from "../../model/Section";

export type ID = SectionSlug;

export type Entity = Section;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
}

@StoreConfig({
    name: "section",
    idKey: "slug"
})
export class SectionStore extends EntityStore<State> {
}

export default new SectionStore();
