import {Query} from "@datorama/akita";
import orderArchiveStore, {createInitialState, OderArchiveState, OrderArchiveStore} from "./OrderArchiveStore";
import {useObservable} from "../../common/useObservable";
import {Order, OrderMetadata, Page} from "../../model/user/OrderArchive";

export class OrderArchiveQuery extends Query<OderArchiveState> {
    public readonly isLoading = this.selectLoading();
    public readonly error = this.selectError<Error | null>();
    public readonly current = this.select("current");
    public readonly page = this.select("page");

    constructor(
        protected store: OrderArchiveStore = orderArchiveStore
    ) {
        super(store);
    }

    public useError(): any {
        return useObservable(this.error, undefined);
    }

    public useIsLoading(): boolean {
        return useObservable(this.isLoading, false);
    }

    public usePage(): Page<OrderMetadata> {
        return useObservable(this.page, createInitialState().page);
    }

    public useCurrent(): Order|undefined {
        return useObservable(this.current, createInitialState().current);
    }
}

export default new OrderArchiveQuery();
