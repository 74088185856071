import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";

export const Flag: FC = ({ children }) => {
    const classes = useStyles(undefined);
    return (
        <Paper className={classes.root} elevation={0} data-cy="item-flag">
            {children}
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        textAlign: "center"
    }
}));

export default Flag;
