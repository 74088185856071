import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    content: {
        "& a": {
            color: theme.palette.secondary.dark,
            textDecoration: "none"
        },
        "& a:hover": {
            textDecoration: "underline"
        }
    }
}));

export interface DescriptionProps {
    children: string;
}

export const Description: FC<DescriptionProps> = ({ children }) => {
    const { t } = useTranslation();
    const classes = useStyles(undefined);
    return (
        <Fragment>
            <Typography variant="h5" component="h3">{t("item-description-heading")}</Typography>
            <Typography variant="body1" className={classes.content}>{parse(children)}</Typography>
        </Fragment>
    );
};

export default Description;
