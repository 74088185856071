import {transaction} from "@datorama/akita";
import categoryService from "../../service/CategoryService";
import categoryQuery from "./CategoryQuery";
import categoryStore, {Filter} from "./CategoryStore";

export class CategoryActions {
    private readonly store = categoryStore;
    private readonly query = categoryQuery;
    private readonly service = categoryService;

    @transaction()
    public async load(section: string): Promise<void> {
        const filter = {section};
        if (this.shouldSkipLoading(filter)) {
            return;
        }

        try {
            this.deselect();
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.service.findAllBySection(section);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    @transaction()
    public async loadForVehicle(section: string, vehicle: string): Promise<void> {
        const filter = {section, vehicle};
        if (this.shouldSkipLoading(filter)) {
            return;
        }

        try {
            this.deselect();
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.service.findAllCategoriesBySectionAndVehicle(section, vehicle);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    @transaction()
    public async loadForMotor(section: string, motor: string): Promise<void> {
        const filter = {section, motor};
        if (this.shouldSkipLoading(filter)) {
            return;
        }

        try {
            this.deselect();
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.service.findAllBySectionAndMotor(section, motor);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(filter: Filter) {
        return this.isFilterEqual(filter) && this.hasNoError();
    }

    private hasNoError() {
        const current = this.query.getValue();

        return !current.error;
    }

    private isFilterEqual(next: Filter) {
        const current = this.query.getValue().filter;
        // @ts-ignore
        return Object.entries(current).every(([key, value]) => next[key] === value)
    }

    public select(slug: string) {
        this.store.setActive(slug);
    }

    public deselect() {
        this.store.setActive(null);
    }

    @transaction()
    public async loadForPreorder(section: string): Promise<void> {
        const filter = {section, preorder: true};
        if (this.shouldSkipLoading(filter)) {
            return;
        }

        try {
            this.deselect();
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.service.findAllByParentForPreorder(section);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }
}

export default new CategoryActions();
