import {Query} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import backorderStore, {createInitialState, Backorder, BackorderState, BackorderStore} from "./BackorderStore";
import {Page} from "../../model/user/OrderArchive";

export class BackorderQuery extends Query<BackorderState> {
    public readonly isLoading = this.selectLoading();
    public readonly error = this.selectError<Error | null>();
    public readonly page = this.select("page");

    constructor(
        protected store: BackorderStore = backorderStore
    ) {
        super(store);
    }

    public useError(): any {
        return useObservable(this.error, null);
    }

    public useIsLoading(): boolean {
        return useObservable(this.isLoading, false);
    }

    public usePage(): Page<Backorder> {
        return useObservable(this.page, createInitialState().page);
    }
}

export default new BackorderQuery();
