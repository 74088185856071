import {transaction} from "@datorama/akita";
import signupService, {SignupService} from "../../service/SignupService";
import signupQuery, {SignupQuery} from "./SignupQuery";
import signupStore, {Entity, ID, SignupStore} from "./SignupStore";

export interface Dependencies {
    store: SignupStore;
    query: SignupQuery;
    service: SignupService;
}

export class SignupActions {
    private readonly store = signupStore;
    private readonly query = signupQuery;
    private readonly service: SignupService = signupService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public reset() {
        this.store.reset();
        this.update("numberOfEmployees", 0);
        this.update("country", "AT");
        this.update("dealer", true);
    }

    public update(key: ID, value: Entity["value"]) {
        this.store.upsert(key, {key, value, pristine: false});
    }

    @transaction()
    public async submit(): Promise<void> {
        this.store.update({
            loading: true,
            success: false,
            error: null
        });

        try {
            const entities = this.query.getAll()
            const form: FormData = entities
                .filter(({value}) => value !== undefined)
                .reduce((form, {key, value}) => {
                    // @ts-ignore
                    form.set(key, value);

                    return form;
                }, new FormData());
            await this.service.create(form);
            this.store.update({success: true});
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    public captchaSolved(): void {
        this.store.update({captcha: true});
    }
}

export default new SignupActions();
