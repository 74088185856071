import {TFunction} from "i18next";
import {useTranslation} from "react-i18next";
import Company from "../../model/Company";
import Section from "../../model/Section";
import VehicleGroup from "../../model/VehicleGroup";
import companyQuery from "../../state/company/CompanyQuery";
import sectionQuery from "../../state/section/SectionQuery";
import vehicleGroupQuery from "../../state/vehiclegroup/VehicleGroupQuery";
import Breadcrumb from "./Breadcrumb";

export function sectionBreadcrumb(section: Section) {
    return {
        text: section.name,
        to: `/${section.slug}/fahrzeuge/`
    };
}

export function vehicleGroupsBreadcrumb(t: TFunction, section: Section) {
    return {
        text: t("vehicle-group-breadcrumb-text"),
        to: `/${section.slug}/fahrzeuge/`
    };
}

export function vehicleGroupBreadcrumb(vehicleGroup: VehicleGroup) {
    const section = vehicleGroup.section;
    const sectionPath = section ? `/${section.slug}` : "";
    return {
        text: vehicleGroup.name,
        to: `${sectionPath}/fahrzeuge/${vehicleGroup.slug}`
    };
}

export function vehicleCompanyBreadcrumb(section: Section, vehicleGroup: VehicleGroup, company: Company) {
    return {
        text: company.name,
        to: `/${section.slug}/fahrzeuge/${vehicleGroup.slug}/${company.slug}`
    };
}

export function useVehicleSelectionBreadcrumbs() {
    const {t} = useTranslation();
    const section = sectionQuery.useActive();
    const vehicleGroup = vehicleGroupQuery.useActive();
    const company = companyQuery.useActive();
    const breadcrumbs: Breadcrumb[] = [];
    const hasOnlySection = !vehicleGroup && !company;

    if (section) {
        if (hasOnlySection) {
            breadcrumbs.push(sectionBreadcrumb(section));
        }
        breadcrumbs.push(vehicleGroupsBreadcrumb(t, section));

        if (vehicleGroup) {
            breadcrumbs.push(vehicleGroupBreadcrumb(vehicleGroup));

            if (company) {
                breadcrumbs.push(vehicleCompanyBreadcrumb(section, vehicleGroup, company));
            }
        }
    }

    return breadcrumbs;
}

export default useVehicleSelectionBreadcrumbs;
