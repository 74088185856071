import React, {FC, Fragment, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, TextField} from "@material-ui/core";
import shoppingCartActions from "../../state/shoppingCart/ShoppingCartActions";
import {
    BACKEND_ERROR_STATUS,
    filterBySlugs,
    parseCsv,
    PARTIAL_SUCCESS_STATUS,
    SUCCESS_STATUS
} from "./ShoppingCartImport";
import ItemSlug from "../../model/item/ItemSlug";
import {Color} from "@material-ui/lab/Alert";
import {StatusSnackbar} from "./StatusSnackbar";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    padded: {
        paddingTop: "5px",
        paddingBottom: "5px"
    },
    error: {
        padding: "5px",
        color: "red",
    },
    csvInput: {
        width: "100%",
        maxHeight: "300px",
        padding: 0
    },
    manual: {
        paddingTop: "5px"
    },
    example: {
        fontFamily: "monospace",
        padding: "2px",
        borderRadius: "5px",
        backgroundColor: "#DDDDDD"
    }
})

export const CsvForm: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const [csvData, setCsvData] = useState("")
    const [importStatus, setImportStatus] = useState<Color | undefined>(undefined);

    const handleCloseSnackbar = () => {
        setImportStatus(undefined);
    };

    const onFileInput = (event: any) => {
        const file = event.target.files[0];
        file.text().then((text: string) => setCsvData(text))
    }

    const onTypeCsv = (event: any) => {
        setCsvData(event.target.value);
    }

    const addToCart = () => {
        handleCloseSnackbar()
        const orderItems = parseCsv(csvData);
        shoppingCartActions.addFormItems(orderItems)
            .then(result => {
                const tmp = filterBySlugs(orderItems, result.filter(it => !!it) as ItemSlug[])
                    .map(item => item.slug + ';' + item.quantity)
                    .join('\r\n')
                setImportStatus( tmp.length === 0 ? SUCCESS_STATUS : PARTIAL_SUCCESS_STATUS)
                setCsvData(tmp);
            })
            .then(() => shoppingCartActions.loadCart())
            .catch(() => {setImportStatus(BACKEND_ERROR_STATUS)});
    }

    return <Fragment>
        <StatusSnackbar closeDelay={3000} show={!!importStatus} severity={importStatus} closeCallback={handleCloseSnackbar} />

        <div className={classes.padded}>
        <div className={classes.manual}>
            Geben Sie Ihre Bestellung in dieser Form ein
            <div className={classes.example}>
                00015;10<br/>
                812026;1<br/>
            </div>
        </div>
            <div className={classes.padded}>
                <Button
                    variant="contained"
                    component="label">oder laden Sie eine .csv Datei hoch<input type="file" hidden onChange={onFileInput}/>
                </Button>
            </div>

        {
            importStatus === BACKEND_ERROR_STATUS && <div className={classes.error}>
                Die verbleibenden Zeilen konnten nicht verarbeitet werden
            </div>
        }

        <TextField
            margin="dense"
            variant="outlined"
            value={csvData}
            label={"Artikelnummer;Menge"}
            rows={10}
            onChange={onTypeCsv}
            className={classes.csvInput}
            multiline={true}>
        </TextField>


        <div className={classes.padded}>
            <Button
                onClick={addToCart}
                variant="contained"
                component="label">
                {t("adding-form-order-button")}
            </Button>
        </div>
    </div>
    </Fragment>
}

export default CsvForm;
