import AuthorizedHttpService from "../service/AuthorizedHttpService";
import Prediction from "./Prediction";
import {Store} from "./Store";
import {Location} from "./Location";

export class MapsService extends AuthorizedHttpService {
    public async getApiKey(): Promise<string> {
        return this.get(`/googlemaps/apiKey`);
    }

    public async predict(query: string): Promise<Prediction[]> {
        return this.get(`/googlemaps/predict?input=${query}`);
    }

    public async getNearStores(place: string): Promise<Store[]> {
        return this.get(`/googlemaps/near?placeId=${place}`);
    }

    public async getLocationForPlace(placeId: string): Promise<Location> {
        return this.get(`/googlemaps/location?placeId=${placeId}`)
    }
}

export default new MapsService();
