import ListItemText from "@material-ui/core/ListItemText";
import React, {FC} from "react";
import {CompanySearchResult} from "../../model/search/CompanySearchResult";

export interface VehicleResultItemProps {
    value: CompanySearchResult;
}

export const CompanyResultItem: FC<VehicleResultItemProps> = ({value}) => {
    const {name} = value;

    return (
        <ListItemText
            data-cy="search-result"
            primary={name} secondary="Marke"/>
    );
};

export default CompanyResultItem;
