import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import StringFilter, {StringFilterProps} from "./StringFilter";

export interface BooleanFilterProps extends Omit<StringFilterProps, "getOptionLabel"> {

}

export const BooleanFilter: FC<BooleanFilterProps> = ({ ...props }) => {
    const { t } = useTranslation();

    return (
        <StringFilter
            {...props}
            getOptionLabel={(value: string) => t(`attribute-BOOLEAN-value-${value}`)}
        />
    );
};

export default BooleanFilter;
