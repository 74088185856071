import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import itemQuery from "../../state/returns/items/ItemQuery";
import Grid from "@material-ui/core/Grid";
import itemActions from "../../state/returns/items/ItemActions";
import Button from "@material-ui/core/Button";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import NumberField from "../form/NumberField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ReturnReason} from "../../model/returns/Reason";
import InvoiceNumberField from "../form/InvoiceNumberField";
import {DatePicker} from "@material-ui/pickers";

export const AddReturnItemForm: FC = () => {
    const {t} = useTranslation();
    const invalid = itemQuery.useIsInvalid();
    const isComplaint = itemQuery.useValue('reason');

    return (
        <Grid container component="form"
              onSubmit={(event: any) => {
                  event.preventDefault();
                  itemActions.addItem().catch(/* It seems like we don't care */);
              }}
        >
            <Grid item md={2}>
                <ItemNumber/>
            </Grid>
            <Grid item md={1}>
                <ItemCount/>
            </Grid>
            <Grid item md={2}>
                <Reason/>
            </Grid>
            <Grid item md={3}>
                <Comment/>
            </Grid>
            <Grid item md={2}>
                <InvoiceNumber/>
            </Grid>
            <Grid item md={2}>
                <InvoiceDate/>
            </Grid>

            {
                isComplaint === 'QUERY' ? <ComplaintSubForm/> : null
            }

            <Grid item md={2}>
                <Button
                    type="submit"
                    fullWidth
                    disabled={invalid}
                    variant="contained"
                    color="primary"
                    children={t("returns-add-item-button")}
                />
            </Grid>
        </Grid>
    );
};

const ComplaintSubForm: FC = () => {
    const {t} = useTranslation();

    return <Grid container item xs={12}>
        <Grid item md={2}>
            <ComplaintField name={'complaintVehicleInfo'} label={t('returns-complaint-vehicle-info')}
                            multiline={false}/>
        </Grid>
        <Grid item md={2}>
            <ComplaintField name={'complaintVehicleIdNumber'} label={t('returns-complaint-vehicle-id')}
                            multiline={false}/>
        </Grid>
        <Grid item md={3}>
            <ComplaintField numeric={true} name={'complaintMileage'} label={t('returns-complaint-vehicle-mileage')}
                            multiline={false}/>
        </Grid>
        <Grid item md={5}>
            <ComplaintField name={'complaintDetails'} label={t('returns-complaint-details')} multiline={true}/>
        </Grid>
    </Grid>
}

const ItemNumber: FC = () => {
    const {t} = useTranslation();
    const name = "itemNumber";
    const value = itemQuery.useValue(name);

    return (
        <TextField
            fullWidth
            required
            label={t("returns-item-number-label")}
            name={name}
            value={value}
            onChange={(event: any) => itemActions.setValue(name, event.target.value)}
        />

    );
};

const ItemCount: FC = () => {
    const {t} = useTranslation();
    const value = itemQuery.useValue("count");

    return (
        <NumberField
            fullWidth
            required
            min={1}
            label={t("returns-item-count-label")}
            name="count"
            value={value}
            onChange={(value: number) => itemActions.setValue("count", value)}
        />

    );
};

const Reason: FC = () => {
    const {t} = useTranslation();
    const name = "reason";
    const value = itemQuery.useValue(name) || "";

    return (
        <FormControl fullWidth required>
            <InputLabel>{t("returns-reason-label")}</InputLabel>
            <Select
                name={name}
                value={value}
                onChange={(event: any) => itemActions.setValue(name, event.target.value)}
            >
                <MenuItem
                    value=""
                />
                {Object.entries(ReturnReason).map(([key, value]) =>
                    <MenuItem
                        key={key}
                        value={value}
                        children={t(`returns-type-${key}`)}
                    />
                )}
            </Select>
        </FormControl>
    );
};

const Comment: FC = () => {
    const {t} = useTranslation();
    const name = "comment";
    const value = itemQuery.useValue(name);

    return (
        <TextField
            fullWidth
            multiline
            rows={2}
            label={t("returns-comment-label")}
            name={name}
            value={value}
            onChange={(event: any) => itemActions.setValue(name, event.target.value)}
        />
    );
};
const InvoiceNumber: FC = () => {
    const {t} = useTranslation();
    const name = "invoiceNumber";
    const value = itemQuery.useValue(name);
    const pristine = itemQuery.useValue("pristine");

    return (
        <InvoiceNumberField
            fullWidth
            required
            label={t("returns-invoice-number-label")}
            name={name}
            value={value}
            pristine={pristine}
            onChange={(value: string) => itemActions.setValue(name, value)}
        />
    );
};

const InvoiceDate: FC = () => {
    const {t} = useTranslation();
    const name = "invoiceDate";
    const value = itemQuery.useValue(name) || null;
    const minDate = moment().subtract(6, "months");
    const maxDate = moment().endOf("day");

    return (
        <DatePicker
            disableToolbar
            autoOk
            required
            variant="inline"
            label={t("returns-invoice-date-label")}
            format={t("date-format")}
            fullWidth
            name={name}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(value: any) => itemActions.setValue(name, value)}
        />
    );
};

interface ComplaintFieldProps {
    name: string;
    label: string;
    multiline: boolean;
    numeric?: boolean;
}

const ComplaintField: FC<ComplaintFieldProps> = ({name, label, multiline, numeric}) => {
    const value = itemQuery.useValue(name);

    return (
        <TextField
            required
            type={numeric ? "number" : "text"}
            fullWidth
            multiline={multiline}
            rows={2}
            label={label}
            name={name}
            value={value}
            onChange={(event: any) => itemActions.setValue(name, event.target.value)}
        />
    );
}

export default AddReturnItemForm;