import {Query} from "@datorama/akita";
import {map} from "rxjs/operators";
import {useObservable} from "../../common/useObservable";
import AccessTokenPayload from "../../model/auth/AccessTokenPayload";
import RoleName from "../../model/auth/RoleName";
import {throttledLoading} from "../throttleLoading";
import authStore, {AuthStore, State} from "./AuthStore";

export class AuthQuery extends Query<State> {
    public readonly currencySymbol$ = this.select('currencySymbol')
    public readonly currencyRateToEuro$ = this.select('currencyRateToEuro')

    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly isLoginOpen = this.select("ui.login.open");
    public readonly session = this.select("session");
    public readonly isLoggedIn = this.session.pipe(map((session) => !!session));
    public readonly accessTokenExpiration = this.select(state => (state.access && state.access.exp) || null);
    private readonly accessTokenPayload = this.select("access");
    private readonly defaultRoles: RoleName[] = [];

    constructor(
        protected store: AuthStore = authStore
    ) {
        super(store);
    }

    public useCurrencySymbol(): string {
        return useObservable(this.currencySymbol$, '€');
    }

    public useCurrencyRateToEuro(): number {
        return useObservable(this.currencyRateToEuro$, 1.0);
    }

    public useIsLoggedIn(): boolean {
        return !!this.useSession();
    }

    public useSession(): State["session"] {
        const current = this.store.getValue().session;

        return useObservable(this.session, current);
    }

    public useHasRole(role: RoleName): boolean {
        const roles = this.useRoles();

        return this.hasRole(roles, role);
    }

    private hasRole(roles: RoleName[], required: RoleName) {
        return roles.indexOf(required) >= 0;
    }

    public useHasAnyRole(required: RoleName[]): boolean {
        const roles = this.useRoles();

        return required.some(role => this.hasRole(roles, role));
    }

    public useRoles(): RoleName[] {
        const { authorities } = this.useAccessTokenPayload() || { authorities: this.defaultRoles };

        return authorities;
    }

    public useAccessTokenPayload(): AccessTokenPayload | null {
        const current = this.store.getValue().access;

        return useObservable(this.accessTokenPayload, current);
    }
}

export default new AuthQuery();
