import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Category from "../../model/Category";

export type ID = string;
export type Entity = Category;

export interface Filter {
    vehicle: string;
    category: string;
}

export interface State extends EntityState<Entity, ID> {
    filter: Filter
}

function createInitialState() {
    return {
        filter: {
            vehicle: "",
            category: ""
        }
    };
}

@StoreConfig({
    name: "vehicle.category",
    idKey: "slug"
})
export class VehicleCategoryStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new VehicleCategoryStore();
