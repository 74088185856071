import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {useTranslation} from "react-i18next";

export interface SignupButtonProps {
    isInvalid: boolean | undefined
    isLoading: boolean | undefined
}

function SubmitButton({isInvalid, isLoading}: SignupButtonProps) {
    const {t} = useTranslation();

    const disabled = isInvalid || isLoading;

    return (
        <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={disabled}
            disableElevation
        >
            {t("signup-action-submit")}
            {isLoading &&
            <Box color="secondary.main" pl={1.5} display="flex">
                <CircularProgress
                    size={24}
                    thickness={5}
                />
            </Box>}
        </Button>
    );
}

export default SubmitButton;
