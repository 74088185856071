import {QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../../../common/useObservable";
import {CompanySlug} from "../../../../model/Company";
import Reference from "../../../../model/Reference";
import {throttledLoading} from "../../../throttleLoading";
import categoryStore, {CompanyState, CompanyStore} from "./CompanyStore";

@QueryConfig({})
export class CompanyQuery extends QueryEntity<CompanyState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();

    constructor(
        protected store: CompanyStore = categoryStore
    ) {
        super(store);
    }

    public useAllCompanies(): Reference<CompanySlug>[] {
        return useObservable(this.all, this.getAll());
    }
}

export default new CompanyQuery();
