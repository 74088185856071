import {transaction} from "@datorama/akita";
import Address from "../../../model/checkout/Address";
import AddressResponse from "../../../model/checkout/AddressResponse";
import addressService, {AddressService} from "../../../service/AddressService";
import shoppingCartActions, {ShoppingCartActions} from "../../shoppingCart/ShoppingCartActions";
import addressEditStore, {EditAddressStore} from "./EditAddressStore";

export interface Dependencies {
    addressEditStore: EditAddressStore;
    addressService: AddressService;
    shoppingCartActions: ShoppingCartActions;
}

export class EditAddressActions {
    private readonly addressEditStore = addressEditStore;
    private readonly shoppingCartActions = shoppingCartActions;
    private readonly addressService: AddressService = addressService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public reset() {
        this.addressEditStore.reset();
    }

    public update(next: Partial<Address>) {
        const current = this.addressEditStore.getValue().address;
        this.addressEditStore.update({address: {...current, ...next}});
    }

    @transaction()
    public async load(id: string): Promise<void> {
        try {
            this.addressEditStore.setLoading(true);
            const result = await this.addressService.findById(id);
            this.addressEditStore.update({id: result.id, address: result, isCompanyAddress: result.companyAddress});
        } catch (error) {
            this.addressEditStore.setError(error);
            throw error;
        } finally {
            this.addressEditStore.setLoading(false);
        }
    }

    @transaction()
    public async save(): Promise<void> {
        const {id, address} = this.addressEditStore.getValue();
        try {
            this.addressEditStore.setLoading(true);
            let result: AddressResponse;
            if (id) {
                // @ts-ignore
                result = await this.addressService.update(id, address);
            } else {
                result = await this.addressService.create(address);
            }
            this.addressEditStore.update({address: result});
            await this.shoppingCartActions.loadCart();
        } catch (error) {
            this.addressEditStore.setError(error);
            throw error;
        } finally {
            this.addressEditStore.setLoading(false);
        }
    }
}

export default new EditAddressActions();
