import tenantQuery from "../state/tenant/TenantQuery";
import {useMemo} from "react";

export const useTenantSwitch = () => {
    const isTenant = tenantQuery.useIsTenant();
    const isInitialized = tenantQuery.useIsInitialized();

    return useMemo(() => ({
        doForBaseShop: (callbackWhenBaseShop: Function) => {
            if(isInitialized && !isTenant) {
                callbackWhenBaseShop();
            }
        },
        selectForBaseOrTenant: <T> (forBase: T, forTenant: T, defaultValue: T): T => {
            if(!isInitialized) {
                return defaultValue;
            }
            return isTenant ? forTenant : forBase;
        }
    }), [isTenant, isInitialized]);

};

export default useTenantSwitch;