import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import ItemSlug from "../../../model/item/ItemSlug";
import Preorder from "../../../model/preorder/Preorder";
import PreorderCartItem from "../../../model/preorder/PreorderCartItem";

export type Entity = PreorderCartItem;
export type ID = ItemSlug;

export interface State extends EntityState<Entity, ID>, Omit<Preorder, "items"> {
    loading: boolean;
    error: Error | null;
    timestamp: number;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        totalPriceWithoutShipping: 0,
        firstOrder: false,
        timestamp: 0
    };
}

@StoreConfig({
    name: "preorder.cart",
    idKey: "slug"
})
export class CartStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }

    public setCart({items, ...rest}: Preorder): void {
        this.set(items);
        this.update({
            ...rest
        });
    }
}

export default new CartStore();
