import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import ResultItem from "../app/component/search/ResultItem";
import AppLink from "../app/component/theme/AppLink";
import SearchResult from "../app/model/search/SearchResult";
import {getLink} from "../app/search/getLink";
import NotFoundError from "../app/service/error/NotFoundError";
import searchService from "../app/service/SearchService";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

const useStyles = makeStyles((theme) => ({
    noSearchResults: {
        whiteSpace: "pre-wrap"
    }
}));

export interface SearchPageProps {
}

export const SearchPage: FC<SearchPageProps> = () => {
    const {t} = useTranslation();
    const search = new URLSearchParams(useLocation().search);
    const query = search.get("query");
    const [options, setOptions] = useState<SearchResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const noSearchResults = !loading && options.length === 0;
    const classes = useStyles(undefined);

    useEffect(() => {
        if (query) {
            setLoading(true);
            searchService.find(query)
                .then(setOptions)
                .catch(setError)
                .then(() => setLoading(false))
            ;
        }
    }, [query]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (loading) {
        return (<CircularProgress/>);
    } else if (noSearchResults) {
        return (
            <Typography className={classes.noSearchResults}
                        variant="body1">{t("search-empty-result", {query})}</Typography>
        );
    } else {
        return (
            <div>
                <List>
                    {options.map((option: SearchResult, index) => (
                        <Fragment key={`key-${query}-${option.name}`}>
                            {index > 0 ? <Divider/> : null}
                            <ListItem button component={AppLink} to={getLink(option)}>
                                <ResultItem value={option}/>
                            </ListItem>
                        </Fragment>
                    ))}
                </List>
            </div>
        );
    }
};

export default SearchPage;
