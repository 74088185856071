import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";
import PaginationActions from "../../app/component/category/PaginationActions";
import ContentHeader from "../../app/component/ContentHeader";
import Currency from "../../app/component/Currency";
import {OrderMetadata} from "../../app/model/user/OrderArchive";
import orderArchiveQuery from "../../app/state/orderArchive/OrderArchiveQuery"
import orderArchiveStore from "../../app/state/orderArchive/OrderArchiveStore"

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    header: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    grid: {
        "&:hover": {
            background: "#efefef"
        },
        padding: theme.spacing(2)
    },
    item: {
        cursor: "pointer"
    },
    deleteIcon: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    },
    table: {
        marginBottom: 10
    },
    notAvailable: {
        color: theme.palette.text.disabled
    },
    minValue: {
        color: theme.palette.error.light
    },
    image: {
        minWidth: theme.spacing(14),
        cursor: "pointer"
    },
    box: {
        minWidth: theme.spacing(54),
    },
    discounted: {
        textDecoration: "line-through"
    },
    link: {
        cursor: "pointer"
    },
    footer: {
        width: "100%"
    }
}));

export const OrderArchivePage: FC = () => {
    const {t} = useTranslation();
    const page = orderArchiveQuery.usePage();

    const classes = useStyles(undefined);

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("action-show-orders"),
            to: "/benutzer/bestellungen/",
        }
    ];

    useEffect(() => {
        const subs = orderArchiveStore.activatePageEffects()
        orderArchiveStore.getPage({})
        return () => {
            subs.forEach(it => it.unsubscribe())
        }
    }, []);


    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        orderArchiveStore.getPage({offset: newPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        orderArchiveStore.getPage({size: parseInt(event.target.value, 10)});
    };

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("action-show-orders")}/>
            {page &&
            <Fragment>
                <TableContainer component={Paper} className={classes.table}>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.header}>
                                <TableCell>{t("order-archive-transactionnumber-header")}</TableCell>
                                <TableCell>{t("order-archive-date-header")}</TableCell>
                                <TableCell>{t("order-archive-shippingaddress-header")}</TableCell>
                                <TableCell>{t("order-archive-totalprice-header")}</TableCell>
                            </TableRow>
                            {
                                page.content.map((item) => (
                                    <OrderArchiveRow
                                        key={'' + item.id}
                                        item={item}/>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    className={classes.footer}
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={page.totalElements}
                    rowsPerPage={page.size}
                    page={page.number}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={PaginationActions}
                />
            </Fragment>
            }
        </Fragment>
    );
};

interface RowProps {
    item: OrderMetadata;
    key: string;
}

const OrderArchiveRow: FC<RowProps> = ({item}: RowProps) => {
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();

    return (
        <Fragment>
            <TableRow hover className={classes.link}
                      onClick={() => push('/benutzer/bestellungen/' + item.id)}>
                <TableCell>
                    <Typography color="textPrimary" component="span">{item.id}</Typography>
                </TableCell>
                <TableCell>
                    <Typography color="textPrimary" component="span">{item.orderedAt}</Typography>
                </TableCell>
                <TableCell>
                    <Typography color="textPrimary" component="span">{item.shippingAddress}</Typography>
                </TableCell>
                <TableCell>
                    <Typography color="textPrimary" component="span"><Currency value={item.totalPrice}/></Typography>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}
