import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {CategorySlug} from "../../../model/Category";
import Reference from "../../../model/Reference";

export type IdType = CategorySlug;
export type EntityType = Reference<CategorySlug>;

export interface SubCategoryState extends EntityState<EntityType, IdType>, ActiveState<IdType> {
    category: string;
}

export function createInitialState(): Partial<SubCategoryState> {
    return {
        loading: false,
        category: ""
    };
}

@StoreConfig({
    name: "app.state.categoryPage.subcategories",
    idKey: "slug"
})
export class SubCategoriesStore extends EntityStore<SubCategoryState> {
    constructor() {
        super(createInitialState());
    }
}

export default new SubCategoriesStore();
