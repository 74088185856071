import {Order, QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import vehicleStore, {State, VehicleStore} from "./VehicleStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class VehicleQuery extends HookQueryEntity<State> {
    constructor(
        protected store: VehicleStore = vehicleStore
    ) {
        super(store);
    }
}

export default new VehicleQuery();
