import {FormControl} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import React, {FC, Fragment, useEffect, useMemo} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Trans, useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import AddressLocationFields from "../../app/component/signup/address/LocationFields";
import DealershipGroup from "../../app/component/signup/DealershipGroup";
import SignupCheckboxField from "../../app/component/signup/SignupCheckboxField";
import SignupFileField from "../../app/component/signup/SignupFileField";
import SignupNumberField from "../../app/component/signup/SignupNumberField";
import SignupPaymentField from "../../app/component/signup/SignupPaymentTypeField";
import SignupTextField from "../../app/component/signup/SignupTextField";
import SubmitButton from "../../app/component/signup/SubmitButton";
import ConflictError from "../../app/service/error/ConflictError";
import configQuery from "../../app/state/config/ConfigQuery";
import signupActions from "../../app/state/signup/SignupActions";
import signupQuery from "../../app/state/signup/SignupQuery";
import InfoMessage from "../../app/component/infomessage/InfoMessage";
import FormSection from "../../app/component/signup/SignupFormSection";
import BadRequestError from "../../app/service/error/BadRequestError";

export const SignupPage: FC = () => {
    const {t} = useTranslation();
    const error = signupQuery.useError();
    const showEmailError = signupQuery.useShowEmailError();
    const success = signupQuery.useSuccess();
    const siteKey = configQuery.useConfig("reCaptchaSiteKey");
    const isValid = signupQuery.useIsInvalid()
    const isLoading = signupQuery.useIsLoading()
    useEffect(() => {
        signupActions.reset();
    }, []);

    async function onSubmit() {
        signupActions.submit().catch(error => console.log(error));
    }

    function captchaSolved() {
        signupActions.captchaSolved();
    }

    const breadcrumbs: Breadcrumb[] = useMemo(() => [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("signup-title"),
            to: "/benutzer/registrieren/",
        }
    ], [t]);

    if (success) {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"
                               header={t("signup-success-title")}/>
                <Trans i18nKey="signup-success-text"
                       components={[<Typography variant="body1"/>]}
                />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("signup-title")}/>
            <Grid
                container
                spacing={4}
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault();
                    onSubmit().then( /* No need to handle the return value here */);
                }}
            >
                <Grid item container md={12}>
                    <InfoMessage>{t(`signup-note`)}</InfoMessage>
                </Grid>
                <FormSection legend={t("signup-company-legend")} includeSeparator={true}
                             data-cy="signup-company-segment">
                    <SignupTextField
                        property="name"
                        label="signup-name-label"
                        required
                    />
                    <SignupTextField
                        property="subName"
                        label="signup-subName-label"
                    />
                    <SignupTextField
                        property="ceoName"
                        label="signup-ceoName-label"
                        required
                    />
                    <SignupNumberField
                        property="numberOfEmployees"
                        required
                        min={0}
                        initialValue={0}
                        label="signup-numberOfEmployees-label"
                    />
                    <SignupTextField
                        property={"uid"}
                        label={"signup-uid-label"}
                        required
                    />
                </FormSection>
                <FormSection legend={t("signup-license-legend")} includeSeparator={true} markRequired={true}>
                    <SignupFileField
                        property="license"
                        label="form-action-upload"
                        accept="application/pdf"
                    />
                </FormSection>
                <FormSection legend={t("signup-address-legend")} includeSeparator={true}>
                    <AddressLocationFields/>
                </FormSection>
                <FormSection legend={t("signup-contact-legend")} includeSeparator={true}>
                    <SignupTextField
                        property="email"
                        label="signup-email-label"
                        required
                        type="email"
                        error={showEmailError}
                        helperText={showEmailError ? t("form-email-invalid") : ""}
                    />
                    {[
                        "contactPerson", "homepage"
                    ].map(property => (
                        <SignupTextField
                            key={property}
                            property={property}
                            label={`signup-${property}-label`}
                        />
                    ))}
                    <SignupTextField
                        key={'phone'}
                        property="phone"
                        label="signup-phone-label"
                        required
                    />
                    {[
                        "mobile", "fax"
                    ].map(property => (
                        <SignupTextField
                            key={property}
                            property={property}
                            label={`signup-${property}-label`}
                        />
                    ))}
                    <SignupTextField
                        multiline
                        rows={4}
                        key={'businessHours'}
                        property="businessHours"
                        label="signup-businessHours-label"
                        required
                    />
                </FormSection>
                <DealershipGroup/>
                <FormSection legend={t("signup-payment-legend")} includeSeparator={true}>
                    <SignupPaymentField property="paymentType" label="signup-paymentType-label"/>
                </FormSection>
                <FormSection legend={t("signup-options-legend")} includeSeparator={false}>
                    <SignupCheckboxField
                        property="dealer"
                        label="signup-dealer-label"
                        initialValue={true}
                    />
                </FormSection>
                <FormSection legend={''} includeSeparator={false}>
                    <SignupCheckboxField
                        property="newsletter"
                        label="signup-newsletter-label"
                    />
                </FormSection>
                <FormSection legend={t("signup-terms-legend")} includeSeparator={false} markRequired={true}>
                    <FormControl required component="fieldset">
                        <SignupCheckboxField
                            property="terms"
                            required
                            label={
                                <Trans i18nKey="signup-terms-label"
                                       components={[<Link color="secondary" component={RouterLink}
                                                          to="/agb.html"/>]}
                                />}
                        />
                    </FormControl>
                </FormSection>
                <FormSection legend="">
                    {siteKey &&
                    <ReCAPTCHA
                        sitekey={siteKey}
                        onChange={captchaSolved}
                    />
                    }
                </FormSection>
                <FormSection legend="">
                    <SubmitButton
                        isInvalid={isValid}
                        isLoading={isLoading}/>
                </FormSection>
                <FormSection legend="">
                    <ErrorMessage error={error}/>
                </FormSection>
            </Grid>
        </Fragment>
    );
};

const ErrorMessage: React.FC<{ error: any }> = ({error}) => {
    const {t} = useTranslation();
    if (!error) {
        return null;
    } else if (error instanceof ConflictError) {
        return (
            <Alert severity="error">
                {t("signup-error-duplicate-email")}
            </Alert>
        );
    } else if (error instanceof BadRequestError) {
        return (
            <Alert severity="error">
                {t('signup-error-bad-request')}
            </Alert>
        );
    }
    else {
        return (
            <Alert severity="error">
                {t("error-500-heading")}<br/>
                {t("error-500-text1")}
            </Alert>
        );
    }
}

export default SignupPage;
