export function fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ev => {
            if(ev?.target?.result) {
                resolve(btoa(ev.target.result as string));
            } else {
                reject('no data');
            }
        }
        reader.readAsBinaryString(file);
    });
}