import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import {fade} from "@material-ui/core/styles/colorManipulator";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, {ChangeEvent, FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import SearchResult from "../../model/search/SearchResult";
import {getLink} from "../../search/getLink";
import search from "../../state/search/Search";
import searchQuery from "../../state/search/SearchQuery";
import ResultItem from "./ResultItem";

export interface SearchBarProps {
    className?: string;
}

export const SearchBar: FC<SearchBarProps> = (props) => {
    const history = useHistory();
    const query = searchQuery.useQuery();
    const options = searchQuery.useAll();
    const loading = searchQuery.useIsLoading();

    function onSearch(query: string) {
        search.input(query).catch(error => console.log(error));
    }

    function select(value: string | SearchResult) {
        if (typeof value === "string") {
            const path = `/search/?query=${encodeURIComponent(value)}`;
            if (path !== history.location.pathname) {
                history.push(path);
            }
        } else {
            const path = getLink(value);

            if (path !== history.location.pathname) {
                history.push(path);
            }
        }
    }

    return (
        <SearchBarPure
            query={query}
            options={options}
            loading={loading}
            search={onSearch}
            select={select}
            clear={() => search.clear()}
            {...props}
        />
    );
};

export default SearchBar;

type InputChangeReason = "input" | "reset" | "clear";

const useStyles = makeStyles(theme => ({
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",

    },
    inputInput: {
        color: theme.palette.secondary.contrastText,
        '&:focus': {
            border: '2px solid ' + theme.palette.primary.main
        },
        padding: theme.spacing(1, 7, 1, 7)
    },
    inputEndAdornment: {
        marginRight: theme.spacing(1)
    },
    clearIndicator: {
        color: "inherit"
    },
    popupIndicator: {
        color: "inherit"
    }
}));

export interface SearchBarPureProps {
    query: string;
    options: SearchResult[];
    loading: boolean;
    search: (query: string) => void;
    select: (option: string | SearchResult) => void;
    clear: () => void;
    className?: string;
}

export const SearchBarPure: FC<SearchBarPureProps> = ({
                                                          className = "",
                                                          query,
                                                          options,
                                                          loading,
                                                          clear,
                                                          search,
                                                          select
                                                      }) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);

    function getOptionLabel(option: string | SearchResult): string {
        if (typeof option === "string") {
            return option;
        } else {
            return option.name;
        }
    }

    function onInputChange(event: ChangeEvent<{}>, value: string, reason: InputChangeReason) {
        if (reason === "input") {
            search(value);
        } else if (reason === "clear") {
            clear();
        }
    }

    function onChange(event: object, value: string | SearchResult<string> | null) {
        value && select(value);
    }

    return (
        <div className={`${classes.search} ${className}`}>
            <div className={classes.searchIcon}>
                <SearchIcon/>
            </div>
            <Autocomplete
                freeSolo
                options={options}
                loading={loading}
                loadingText={t("search-loading-text")}
                noOptionsText={t("search-empty-result")}
                inputValue={query}
                forcePopupIcon={!!options.length && !loading}
                filterOptions={(options) => options}
                getOptionLabel={getOptionLabel}
                onInputChange={onInputChange}
                onChange={onChange}
                closeIcon={<CloseIcon fontSize="small"/>}
                classes={{
                    clearIndicator: classes.clearIndicator,
                    popupIndicator: classes.popupIndicator
                }}
                renderOption={(option: SearchResult) => {
                    return <ResultItem
                        key={`key-${query}-${option.slug}`}
                        value={option}/>
                }}
                renderInput={params => (
                    <InputBase
                        data-cy="search"
                        placeholder={t("search-placeholder")}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput
                        }}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        endAdornment={
                            <Fragment>
                                {loading ?
                                    <CircularProgress
                                        className={classes.inputEndAdornment}
                                        color="inherit"
                                        size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        }
                        fullWidth
                    />
                )}
            />
        </div>
    );
};

