import {Query} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import tenantStore, {State, TenantStore} from "./TenantStore";

export class TenantQuery extends Query<State> {
    public readonly isTenant$ = this.select('tenant');
    public readonly errorFlag$ = this.select('errorFlag');
    public readonly isInitialized$ = this.select('initialized');

    constructor(protected store: TenantStore = tenantStore) {
        super(store);
    }

    public useIsTenant(): boolean {
        return useObservable(this.isTenant$, false);
    }

    public useIsInitialized(): boolean {
        return useObservable(this.isInitialized$, false);
    }

    public useErrorFlag(): boolean {
        return useObservable(this.errorFlag$, false);
    }
}

export default new TenantQuery();
