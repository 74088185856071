import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Company from "../../model/Company";

export interface FilterState {
    group: string | null;
}

export type ID = string;

export type Entity = Company;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    filter: FilterState;
}

export function createInitialFilterState(): FilterState {
    return {
        group: null
    };
}

@StoreConfig({
    name: "company",
    idKey: "slug"
})
export class CompanyStore extends EntityStore<State> {
    constructor() {
        super({loading: false, filter: createInitialFilterState()});
    }
}

export default new CompanyStore();
