import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Reference from "../../model/Reference";

export type ID = string;
export type Entity = Reference;

export interface Filter {
    section: string;
    vehicle?: string;
    motor?: string;
    preorder?: boolean;
}

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    filter: Filter;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        filter: {section: ""}
    };
}

@StoreConfig({
    name: "category",
    idKey: "slug"
})
export class CategoryStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new CategoryStore();
