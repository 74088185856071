import Config from "../model/Config";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class ConfigService extends AuthorizedHttpService {
    public async getConfig(): Promise<Config> {
        return this.get("/config/");
    }
}

export default new ConfigService();
