import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {HashMap} from "@datorama/akita/lib/types";
import PaymentType from "../../model/checkout/PaymentType";
import ShippingType from "../../model/checkout/ShippingType";
import ItemSlug from "../../model/item/ItemSlug";
import ShopOrder from "../../model/shoppingCart/ShopOrder";
import ShoppingCartItem from "../../model/shoppingCart/ShoppingCartItem";
import {Voucher} from "../../model/shoppingCart/Voucher";
import {ApplyVoucherResponse} from "../../service/ShoppingCartService";

export type Entity = ShoppingCartItem;

export type ID = ItemSlug;

export interface State extends EntityState<Entity, ID> {
    entities: HashMap<Entity>;
    ids: ID[];
    totalPriceWithoutShipping: number;
    itemTotal: number;
    rebatedTotal: number;
    systemRebate: {
        discount: number;
        value: number;
        rebateSystemName: string;
    };
    voucherRebate: number;
    mmzValue: number;
    netTotal: number;
    vatPrice: number;
    totalPrice: number;
    missingForFreeShipping: number;
    loading: boolean;
    paymentType: PaymentType;
    shippingType: ShippingType;
    error: Error | null;
    firstOrder: boolean;
    timestamp: number;
    voucher: Voucher;
    applyVoucherResponse: ApplyVoucherResponse
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        totalPriceWithoutShipping: 0,
        missingForFreeShipping: 0,
        paymentType: PaymentType.SOFORT,
        shippingType: ShippingType.POST,
        firstOrder: false,
        timestamp: 0
    };
}

@StoreConfig({
    name: "shopping-cart",
    idKey: "slug"
})
export class ShoppingCartStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }

    akitaPreAddEntity(item: Entity) {
        const slug: ID = item.item.slug;

        // @ts-ignore
        return {slug, ...item};
    }

    public setShopOrder({shopOrderItems, ...rest}: ShopOrder): void {
        this.set(shopOrderItems);
        this.update({
            ...rest
        });
    }
}

export default new ShoppingCartStore();
