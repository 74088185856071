import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import invoiceQuery from "../../app/state/invoice/InvoiceQuery"
import invoiceStore from "../../app/state/invoice/InvoiceStore"
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {Invoice} from "../../app/service/InvoiceService";
import NotFoundErrorPage from "../../errors/404";
import AppLink from "../../app/component/theme/AppLink";
import Currency from "../../app/component/Currency";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    currency: {
        color: theme.palette.text.primary
    }
}))

interface Params {
    number: string;
}

export const InvoicePage: FC = () => {
    const {number} = useParams<Params>();
    const {t} = useTranslation();
    const currentInvoice = invoiceQuery.useCurrent();

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("action-show-invoices"),
            to: "/benutzer/rechnungen/",
        },
        {
            text: number,
            to: "/benutzer/rechnungen/" + number
        }
    ];

    useEffect(() => {
        const subs = invoiceStore.activateInvoiceDetailEffects()
        invoiceStore.getInvoice(number);
        return () => {
            subs.forEach(it => it.unsubscribe())
        }
    }, [number]);

    return currentInvoice ? <InvoiceDetails invoice={currentInvoice} breadcrumbs={breadcrumbs}/> : <NotFoundErrorPage/>
};

export const InvoiceDetails: FC<{ invoice: Invoice, breadcrumbs: Breadcrumb[] }> = ({invoice, breadcrumbs}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"
                           header={t('invoice-number-header') + ' ' + invoice.number}/>
            <Grid xs={6} container direction="column" spacing={2}>

                <Grid item container spacing={6} justify={"space-between"}>
                    <Grid item>
                        <Typography data-cy={'invoice-date'} component={'h3'}>Rechnungsdatum</Typography>
                    </Grid>
                    <Grid item>
                        <Typography data-cy={'invoice-date'} component={'h3'}>{invoice.date}</Typography>
                    </Grid>
                </Grid>

                <Grid item container spacing={6} justify={"space-between"}>
                    <Grid item>
                        <Typography data-cy={'invoice-date'} component={'h3'}>Rechungsbetrag</Typography>
                    </Grid>
                    <Grid item>
                        <Typography data-cy={'invoice-date'} component={'h3'}><Currency className={classes.currency}
                                                                                        value={invoice.value}/>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    {!!invoice.filename ?
                        <Link data-cy={'invoice-download-link'} color="primary"
                              href={invoice.filename}>{t("invoice-download")}</Link>
                        : <Typography data-cy={'invoice-download-link-absent'} component={'h3'}
                                      color={'error'}>{t('invoice-download-missing')}</Typography>
                    }
                </Grid>
                <Grid item>
                    {!!invoice.orderId ?
                        <AppLink
                            data-cy={'invoice-order-link'}
                            color="primary"
                            to={`/benutzer/bestellungen/${invoice.orderId}/`}>
                            {t("invoice-order-link", invoice)}
                        </AppLink> : null
                    }
                </Grid>
                {
                    invoice.trackingLinks ?

                    invoice.trackingLinks.map((trackingLink, index) => <Grid item key={trackingLink} container
                                                                             spacing={6} justify={"space-between"}>
                        <Grid item>
                            <Typography data-cy={'tracking-link-' + index}
                                        component={'h3'}>{index === 0 ? t('invoice-shipment-tracking') : ''}</Typography>
                        </Grid>
                        <Grid item>
                            <a target='_blank' rel='noopener noreferrer' href={trackingLink}>{trackingLink}</a>
                        </Grid>
                    </Grid>)
                        : null
                }
            </Grid>
        </Fragment>
    )
}


export default InvoicePage;
