import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import AddToCartMethods from "../app/component/shoppingcart/AddToCartMethods";
import {GridDefaultSpacing} from "../app/config/Config";
import ShoppingCartItem from "../app/model/shoppingCart/ShoppingCartItem";
import ForbiddenError from "../app/service/error/ForbiddenError";
import NotFoundError from "../app/service/error/NotFoundError";
import shoppingCartQuery from "../app/state/shoppingCart/ShoppingCartQuery";
import shoppingCartActions from "../app/state/shoppingCart/ShoppingCartActions";
import configQuery from "../app/state/config/ConfigQuery";
import PageForbidden from "../errors/403";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import ShoppingCartTable from "../app/shoppingcart/ShoppingCartTable";
import {CartTurnoverInfoComponent} from "../app/component/checkout/CartTurnoverInfoComponent";
import TenantSwitch from "../app/component/tenant/TenantSwitch";
import authQuery from "../app/state/auth/AuthQuery";
import {useTurnoverForRebateGroups} from "../app/component/checkout/useTurnover";
import {printPromiseError} from "../app/common/util/errorHelper";

const useStyles = makeStyles(theme => ({
    table: {
        marginBottom: 10
    },
    orderButton: {
        margin: theme.spacing(2)
    }
}));


export const ShoppingCart: FC = () => {
    const {t} = useTranslation();
    const error = shoppingCartQuery.useError();
    const breadcrumbs: Breadcrumb[] = [];
    const classes = useStyles(undefined);
    const items: ShoppingCartItem[] = shoppingCartQuery.useAll();
    const itemTotal = shoppingCartQuery.useItemTotal();
    const rebatedTotal = shoppingCartQuery.useRebatedTotal();
    const systemRebate = shoppingCartQuery.useSystemRebate();
    const voucherRebate = shoppingCartQuery.useVoucherRebate();
    const mmzValue = shoppingCartQuery.useMmzValue();
    const netTotal = shoppingCartQuery.useNetTotal();
    const vatPrice = shoppingCartQuery.useVatPrice();
    const totalPrice = shoppingCartQuery.useTotalPrice();
    const missingForFreeShipping = shoppingCartQuery.useMissingForFreeShipping();
    const voucher = shoppingCartQuery.useVoucher();
    const isEmpty = shoppingCartQuery.useIsEmpty();
    const mmzLimitPrice = configQuery.useConfig('mmzLimitPrice');
    const showMinValueWarning = !!mmzValue;
    const currencySymbol = authQuery.useCurrencySymbol();
    const applyVoucherResponse = shoppingCartQuery.useApplyVoucherResponse();

    const applyVoucherCode = (voucher: string) => {
        if (voucher && voucher.trim()) {
            shoppingCartActions.applyVoucherCode(voucher).catch(printPromiseError);
        } else {
            shoppingCartActions.removeVoucherCode().catch(printPromiseError)
        }

    }

    const voucherCode = voucher?.code;
    useEffect(() => {
        if (voucherCode) {
            applyVoucherCode(voucherCode);
        }
    }, [itemTotal, voucherCode]);

    const {rebateSystemAssigned, turnover, diffToNextRebateGroup} = useTurnoverForRebateGroups();

    breadcrumbs.push(
        {
            text: t("shopping-cart-breadcrumb-text"),
            to: `/shop/warenkorb.html`
        }
    );

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error instanceof ForbiddenError) {
        return (<PageForbidden/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"
                           header={t("shopping-cart-title")}></ContentHeader>

            <AddToCartMethods/>
            { rebateSystemAssigned ?
                <TenantSwitch forBase={
                    <div style={{maxWidth: '50%'}}>
                        <CartTurnoverInfoComponent currencySymbol={currencySymbol} turnover={turnover}
                                                   label={t('cart-turnover-label')}/>
                        <CartTurnoverInfoComponent currencySymbol={currencySymbol} turnover={diffToNextRebateGroup}
                                                   label={t('cart-rebategroup-diff-label')}/>
                    </div>
                }/> : null
            }

            {isEmpty &&
            <span data-cy="shopping-cart-empty">{t("shopping-cart-empty-text")}</span>
            }
            {!isEmpty &&
            <Fragment>
                <TableContainer component={Paper} className={classes.table}>
                    <ShoppingCartTable items={items} showMinValueWarning={showMinValueWarning} mmzValue={mmzValue}
                                       mmzLimitPrice={+mmzLimitPrice} itemTotal={itemTotal} netTotal={netTotal}
                                       vatPrice={vatPrice} totalPrice={totalPrice}
                                       rebatedTotal={rebatedTotal}
                                       systemRebate={systemRebate}
                                       voucherRebate={voucherRebate}
                                       missingForFreeShipping={missingForFreeShipping}
                                       voucherCode={voucher?.code}
                                       onVoucherCodeApply={applyVoucherCode}
                                       applyVoucherResponse={applyVoucherResponse}/>
                </TableContainer>
                <Grid container spacing={GridDefaultSpacing}>
                    <Grid item md={10}/>
                    <Grid item md={2}>
                        <Button data-cy="shopping-cart-order-button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disableElevation
                                component={RouterLink}
                                to="/shop/versand.html"
                                className={classes.orderButton}
                        >
                            {t("shopping-cart-order")}
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
            }
        </Fragment>
    );
};

export default ShoppingCart;
