import en from "../../../i18n/en.json";
import de from "../../../i18n/de.json";
import i18n from "i18next";

const LOCAL_STORAGE_KEY_USER_LANGUAGE = 'userLanguage';

export enum Language {
    GERMAN = 'de',
    ENGLISH = 'en',
    DEFAULT = GERMAN,
    NOT_SET = ''
}

export async function changeUserLanguage(language: Language): Promise<any> {
    return new Promise((resolve, reject) => {
        i18n.changeLanguage(language, (error) => {
            if (!error) {
                console.log(`Changed the language to ${language}`);
                storeUserLanguage(language)
                resolve();
            } else {
                console.error(`An error occurred changing the language to ${language}`, error);
                reject(error);
            }
        }).catch(/* don't care as result is handled in callback */);
    });
}

function storeUserLanguage(language: Language) {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_LANGUAGE, language);
}

export function mapLocaleForLanguageUse(locale: string): Language {
    let mappedUserLanguage = Language.DEFAULT;
    if (locale) {
        if (locale.startsWith(Language.ENGLISH)) {
            mappedUserLanguage = Language.ENGLISH;
        } else if (locale.startsWith(Language.GERMAN)) {
            mappedUserLanguage = Language.GERMAN;
        }
    }
    return mappedUserLanguage;
}

export function getUserLanguageAndTranslations(): [Language, object] {
    let language = localStorage.getItem(LOCAL_STORAGE_KEY_USER_LANGUAGE);
    if (!language) {
        // Removed for now as this breaks tests (test browser is English, tests are looking for German strings)
        /* const userLanguage: string = navigator.language || (navigator as any).userLanguage;
        const mappedUserLanguage = mapLocaleForLanguageUse(userLanguage);
        storeUserLanguage(mappedUserLanguage);
        language = mappedUserLanguage; */
        language = Language.DEFAULT;
    }
    switch (language) {
        case Language.ENGLISH:
            return [Language.ENGLISH, en];
        case Language.GERMAN:
            return [Language.GERMAN, de];
        default:
            return [Language.GERMAN, de];
    }
}