import CircularProgress from "@material-ui/core/CircularProgress";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";
import AddressForm from "../../app/component/address/AddressForm";
import ContentHeader from "../../app/component/ContentHeader";
import Address from "../../app/model/checkout/Address";
import NotFoundError from "../../app/service/error/NotFoundError";
import addressEditActions from "../../app/state/address/edit/EditAddressActions";
import addressEditQuery from "../../app/state/address/edit/EditAddressQuery";
import NotFoundErrorPage from "../../errors/404";
import InternalServerErrorPage from "../../errors/500";

export const AddressCreatePage: FC = () => {
    const error = addressEditQuery.useError();
    const address = addressEditQuery.useAddress();
    const {t} = useTranslation();
    const historyPush = useHistoryPushNew();

    useEffect(() => {
        addressEditActions.reset();
    }, []);

    function update<T extends keyof Address>(property: T, value: Address[T]) {
        addressEditActions.update({[property]: value});
    }

    function save() {
        addressEditActions.save()
            .then(() => historyPush("/benutzer/adresse/"))
            .catch(error => console.log(error));
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("address-manage-breadcrumb"),
            to: "/benutzer/adresse/",
        },
        {
            text: t("address-edit-breadcrumb"),
            to: `/benutzer/adresse/create`
        }
    ];

    if (error instanceof NotFoundError) {
        return (<NotFoundErrorPage/>);
    } else if (error) {
        return (<InternalServerErrorPage/>);
    } else if (address) {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("address-create-title")}></ContentHeader>
                <AddressForm
                    address={address}
                    onSubmit={save}
                    onChange={update}
                    isCompanyAddress={false}
                />
            </Fragment>
        );
    } else {
        return (<CircularProgress/>);
    }
};

export default AddressCreatePage;
