import {TextField} from "@material-ui/core";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {isInvalidEmail} from "../../common/validation";

export const EmailField: FC<any> = ({value, pristine, ...props}) => {
    const {t} = useTranslation();
    const showError = !pristine && isInvalidEmail(value);

    return (
        <TextField
            value={value}
            type="email"
            error={showError}
            helperText={showError ? t("form-email-invalid") : ""}
            {...props}
        />
    );
};

export default EmailField;
