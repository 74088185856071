import {QueryConfig} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import {HookQueryEntity} from "../../HookQueryEntity";
import shoppingCartStore, {CartStore, Entity, ID, State} from "./CartStore";

@QueryConfig({})
export class CartQuery extends HookQueryEntity<State> {
    private readonly netTotal = this.select("netTotal");
    private readonly rebatedTotal = this.select("rebatedTotal");
    private readonly systemRebate = this.select("systemRebate");
    private readonly vatPrice = this.select("vat");
    private readonly totalPrice = this.select("total");
    private readonly validTo = this.select("validTo");

    constructor(
        protected store: CartStore = shoppingCartStore
    ) {
        super(store);
    }

    public useIsEmpty(): boolean {
        return !this.useAll().length;
    }

    public useNetTotal(): State["netTotal"] {
        const current = this.getValue().netTotal;

        return useObservable(this.netTotal, current);
    }

    public useVatPrice(): State["vat"] {
        const current = this.getValue().vat;

        return useObservable(this.vatPrice, current);
    }

    public useTotalPrice(): State["total"] {
        const current = this.getValue().total;
        return useObservable(this.totalPrice, current);
    }

    public useRebatedTotal(): State["rebatedTotal"] {
        const current = this.getValue().rebatedTotal;
        return useObservable(this.rebatedTotal, current);
    }

    public useSystemRebate(): State["systemRebate"] {
        const current = this.getValue().systemRebate;
        return useObservable(this.systemRebate, current);
    }


    public useCount(): number {
        return this.useAll().reduce((sum, {quantity}) => sum + quantity, 0);
    }

    public useValidTo(): State["validTo"] {
        return useObservable(this.validTo, this.getValue().validTo);
    }

    public useIsOpen(): boolean {
        const {error, timestamp, orderedAt} = this.useState();

        return timestamp > 0 && !error && !orderedAt;
    }

    public useItemQuantity(slug: ID): Entity["quantity"] {
        const all = this.useAll();
        const item = all.find(item => item.slug === slug);

        return item?.quantity || 0;
    }
}

export default new CartQuery();
