import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import React, {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import useHistoryPushNew from "../../common/useHistoryPushNew";
import ItemSlug from "../../model/item/ItemSlug";
import cartActions from "../../state/preorder/cart/CartActions";
import Currency from "../Currency";
import NumberField from "../form/NumberField";

const useStyles = makeStyles((theme) => ({
    orderButton: {
        "&:hover": {
            backgroundColor: theme.palette.warning.light
        },
        backgroundColor: theme.palette.warning.main
    },
    prices: {
        marginBottom: theme.spacing(4)
    },
}));

export interface OrderPanelProps {
    slug: ItemSlug
    price: number;
}

export const PreorderOrderPanel: FC<OrderPanelProps> = ({slug, price}) => {
    const {t} = useTranslation();
    const addToCartButtonText = t("preorder-order-button-text");
    const amountLabel = t("order-amount-input-label");
    const [quantity, setQuantity] = useState(1);
    const push = useHistoryPushNew();
    const classes = useStyles(undefined);

    function onClickAddShoppingCart() {
        cartActions.addSlug(slug, quantity);
        push("/vor-teile-wochen/warenkorb.html");
    }

    function onChangeQuantity(value: number) {
        setQuantity(value);
    }

    if (!price) {
        return null;
    }

    return (
        <Fragment>
            <Grid
                className={classes.prices}
                container
                justify="space-between"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={6}>
                    <Box fontWeight="fontWeightMedium">
                        {t("preorder-item-price")}
                    </Box>
                </Grid>
                <Grid item xs>
                    <Typography variant="h5" component="div" align="right">
                        <Currency value={price}/>
                    </Typography>
                </Grid>
            </Grid>

            <form noValidate autoComplete="off">
                <Grid
                    container
                    justify="space-between"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <NumberField
                            value={quantity}
                            onChange={onChangeQuantity}
                            size="small"
                            variant="outlined"
                            fullWidth
                            label={amountLabel}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            className={classes.orderButton}
                            variant="contained"
                            color="primary"
                            data-cy="item-order-button"
                            fullWidth
                            startIcon={<AddShoppingCartIcon/>}
                            onClick={onClickAddShoppingCart}
                        >
                            {addToCartButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
};

export default PreorderOrderPanel;
