import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Breadcrumb} from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import ListItemLink from "../../app/component/ListItemLink";
import {CategorySlug} from "../../app/model/Category";
import Reference from "../../app/model/Reference";
import NotFoundError from "../../app/service/error/NotFoundError";
import keywordService from "../../app/service/KeywordService";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";

interface Params {
    section: string;
}

type Entity = Reference<CategorySlug>;

export const KeywordPage: FC = () => {
    const {section} = useParams<Params>();
    const {t} = useTranslation();
    const [items, setItems] = useState<Entity[]>([]);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const breadcrumbs: Breadcrumb[] = [
        {
            text: section,
            to: `/${section}/fahrzeuge/`
        },
        {
            text: t("keyword-breadcrumb"),
            to: `/${section}/stichwortverzeichnis.html`
        }
    ];

    useEffect(() => {
        setLoading(true);
        keywordService.findAllBySection(section)
            .then(setItems)
            .catch(setError)
            .then(() => setLoading(false))
    }, [section])

    const ids: number[] = getIdsSortedByName(items)
    const groups: string[] = getGroups(items);

    let currentGroup = "";

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (loading) {
        return (<CircularProgress/>)
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("keyword-page-title", {section})}></ContentHeader>
                <Grid container alignItems="flex-start">
                    <Grid item md={3} sm={12}>
                        <Typography variant="h5" component="h2">{t("keyword-index-title")}</Typography>
                        <Index ids={ids} groups={groups}/>
                    </Grid>
                    <Grid item container md>
                        <List component="div">
                            {ids.map(id => (
                                <Fragment key={id}>
                                    {currentGroup !== groups[id] ? (currentGroup = groups[id]) && (
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            id={groups[id]}
                                            children={groups[id]}
                                        />
                                    ) : null}
                                    <ListItemLink
                                        primary={items[id].name}
                                        path={`/${section}/produkte/${items[id].slug}/`}
                                    />
                                </Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
};

function getIdsSortedByName(items: Entity[]) {
    return items
        .map(({name}, index) => ({
            sort: name.toLowerCase().trim(),
            index
        }))
        .sort(({sort: a}, {sort: b}) => a.localeCompare(b))
        .map(({index}) => index);
}

function getGroups(items: Entity[]) {
    return items.map(({name}) => group(name));
}

function group(name: string): string {
    const firstLetter = name
        .substr(0, 1)
        .toUpperCase()
        .replace("Ä", "A")
        .replace("Ö", "O")
        .replace("Ü", "U")
    ;
    if (firstLetter.match(/[0-9]/)) {
        return "0-9";
    }
    return firstLetter;
}

const Index: FC<{ ids: number[], groups: string[] }> = ({ids, groups}) => {
    const uniqueGroups = ids
        .map((id) => groups[id])
        .filter((value, index, self) => self.indexOf(value) === index)
    return (
        <List>
            {uniqueGroups.map((group: any) => (
                <ListItem
                    key={group}
                    component={Link}
                    href={`#${group}`}
                    color="secondary"
                >
                    <ListItemText>{group}</ListItemText>
                </ListItem>
            ))}
        </List>
    );
}

export default KeywordPage;
