import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC} from "react";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        paddingTop: "100%",
        position: "relative"
    },
    wrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: "100%",
        width: "100%",
        textAlign: "center",
        display: "flex",
        "& img": {
            maxWidth: "100%",
            maxHeight: "100%",
            margin: "auto"
        }
    }
}));

export const AspectRatio: FC = ({ children }) => {
    const classes = useStyles(undefined);
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                {children}
            </div>
        </div>
    );
};
