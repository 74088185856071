import {QueryConfig} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import {HookQueryEntity} from "../../HookQueryEntity";
import itemStore, {ItemStore, State} from "./ItemStore";

@QueryConfig({})
export class ItemQuery extends HookQueryEntity<State> {
    constructor(
        protected store: ItemStore = itemStore
    ) {
        super(store);
    }

    public usePage(): State["page"] {
        return useObservable(this.select("page"), this.getValue().page);
    }

    public useSize(): State["size"] {
        return useObservable(this.select("size"), this.getValue().size);
    }

    public useCount(): State["count"] {
        return useObservable(this.select("count"), this.getValue().count);
    }
}

export default new ItemQuery();
