import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Profile} from "../../model/user/Profile";
import NumberField from "../form/NumberField";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import ProfileTextField from "./ProfileTextField";
import {isInvalidEmail} from "../../common/validation";

interface ProfileFieldsProps {
    profile: Profile;
    isValid: boolean;
    onChange: <T extends keyof Profile>(property: T, value: Profile[T]) => void;
    onSubmit: () => void;
}

export const ProfileFields: React.FC<ProfileFieldsProps> = props => {
    const {t} = useTranslation();
    const {profile, isValid, onChange, onSubmit} = props;
    return (
        <Grid
            container
            spacing={4}
            component="form"
            onSubmit={(event: any) => {
                event.preventDefault();
                onSubmit();
            }}
        >
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">Preise</Typography>
                </Grid>
                <Grid item md={7}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={profile.displayPriceBox}
                            name="displayPriceBox"
                            onChange={(event) => onChange("displayPriceBox", event.target.checked)}
                        />}
                        label={t("user-profile-display-pricebox")}
                    />
                </Grid>
                <Grid item md={7}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={profile.displayBuyingPrice}
                            name="displayBuyingPrice"
                            onChange={(event) => onChange("displayBuyingPrice", event.target.checked)}
                        />}
                        label={t("user-profile-display-ek")}
                    />
                </Grid>
                <Grid item md={7}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={profile.displayRecommendedRetailPrice}
                            name="displayRecommendedRetailPrice"
                            onChange={(event) => onChange("displayRecommendedRetailPrice", event.target.checked)}
                        />}
                        label={t("user-profile-display-vk")}
                    />
                </Grid>
            </Grid>
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("user-profile-company-legend")}</Typography>
                </Grid>
                {
                    ([
                        "name", "subname", "ceoName"
                    ] as (keyof Profile)[]).map(property => (
                        <Grid key={property} item md={7}>
                            <ProfileTextField
                                property={property}
                                data={profile}
                                onChange={onChange}
                            />
                        </Grid>
                    ))
                }
                <Grid item md={7}>
                    <NumberField
                        value={profile.numberOfEmployees || ""}
                        name="numberOfEmployees"
                        onChange={(value: number) => onChange("numberOfEmployees", value)}
                        fullWidth
                        label={t("user-profile-numberOfEmployees-label")}/>
                </Grid>
                <Grid item md={7}>
                    <ProfileTextField
                        property={"uid"}
                        data={profile}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("user-profile-contact-legend")}</Typography>
                </Grid>
                <Grid item md={7}>
                    <ProfileTextField
                        property="email"
                        data={profile}
                        type="email"
                        error={isInvalidEmail(profile.email)}
                        helperText={isInvalidEmail(profile.email) ? t("form-email-invalid") : ""}
                        onChange={onChange}
                    />
                </Grid>
                {([
                    "contactPerson", "homepage"
                ] as (keyof Profile)[]).map(property => (
                    <Grid key={property} item md={7}>
                        <ProfileTextField
                            property={property}
                            data={profile}
                            onChange={onChange}
                        />
                    </Grid>
                ))}
                <Grid item md={7}>
                    <ProfileTextField
                        multiline
                        property="businessHours"
                        data={profile}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("user-profile-dealership-legend")}</Typography>
                </Grid>
                {
                    ([
                        "moped", "motorcycle", "mopedCar", "ecommerce", "farmEquipment", "cycles"
                    ] as (keyof Profile)[]).map(property => (
                        <Grid key={property} item md={7}>
                            <ProfileTextField
                                property={property}
                                data={profile}
                                onChange={onChange}
                            />
                        </Grid>
                    ))
                }
            </Grid>

            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("user-profile-newsletter-legend")}</Typography>
                </Grid>
                <Grid item md={7}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={profile.newsletter}
                            name="newsletter"
                            onChange={(event) => onChange("newsletter", event.target.checked)}
                        />}
                        label={t("user-profile-newsletter-label")}
                    />
                </Grid>
            </Grid>
            <Grid item md={3}>
                <Button
                    type="submit"
                    variant="contained"
                    color="default"
                    disabled={!isValid}
                    disableElevation>{t("form-action-save")}</Button>
            </Grid>
        </Grid>
    );
}