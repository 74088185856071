import React, {FC, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useObservable} from "../app/common/useObservable";
import VehicleSelection from "../app/component/vehicleselection/VehicleSelection";
import NotFoundError from "../app/service/error/NotFoundError";
import vehicleSelectionActions from "../app/state/vehicleselection/VehicleSelectionActions";
import vehicleSelectionQuery from "../app/state/vehicleselection/VehicleSelectionQuery";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

interface Params {
    section: string;
    vehicleGroup?: string;
    company?: string;
}

export const VehicleSelectionPage: FC = () => {
    const {section, vehicleGroup, company} = useParams<Params>();
    const error = useObservable(vehicleSelectionQuery.error, null);

    useEffect(() => {
        vehicleSelectionActions.navigateTo(section, vehicleGroup, company).catch(error => console.log(error));
    }, [section, vehicleGroup, company]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (<VehicleSelection/>);
    }
};

export default VehicleSelectionPage;
