import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {GridDefaultSpacing} from "../../config/Config";
import Attribute from "../../model/attribute/Attribute";
import AttributeType from "../../model/attribute/AttributeType";
import Company from "../../model/Company";
import AttributeRow from "../attributetable/AttributeRow";
import Flag from "./Flag";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface AttributesProps {
    company: Company | null;
    attributes: Attribute[];
}

const useStyles = makeStyles(theme => ({
    flags: {
        marginTop: 3
    },
    headerCell: {
        paddingLeft: 5
    }

}));

export const Attributes: FC<AttributesProps> = ({ attributes, company }) => {
    const { t } = useTranslation();
    const classes = useStyles(undefined);
    const heading = t("item-attributes-heading");
    const flags = attributes.filter(({ attributeType }) => attributeType === AttributeType.BOOLEAN);
    const rows: AttributeRow[] = attributes
        .filter(({ attributeType }) => attributeType !== AttributeType.BOOLEAN)
        .map(({ attributeName, value, attributeMeasure }) => ({
            name: attributeName,
            value: `${value} ${attributeMeasure || ""}`
        }));

    if (company) {
        rows.unshift({
            name: t("item-attribute-company"), value: company.name
        });
    }

    return (
        <Fragment>
            <Typography variant="h5" component="h3" gutterBottom>{heading}</Typography>
            <TableContainer>
                <Table size="small" aria-label={heading} padding="default">
                    <TableBody>
                        {rows.map(({ name, value }) => (
                            <TableRow key={name} hover data-cy="item-attribute" >
                                <TableCell className={classes.headerCell}
                                    variant="head"
                                    component="th"
                                    scope="row">
                                    {name}
                                </TableCell>
                                <TableCell>{value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                flags.length > 0 &&
                <Grid container spacing={GridDefaultSpacing} className={classes.flags}>
                    {
                        flags.map(({ attributeName }) => (
                            <Grid key={attributeName} item lg={3} xs={4}>
                                <Flag>{attributeName}</Flag>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </Fragment>
    );
};

export default Attributes;    
