import {transaction} from "@datorama/akita";
import itemService, {ItemService} from "../../../service/returns/ItemService";
import itemQuery from "./ItemQuery";
import itemStore, {createInitialState, State} from "./ItemStore";
import returnFilesActions from "../files/ReturnFilesActions"

export class ItemActions {
    private readonly store = itemStore;
    private readonly query = itemQuery;
    private readonly service: ItemService = itemService;

    @transaction()
    public async load(): Promise<void> {
        this.clear();
        this.startRequest();
        await this.findCurrent();
    }

    private async findCurrent() {
        try {
            const result = await this.service.findCurrent();
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private startRequest() {
        this.store.update({
            loading: true,
            error: null,
            success: false
        });
    }

    @transaction()
    public async removeItem(id: string): Promise<void> {
        this.startRequest();

        try {
            await this.service.removeItem(id);
            this.store.remove(id);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    @transaction()
    public async addItem(): Promise<void> {
        this.startRequest();
        try {
            await this.service.addItem(this.query.getItem());
            await this.load();
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private clear() {
        this.store.update(createInitialState());
    }

    public setValue<K extends keyof State>(key: K, value: State[K]): void {
        this.store.update({[key]: value});
        if (key === "invoiceNumber") {
            this.store.update({pristine: false});
        }
    }

    @transaction()
    public async submitForm(contact: string, email: string): Promise<void> {
        this.startRequest();

        try {
            await this.service.submitForm(contact, email);
            this.store.update({success: true});
            await this.findCurrent();
            await returnFilesActions.load();
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }
}

export default new ItemActions();
