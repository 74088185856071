import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../../../../HookQueryEntity";
import {throttledLoading} from "../../../../throttleLoading";
import categoryStore, {CategoryAttributeStore, Entity, State} from "./CategoryAttributeStore";

@QueryConfig({})
export class CategoryAttributeQuery extends HookQueryEntity<State> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();

    constructor(
        protected store: CategoryAttributeStore = categoryStore
    ) {
        super(store);
    }

    public useAllNames(): Entity["name"][] {
        return this.useAll().map(({name}) => name).sort();
    }
}

export default new CategoryAttributeQuery();
