import Grid from "@material-ui/core/Grid";
import React, {FC, useEffect} from "react";
import LatestItemList from "../app/component/item/LatestItemList";
import NewsList from "../app/component/news/NewsList";
import authQuery from "../app/state/auth/AuthQuery";
import companyActions from "../app/state/vehicleselectionshortcut/company/CompanyActions";
import vehicleGroupActions from "../app/state/vehicleselectionshortcut/vehiclegroup/VehicleGroupActions";
import VehicleSelectionShortcut from "../app/component/vehicleselectionshortcut/VehicleSelectionShortcut";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(1),
        width: "100%",
        marginTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        color: theme.palette.warning.main,
        fontWeight: 'bolder',
        fontStyle: 'italic',
        fontSize: 'xxx-large',

    },
    padded: {
        paddingTop: theme.spacing(6)
    }
}));

export const Index: FC = () => {
    const isLoggedIn = authQuery.useIsLoggedIn();
    const classes = useStyles(undefined);

    useEffect(() => {
        vehicleGroupActions.load().catch(error => console.log(error));
        companyActions.load().catch(error => console.log(error));
    }, []);

    return <Grid className={classes.padded} justify={"space-evenly"} direction={"column"} container spacing={4}>
            {
                isLoggedIn ?
                    <Grid xs={12} spacing={4}  container item wrap={"wrap"} justify={"space-evenly"}>
                        <VehicleSelectionShortcut direction={"row"}/>
                    </Grid>
                    : null
            }
            <Grid xs={12} container spacing={4} item wrap={"wrap"}  justify={"space-evenly"}>
                <NewsList/>
            </Grid>
            {
                isLoggedIn ? <Grid xs={12} spacing={4} container item wrap={"wrap"} justify={"space-evenly"}><LatestItemList/></Grid>
                    : null
            }
        </Grid>


};

export default Index;
