import Shipping from "../model/checkout/Shipping";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class ShippingService extends AuthorizedHttpService {
    public async findForCurrentUser(): Promise<Shipping[]> {
        return this.get("/shippings/");
    }
}

export default new ShippingService();
