import CircularProgress from "@material-ui/core/CircularProgress";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import VehicleItemTree from "../app/component/vehicle/itemtree/VehicleItemTree";
import Company from "../app/model/Company";
import VehicleItem from "../app/model/VehicleItem";
import NotFoundError from "../app/service/error/NotFoundError";
import itemService from "../app/service/ItemService";
import companyActions from "../app/state/company/CompanyActions";
import companyQuery from "../app/state/company/CompanyQuery"
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import ProductPage from "./produkt";

interface Params {
    slug: string;
}

export const CompanyPage: FC = () => {
    const {slug} = useParams<Params>();
    const [items, setItems] = useState<VehicleItem[]>()
    const [error, setError] = useState<any>();
    const company = companyQuery.useActive()

    useEffect(() => {
        companyActions.loadSingle(slug)
            .catch(setError)
        itemService.findAllByCompany(slug)
            .then(setItems)
            .catch(setError)
        ;
    }, [slug]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (items && company) {

        const breadcrumbs: Breadcrumb[] = [
            {
                text: company.name,
                to: `/marke/${slug}.html`
            }]

        return <Fragment>
            <CompanyHeader breadCrumbs={breadcrumbs} company={company}/>
            <div data-cy="company-items-tree">
                <VehicleItemTree mini={true} items={items} categories={[]}/>
            </div>
        </Fragment>
    } else {
        return (<CircularProgress/>);
    }
};

export default ProductPage;

interface CompanyHeaderProps {
    company: Company
    breadCrumbs: Breadcrumb[]
}

const CompanyHeader: FC<CompanyHeaderProps> = ({company, breadCrumbs}) => {
    if (company.image) {
        return<Fragment>
            <ContentHeader breadCrumbs={breadCrumbs} data-cy="company-page-header"  header={company.name}></ContentHeader>
            <img src={company.image!.locationDefault} alt={"Firmenlogo"}/>
        </Fragment>
    } else {
        return <ContentHeader  data-cy="company-page-header" header={company.name}></ContentHeader>
    }

}


