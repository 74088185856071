import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import ListItemLink from "../app/component/ListItemLink";
import {GridDefaultSpacing} from "../app/config/Config";
import NotFoundError from "../app/service/error/NotFoundError";
import companyQuery from "../app/state/motor/selection/company/CompanyQuery";
import motorQuery from "../app/state/motor/selection/motor/MotorQuery";
import motorSelectionActions from "../app/state/motor/selection/MotorSelectionActions";
import motorSelectionQuery from "../app/state/motor/selection/MotorSelectionQuery";
import sectionQuery from "../app/state/section/SectionQuery";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

interface Params {
    section: string;
    company?: string;
}

export const MotorSelectionPage: FC = () => {
    const {t} = useTranslation();
    const {section, company} = useParams<Params>();
    const error = motorSelectionQuery.useError();
    const companyName = companyQuery.useActiveName();
    const sectionName = sectionQuery.useActiveName() || "";
    const breadcrumbs: Breadcrumb[] = [
        {
            text: sectionName,
            to: `/${section}/motoren/`
        },
        {
            text: t("motor-breadcrumb-text"),
            to: `/${section}/motoren/`
        }
    ];

    if (companyName) {
        breadcrumbs.push({
            text: companyName,
            to: `/${section}/motoren/${company}/`
        })
    }

    useEffect(() => {
        motorSelectionActions.navigateTo(section, company).catch(error => console.log(error));
    }, [section, company]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("motor-selection-title")}></ContentHeader>
                <Grid container spacing={GridDefaultSpacing}>
                    <Grid item md={3} sm={4}>
                        <Typography variant="h5"
                                    component="h2">{t("motor-selection-companies-title")}</Typography>
                        <CompanyList/>
                    </Grid>
                    {company &&
                    <Grid item md sm>
                        <Typography variant="h5"
                                    component="h2">{t("motor-selection-motors-title")}</Typography>
                        <MotorList/>
                    </Grid>
                    }
                </Grid>
            </Fragment>
        );
    }
};

const CompanyList: FC = () => {
    const {section} = useParams<Params>();
    const current = companyQuery.useActiveId();
    const companies = companyQuery.useAll();
    const loading = companyQuery.useIsLoading();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <List>
                {companies.map(({name, slug}) => (
                    <ListItemLink
                        data-cy="company-item"
                        key={slug}
                        primary={name}
                        path={`/${section}/motoren/${slug}/`}
                        disabled={slug === current}
                    />
                ))}
            </List>
        );
    }
};

const MotorList: FC = () => {
    const {section} = useParams<Params>();
    const motors = motorQuery.useAll();
    const loading = motorQuery.useIsLoading();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <List>
                {motors.map(({name, slug}) => (
                    <ListItemLink
                        data-cy="motor-item"
                        key={slug}
                        primary={name}
                        path={`/${section}/motor/${slug}/`}
                        disabled={false}
                    />
                ))}
            </List>
        );
    }
};

export default MotorSelectionPage;
