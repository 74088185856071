import {merge} from "rxjs"
import {useObservable} from "../../../common/useObservable";
import companyQuery from "./company/CompanyQuery"
import motorQuery from "./motor/MotorQuery"

export class MotorSelectionQuery {
    private readonly companyQuery = companyQuery;
    private readonly motorQuery = motorQuery;
    private readonly error = merge(this.companyQuery.error, this.motorQuery.error)

    public useError(): any {
        return useObservable(this.error, null);
    }
}

export default new MotorSelectionQuery();
