import CategoryAttribute from "../model/CategoryAttribute";
import AuthorizedHttpService from "./AuthorizedHttpService";

export interface AttributeFilter {
    query: string;
    "category.slug": string;

    [key: string]: string | number;
}

export class AttributeService extends AuthorizedHttpService {

    public async find(slug: string, filter: AttributeFilter): Promise<string[]> {
        return this.get(`/attributes/${slug}/values/filtered`, filter);
    }

    public async findAllByCategory(category: string): Promise<CategoryAttribute[]> {
        return this.get(`/attributes/`, {category});
    }
}

export default new AttributeService();
