import getCategoryPath from "../common/route/getCategoryPath";
import getItemPath from "../common/route/getItemPath";
import getMotorPath from "../common/route/getMotorPath";
import getVehiclePath from "../common/route/getVehiclePath";
import {isCategorySearchResult} from "../model/search/CategorySearchResult";
import {isItemSearchResult} from "../model/search/ItemSearchResult";
import {isMotorSearchResult} from "../model/search/MotorSearchResult";
import SearchResult from "../model/search/SearchResult";
import {isVehicleSearchResult} from "../model/search/VehicleSearchResult";
import {isCompanySearchResult} from "../model/search/CompanySearchResult";
import getCompanyPath from "../common/route/getCompanyPath";

export function getLink(searchResult: SearchResult): string {
    if (isVehicleSearchResult(searchResult)) {
        return getVehiclePath(searchResult.sectionSlug, searchResult.slug);
    } else if (isMotorSearchResult(searchResult)) {
        return getMotorPath(searchResult.section.slug, searchResult.slug);
    } else if (isCategorySearchResult(searchResult)) {
        return getCategoryPath(searchResult.slug);
    } else if (isItemSearchResult(searchResult)) {
        return getItemPath(searchResult.slug);
    } else if (isCompanySearchResult(searchResult)) {
        return getCompanyPath(searchResult.slug)
    }
    return "/";
}
