import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useObservable} from "../../../common/useObservable";
import CategoryAttribute from "../../../model/CategoryAttribute";
import categoryAttributeQuery from "../../../state/categoryPage/filter/attribute/category/CategoryAttributeQuery";
import itemActions from "../../../state/categoryPage/item/ItemActions";
import itemQuery from "../../../state/categoryPage/item/ItemQuery";
import AttributeFilter from "./attribute/AttributeFilter";
import {MoreHoriz} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    filtersButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main
    }
}));

export const CategoryFilters: FC = () => {
    const category = itemQuery.useCategory();
    const loading = useObservable(categoryAttributeQuery.isLoading, false);
    const attributes = categoryAttributeQuery.useAll();

    function resetFilter() {
        itemActions.resetFilter();
    }

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <CategoryFiltersPure
                attributes={attributes}
                category={category}
                resetFilter={resetFilter}
            />
        );
    }
};

export default CategoryFilters;

export interface CategorySelectionProps {
    attributes: CategoryAttribute[];
    category: string;

    resetFilter(): void;
}

export const CategoryFiltersPure: FC<CategorySelectionProps> = ({attributes, resetFilter}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const preferred = attributes.filter(({preferred}) => preferred);
    const other = attributes.filter(({preferred}) => !preferred);
    const hasPreferred = preferred.length > 0;
    const [open, setOpen] = useState(false);
    const showOther = open || !hasPreferred;

    const toggleMoreFilters = () => setOpen(lastValue => !lastValue);

    return (
        <React.Fragment>
            {preferred
                .map(({name, slug, type}) => (
                    <FilterGridItem key={slug}>
                        <AttributeFilter data-cy={`filter-${slug}`} name={name} slug={slug} type={type}/>
                    </FilterGridItem>
                ))
            }
            {showOther && other
                .map(({name, slug, type}) => (
                    <FilterGridItem key={slug}>
                        <AttributeFilter data-cy={`filter-${slug}`} name={name} slug={slug} type={type}/>
                    </FilterGridItem>
                ))
            }
            <Hidden xsDown>
                <Grid item sm={12}/>
            </Hidden>
            <Hidden smDown>
                <Grid item md/>
            </Hidden>
            {
                hasPreferred &&
                <FilterGridItem>
                    <Button
                        fullWidth
                        variant="contained"
                        color="default"
                        disableElevation
                        data-cy="filter-category-expand"
                        onClick={toggleMoreFilters}
                        startIcon={<MoreHoriz />}
                        endIcon={open ? <ExpandLess/> : <ExpandMore/>}
                        children={t("category-filter-page-more-filters")}
                        className={classes.filtersButton}
                    />
                </FilterGridItem>
            }
            <FilterGridItem>
                <Button
                    data-cy="filter-category-reset"
                    variant="contained"
                    color="default"
                    fullWidth
                    disableElevation
                    onClick={resetFilter}
                    className={classes.filtersButton}>
                    {t("filter-category-reset")}
                </Button>
            </FilterGridItem>
        </React.Fragment>
    );
};

export const FilterGridItem: FC<any> = (props) => {
    return (
        <Grid item lg={3} md={4} sm={6} xs={12} {...props} />
    );
};
