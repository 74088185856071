import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Motor from "../../model/motor/Motor";
import AttributeRow from "../attributetable/AttributeRow";


export interface MotorAttributesProps {
    motor: Motor;
}

export const MotorAttributes: FC<MotorAttributesProps> = ({motor}) => {
    const {t} = useTranslation();
    const rows: AttributeRow[] = [
        {
            name: t("motor-attribute-coolingSystem"), value: motor.coolingSystem && t(`motor-enum-cooling-system-${motor.coolingSystem}`)
        },
        {
            name: t("motor-attribute-stroke"), value: motor.stroke && t(`motor-enum-stroke-${motor.stroke}`)
        },
        {
            name: t("motor-attribute-transmission"), value: motor.transmission && t(`motor-enum-transmission-${motor.transmission}`)
        },
        {
            name: t("motor-attribute-bore"), value: motor.bore
        },

        {
            name: t("motor-attribute-upstroke"), value: motor.upstroke
        },
        {
            name: t("motor-attribute-cubicCapacity"), value: motor.cubicCapacity
        },
        {
            name: t("motor-attribute-power"), value: motor.power
        }
    ].filter(({value}) => !!value) as AttributeRow[];

    return (
        <Fragment>
            {rows.map(({name, value}) => (
                <Fragment key={name}>
                    <Typography variant="h6" component="div">{name}</Typography>
                    <Typography paragraph variant="body1">{value}</Typography>
                </Fragment>
            ))}
        </Fragment>
    );
};

export default MotorAttributes;    
