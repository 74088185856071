import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Item from "../../model/item/Item";

export interface ItemState extends EntityState<Item, string>, ActiveState<string> {
    page: number;
    size: number;
    totalItemsCount: number;
}

export function createInitialState(): Partial<ItemState> {
    return {
        loading: false,
        page: 0,
        size: 10,
        totalItemsCount: -1
    };
}

@StoreConfig({
    name: "latestItems",
    idKey: "slug"
})
export class ItemStore extends EntityStore<ItemState> {
    constructor() {
        super(createInitialState());
    }
}

export default new ItemStore();
