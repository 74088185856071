import itemService from "../../../service/ItemService";
import pageSize from "../../common/PageSize";
import itemQuery from "./ItemQuery";
import itemStore from "./ItemStore";

export class ItemActions {
    private readonly store = itemStore;
    private readonly query = itemQuery;
    private readonly service = itemService;
    private readonly pageSize = pageSize;

    public async setPage(page: number): Promise<void> {
        this.store.update({page});
        await this.loadFilter();
    }

    public async setSize(size: number): Promise<void> {
        this.store.update({page: 0, size});
        this.pageSize.save(size);
        await this.loadFilter();
    }

    public async load(category: string): Promise<void> {
        this.store.update({category, page: 0});
        await this.loadFilter();
    }

    private async loadFilter() {
        const {category, page, size} = this.query.getValue();
        this.store.setLoading(true);
        try {
            const {items, count} = await this.service.findAllPreorderByCategory(category, page, size);
            this.store.set(items);
            this.store.update({count});
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }
}

export default new ItemActions();
