import authActions, {AuthActions} from "../state/auth/AuthActions";
import authStore, {AuthStore} from "../state/auth/AuthStore";
import UnauthorizedError from "./error/UnauthorizedError";
import HttpService, {RequestParams} from "./HttpService";

export abstract class AuthorizedHttpService extends HttpService {
    private readonly authStore: AuthStore = authStore;
    private readonly authActions: AuthActions = authActions;

    protected async request({headers, ...rest}: RequestParams): Promise<any> {
        const token = this.authStore.getAccessToken();

        if (token) {
            headers = Object.assign({}, {
                "Authorization": `Bearer ${token}`
            }, headers);
        }

        try {
            return await super.request({headers, ...rest});
        } catch (e) {
            if (e instanceof UnauthorizedError) {
                this.authActions.logout();
            }
            throw e;
        }
    }
}

export default AuthorizedHttpService;
