import AuthorizedHttpService from "./AuthorizedHttpService";
import WatchList from "../model/watchLIst/WatchList";
import ItemSlug from "../model/item/ItemSlug";

export class WatchListService extends AuthorizedHttpService {

    public find(): Promise<WatchList> {
        return this.get("/watchlist/");
    }

    public add(slug: ItemSlug): Promise<any> {
        return this.put("/watchlist/" + slug);
    }

    public remove(slug: ItemSlug): Promise<any> {
        return this.delete("/watchlist/" + slug);
    }
}

export default new WatchListService();
