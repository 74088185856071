import {QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import {throttledLoading} from "../../throttleLoading";
import subCategoriesStore, {EntityType, SubCategoriesStore, SubCategoryState} from "./SubCategoriesStore";

@QueryConfig({})
export class SubCategoriesQuery extends QueryEntity<SubCategoryState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();

    constructor(
        protected store: SubCategoriesStore = subCategoriesStore
    ) {
        super(store);
    }

    public useAllSubCategories(): EntityType[] {
        return useObservable(this.all, this.getAll());
    }
}

export default new SubCategoriesQuery();
