import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import vehicleItemStore, {MotorItemStore, State} from "./MotorItemStore";

@QueryConfig({})
export class MotorItemQuery extends HookQueryEntity<State> {
    constructor(
        protected store: MotorItemStore = vehicleItemStore
    ) {
        super(store);
    }
}

export default new MotorItemQuery();
