import SearchResult from "./SearchResult";
import {SearchResultType} from "./SearchResultType";
import {CompanySlug} from "../Company";

export interface CompanySearchResult extends SearchResult {
    type: SearchResultType.COMPANY;
    companySlug: CompanySlug;
}

export function isCompanySearchResult(searchResult: SearchResult): searchResult is CompanySearchResult {
    return searchResult.type === SearchResultType.COMPANY;
}
