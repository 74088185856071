import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, Fragment} from "react";
import SearchBar from "../../app/component/search/SearchBar";
import {LogoHeader} from "./LogoHeader";
import {ActionsHeader} from "./ActionsHeader";
import {MenuHeader} from "./MenuHeader";

const useStyles = makeStyles(theme => ({
    search: {
        marginTop: theme.spacing(3),
        position: 'relative',
        flexGrow: 1,
        height: '30pt',
    },
    topbar: {
        backgroundColor: theme.palette.secondary.dark,
        borderBottom: '' + theme.spacing(1) + 'px solid ' + theme.palette.secondary.main + '55',
        position: 'fixed',
        display: "flex",
        top: 0,
        width: '100vw',
        height: theme.spacing(12),
        zIndex: 13,
    },
    subbar: {
        backgroundColor: theme.palette.secondary.dark,
        position: 'fixed',
        display: "flex",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        top: theme.spacing(12),
        width: '100vw',
        height: theme.spacing(12),
        zIndex: 10
    }
}));

export const Header: FC = () => {
    const classes = useStyles(undefined);

    return (
        <Fragment>
            <div className={classes.topbar} id={'back-to-top-anchor'}>
                <LogoHeader/>
                <ActionsHeader/>

            </div>
            <div className={classes.subbar}>
                <SearchBar className={classes.search}/>
                <MenuHeader/>
            </div>

        </Fragment>

    );
};

export default Header;
