import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import {useTranslation} from "react-i18next";
import signupActions from "../../state/signup/SignupActions";
import signupQuery from "../../state/signup/SignupQuery";
import RedGreenSwitch from "../form/RedGreenSwitch";

export interface Props {
    property: string;
    initialValue?: boolean;
    label: string | JSX.Element;
    required?: boolean
}

function SignupCheckboxField({initialValue, property, label, ...props}: Props) {
    const {t} = useTranslation();
    // @ts-ignore
    const value = signupQuery.useValue(property, initialValue || false);

    function onChange(event: any) {
        // @ts-ignore
        signupActions.update(property, event.target.checked);
    }

    return (
        <FormControlLabel
            control={<RedGreenSwitch
                {...props}
                checked={!!value}
                name={property}
                onChange={onChange}
            />}
            label={typeof label === "string" ? t(label) : label}
        />
    );
}

export default SignupCheckboxField;
