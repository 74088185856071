import Alert from "@material-ui/lab/Alert";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import {Profile, TenantProfile} from "../../app/model/user/Profile";
import PageInternalServerError from "../../errors/500";
import profileStore from "../../app/state/user/ProfileStore";
import profileQuery from "../../app/state/user/ProfileQuery";
import {ProfileFields} from "../../app/component/profile/ProfileFields";
import {isInvalidEmail} from "../../app/common/validation";
import TenantSwitch from "../../app/component/tenant/TenantSwitch";
import {TenantProfileFields} from "../../app/component/profile/TenantProfileFields";

export const ProfilePage: FC = () => {
    const {t} = useTranslation();

    const store = profileStore;
    const profileState = profileQuery.useProfile();

    useEffect(() => {
        profileStore.fetchProfile();
    }, [])

    const error = profileQuery.useError();
    const loading = profileQuery.useLoading();
    const [dirty, setDirty] = useState(false);
    const [profile, setProfile] = useState(profileState); // wokring copy workaround for existing solution

    const isInvalid = isInvalidEmail(profile.email);

    useEffect(() => {
        const subs = store.activateProfileEffects()
        store.fetchProfile();
        return () => {
            subs.forEach(sub => sub.unsubscribe());
        }
    }, [store]);

    useEffect(() => { setProfile(profileState); }, [profileState])

    async function onSubmit() {
        store.updateProfile(profile);
        setDirty(true);
    }

    function onChange<P extends Profile, T extends keyof P>(property: T, value: P[T]) {
        setProfile(Object.assign({}, profile, {[property]: value}));
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("user-profile-breadcrumb"),
            to: "/benutzer/profil.html"
        }
    ];

    if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("user-profile-page-title")}></ContentHeader>
                {
                    ( !loading && dirty ) &&
                    <Alert data-cy="alert" severity="success">{t(`user-profile-save-success`)}</Alert>
                }
                <TenantSwitch
                    forBase={<ProfileFields profile={profile} isValid={!isInvalid} onChange={onChange} onSubmit={onSubmit} />}
                    forTenant={<TenantProfileFields profile={profile as TenantProfile} isValid={!isInvalid} onChange={onChange} onSubmit={onSubmit} />}
                />
            </Fragment>
        );
    }
};

export default ProfilePage;
