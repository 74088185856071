import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {GridDefaultSpacing} from "../../config/Config";
import Company from "../../model/Company";
import companyQuery from "../../state/company/CompanyQuery";
import sectionQuery from "../../state/section/SectionQuery";
import vehicleGroupQuery from "../../state/vehiclegroup/VehicleGroupQuery";
import ContentHeader from "../ContentHeader";
import VehicleGroupImages from "../vehiclegroup/VehicleGroupImages";
import VehicleGroupList from "../vehiclegroup/VehicleGroupList";
import VehicleSelectionShortcut from "../vehicleselectionshortcut/VehicleSelectionShortcut";
import VehicleGroupingList from "./vehicle/VehicleGroupingList";
import VehicleCompanyList from "./VehicleCompanyList";
import useVehicleSelectionBreadcrumbs from "../../common/breadcrumb/useVehicleSelectionBreadcrumbs";

export const VehicleSelection: FC = () => {
    const currentCompanySlug = companyQuery.useActiveId();
    const currentSectionSlug = sectionQuery.useActiveId();
    const currentSectionName = sectionQuery.useActiveName() || "";
    const currentVehicleGroupSlug = vehicleGroupQuery.useActiveId();
    const loading = companyQuery.useIsLoading();
    const companies = companyQuery.useAll();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <VehicleSelectionPure
                currentCompanySlug={currentCompanySlug}
                currentSectionName={currentSectionName}
                currentSectionSlug={currentSectionSlug}
                currentVehicleGroupSlug={currentVehicleGroupSlug}
                companies={companies}
            />
        );
    }
};

export default VehicleSelection;

export interface VehicleSelectionProps {
    currentSectionName: string;
    currentSectionSlug?: string | null;
    currentCompanySlug?: string | null;
    currentVehicleGroupSlug?: string | null;
    companies: Company[];
}

export const VehicleSelectionPure: FC<VehicleSelectionProps> = (props) => {
    const breadcrumbs = useVehicleSelectionBreadcrumbs();
    const {
        currentSectionName,
        currentCompanySlug,
        currentVehicleGroupSlug
    } = props;
    const {t} = useTranslation();

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs}  header={currentSectionName}></ContentHeader>
            <Grid container spacing={5}>
                <Grid item container direction="column" spacing={GridDefaultSpacing} md={3}>
                    <Grid item>
                        <VehicleSelectionShortcut/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" component="h2">{t("vehicle-group-list-header")}</Typography>
                        <VehicleGroupList/>
                    </Grid>
                </Grid>
                {
                    !currentVehicleGroupSlug ?
                        <Grid item md>
                            <VehicleGroupImages/>
                        </Grid>
                        :
                        <Grid item md={3}>
                            <Typography variant="h5"
                                        component="h2">{t("vehicle-group-company-list-header")}</Typography>
                            <VehicleCompanyList/>
                        </Grid>
                }
                {
                    currentCompanySlug &&
                    <Grid item md>
                        <Typography variant="h5" component="h2">{t("vehicle-group-model-list-header")}</Typography>
                        <VehicleGroupingList/>
                    </Grid>
                }
            </Grid>
        </Fragment>
    );
};
