import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import getVehiclePath from "../../common/route/getVehiclePath";
import VehicleReference from "../../model/VehicleReference";
import AppLink from "../theme/AppLink";

export interface VehiclesProps {
    vehicles: VehicleReference[];
}

interface VehicleMap {
    [copmanyName: string]: VehicleReference[];
}

export interface VehicleGroupProps {
    name: string;
    vehicles: VehicleReference[];
}

const useStyles = makeStyles((theme) => ({
    nestedList: {
        paddingLeft: theme.spacing(4),
        display: "block",
    }
}));

export const VehicleGroup: FC<VehicleGroupProps> = ({name, vehicles}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles(undefined);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <ListItem data-cy="item-vehicles-list" button onClick={handleClick}>
                <ListItemText primary={name}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open}>
                <List component="div" disablePadding>
                    {
                        vehicles.map(({attribute, name, slug, vehicleGroup}) => {
                            return (
                                <ListItem key={name} className={classes.nestedList}>
                                    <div>
                                        <AppLink to={getVehiclePath(vehicleGroup.section ? vehicleGroup.section.name : '', slug)}>
                                            <ListItemText data-cy={"item-vehicles-group-list"}>{name}</ListItemText>
                                        </AppLink>
                                    </div>
                                    <div className={classes.nestedList}>
                                        {attribute?.attributeName}
                                    </div>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </Collapse>
        </Fragment>
    );
};

export const Vehicles: FC<VehiclesProps> = ({vehicles}) => {
    const {t} = useTranslation();
    const heading = t("item-vehicles-heading");
    const index = vehicles.reduce<VehicleMap>(
        (prev, vehicle) => {
            const key = vehicle.company.name;
            const items = prev[key] || [];
            prev[key] = items;
            items.push(vehicle);

            return prev;
        }
        , {}
    );


    return (
        <Fragment>
            <Typography variant="h5" component="h3">{heading}</Typography>
            <List component="div">
                {
                    Object.entries(index).sort()
                        .map(([companyName, vehicles]) => (
                            <VehicleGroup key={companyName} name={companyName} vehicles={vehicles}/>
                        ))
                }
            </List>
        </Fragment>
    );
};

export default Vehicles;    
