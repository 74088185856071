import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import Address from "../app/component/checkout/Address";
import PaymentOptions from "../app/component/checkout/PaymentOptions";
import ShippingOptions from "../app/component/checkout/ShippingOptions";
import ContentHeader from "../app/component/ContentHeader";
import {GridDefaultSpacing} from "../app/config/Config";
import {Row} from "../layout/footer/Footer";

const useStyles = makeStyles((theme) => ({
    grid: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    button: {
        paddingLeft: theme.spacing(2)
    },
    box: {
        marginTop: theme.spacing(5)
    }
}));


export const Checkout: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const breadcrumbs: Breadcrumb[] = [];

    breadcrumbs.push(
        {
            text: t("shopping-cart-breadcrumb-text"),
            to: `/shop/warenkorb.html`
        },
        {
            text: t("checkout-breadcrumb-text"),
            to: `/shop/versand.html`
        }
    );

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("checkout-heading")}></ContentHeader>
            <Grid container spacing={GridDefaultSpacing} className={classes.grid}>
                <Address/>
                <Row>
                    <Box display="flex" className={classes.box}>
                        <Grid item md={6}>
                            <PaymentOptions/>
                        </Grid>
                        <Grid item md={6}>
                            <ShippingOptions/>
                        </Grid>
                    </Box>
                </Row>
                <Row>
                    <Box display="flex">
                        <Grid item md sm={false}/>
                        <Grid item md={2} sm={12}>
                            <Button data-cy="checkout-back-button" variant="contained" color="default" fullWidth
                                    disableElevation
                                    component={RouterLink}
                                    to="/shop/warenkorb.html"
                            >
                                {t("back")}
                            </Button>
                        </Grid>

                        <Grid item md={2} sm={12} className={classes.button}>
                            <Button data-cy="checkout-next-button" variant="contained" color="secondary" fullWidth
                                    disableElevation
                                    component={RouterLink}
                                    to="/shop/bestaetigen.html"
                            >
                                {t("next")}
                            </Button>
                        </Grid>
                    </Box>
                </Row></Grid>
        </Fragment>
    );
};

export default Checkout;
