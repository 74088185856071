import companyService from "../../../../service/CompanyService";
import companyStore from "./CompanyStore";

export class CompanyActions {
    private readonly store = companyStore;
    private readonly service = companyService;

    public async load(section: string) {
        if (this.shouldSkipLoading(section)) {
            return
        }

        try {
            this.store.update({
                loading: true,
                error: null,
                section
            });
            const result = await this.service.findAllForMotors(section);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(next: string) {
        const {section, error} = this.store.getValue();
        return section === next && !error;
    }

    public select(slug: string) {
        // @ts-ignore
        this.store.setActive(slug);
    }

    public deselect() {
        this.store.setActive(null);
    }
}

export default new CompanyActions();
