import React, {FC} from "react";
import AttributeType from "../../../../model/attribute/AttributeType";
import BooleanFilter, {BooleanFilterProps} from "./BooleanFilter";
import StringFilter, {StringFilterProps} from "./StringFilter";

export interface AttributeFilterProps extends StringFilterProps, BooleanFilterProps {
    type: AttributeType;
}

export const AttributeFilter: FC<AttributeFilterProps> = ({ type, ...props }) => {
    if (type === AttributeType.BOOLEAN) {
        return (
            <BooleanFilter {...props} />
        );
    } else {
        return (
            <StringFilter {...props} />
        );
    }
};

export default AttributeFilter;
