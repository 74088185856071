import ListItemText from "@material-ui/core/ListItemText";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {VehicleSearchResult} from "../../model/search/VehicleSearchResult";

export interface VehicleResultItemProps {
    value: VehicleSearchResult;
}

export const VehicleResultItem: FC<VehicleResultItemProps> = ({value}) => {
    const {t} = useTranslation();
    const {name} = value;
    const {chassisNumber} = value;

    return (
        <ListItemText
            data-cy="search-result"
            primary={name} secondary={t("result-item-vehicle-chassis-number", {chassisNumber})}/>
    );
};

export default VehicleResultItem;
