import Content from "../model/Content";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class ContentService extends AuthorizedHttpService {

    public async find(name: string): Promise<Content> {
        return this.get(`/content/${name}`);
    }
}

export default new ContentService();
