interface HSV {
    hue: number
    saturation: number
    value: number
}

interface RGB {
    red: number
    green: number
    blue: number
}

interface ColorScheme {
    light: string,
    dark: string,
    base: string
}

/**
 * https://www.rapidtables.com/convert/color/hsv-to-rgb.html
 * https://www.rapidtables.com/convert/color/rgb-to-hsv.html
 * */
export class ThemeCalculator {

    private readonly darkerDiff = 0.35;
    private readonly lighterDiff = 0.3;

    private readonly darkerDiffSat = 0.02;
    private readonly lighterDiffSat = 0.02;

    public getContrastText(color: string): string {
        const {value, saturation} = this.rgbToHSV(this.hexToRgb(color))
        if (value - saturation > 0.6) {
            return '#222222'
        } else {
            return '#fefefe'
        }
    }

    public getThemeColors(base: string): ColorScheme {
        const baseRgb = this.hexToRgb(base)
        const baseHsv = this.rgbToHSV(baseRgb)

        const ret = {
            light: this.rgbToString(this.hsvToRgb({
                ...baseHsv,
                value: Math.min(0.99, baseHsv.value + this.lighterDiff),
                saturation: baseHsv.saturation !== 0 ? Math.min(0.99, baseHsv.saturation - this.lighterDiffSat) : 0
            })),
            base: base,
            dark: this.rgbToString(this.hsvToRgb({
                ...baseHsv,
                value: Math.max(0.05, baseHsv.value - this.darkerDiff),
                saturation: baseHsv.saturation !== 0 ? Math.max(0.01, baseHsv.saturation + this.darkerDiffSat) : 0
            })),
        }

        return ret
    }

    public getComplement(color: string): string {
        const {red, green, blue} = this.hexToRgb(color)
        const rc = Math.max(red, blue, green) + Math.min(red, blue, green) - red
        const bc = Math.max(red, blue, green) + Math.min(red, blue, green) - blue
        const gc = Math.max(red, blue, green) + Math.min(red, blue, green) - green

        const palette = this.getThemeColors(this.rgbToString({
            red: rc,
            green: gc,
            blue: bc
        }))

        return palette.light
    }

    private hsvToRgb(hsv: HSV): RGB {
        const diff = this.hsvToRgbDiff(hsv);
        const c = hsv.value * hsv.saturation
        const m = hsv.value - c

        return {
            red: (diff.red + m) * 255,
            green: (diff.green + m) * 255,
            blue: (diff.blue + m) * 255
        }
    }

    private hsvToRgbDiff(hsv: HSV): RGB {
        const c = hsv.value * hsv.saturation
        const x = c * (1 - Math.abs((hsv.hue / 60) % 2 - 1))

        if (hsv.hue >= 0 && hsv.hue < 60) {
            return {
                red: c,
                green: x,
                blue: 0
            }
        }
        if (hsv.hue >= 60 && hsv.hue < 120) {
            return {
                red: x,
                green: c,
                blue: 0
            }
        }
        if (hsv.hue >= 120 && hsv.hue < 180) {
            return {
                red: 0,
                green: c,
                blue: x
            }
        }
        if (hsv.hue >= 180 && hsv.hue < 240) {
            return {
                red: 0,
                green: x,
                blue: c
            }
        }
        if (hsv.hue >= 240 && hsv.hue < 300) {
            return {
                red: x,
                green: 0,
                blue: c
            }
        } else {
            return {
                red: c,
                green: 0,
                blue: x
            }
        }
    }

    private hexToRgb(hex: string): RGB {
        const value = hex.startsWith("#") ? hex.substr(1) : hex
        return {
            red: parseInt("0x" + value.substr(0, 2)),
            green: parseInt("0x" + value.substr(2, 2)),
            blue: parseInt("0x" + value.substr(4, 2)),
        }
    }

    private rgbToHSV(rgb: RGB): HSV {
        return {
            hue: this.hue(rgb),
            saturation: this.sat(rgb),
            value: this.val(rgb),
        }
    }

    private rgbToString(rgb: RGB): string {
        let red = (Math.abs(rgb.red) % 255).toString(16).split(".")[0];
        let green = (Math.abs(rgb.green) % 255).toString(16).split(".")[0]
        let blue = (Math.abs(rgb.blue) % 255).toString(16).split(".")[0]
        return '#' + (red.length === 1 ? "0" + red : red) + (green.length === 1 ? "0" + green : green) + (blue.length === 1 ? "0" + blue : blue)
    }

    private val({red, green, blue}: RGB): number {
        const dr = red / 255
        const dg = green / 255
        const db = blue / 255
        return Math.max(dr, dg, db);
    }

    private sat({red, green, blue}: RGB): number {
        const dr = red / 255
        const dg = green / 255
        const db = blue / 255

        const maxContrast = Math.max(dr, dg, db);
        const minContrast = Math.min(dr, dg, db);

        const delta = maxContrast - minContrast

        return maxContrast === 0 ? 0 : delta / maxContrast
    }

    private hue({red, green, blue}: RGB): number {
        const dr = red / 255
        const dg = green / 255
        const db = blue / 255

        const maxContrast = Math.max(dr, dg, db);
        const minContrast = Math.min(dr, dg, db);

        const delta = maxContrast - minContrast

        if (delta === 0) {
            return 0
        }

        if (maxContrast === dr) {
            return (60 * (((dg - db) / delta) % 6)) % 360
        }

        if (maxContrast === dg) {
            return (60 * (((db - dr) / delta) + 2)) % 360
        }


        return (60 * (((dr - dg) / delta) + 4)) % 360

    }
}

export default new ThemeCalculator();