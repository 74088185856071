import {useTranslation} from "react-i18next";
import {Vehicle} from "../../model/Vehicle";
import Breadcrumb from "./Breadcrumb";

export function useVehicleBreadcrumbs(vehicle?: Vehicle | null) {
    const {t} = useTranslation();
    const breadcrumbs: Breadcrumb[] = [];

    if (vehicle) {
        let sectionPath = "";
        const vehiclesPrefix = "fahrzeuge";
        const vehicleGroup = vehicle.vehicleGroup;
        const section = vehicleGroup.section;
        if (section) {
            sectionPath = `/${section.slug}`;
            breadcrumbs.push({
                text: t("vehicle-group-breadcrumb-text"),
                to: `${sectionPath}/${vehiclesPrefix}/`
            })

        }
        breadcrumbs.push(
            {
                text: vehicleGroup.name,
                to: `${sectionPath}/${vehiclesPrefix}/${vehicleGroup.slug}/`
            },
            {
                text: vehicle.company.name,
                to: `${sectionPath}/${vehiclesPrefix}/${vehicleGroup.slug}/${vehicle.company.slug}`
            },
            {
                text: vehicle.name,
                to: `${sectionPath}/fahrzeug/${vehicle.slug}.html`
            }
        );
    }

    return breadcrumbs;
}

export default useVehicleBreadcrumbs;
