import {MotorSlug} from "../motor/Motor";
import Reference from "../Reference";
import {SectionSlug} from "../Section";
import SearchResult from "./SearchResult";
import {SearchResultType} from "./SearchResultType";

export interface MotorSearchResult extends SearchResult<MotorSlug> {
    type: SearchResultType.CATEGORY;
    section: Reference<SectionSlug>;
}

export function isMotorSearchResult(result: SearchResult): result is MotorSearchResult {
    return result.type === SearchResultType.MOTOR;
}
