import {useEffect, useState} from "react";
import ItemModel, {Item} from "../../../model/item/Item";
import itemService from "../../../service/ItemService";
import authQuery from "../../auth/AuthQuery";
import query from "./ItemQuery";

export function useItem(slug: string): [Item | undefined, Error | undefined] {
    const [item, setItem] = useState<ItemModel>();
    const [error, setError] = useState();
    const roles = authQuery.useRoles();

    useEffect(() => {
        if (query.hasEntity(slug)) {
            const entity = query.getEntity(slug);
            // @ts-ignore
            setItem({
                ...entity,
                // @ts-ignore
                images: (entity.image && [entity.image]) || [],
                description: "",
                alternatives: [],
                price: null
            });
        }

        itemService.read(slug)
            .then(setItem)
            .catch(setError)
        ;
    }, [slug, roles]);

    return [item, error];
}
