import {VehicleGroupSlug} from "../../../model/VehicleGroup";
import vehicleGroupService, {VehicleGroupService} from "../../../service/VehicleGroupService";
import vehicleGroupStore, {VehicleGroupStore} from "./VehicleGroupStore";

export interface Dependencies {
    vehicleGroupStore: VehicleGroupStore;
    vehicleGroupService: VehicleGroupService;
}

export class VehicleGroupActions {
    private readonly vehicleGroupStore = vehicleGroupStore;
    private readonly vehicleGroupService = vehicleGroupService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async load() {
        try {
            this.vehicleGroupStore.update({
                loading: true,
                error: null
            });
            const result = await this.vehicleGroupService.read();
            this.vehicleGroupStore.set(result);
        } catch (error) {
            this.vehicleGroupStore.setError(error);
        } finally {
            this.vehicleGroupStore.setLoading(false);
        }
    }

    public select(slug: VehicleGroupSlug) {
        this.vehicleGroupStore.setActive(slug);
    }

    public deselect() {
        this.vehicleGroupStore.setActive(null);
    }
}

export default new VehicleGroupActions();
