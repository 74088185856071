import {Order, QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import companyStore, {State, VehicleGroupStore} from "./VehicleGroupStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class VehicleGroupQuery extends HookQueryEntity<State> {
    constructor(
        protected store: VehicleGroupStore = companyStore
    ) {
        super(store);
    }
}

export default new VehicleGroupQuery();
