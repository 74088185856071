import Link from "@material-ui/core/Link";
import React, {forwardRef} from "react";
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom";
import {HashLink, HashLinkProps} from 'react-router-hash-link';

type LinkColor = "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";

interface AppLinkProps extends RouterLinkProps, HashLinkProps {
    color?: LinkColor;
    hashLink?: boolean;
}

export const AppLink = forwardRef<any, AppLinkProps>(({color = "inherit", ...props}, ref) => {
    return (
        <Link ref={ref} component={props.hashLink ? HashLink : RouterLink} color={color} {...props} />
    );
});

export default AppLink;
