import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../../HookQueryEntity";
import categoryStore, {CategoryStore, Entity, State} from "./CategoryStore";

@QueryConfig({})
export class CategoryQuery extends HookQueryEntity<State> {
    constructor(
        protected store: CategoryStore = categoryStore
    ) {
        super(store);
    }

    public useFirst(): Entity | null {
        return this.useAll()[0] || null;
    }
}

export default new CategoryQuery();
