import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import saleQuery from "../../state/sale/SaleQuery";
import ItemRow from "../../common/ItemRow";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    discountQuantity: {
        fontSize: "small"
    },
    image: {
        [theme.breakpoints.down("sm")]: {
            maxHeight: theme.spacing(10),
            maxWidth: theme.spacing(10)
        },
        [theme.breakpoints.up("md")]: {
            maxHeight: theme.spacing(15),
            maxWidth: theme.spacing(15)
        },
        [theme.breakpoints.up("lg")]: {
            maxHeight: theme.spacing(15),
            maxWidth: theme.spacing(15)
        }
    },
    itemCell: {
        cursor: "pointer"
    },
    table: {
        marginBottom: theme.spacing(4)
    },
    quantityCell: {
        width: theme.spacing(25)
    },
    discounted: {
        textDecoration: "line-through",
        fontWeight: 'bold'
    },
    offer: {
        fontSize: 'large',
        fontWeight: 'bold'
    }
}));

const SaleItemList: FC = () => {
    const {t} = useTranslation()
    const classes = useStyles(undefined);
    const categories = saleQuery.useSaleCategories();
    const isEmpty = !categories.length;

    if (isEmpty) {
        return (<h3>{t('no-sale-title')}</h3>);
    } else {
        return (
            <Fragment>
                {categories.map(({category, items}) =>
                    <Fragment key={category.slug}>
                        <Typography component="h2" variant="h4" id={category.slug} gutterBottom>{category.name}</Typography>
                        <TableContainer component={Paper} className={classes.table}>
                            <Table>
                                <TableBody>
                                    {items.map(item => <ItemRow key={item.slug} item={item}/>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Fragment>
                )}
            </Fragment>
        );
    }
};


export default SaleItemList;
