import {Order, QueryConfig} from "@datorama/akita";
import {useObservable} from "../../../../common/useObservable";
import {HookQueryEntity} from "../../../HookQueryEntity";
import {Entity} from "../../../section/SectionStore";
import companyStore, {State} from "./CompanyStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class CompanyQuery extends HookQueryEntity<State> {
    private readonly activeName = this.selectActive(section => section.name);

    constructor(
        protected store = companyStore
    ) {
        super(store);
    }

    public useActiveName(): Entity["name"] | "" {
        return useObservable(this.activeName, this.getActive()?.name) || "";
    }
}

export default new CompanyQuery();
