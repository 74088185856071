import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import categoryStore, {CategoryStore, State} from "./CategoryStore";

@QueryConfig({})
export class CategoryQuery extends HookQueryEntity<State> {
    public readonly currentSlug = this.selectActiveId();
    public readonly current = this.selectActive();

    constructor(
        protected store: CategoryStore = categoryStore
    ) {
        super(store);
    }
}

export default new CategoryQuery();
