import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import React, {FC, Fragment, useEffect, useMemo, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Trans, useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import SubmitButton from "../../app/component/signup/SubmitButton";
import ConflictError from "../../app/service/error/ConflictError";
import configQuery from "../../app/state/config/ConfigQuery";
import {SimpleinputProps, useInput} from "./useInput";
import TextField from "@material-ui/core/TextField";
import CountrySelection from "../../app/component/address/CountrySelection";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import tenantSignupService from "../../app/service/TenantSignupService";
import {TenantSignupRequest} from "./TenantSignupRequest";

export const TenantSignupPage: FC = () => {

    const firstName = useInput(SimpleinputProps(''));
    const lastName = useInput(SimpleinputProps(''));
    const street = useInput(SimpleinputProps(''));
    const zip = useInput(SimpleinputProps(''));
    const city = useInput(SimpleinputProps(''));
    const country = useInput({initialValue: '', customValidation: (value => !!value)});
    const email = useInput(SimpleinputProps(''));
    const password = useInput(SimpleinputProps(''));

    const newsletter = useInput<boolean>({initialValue: true, valueFn: (event: any) => event.target.checked});
    const dsgvo = useInput<boolean>({
        initialValue: false,
        valueFn: (event: any) => event.target.checked,
        customValidation: (value) => value,
        initiallyValid: false
    });
    const terms = useInput<boolean>({
        initialValue: false,
        valueFn: (event: any) => event.target.checked,
        customValidation: (value) => value,
        initiallyValid: false
    });

    const [captchaSolved, setCaptchaSolved] = useState(false)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);

    const submit = () => {
        setLoading(true);
        tenantSignupService.create({
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            password: password.value,
            street: street.value,
            zip: zip.value,
            city: city.value,
            country: country.value,
            newsletter: newsletter.value,
            terms: terms.value,
            dsgvo: dsgvo.value,
        } as TenantSignupRequest)
            .then(() => {
                setLoading(false);
                setSuccess(true);
                setError(false);
            })
            .catch((err) => {
                setLoading(false);
                setSuccess(false);
                setError(true);
            })
    }

    useEffect(() => {
        setValid(firstName.valid && lastName.valid && email.valid && password.valid && street.valid && city.valid && country.valid && zip.valid && terms.valid && dsgvo.valid && captchaSolved)
    }, [firstName.valid, lastName.valid, email.valid, password.valid, street.valid, city.valid, country.valid, zip.valid, terms.valid, dsgvo.valid, captchaSolved])


    const {t} = useTranslation();
    const siteKey = configQuery.useConfig("reCaptchaSiteKey");
    const shopShortName = configQuery.useConfig("shopShortName");

    const breadcrumbs: Breadcrumb[] = useMemo(() => [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("signup-title"),
            to: "/benutzer/registrieren/",
        }
    ], [t]);

    if (success) {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("signup-success-title")}></ContentHeader>
                <Trans i18nKey="signup-success-text-for-tenant"
                       components={[<Typography variant="body1"/>]}
                       values={{shopName: shopShortName}}
                />
            </Fragment>
        );
    }

    return (
        <Fragment>
            <ContentHeader  breadCrumbs={breadcrumbs} data-cy="page-title" header={t("signup-title")}></ContentHeader>
            <Grid
                container
                spacing={4}
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault();
                    submit();
                }}
            >
                <Grid item container md={12}>
                    <Grid item md={12}>
                        <Typography variant="h5" component="h2">{t("signup-individual-legend")}</Typography>
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...firstName}
                            label={t("signup-firstName-label")}
                            required
                        />
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...lastName}
                            required
                            label={t("signup-lastName-label")}
                        />
                    </Grid>
                </Grid>
                <Grid item container md={12}>
                    <Grid item md={12}>
                        <Typography variant="h5" component="h2">{t("signup-contact-legend")}</Typography>
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...email}
                            label={t("signup-email-label")}
                            required
                            type="email"
                        />
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...password}
                            label={t("signup-password-label")}
                            required
                            type="password"
                        />
                    </Grid>

                </Grid>

                <Grid item container md={12}>
                    <Grid item md={12}>
                        <Typography variant="h5" component="h2">{t("signup-address-legend")}</Typography>
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...street}
                            label={t("form-address-location-street-label")}
                            required
                        />
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...city}
                            required
                            label={t("form-address-location-city-label")}
                        />
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            {...zip}
                            required
                            label={t("form-address-location-zip-label")}
                        />
                    </Grid>
                    <Grid item md={7}>
                        <CountrySelection
                            {...country}
                            required
                        />
                    </Grid>
                </Grid>


                <Grid item container md={12}>
                    <Grid item md={7}>
                        <FormControlLabel
                            control={<Checkbox
                                {...newsletter}
                                checked={newsletter.value}
                            />}
                            label={t("signup-newsletter-label")}/>
                    </Grid>
                    <Grid item md={7}>
                        <FormControlLabel
                            control={<Checkbox
                                {...terms}
                                checked={terms.value}
                            />}
                            label={t("signup-terms-label")}/>
                    </Grid>
                    <Grid item md={7}>
                        <FormControlLabel
                            control={<Checkbox
                                {...dsgvo}
                                checked={dsgvo.value}
                            />}
                            label={t("signup-dsgvo-label")}/>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    {siteKey &&
                    <ReCAPTCHA
                        sitekey={siteKey}
                        onChange={() => setCaptchaSolved(true)}
                    />
                    }
                </Grid>
                <Grid item md={3}>
                    <SubmitButton
                        isInvalid={!valid}
                        isLoading={loading}
                    />
                </Grid>

                <Grid item md={12}>
                    <ErrorMessage error={error}/>
                </Grid>

            </Grid>
        </Fragment>
    );
};

function ErrorMessage({error}: any) {
    const {t} = useTranslation();
    if (!error) {
        return null;
    } else if (error instanceof ConflictError) {
        return (
            <Alert severity="error">
                {t("signup-error-duplicate-email")}
            </Alert>
        );
    } else {
        return (
            <Alert severity="error">
                {t("error-500-heading")}<br/>
                {t("error-500-text1")}
            </Alert>
        );
    }
}

export default TenantSignupPage;
