import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import {Path} from "history";
import React, {FC, Fragment} from "react";
import Reference from "../../model/Reference";
import ListItemLink from "../ListItemLink";

export interface Props {
    heading?: string;
    items: Reference[];

    to(slug: string): Path;
}

export const ReferenceList: FC<Props> = ({heading, items, to}) => {
    return (
        <Fragment>
            {heading && <Typography variant="h5" component="h3">{heading}</Typography>}
            <List dense>
                {
                    items.map(({name, slug}) => (
                        <ListItemLink
                            key={slug}
                            disabled={false}
                            primary={name}
                            path={to(slug)}
                        />
                    ))
                }
            </List>
        </Fragment>
    );
};

export default ReferenceList;
