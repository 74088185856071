import {Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import Company from "../../../model/Company";
import {throttledLoading} from "../../throttleLoading";
import companyStore, {CompanyState, CompanyStore} from "./CompanyStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class CompanyQuery extends QueryEntity<CompanyState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();
    public readonly current = this.selectActive();
    public readonly currentSlug = this.selectActiveId();

    constructor(
        protected store: CompanyStore = companyStore
    ) {
        super(store);
    }

    public useAll(): Company[] {
        return useObservable(this.all, this.getAll());
    }

    public useCurrent(): Company | undefined {
        return useObservable(this.current, this.getActive());
    }
}

export default new CompanyQuery();
