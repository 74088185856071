import {useTranslation} from "react-i18next";
import categoryQuery from "../../state/categoryPage/filter/category/CategoryQuery";
import Breadcrumb from "./Breadcrumb";

export function useFilterBreadcrumbs() {
    const {t} = useTranslation();
    const category = categoryQuery.useCategory();
    const breadcrumbs: Breadcrumb[] = [];

    if (category) {
        const section = category.section;
        const linage = [...category.linage];
        linage.reverse();
        const [, , ...categories] = linage;
        const categoryBreadcrumbs: Breadcrumb[] = categories
            .map(({name, slug}) => ({
                text: name,
                to: `/produkte/k/${slug}/`
            }));
        breadcrumbs.push(
            {
                text: section.name,
                to: `/${section.slug}/fahrzeuge/`
            },
            {
                text: t("category-breadcrumb-text"),
                to: `/${section.slug}/produkte/`
            }
        );
        breadcrumbs.push.apply(breadcrumbs, categoryBreadcrumbs);
    }
    return breadcrumbs;
}

export default useFilterBreadcrumbs;
