import SearchResult from "../model/search/SearchResult";
import AuthorizedHttpService from "./AuthorizedHttpService";

export interface SearchService {
    find(query: string): Promise<SearchResult[]>;
}

export class HttpSearchService extends AuthorizedHttpService implements SearchService {
    public async find(query: string): Promise<SearchResult[]> {
        return this.get(`/searches`, { query });
    }
}

export default new HttpSearchService();
