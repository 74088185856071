import {Query} from "@datorama/akita";
import invoiceStore, {createInitialState, InvoiceState, InvoiceStore} from "./InvoiceStore";
import {useObservable} from "../../common/useObservable";
import {Page} from "../../model/user/OrderArchive";
import {Invoice, InvoiceListItem} from "../../service/InvoiceService";

export class InvoiceQuery extends Query<InvoiceState> {
    public readonly isLoading = this.selectLoading();
    public readonly error = this.selectError<Error | null>();
    public readonly current = this.select("current");
    public readonly page = this.select("page");

    constructor(
        protected store: InvoiceStore = invoiceStore
    ) {
        super(store);
    }

    public useError(): any {
        return useObservable(this.error, undefined);
    }

    public useIsLoading(): boolean {
        return useObservable(this.isLoading, false);
    }

    public usePage(): Page<InvoiceListItem> {
        return useObservable(this.page, createInitialState().page);
    }

    public useCurrent(): Invoice|undefined {
        return useObservable(this.current, createInitialState().current);
    }
}

export default new InvoiceQuery();
