import Vehicle from "../model/Vehicle";
import AuthorizedHttpService from "./AuthorizedHttpService";

export interface VehicleFilter {
    company: string;
    group: string;
}

export interface VehicleService {
    read(slug: string): Promise<Vehicle>;

    findAllByCompanyAndGroup(filter: Partial<VehicleFilter>): Promise<Vehicle[]>;
}

export class HttpVehicleService extends AuthorizedHttpService implements VehicleService {
    public async read(slug: string): Promise<Vehicle> {
        return this.get(`/vehicles/${slug}`);
    }

    public async findAllByCompanyAndGroup(filter: VehicleFilter): Promise<Vehicle[]> {
        return this.get(`/vehicles/`, filter as any);
    }
}

export default new HttpVehicleService() as VehicleService;
