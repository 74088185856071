import React, {FC, Fragment, useEffect, useState} from "react";
import GoogleMapReact from 'google-map-react';
import {RoomRounded} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import StorePopUp from "./StorePopUp";
import {Store} from "./Store";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapsService from "./MapsService";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles({
    marker: {
        fontSize: "30pt",
        transform: 'translate(-50%, -70%)'
    },
    largemarker: {
        fontSize: "50pt",
        zIndex: 99,
        transform: 'translate(-50%, -70%)'
    },
    popUpContainer: {
        opacity: 0.90,
        zIndex: 100,
        position: 'absolute',
        width: '300px',
        top: '-160px',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '5px',
        boxShadow: '2px 4px 4px 2px #ccc'
    },
    hide: {
        display: "none"
    }
})

interface LocationPinProps {
    store: Store
}

const LocationPin: FC<LocationPinProps> = ({store}: LocationPinProps) => {
    const classes = useStyles(undefined);
    const [popUp, setPopUp] = useState(false);

    return (<div
        onMouseEnter={() => setPopUp(true)}
        onMouseLeave={() => setPopUp(false)}>
        <Fragment>
            <RoomRounded className={popUp ? classes.largemarker : classes.marker} color={'secondary'}/>
            <div className={popUp ? classes.popUpContainer : classes.hide}>
                <StorePopUp store={store}/>
            </div>
        </Fragment>
    </div>)
}

interface LocationPinWrapperProps {
    store: Store
    lat: number
    lng: number
}

const LocationPinAnimationWrapper: FC<LocationPinWrapperProps> = ({store}: LocationPinWrapperProps) => {
    return (
        <Slide direction={"down"} in={true} mountOnEnter>
            <div>
                <LocationPin store={store}></LocationPin>
            </div>
        </Slide>
    )
}

interface MapContainerProps {
    stores: Store[]
    centerlat: number
    centerlng: number
}

const MapContainer: FC<MapContainerProps> = ({stores, centerlat, centerlng}: MapContainerProps) => {

    const [err, setError] = useState<string>('');
    const [apiKey, setApiKey] = useState<string>('');
    const [delayedStores, setDelayedStores] = useState<Store[]>([]);

    useEffect(() => {
        MapsService.getApiKey()
            .then(result => {
                setApiKey(result);
            })
            .catch(err => {
                setError(err);
                console.log(err);
            })
    }, [])

    function zoomEnd(args: any) {
        setDelayedStores(stores);
    }

    return (
        <Fragment>
            {
                apiKey !== '' &&
                <GoogleMapReact
                    bootstrapURLKeys={{key: apiKey}}
                    center={{lat: centerlat, lng: centerlng}}
                    zoom={(stores && stores.length > 0) ? 12 : 8}
                    onZoomAnimationEnd={zoomEnd}>
                    {
                        (delayedStores.length > 0) &&
                        stores.map(store => (
                            <LocationPinAnimationWrapper
                                key={store.lat + '/' + store.lng}
                                lat={store.lat}
                                lng={store.lng}
                                store={store}>
                            </LocationPinAnimationWrapper>
                        ))
                    }
                </GoogleMapReact>
            }
            {
                (!apiKey && !err) &&
                <CircularProgress color="secondary" />
            }
            {
                (!apiKey && err) &&
                <Typography component={'h2'}>Google Maps sind derzeit nicht verfuegbar</Typography>
            }


        </Fragment>

    )
}

export default MapContainer;
