import {Store, StoreConfig} from "@datorama/akita";
import jwt_decode from "jwt-decode";
import clientStorage, {ClientStorage} from "../../common/ClientStorage";
import AccessTokenPayload from "../../model/auth/AccessTokenPayload";
import JwtResponse, {AccessToken, RefreshToken} from "../../model/auth/JwtResponse";
import TokenPayload from "../../model/auth/TokenPayload";
import {CurrencyResponse} from "../../model/user/CurrencyResponse";

export interface State {
    "ui.login.open": boolean;
    loading: boolean;
    initialized: boolean;
    error: Error | null;
    session: JwtResponse | null;
    access: AccessTokenPayload | null;
    currencySymbol: string;
    currencyRateToEuro: number;
}

export const defaultCurrencySymbol = '€';
export const defaultCurrencyRate = 1.0;

export function createInitialState(storage = clientStorage, now: () => number = Date.now): Partial<State> {
    let session: State["session"] = null;
    let access: State["access"] = null;
    try {
        session = createInitialSessionState(storage);
        if (session) {
            const refresh = jwt_decode<TokenPayload>(session.refresh_token);
            if (refresh.exp * 1000 < now()) {
                session = null;
            }
            access = createAccess(session);
        }
    } catch (error) {
        // ignore session from storage
    }

    return {
        "ui.login.open": false,
        loading: false,
        initialized: false,
        error: null,
        currencySymbol: defaultCurrencySymbol,
        currencyRateToEuro: defaultCurrencyRate,
        session, access
    };
}

function createInitialSessionState(storage: ClientStorage): State["session"] {
    const access_token = storage.getItem("access_token") as AccessToken | null;
    const refresh_token = storage.getItem("refresh_token") as RefreshToken | null;

    if (access_token && refresh_token) {
        return {access_token: access_token, refresh_token: refresh_token};
    }

    return null;
}

function createAccess(session: State["session"]): State["access"] {
    let access: State["access"] = null;

    if (session) {
        access = jwt_decode<AccessTokenPayload>(session.access_token);
    }

    return access;
}

@StoreConfig({
    name: "auth"
})
export class AuthStore extends Store<State> {
    constructor() {
        super(createInitialState());
    }

    public getAccessToken(): AccessToken | null {
        const session = this.getValue().session;

        if (!session) {
            return null;
        }

        return session.access_token;
    }

    public setCurrencyInfo({symbol, rateToEuro}: CurrencyResponse): void {
        this.update({currencySymbol: symbol, currencyRateToEuro: rateToEuro})
    }

    public getRefreshToken(): RefreshToken | null {
        const session = this.getValue().session;

        if (!session) {
            return null;
        }

        return session.refresh_token;
    }

    public setSession(session: State["session"]): void {
        this.update({
            session, access: createAccess(session)
        });
    }
}

export default new AuthStore();
