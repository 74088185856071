import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import React, {FC} from "react";
import Company from "../../model/Company";
import companyQuery from "../../state/company/CompanyQuery";
import sectionQuery from "../../state/section/SectionQuery";
import vehicleGroupQuery from "../../state/vehiclegroup/VehicleGroupQuery";
import ListItemLink from "../ListItemLink";

export const VehicleCompanyList: FC = () => {
    const currentCompanySlug = companyQuery.useActiveId();
    const currentSectionSlug = sectionQuery.useActiveId();
    const currentVehicleGroupSlug = vehicleGroupQuery.useActiveId();
    const loading = companyQuery.useIsLoading();
    const companies = companyQuery.useAll();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <VehicleCompanyListPure
                currentCompanySlug={currentCompanySlug}
                currentSectionSlug={currentSectionSlug}
                currentVehicleGroupSlug={currentVehicleGroupSlug}
                companies={companies}
            />
        );
    }
};

export default VehicleCompanyList;


export interface VehicleCompanyListProps {
    currentSectionSlug?: string | null;
    currentCompanySlug?: string | null;
    currentVehicleGroupSlug?: string | null;
    companies: Company[];
}

export const VehicleCompanyListPure: FC<VehicleCompanyListProps> = (props) => {
    const {
        currentSectionSlug,
        currentCompanySlug,
        currentVehicleGroupSlug,
        companies
    } = props;

    return (
        <List>
            {companies.map(({name, slug}) => (
                <ListItemLink
                    data-cy="vehicle-company-title"
                    key={slug}
                    primary={name}
                    path={`/${currentSectionSlug}/fahrzeuge/${currentVehicleGroupSlug}/${slug}/`}
                    disabled={slug === currentCompanySlug}
                />
            ))}
        </List>
    );
};
