import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Address from "../../model/checkout/Address";
import AddressType from "../../model/checkout/AddressType";
import CountrySelection from "./CountrySelection";

const useStyles = makeStyles(theme => ({
    form: {}
}));

export interface AddressFormProps {
    onChange: <T extends keyof Address>(property: T, value: Address[T]) => void;
    onSubmit: () => void;
    address: Address;
    isCompanyAddress: boolean;
}

export const AddressForm: FC<AddressFormProps> = ({address, onChange, onSubmit, isCompanyAddress}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const isInvalid: boolean = !address.name || !address.country || !address.city || !address.zip || !address.street;

    return (
        <Grid
            container
            spacing={4}
            component="form"
            className={classes.form}
            onSubmit={(event: any) => {
                event.preventDefault();
                onSubmit();
            }}
        >
            <Grid item md={12}>
                <Typography variant="h5" component="h2">{t("address-type-legend")}</Typography>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                disabled={isCompanyAddress}
                                checked={address.companyAddress}
                                name="companyAddress"
                                onChange={(event) => onChange("companyAddress", event.target.checked)}
                            />}
                            label={t("address-type-" + AddressType.COMPANY)}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                data-cy="addressform-checkbox-billing"
                                checked={address.billing}
                                name="billing"
                                onChange={(event) => onChange("billing", event.target.checked)}
                            />}
                            label={t("address-type-" + AddressType.BILLING)}
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={address.delivery}
                                name="delivery"
                                onChange={(event) => onChange("delivery", event.target.checked)}
                            />}
                            label={t("address-type-" + AddressType.DELIVERY)}
                        />
                    </FormGroup>
                </FormControl>

                <Typography variant="h5" component="h2">{"Haendlersuche"}</Typography>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                checked={address.dealerLocator}
                                name="dealerLocator"
                                onChange={(event) => onChange("dealerLocator", event.target.checked)}
                            />}
                            label={"diese Adresse soll in der Haendlersuche gefunden werden"}
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("address-mail-legend")}</Typography>
                </Grid>
                <Grid item md={7}>
                    <AddressTextField required property="name" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={7}>
                    <AddressTextField property="subName" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={7}>
                    <AddressTextField required property="street" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={5}/>
                <Grid item md={2}>
                    <AddressTextField required property="zip" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={5}>
                    <AddressTextField required property="city" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={7}>
                    <CountrySelection
                        value={address.country}
                        required
                        onChange={(event: any) => onChange("country", event.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item container md={12}>
                <Grid item md={12}>
                    <Typography variant="h5" component="h2">{t("address-contact-legend")}</Typography>
                </Grid>
                <Grid item md={7}>
                    <AddressTextField property="phone" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={7}>
                    <AddressTextField property="mobile" address={address} onChange={onChange}/>
                </Grid>
                <Grid item md={7}>
                    <AddressTextField property="fax" address={address} onChange={onChange}/>
                </Grid>
            </Grid>
            <Grid item md={3}>
                <Button
                    type="submit"
                    variant="contained"
                    color="default"
                    disabled={isInvalid}
                    disableElevation
                    data-cy="addressform-save-button">{t("form-action-save")}</Button>
            </Grid>
        </Grid>
    );
};

interface AddressTextFieldProps<T extends keyof Address> {
    onChange(property: T, value: Address[T]): void;

    address: Address;
    property: T;
    required?: boolean;
}

const AddressTextField: FC<AddressTextFieldProps<keyof Address>> = ({address, property, onChange, ...props}) => {
    const {t} = useTranslation();
    return (
        <TextField
            {...props}
            fullWidth
            label={t(`address-${property}-label`)}
            name={property}
            value={address[property]}
            onChange={(event) => onChange(property, event.target.value)}
        />
    );
}

export default AddressForm;
