import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import PaginationActions from "../../app/component/category/PaginationActions";
import ContentHeader from "../../app/component/ContentHeader";
import Currency from "../../app/component/Currency";
import query from "../../app/state/backorder/BackorderQuery"
import store, {Backorder} from "../../app/state/backorder/BackorderStore"
import getItemPath from "../../app/common/route/getItemPath";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";

const useStyles = makeStyles(theme => ({
    image: {
        width: '100%',
    },
    itemCell: {
        flexBasis: "40%",
        flexGrow: 1,
    },
    imageCell: {
        flexBasis: "10%",
        flexGrow: 0,
    },
    priceCell: {
        flexBasis: "10%"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    linkrow: {
        cursor: 'pointer',
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    verticalTop: {
        verticalAlign: "top"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        "& > *": {
            color: theme.palette.secondary.contrastText
        },
        backgroundColor: theme.palette.secondary.dark,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    grid: {
        "&:hover": {
            background: "#efefef"
        },
        padding: theme.spacing(2)
    },
    item: {
        cursor: "pointer"
    },
    deleteIcon: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    },
    table: {
        marginBottom: 10
    },
    notAvailable: {
        color: theme.palette.text.disabled
    },
    minValue: {
        color: theme.palette.error.light
    },
    box: {
        minWidth: theme.spacing(54),
    },
    discounted: {
        textDecoration: "line-through"
    },
    link: {
        cursor: "pointer"
    },
    footer: {
        width: "100%"
    }
}));

export const BackorderPage: FC = () => {
    const {t} = useTranslation();
    const page = query.usePage();

    const classes = useStyles(undefined);

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("action-show-backorders"),
            to: "/benutzer/rueckstaende/",
        }
    ];

    useEffect(() => {
        const subs = store.activatePageEffects()
        store.getPage({})
        return () => {
            subs.forEach(it => it.unsubscribe())
        }
    }, []);


    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        store.getPage({offset: newPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        store.getPage({size: parseInt(event.target.value, 10)});
    };

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("action-show-backorders")} />
            {page &&
            <Fragment>
                <TableContainer component={Paper} className={classes.table}>
                    <Table>
                        <TableBody>
                            <TableRow className={classes.header}>
                                <TableCell className={classes.imageCell}></TableCell>
                                <TableCell className={classes.itemCell}>{t("backorder-header-item")}</TableCell>
                                <TableCell className={classes.priceCell}>{t("backorder-header-price")}</TableCell>
                                <TableCell className={classes.priceCell}>{t("backorder-header-amount")}</TableCell>
                                <TableCell className={classes.priceCell}>{t("backorder-header-date")}</TableCell>
                            </TableRow>
                            {
                                page.content.map((item) => (
                                    <BackorderRow
                                        key={'' + item.id}
                                        item={item}/>
                                ))
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        className={classes.footer}
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={page.totalElements}
                        rowsPerPage={page.size}
                        page={page.number}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={PaginationActions}
                    />
                </TableContainer>


            </Fragment>
            }
        </Fragment>
    );
};

interface RowProps {
    item: Backorder;
    key: string;
}


const BackorderRow: FC<RowProps> = ({item}: RowProps) => {
    const classes = useStyles();
    const push = useHistoryPushNew();

    return (
        <Fragment>
            <TableRow className={item.itemReference.slug ? classes.linkrow : classes.row} onClick={() => {
                if (item.itemReference.slug) {
                    push(getItemPath(item.itemReference.slug))
                }
            }} hover={!!item.itemReference.slug}>
                <TableCell className={classes.imageCell}>
                        {
                            item.imageDto ?
                            <img className={classes.image}
                                 src={item.imageDto.locationSmall}
                                 alt={item.imageDto.description}/>
                                : null
                        }
                </TableCell>
                <TableCell className={classes.itemCell}>
                    <Typography color="textPrimary" component="span">{item.itemReference.slug ? `${item.itemReference.slug} - ${item.itemReference.name}` : item.itemReference.name}</Typography>
                </TableCell>
                <TableCell className={classes.priceCell}>
                    <Typography color="textPrimary" component="span"><Currency value={item.pricePerPiece}/></Typography>
                </TableCell>
                <TableCell className={classes.priceCell}>
                    <Typography color="textPrimary" component="span">{item.amount}</Typography>
                </TableCell>
                <TableCell className={classes.priceCell}>
                    <Typography color="textPrimary" component="span">{item.expectedDeliveryDate}</Typography>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}
