import PropTypes from 'prop-types';
import React, {Component, FC, useEffect, useState} from 'react';

import {clamp, midpoint, touchDistance, touchPt} from './geometry';
import makePassiveEventOption from './makePassiveEventOption';

// The amount that a value of a dimension will change given a new scale
// @ts-ignore
const coordChange = (coordinate, scaleRatio) => {
    return (scaleRatio * coordinate) - coordinate;
};

const translationShape = PropTypes.shape({x: PropTypes.number, y: PropTypes.number});

interface Translation {
    x: number;
    y: number;
}

interface Value {
    scale: number;
    translation: Translation
}

interface MapInteractionControlledProps {
    children?: Function;
    value: Value;
    onChange: Function;

    disableZoom?: boolean;
    disablePan?: boolean;
    translationBounds?: {

        xMin?: number;
        xMax?: number;
        yMin?: number;
        yMax?: number;
    }
    minScale?: number;
    maxScale?: number;
}

export class MapInteractionControlled extends Component {
    static get propTypes() {
        return {
            // The content that will be transformed
            children: PropTypes.func,

            // This is a controlled component
            value: PropTypes.shape({
                scale: PropTypes.number.isRequired,
                translation: translationShape.isRequired,
            }).isRequired,
            onChange: PropTypes.func.isRequired,

            disableZoom: PropTypes.bool,
            disablePan: PropTypes.bool,
            translationBounds: PropTypes.shape({
                xMin: PropTypes.number, xMax: PropTypes.number, yMin: PropTypes.number, yMax: PropTypes.number
            }),
            minScale: PropTypes.number,
            maxScale: PropTypes.number,
            showControls: PropTypes.bool,
            plusBtnContents: PropTypes.node,
            minusBtnContents: PropTypes.node,
            btnClass: PropTypes.string,
            plusBtnClass: PropTypes.string,
            minusBtnClass: PropTypes.string,
            controlsClass: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            minScale: 0.05,
            maxScale: 3,
            showControls: false,
            translationBounds: {},
            disableZoom: false,
            disablePan: false
        };
    }

// @ts-ignore
    constructor(props) {
        super(props);

        this.state = {
            shouldPreventTouchEndDefault: false
        };

// @ts-ignore
        this.startPointerInfo = undefined;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);

        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);

        this.onMouseUp = this.onMouseUp.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.onWheel = this.onWheel.bind(this);
    }

    componentDidMount() {
        const passiveOption = makePassiveEventOption(false);

        this.getContainerNode().addEventListener('wheel', this.onWheel, passiveOption);

        /*
          Setup events for the gesture lifecycle: start, move, end touch
        */

        // start gesture
        this.getContainerNode().addEventListener('touchstart', this.onTouchStart, passiveOption);
        this.getContainerNode().addEventListener('mousedown', this.onMouseDown, passiveOption);

        // move gesture
        window.addEventListener('touchmove', this.onTouchMove, passiveOption);
        window.addEventListener('mousemove', this.onMouseMove, passiveOption);

        // end gesture
        const touchAndMouseEndOptions = {capture: true, ...passiveOption};
        window.addEventListener('touchend', this.onTouchEnd, touchAndMouseEndOptions);
        window.addEventListener('mouseup', this.onMouseUp, touchAndMouseEndOptions);

    }

    componentWillUnmount() {
        this.getContainerNode().removeEventListener('wheel', this.onWheel);

        // Remove touch events
        this.getContainerNode().removeEventListener('touchstart', this.onTouchStart);
        window.removeEventListener('touchmove', this.onTouchMove);
        window.removeEventListener('touchend', this.onTouchEnd);

        // Remove mouse events
        this.getContainerNode().removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    /*
      Event handlers

      All touch/mouse handlers preventDefault because we add
      both touch and mouse handlers in the same session to support devicse
      with both touch screen and mouse inputs. The browser may fire both
      a touch and mouse event for a *single* user action, so we have to ensure
      that only one handler is used by canceling the event in the first handler.

      https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent
    */

// @ts-ignore
    onMouseDown(e) {
        e.preventDefault();
        this.setPointerState([e]);
    }

// @ts-ignore
    onTouchStart(e) {
        e.preventDefault();
        this.setPointerState(e.touches);
    }

// @ts-ignore
    onMouseUp() {
// @ts-ignore
        this.setPointerState();
    }

// @ts-ignore
    onTouchEnd(e) {
        this.setPointerState(e.touches);
    }

// @ts-ignore
    onMouseMove(e) {
// @ts-ignore
        if (!this.startPointerInfo || this.props.disablePan) {
            return;
        }
        e.preventDefault();
        this.onDrag(e);
    }

// @ts-ignore
    onTouchMove(e) {
// @ts-ignore
        if (!this.startPointerInfo) {
            return;
        }

        e.preventDefault();

// @ts-ignore
        const {disablePan, disableZoom} = this.props;

// @ts-ignore
        const isPinchAction = e.touches.length === 2 && this.startPointerInfo.pointers.length > 1;
        if (isPinchAction && !disableZoom) {
            this.scaleFromMultiTouch(e);
// @ts-ignore
        } else if ((e.touches.length === 1) && this.startPointerInfo && !disablePan) {
            this.onDrag(e.touches[0]);
        }
    }

    // handles both touch and mouse drags
// @ts-ignore
    onDrag(pointer) {
// @ts-ignore
        const {translation, pointers} = this.startPointerInfo;
        const startPointer = pointers[0];
        const dragX = pointer.clientX - startPointer.clientX;
        const dragY = pointer.clientY - startPointer.clientY;
        const newTranslation = {
            x: translation.x + dragX,
            y: translation.y + dragY
        };

        const shouldPreventTouchEndDefault = Math.abs(dragX) > 1 || Math.abs(dragY) > 1;

        this.setState({
            shouldPreventTouchEndDefault
        }, () => {
// @ts-ignore
            this.props.onChange({
// @ts-ignore
                scale: this.props.value.scale,
                translation: this.clampTranslation(newTranslation)
            });
        });
    }

// @ts-ignore
    onWheel(e) {
// @ts-ignore
        if (this.props.disableZoom) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const scaleChange = 2 ** (e.deltaY * 0.02);

        const newScale = clamp(
// @ts-ignore
            this.props.minScale,
// @ts-ignore
            this.props.value.scale + (1 - scaleChange),
// @ts-ignore
            this.props.maxScale
        );

        const mousePos = this.clientPosToTranslatedPos({x: e.clientX, y: e.clientY});

        this.scaleFromPoint(newScale, mousePos);
    }

// @ts-ignore
    setPointerState(pointers) {
        if (!pointers || pointers.length === 0) {
// @ts-ignore
            this.startPointerInfo = undefined;
            return;
        }

// @ts-ignore
        this.startPointerInfo = {
            pointers,
// @ts-ignore
            scale: this.props.value.scale,
// @ts-ignore
            translation: this.props.value.translation,
        }
    }

// @ts-ignore
    clampTranslation(desiredTranslation, props = this.props) {
        const {x, y} = desiredTranslation;
// @ts-ignore
        let {xMax, xMin, yMax, yMin} = props.translationBounds;
        xMin = xMin !== undefined ? xMin : -Infinity;
        yMin = yMin !== undefined ? yMin : -Infinity;
        xMax = xMax !== undefined ? xMax : Infinity;
        yMax = yMax !== undefined ? yMax : Infinity;

        return {
            x: clamp(xMin, x, xMax),
            y: clamp(yMin, y, yMax)
        };
    }

// @ts-ignore
    translatedOrigin(translation = this.props.value.translation) {
        const clientOffset = this.getContainerBoundingClientRect();
        return {
            x: clientOffset.left + translation.x,
            y: clientOffset.top + translation.y
        };
    }

    // From a given screen point return it as a point
    // in the coordinate system of the given translation
// @ts-ignore
    clientPosToTranslatedPos({x, y}, translation = this.props.value.translation) {
        const origin = this.translatedOrigin(translation);
        return {
            x: x - origin.x,
            y: y - origin.y
        };
    }

// @ts-ignore
    scaleFromPoint(newScale, focalPt) {
// @ts-ignore
        const {translation, scale} = this.props.value;
        const scaleRatio = newScale / (scale !== 0 ? scale : 1);

        const focalPtDelta = {
            x: coordChange(focalPt.x, scaleRatio),
            y: coordChange(focalPt.y, scaleRatio)
        };

        const newTranslation = {
            x: translation.x - focalPtDelta.x,
            y: translation.y - focalPtDelta.y
        };
// @ts-ignore
        this.props.onChange({
            scale: newScale,
            translation: this.clampTranslation(newTranslation)
        })
    }

    // Given the start touches and new e.touches, scale and translate
    // such that the initial midpoint remains as the new midpoint. This is
    // to achieve the effect of keeping the content that was directly
    // in the middle of the two fingers as the focal point throughout the zoom.
// @ts-ignore
    scaleFromMultiTouch(e) {
// @ts-ignore
        const startTouches = this.startPointerInfo.pointers;
        const newTouches = e.touches;

        // calculate new scale
        const dist0 = touchDistance(startTouches[0], startTouches[1]);
        const dist1 = touchDistance(newTouches[0], newTouches[1]);
        const scaleChange = dist1 / dist0;

// @ts-ignore
        const startScale = this.startPointerInfo.scale;
        const targetScale = startScale + ((scaleChange - 1) * startScale);
// @ts-ignore
        const newScale = clamp(this.props.minScale, targetScale, this.props.maxScale);

        // calculate mid points
        const startMidpoint = midpoint(touchPt(startTouches[0]), touchPt(startTouches[1]))
        const newMidPoint = midpoint(touchPt(newTouches[0]), touchPt(newTouches[1]));

        // The amount we need to translate by in order for
        // the mid point to stay in the middle (before thinking about scaling factor)
        const dragDelta = {
            x: newMidPoint.x - startMidpoint.x,
            y: newMidPoint.y - startMidpoint.y
        };

        const scaleRatio = newScale / startScale;

        // The point originally in the middle of the fingers on the initial zoom start
// @ts-ignore
        const focalPt = this.clientPosToTranslatedPos(startMidpoint, this.startPointerInfo.translation);

        // The amount that the middle point has changed from this scaling
        const focalPtDelta = {
            x: coordChange(focalPt.x, scaleRatio),
            y: coordChange(focalPt.y, scaleRatio)
        };

        // Translation is the original translation, plus the amount we dragged,
        // minus what the scaling will do to the focal point. Subtracting the
        // scaling factor keeps the midpoint in the middle of the touch points.
        const newTranslation = {
// @ts-ignore
            x: this.startPointerInfo.translation.x - focalPtDelta.x + dragDelta.x,
// @ts-ignore
            y: this.startPointerInfo.translation.y - focalPtDelta.y + dragDelta.y
        };

// @ts-ignore
        this.props.onChange({
            scale: newScale,
            translation: this.clampTranslation(newTranslation)
        });
    }

    discreteScaleStepSize() {
// @ts-ignore
        const {minScale, maxScale} = this.props;
        const delta = Math.abs(maxScale - minScale);
        return delta / 10;
    }

    // Scale using the center of the content as a focal point
// @ts-ignore
    changeScale(delta) {
// @ts-ignore
        const targetScale = this.props.value.scale + delta;
// @ts-ignore
        const {minScale, maxScale} = this.props;
        const scale = clamp(minScale, targetScale, maxScale);

        const rect = this.getContainerBoundingClientRect();
        const x = rect.left + (rect.width / 2);
        const y = rect.top + (rect.height / 2);

        const focalPoint = this.clientPosToTranslatedPos({x, y});
        this.scaleFromPoint(scale, focalPoint);
    }

// @ts-ignore
    getContainerNode() {
// @ts-ignore
        return this.containerNode
    }

    getContainerBoundingClientRect() {
        return this.getContainerNode().getBoundingClientRect();
    }

    render() {
// @ts-ignore
        const {children} = this.props;
// @ts-ignore
        const scale = this.props.value.scale;
        // Defensively clamp the translation. This should not be necessary if we properly set state elsewhere.
// @ts-ignore
        const translation = this.clampTranslation(this.props.value.translation);

        /*
          This is a little trick to allow the following ux: We want the parent of this
          component to decide if elements inside the map are clickable. Normally, you wouldn't
          want to trigger a click event when the user *drags* on an element (only if they click
          and then release w/o dragging at all). However we don't want to assume this
          behavior here, so we call `preventDefault` and then let the parent check
          `e.defaultPrevented`. That value being true means that we are signalling that
          a drag event ended, not a click.
        */
// @ts-ignore
        const handleEventCapture = (e) => {
// @ts-ignore
            if (this.state.shouldPreventTouchEndDefault) {
                e.preventDefault();
                this.setState({shouldPreventTouchEndDefault: false});
            }
        }

        return (
            <div
                ref={(node) => {
// @ts-ignore
                    this.containerNode = node;
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative', // for absolutely positioned children
                    touchAction: 'none'
                }}
                onClickCapture={handleEventCapture}
                onTouchEndCapture={handleEventCapture}
            >
                {
// @ts-ignore
                    (children || undefined) && children({translation, scale})
                }
            </div>
        );
    }
}

interface Props {
    translationBounds?: Partial<{
        xMin: number;
        xMax: number;
        yMin: number;
        yMax: number;
    }>;
    viewport: [number, number];
    scene: [number, number];
    maxScale: number,
}


const MapInteractionController: FC<Props> = (props) => {
    const [state, setState] = useState({
        scale: 1,
        translation: {x: 0, y: 0}
    });
    const {viewport, scene, ...innerProps} = props;
    const minScale = Math.min(viewport[0] / scene[0], viewport[1] / scene[1], 1);
    useEffect(() => {
        setState({
            scale: minScale,
            translation: {
                x: (viewport[0] - scene[0] * minScale) * 0.5,
                y: (viewport[1] - scene[1] * minScale) * 0.5
            }
        });
    }, [minScale, viewport, scene, setState]);

    return (
        <MapInteractionControlled
            onChange={setState}
            value={state}
            minScale={minScale}
            {...innerProps}
        />
    );
};

export default MapInteractionController;
