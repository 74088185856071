import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {format as formatBytes} from "bytes";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import FileInfo from "../../model/FileInfo";

export interface FilesProps {
    files: FileInfo[];
}

export const Files: FC<FilesProps> = ({ files }) => {
    const { t } = useTranslation();
    const thousandsSeparator = t("numeral-format-delimiters-thousands");

    function formatSize(size: number): string {
        return formatBytes(size, {
            thousandsSeparator
        });
    }

    return (
        <Fragment>
            <Typography variant="h5" component="h3">{t("item-files-heading")}</Typography>
            <List dense>
                {
                    files.map(({ name, filename, uri, size, mime }) => (
                        <ListItem key={name} data-cy="item-file">
                            <Link href={uri}>
                                <Typography color="secondary" component="span">
                                    {name}
                                </Typography>
                                {" "}
                                ({t([`file-mime-${mime}`, "file-mime-default"])}, {formatSize(size)})
                            </Link>
                        </ListItem>
                    ))
                }
            </List>
        </Fragment>
    );
};

export default Files;
