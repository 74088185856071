import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import React, {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import {useObservable} from "../../common/useObservable";
import UnauthorizedError from "../../service/error/UnauthorizedError";
import authActions from "../../state/auth/AuthActions";
import authQuery from "../../state/auth/AuthQuery";
import PasswordField from "./PasswordField";

const useStyles = makeStyles(theme => ({
    actions: {
        marginTop: theme.spacing(2)
    },
    circularProgress: {
        color: theme.palette.secondary.dark
    }
}));

export const LoginForm: FC = () => {
    const classes = useStyles(undefined);
    const {t} = useTranslation();
    const loading = useObservable(authQuery.isLoading, false);
    const error = useObservable(authQuery.error, null);
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [keepLoggedIn, setKeepLoggedIn] = useState<boolean>(false);

    function createErrorMessage(error: Error | null) {
        if (error instanceof UnauthorizedError) {
            return t("auth-login-dialog-error-unauthorized");
        } else if (error) {
            return (
                <Fragment>
                    {t("error-500-heading")}<br/>
                    {t("error-500-text1")}
                </Fragment>
            );
        }
        return "";
    }

    const errorMessage = createErrorMessage(error);

    return (
        <form onSubmit={(event) => {
            event.preventDefault();
            authActions.login(username, password, keepLoggedIn)
                .catch(error => console.log(error));
        }}>
            <div>
                <TextField
                    data-cy="login-dialog-username"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t("auth-login-dialog-username")}
                    name="username"
                    autoFocus
                    autoComplete="off"
                    type="text"
                    onChange={(event) => setUsername(event.target.value)}
                    FormHelperTextProps={{error: true}}
                />
                <PasswordField
                    data-cy="login-dialog-password"
                    variant="outlined"
                    margin="normal"
                    name="password"
                    required
                    fullWidth
                    label={t("auth-login-dialog-password")}
                    autoComplete="off"
                    onChange={(event) => setPassword(event.target.value)}
                    helperText={errorMessage}
                    FormHelperTextProps={{error: true}}
                />
                <FormControlLabel
                    control={<Checkbox
                        value={keepLoggedIn}
                        onChange={(event) => setKeepLoggedIn(event.target.checked)}
                    />}
                    label={t("auth-login-dialog-keep-logged-in")}
                />
            </div>
            <div>
                <div>
                    <Link
                        color="secondary"
                        onClick={() => authActions.closeLoginDialog()}
                        component={RouterLink}
                        to="/benutzer/password/reset/"
                    >{t("password-reset-link")}</Link>
                </div>
                <div>

                    <Link
                        onClick={() => authActions.closeLoginDialog()}
                        component={RouterLink}
                        color="secondary"
                        to="/benutzer/registrieren/"
                    >{t("signup-action")}</Link>
                </div>
            </div>
            <Box width="100%" className={classes.actions}>
                <Button
                    data-cy="login-dialog-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    {t("account-action-login")}
                    {loading &&
                    <Box color="secondary.main" pl={1.5} display="flex">
                        <CircularProgress
                            size={24}
                            thickness={5}
                            className={classes.circularProgress}
                        />
                    </Box>}
                </Button>
            </Box>
        </form>
    );
};

export default LoginForm;
