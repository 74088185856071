import categoryService from "../../../service/CategoryService";
import categoryStore from "./SubCategoriesStore";

export class SubCategoriesActions {
    private readonly store = categoryStore;
    private readonly service = categoryService;

    public async load(category: string): Promise<void> {
        if (this.shouldSkipLoading(category)) {
            return;
        }

        try {
            this.store.update({
                loading: true,
                error: null,
                category
            });
            const result = await this.service.findAllBySection(category);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(next: string) {
        return this.isFilterEqual(next) && this.hasNoError();
    }

    private hasNoError() {
        const current = this.store.getValue();

        return !current.error;
    }

    private isFilterEqual(next: string) {
        const current = this.store.getValue().category;

        return next === current;
    }
}

export default new SubCategoriesActions();
