import {Store, StoreConfig} from "@datorama/akita";
import {Page} from "../../model/user/OrderArchive";
import {from, of, Subject, Subscription} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators"
import Image from "../../model/Image";
import backorderService from "../../service/BackorderService";
import ItemReference from "../../model/item/ItemReference";

export interface Backorder {
    id: number;
    itemReference: ItemReference;
    imageDto: Image;
    amount: number;
    pricePerPiece: number;
    expectedDeliveryDate: string;
}

export interface BackorderState {
    error?: any;
    loading: boolean;
    page: Page<Backorder>;
}

export const EMPTYPAGE: Page<Backorder> = {
    content: [],
    numberOfElements: 0,
    number: 0,
    totalElements: 0,
    size: 10,
    totalPages: 0
};

export function createInitialState(): BackorderState {
    return {
        loading: false,
        page: EMPTYPAGE,
    };
}

interface GetPageAction {
    size?: number;
    offset?: number;
}

@StoreConfig({name: "backorder"})
export class BackorderStore extends Store<BackorderState> {

    private service = backorderService;

    constructor() {
        super(createInitialState());
    }

    private readonly getPageAction$: Subject<GetPageAction> = new Subject<GetPageAction>()
    private readonly getPageEffect$ = this.getPageAction$.pipe(
        switchMap(action => from(this.service.getPage(action.offset, action.size)).pipe(
            tap(result => this.update({page: result, loading: false})),
            catchError(error => {
                console.log(error)
                this.update({error: error, page: createInitialState().page, loading: false})
                return of();
            })
            )
        )
    )

    public activatePageEffects(): Subscription[] {
        return [
            this.getPageEffect$.subscribe()
        ]
    }

    public getPage(action: GetPageAction): void {
        const {size, number} = this.getValue().page;
        this.getPageAction$.next({ size, offset: number, ...action })
    }
}

export default new BackorderStore();
