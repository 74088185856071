import {Query, QueryConfig} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import languageStore, {LanguageStore, State} from "./LanguageStore";

@QueryConfig({})
export class LanguageQuery extends Query<State> {
    private readonly value = this.select();

    constructor(
        protected store: LanguageStore = languageStore
    ) {
        super(store);
    }

    public useValue(): State {
        return useObservable(this.value, this.getValue());
    }

}

export default new LanguageQuery();
