import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, Fragment, useEffect} from "react";
import ContentHeader from "../app/component/ContentHeader";
import SaleItemList from "../app/sale/component/SaleItemList";
import NotFoundError from "../app/service/error/NotFoundError";
import saleQuery from '../app/state/sale/SaleQuery'
import saleStore, {SaleType} from '../app/state/sale/SaleStore'
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import {useRememberScrollY} from "../app/component/hooks/RememberScrollY";
import Grid from "@material-ui/core/Grid";
import {ProductCategoryListPure} from "../app/component/vehicle/ProductCategoryList";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    center: {
        marginTop: '15pt',
        textAlign: 'center'
    },
    stickyCategoryList: {
        [theme.breakpoints.up("lg")]: {
            position: "sticky",

        },
        top: theme.spacing(6)
    }
}));

export interface Props {
    type: SaleType
}

export const SalePage: FC<Props> = ({type}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const error = saleQuery.useError()
    const loading = saleQuery.useIsLoading();
    const store = saleStore;
    const categories = saleQuery.useSaleCategories();

    useRememberScrollY(!loading)

    useEffect(() => {
        const subs = store.activateEffects()
        store.getSalePage({type});
        return () => {
            subs.forEach(it => it.unsubscribe())
        }
    }, [type, store]);


    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader data-cy="page-title" header={type === SaleType.CLEARANCE ? t('clearance-heading') : t('sale-heading')}
            />
            {loading &&
            <div className={classes.center}>
                <CircularProgress/>
            </div>
            }
            {!loading ?
                <Grid container direction='row' alignItems={'flex-start'}>
                    <Grid item xs={12} lg={3} className={classes.stickyCategoryList}>
                            <ProductCategoryListPure
                                heading={t('category-list-title')}
                                hashLinks={true}
                                categories={categories.map(it => it.category)}
                                activeSlug={''}
                                path={`${window.location.pathname}#`}
                            />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <SaleItemList/>
                    </Grid>
                </Grid>
                : null}
        </Fragment>
    );
}
