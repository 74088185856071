import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import authQuery from "../../state/auth/AuthQuery";
import ContentHeader from "../ContentHeader";
import LoginForm from "./LoginForm";

export const AuthGuard: FC = ({children}) => {
    const {t} = useTranslation();
    const isLoggedIn = authQuery.useIsLoggedIn();

    if (isLoggedIn) {
        return (
            <Fragment>{children}</Fragment>
        );
    } else {
        return (
            <Fragment>
                <ContentHeader header={t("auth-login-dialog-title")}></ContentHeader>
                <Grid container>
                    <Grid item md={6}><LoginForm/></Grid>
                </Grid>
            </Fragment>
        );
    }
};

export default AuthGuard;
