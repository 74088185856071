import News from "../model/News";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class NewsService extends AuthorizedHttpService {
    public async getAllCurrentNews(): Promise<News[]> {
        return this.get(`/news/`);
    }
}

export default new NewsService();
