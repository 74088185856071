import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import React, {FC, Fragment, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import ReturnForm from "../../app/model/returns/ReturnForm";
import NotFoundError from "../../app/service/error/NotFoundError";
import returnFormService from "../../app/service/returns/ReturnFormService";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";

export const ReturnsArchivePage: FC = () => {
    const {t} = useTranslation();
    const breadcrumbs: Breadcrumb[] = [];
    const [items, setItems] = useState<ReturnForm[]>([]);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        returnFormService.list().then(setItems).catch(setError)
    }, []);

    breadcrumbs.push(
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("returns-archive-page-title"),
            to: "/ruecksendungen/"
        }
    );

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("returns-archive-page-title")}></ContentHeader>
            <Grid container>
                {items.length > 0}
                <Grid item xs={12} component={List}>
                    {items.map(item =>
                        <ListItem button component={RouterLink} to={`/ruecksendungen/${item.id}`}>
                            <ListItemText
                                primary={<Trans i18nKey="returns-archive-list-entry"
                                                values={{sentAt: moment(item.sentAt).format(t("date-time-format"))}}
                                                components={[<strong/>]}
                                />}
                            />
                        </ListItem>
                    )}
                </Grid>
                {items.length === 0 &&
                <Grid item xs={12}>
                    <Alert severity="info">{t("returns-archive-empty-list")}</Alert>
                </Grid>
                }
                <Grid item md={3} sm={4}>
                    <Button variant="contained" color="default" fullWidth
                            disableElevation
                            component={RouterLink}
                            to="/ruecksendungen/add"
                            children={t("footer-add-returns")}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ReturnsArchivePage;
