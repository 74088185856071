import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment, useEffect} from "react";
import {useParams} from "react-router";
import useCategoryBreadcrumbs from "../app/common/breadcrumb/useCategoryBreadcrumbs";
import {useObservable} from "../app/common/useObservable";
import CategoryList from "../app/component/category/categoryselection/CategoryList";
import CategoryImages from "../app/component/category/categoryselection/CategorySelectionImages";
import ContentHeader from "../app/component/ContentHeader";
import NotFoundError from "../app/service/error/NotFoundError";
import categoryActions from "../app/state/category/CategoryActions";
import categoryQuery from "../app/state/category/CategoryQuery";
import sectionActions from "../app/state/section/SectionActions";
import sectionQuery from "../app/state/section/SectionQuery";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

interface Params {
    section: string;
}

export const CategorySelectionPage: FC = () => {
    const {section} = useParams<Params>();
    const breadcrumbs = useCategoryBreadcrumbs(section);
    const error = useObservable(categoryQuery.error, null);
    const sectionName = sectionQuery.useActiveName() || "";

    useEffect(() => {
        sectionActions.select(section).catch(error => console.log(error));
        categoryActions.load(section).catch(error => console.log(error));
    }, [section]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={sectionName}></ContentHeader>
            <Grid container>
                <Grid item md={3}>
                    <CategoryList/>
                </Grid>

                <Grid item md>
                    <CategoryImages/>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default CategorySelectionPage;
