import fetch from "isomorphic-unfetch";
import HttpService from "./HttpService";

export class SignupService extends HttpService {
    public async create(data: FormData): Promise<void> {
        const path = "/signup/";
        const body = data;
        const url = this.createUrl(path);

        const response = await fetch(url, {
            method: "POST",
            body
        });

        this.checkForError(response);

        try {
            await response.json();
        } catch (e) {
        }
    }
}

export default new SignupService();
