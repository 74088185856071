import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import getItemPath from "../../common/route/getItemPath";
import useHistoryPushNew from "../../common/useHistoryPushNew";
import Item from "../../model/item/Item";
import PaginationActions from "../category/PaginationActions";
import CompanyLogo from "../CompanyLogo";
import AppLink from "../theme/AppLink";

export interface PagedItemProps {
    items: Item[];
    page: number;
    size: number;
    count: number;
    setPage: (newPage: number) => void;
    setSize: (newSize: number) => void;
}

export const PagedItemListPure: FC<PagedItemProps> = ({items, page, size, count, setPage, setSize}) => {
    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setSize(parseInt(event.target.value, 10));
    }

    return (
        <Fragment>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {items.map(item => <ItemRow key={item.slug} item={item}/>)}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PaginationActions}
            />
        </Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    image: {
        [theme.breakpoints.down("sm")]: {
            maxHeight: theme.spacing(10),
            maxWidth: theme.spacing(10)
        },
        [theme.breakpoints.up("md")]: {
            maxHeight: theme.spacing(15),
            maxWidth: theme.spacing(15)
        },
        [theme.breakpoints.up("lg")]: {
            maxHeight: theme.spacing(20),
            maxWidth: theme.spacing(20)
        }
    },
    itemCell: {
        width: "100%"
    },
    itemAttributes: {
        marginTop: 0,
        marginBottom: 0
    },
    company: {
        maxHeight: theme.spacing(5),
        maxWidth: theme.spacing(15),
        [theme.breakpoints.up("lg")]: {
            maxHeight: theme.spacing(10),
            maxWidth: theme.spacing(20)
        }
    },
    row: {
        cursor: "pointer"
    },
    flags: {
        marginTop: 0
    }
}));

interface ItemRowProps {
    item: Item;
}

const ItemRow: FC<ItemRowProps> = ({item}) => {
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();
    const images = item.images || [];

    return <TableRow
        hover
        onClick={() => push(getItemPath(item.slug))}
        className={classes.row}
    >
        <TableCell
            align="center"
            className={classes.verticalTop}
        >
            {
                images.length > 0 &&
                <img className={classes.image}
                     src={images[0].locationDefault}
                     alt={images[0].description}/>
            }
        </TableCell>
        <TableCell component="th" scope="row" className={classes.itemCell}>
            <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                {item.company && item.company.name + " "}
                <Typography color="secondary" component="span"
                            variant="inherit">{item.itemNumber}</Typography>
            </Box>
            <AppLink to={getItemPath(item.slug)}>{item.name}</AppLink>
        </TableCell>
        <TableCell
            align="right"
            className={classes.verticalTop}
        >
            {item.company &&
            <CompanyLogo className={classes.company} company={item.company}/>}
        </TableCell>
    </TableRow>;
}
