import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";

export const FinishedPage: FC = () => {
    const {t} = useTranslation();
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("preorder-code-page-title"),
            to: "/vor-teile-wochen/code.html"
        }
    ];

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("preorder-cart-finished-page-title")}></ContentHeader>
            <Typography variant="body1">
                {t("preorder-cart-finished-text-1")}
            </Typography>
            <Typography variant="body1">
                {t("preorder-cart-finished-text-2")}
            </Typography>
        </Fragment>
    );
};

export default FinishedPage;
