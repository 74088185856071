import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InternalServerErrorPage from "../../../errors/500";
import Shipping from "../../model/checkout/Shipping";
import ShippingType from "../../model/checkout/ShippingType";
import shippingService from "../../service/ShippingService";
import shoppingCartActions from "../../state/shoppingCart/ShoppingCartActions";
import shoppingCartQuery from "../../state/shoppingCart/ShoppingCartQuery";

const useStyles = makeStyles((theme) => ({
    radioGroup: {
        padding: theme.spacing(1)
    },
    image: {
        "& > img": {
            maxWidth: theme.spacing(18),
            maxHeight: theme.spacing(6)
        }
    },
    grid: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        fontWeight: "bold"
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(1)
    },
    label: {
        alignItems: "flex-start"
    }
}));


const ShippingOptions: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    let shippingType: ShippingType = shoppingCartQuery.useShippingType();
    const isShippingDisabled = shoppingCartQuery.useIsShippingDisabled();
    const [shippingOptions, setShippingOptions] = useState<Shipping[]>([]);
    const [error, setError] = useState<Error | null>(null);

    if (shippingOptions.length === 1) {
        shippingType = shippingOptions[0].type;
        shoppingCartActions.setShippingType(shippingType).catch(error => console.log(error));
    }

    useEffect(() => {
        shippingService.findForCurrentUser()
            .then(setShippingOptions)
            .catch(setError);
    }, []);


    function onChange(value: ShippingType) {
        shoppingCartActions.setShippingType(value).catch(error => console.log(error));
    }

    return (
        <Fragment>
            {
                error && <InternalServerErrorPage/>
            }
            <FormControl component="fieldset">
                <Typography variant="subtitle1" className={classes.grid} data-cy="shipping-title">
                    {t("checkout-shipping-options")}</Typography>
                <RadioGroup className={classes.radioGroup} aria-label="shipping-options" name="shipping-options"
                            value={shippingType}
                            onChange={event => onChange(event.target.value as ShippingType)}>

                    {shippingOptions.length <= 0 &&
                    <Typography variant="subtitle1">
                        {t("checkout-no-shipping-options-available")}</Typography>}

                    {shippingOptions.map(shippingOption => (
                        <Paper key={shippingOption.type} className={classes.paper} variant="outlined">
                            <FormControlLabel
                                data-cy="shipping-options-radio-button"
                                className={classes.label}
                                value={shippingOption.type}
                                control={<Radio/>}
                                disabled={isShippingDisabled(shippingOption.type)}
                                label={
                                    <div className={classes.image}
                                         dangerouslySetInnerHTML={{__html: shippingOption.description}}
                                    />
                                }
                            />
                            {shippingOption.type === ShippingType.NOX && isShippingDisabled(shippingOption.type) &&
                            <FormHelperText
                                children={t("checkout-nox-hint")}
                            />
                            }
                        </Paper>
                    ))}
                </RadioGroup>
            </FormControl></Fragment>
    );
};
export default ShippingOptions;
