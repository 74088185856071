import {QueryConfig, QueryEntity} from "@datorama/akita";
import itemStore, {ItemState, ItemStore} from "./LatestItemsStore";
import {throttledLoading} from "../throttleLoading";

@QueryConfig({})
export class LatestItemsQuery extends QueryEntity<ItemState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();
    public readonly page = this.select("page");
    public readonly size = this.select("size");
    public readonly count = this.select("totalItemsCount");

    constructor(
        protected store: ItemStore = itemStore
    ) {
        super(store);
    }
}

export default new LatestItemsQuery();
