import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import React, {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import PasswordField from "../../app/component/auth/PasswordField";
import ContentHeader from "../../app/component/ContentHeader";
import BadRequestError from "../../app/service/error/BadRequestError";
import userService from "../../app/service/UserService";
import InternalServerErrorPage from "../../errors/500";

export const ChangePasswordPage: FC = () => {
    const {t} = useTranslation();
    const [error, setError] = useState<any>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const isInvalid: boolean = !oldPassword || !newPassword;

    function clear() {
        setSuccess(false);
        setError(null);
    }

    function onSubmit() {
        clear();
        userService.changePassword(oldPassword, newPassword)
            .then(() => {
                setSuccess(true);
                setOldPassword("");
                setNewPassword("");
            })
            .catch(setError)
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("password-change-title"),
            to: "/benutzer/password/",
        }
    ];

    let errorMessage: any = "";
    if (error instanceof BadRequestError) {
        errorMessage = t("password-change-save-error");
    } else if (error) {
        return <InternalServerErrorPage/>
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("password-change-title")}></ContentHeader>
            {
                success &&
                <Alert data-cy="alert" severity="success">{t(`password-change-save-success`)}</Alert>
            }
            <Grid
                container
                spacing={4}
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault();
                    onSubmit();
                }}
            >
                <Grid item container md={12}>
                    <Grid item md={7}>
                        <PasswordField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoComplete="off"
                            required
                            label={t(`password-change-old-password-label`)}
                            name="old-password"
                            value={oldPassword}
                            onChange={(event) => {
                                clear();
                                setOldPassword(event.target.value);
                            }}
                            helperText={errorMessage}
                            FormHelperTextProps={{error: true}}
                        />
                    </Grid>
                    <Grid item md={7}>
                        <PasswordField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoComplete="off"
                            required
                            label={t(`password-change-new-password-label`)}
                            name="new-password"
                            value={newPassword}
                            onChange={(event) => {
                                clear();
                                setNewPassword(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item md={3}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="default"
                        disabled={isInvalid}
                        disableElevation>{t("form-action-save")}</Button>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ChangePasswordPage;
