import {Query} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import Category from "../../model/Category";
import saleStore, {createInitialState, SaleState, SaleStore} from "./SaleStore";

export class SaleQuery extends Query<SaleState> {
    public readonly isLoading = this.selectLoading();
    public readonly error = this.selectError<Error | null>();
    public readonly page = this.select("salePage");
    public readonly saleCategories = this.select("saleCategories");
    public readonly categories = this.select("categories");

    constructor(protected store: SaleStore = saleStore) {
        super(store);
    }

    public useError(): any {
        return useObservable(this.error, undefined);
    }

    public useIsLoading(): boolean {
        return useObservable(this.isLoading, false);
    }

    public useSaleCategories(): SaleState["saleCategories"] {
        return useObservable(this.saleCategories, this.getValue().saleCategories);
    }

    public useCategories(): Category[] {
        return useObservable(this.categories, createInitialState().categories)
    }
}

export default new SaleQuery();
