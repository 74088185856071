import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Path} from "history";
import React, {FC, Fragment, ReactNode, useState} from "react";
import AppLink from "../../app/component/theme/AppLink";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {KeyboardArrowDown} from "@material-ui/icons";

export interface HeaderMenuProps {
    title: string;
    items: {
        text: ReactNode;
        path: Path;
    }[];
}

const useStyles = makeStyles(theme => ({
    fillLink: {
        padding: theme.spacing(1),
        width: '100%',
        height: '100%',
        "&:hover": {
            textDecoration: 'none'
        }
    },
    clearPadding: {
        padding: 0
    },
    sectionButton: {
        "&:hover": {
            backgroundColor: theme.palette.warning.main,
        },
        color: theme.palette.secondary.contrastText,
        borderRadius: 0,
        height: '100%',
        fontWeight: 'bolder',
        border: 0,
        width: theme.spacing(20)
    },
}));

export const HeaderMenu: FC<HeaderMenuProps> = ({title, items}) => {
    const classes = useStyles(undefined);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Fragment>
            <Button
                className={classes.sectionButton}
                variant="outlined"
                color="inherit"
                onClick={handleClick}
                data-cy="header-menu-button">
                {title}<KeyboardArrowDown/>
            </Button>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map(({path, text}) => (
                    <MenuItem
                        onClick={handleClose}
                        href={path}
                        key={path}
                        className={classes.clearPadding}
                        data-cy="header-menu-item">
                        <AppLink className={classes.fillLink} to={path}>
                            {text}
                        </AppLink>
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};

export default HeaderMenu;
