import Category from "../../../../model/Category";
import service from "../../../../service/CategoryService";
import store from "./CategoryStore";

export class CategoryActions {
    private readonly store = store;
    private readonly service = service;

    public async load(slug: string): Promise<Category> {
        this.store.update({
            loading: true,
            error: null
        });
        try {
            const result = await this.service.findOneBySlug(slug);
            this.store.update({category: result});
            return result;
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }
}

export default new CategoryActions();
