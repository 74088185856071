import AuthorizedHttpService from "./AuthorizedHttpService";
import {Page} from "../model/user/OrderArchive";

export class InvoiceService extends AuthorizedHttpService {
    public async getPage(offset?: number, size?: number): Promise<Page<InvoiceListItem>> {
        const url = '/invoice/'
            + '?page=' + offset?? '0'
            + '&size=' + size ?? '25';
        return this.get(url);
    }

    public async getByNumber(number: string): Promise<Invoice> {
        const url = '/invoice/' + number
        return this.get(url);
    }
}

export interface Invoice {
    number: string;
    filename?: string;
    date: string;
    value: number;
    orderId: number;
    trackingLinks: string[];
}

export interface InvoiceListItem {
    number: string;
    date: string;
}

export default new InvoiceService();