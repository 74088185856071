import React from "react";
import {Icon} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
    }
});

interface ImageIcon {
    src: string;
}

export const ImageIcon: React.FC<ImageIcon> = props => {
    const classes = useStyles(props);
    return (<Icon>
        <img className={classes.imageIcon} src={props.src} alt={props.src}/>
    </Icon>);
};

export default ImageIcon;