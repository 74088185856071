import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Panel} from "./Panel";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    content: {
        "& a": {
            color: theme.palette.secondary.dark,
            textDecoration: "none"
        },
        "& a:hover": {
            textDecoration: "underline"
        }
    }
}));

export interface InternalNoteProps {
    internalNote: string;
}

export const InternalNote: FC<InternalNoteProps> = ({internalNote}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    return (
        <Panel>
            <Typography variant="h5" component="h3">{t("item-internal-note-heading")}</Typography>
            <Typography variant="body1" className={classes.content}>{parse(internalNote)}</Typography>
        </Panel>
    );
};

export default InternalNote;
