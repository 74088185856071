import AuthorizedHttpService from "./AuthorizedHttpService";
import {Page} from "../model/user/OrderArchive";
import {Backorder} from "../state/backorder/BackorderStore";

export class BackorderService extends AuthorizedHttpService {

    public getPage(page: number = 0, size: number = 10, direction: string = 'desc'): Promise<Page<Backorder>> {
        const query = `?page=${page}&size=${size}&sortOrder=${direction}`
        return this.get(`/backorder/${query}`);
    }
}

export default new BackorderService();
