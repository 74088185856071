import React, {FC, Fragment, ReactFragment} from "react";
import stateQuery from "../../state/tenant/TenantQuery";
import PageInternalServerError from '../../../errors/500'
import {CircularProgress} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface TenantSwitchProps {
    forTenant?: ReactFragment,
    forBase?: ReactFragment
}

const useStyles = makeStyles(theme => ({
    circularProgress: {
        color: theme.palette.secondary.dark
    }
}));

export const TenantSwitch: FC<TenantSwitchProps> = ({forTenant, forBase}) => {
    const classes = useStyles(undefined);
    const isTenant = stateQuery.useIsTenant()
    const error = stateQuery.useErrorFlag()
    const isInitialized = stateQuery.useIsInitialized()

    if (!isInitialized) {
        return <CircularProgress
        size={24}
        thickness={5}
        className={classes.circularProgress}
        />
    }

    if (error) {
        return <PageInternalServerError/>
    }

    if (isTenant) {
        return <Fragment>{forTenant}</Fragment>;
    } else {
        return <Fragment>{forBase}</Fragment>;
    }
};

export default TenantSwitch;
