import React, {FC, Fragment, useEffect, useMemo, useState} from "react";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import MapContainer from "../app/googlemaps/MapContainer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MapsAutocompleteField from "../app/googlemaps/MapsAutocompleteField";
import {Store} from "../app/googlemaps/Store";
import {orange, red} from "@material-ui/core/colors";
import userService from '../app/service/UserService';
import {Location} from "../app/googlemaps/Location";

const useStyles = makeStyles({
    map: {
        width: "100",
        height: "50vh"
    },
    padded: {
        marginBottom: '10px'
    },
    error: {
        color: red["800"],
    },
    warn: {
        color: orange["800"],
    }
})

export const StoreLocator: FC = () => {
    const classes = useStyles(undefined);

    const [error, setError] = useState('')
    const [centerLat, setCenterLat] = useState<number>(48)
    const [centerLng, setCenterLng] = useState<number>(16)
    const [stores, setStores] = useState<Store[]>([])
    const [selfStoreLocator, setSelfStoreLocator] = useState<Boolean>(false)


    useEffect(() => {
        userService.getProfile().then(
            ({storeLocatorActive}) => setSelfStoreLocator(storeLocatorActive)
        )
    }, [])

    const breadcrumbs: Breadcrumb[] = useMemo(() => [
        {
            text: 'Händlersuche',
            to: "/haendler.html",
        }
    ], []);

    function callback(center: Location, stores: Store[]) {
        setStores(stores);
        if (stores.length === 0) {
            setError('no stores near this address');
            return
        }

        setCenterLat(center.lat)
        setCenterLng(center.lng)
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={'Händlersuche'}></ContentHeader>
            <div>
                {
                    !selfStoreLocator &&
                    <div className={classes.padded}>
                        <p className={classes.warn}>
                            Ihr Unternehmen kann derzeit in der Händlersuche nicht gefunden werden.
                        </p>
                        <a href={"/benutzer/adresse"}>Sie können das hier ändern.</a>
                    </div>
                }
                {
                    !!error &&
                    <p className={classes.error}>In Ihrer Nähe konnten keine Händler gefunden werden.</p>
                }
            </div>

            <div className={classes.padded}>
                <MapsAutocompleteField callback={callback} error={(err) => {
                    setError(err);
                    console.log(err);
                }}></MapsAutocompleteField>
            </div>
            <div className={classes.map}>
                <MapContainer stores={stores} centerlat={centerLat} centerlng={centerLng}></MapContainer>
            </div>
        </Fragment>
    );
};

export default StoreLocator;
