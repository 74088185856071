import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {HashMap} from "@datorama/akita/lib/types";
import ItemSlug from "../../model/item/ItemSlug";
import WatchListItem from "../../model/watchLIst/WatchListItem";
import WatchList from "../../model/watchLIst/WatchList";

export type Entity = WatchListItem;

export type ID = ItemSlug;

export interface State extends EntityState<Entity, ID> {
    entities: HashMap<Entity>;
    ids: ID[];
    error: Error | null;
    loading: boolean;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
    };
}

@StoreConfig({
    name: "watchlist",
    idKey: "slug"
})
export class WatchListStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }

    akitaPreAddEntity(item: Entity): Entity {
        // @ts-ignore
        return {
            ...item,
            slug: item.item.slug,
        };
    }

    public setWatchList({watchListItems}: WatchList): void {
        this.set(watchListItems);
    }
}

export default new WatchListStore();
