import {useState} from "react";

export function  SimpleinputProps<T> (initialValue: T): UseInputProps<T> {
    return {
        initialValue: initialValue
    }
}

export interface UseInputProps<T> {
    initialValue: T
    valueFn?: (event: any) => T
    customValidation?: (value: T) => boolean
    initiallyValid?: boolean
}

export function useInput<T>({initialValue, valueFn, customValidation, initiallyValid}: UseInputProps<T>) {
    const [value, setValue] = useState(initialValue);
    const [valid, setValid] = useState(initiallyValid)
    return {
            valid: !!valid,
            checked: value,
            value,
            onChange: (event: any) => {



                const value = event.target.checked ? event.target.checked : event.target.value;
                if (customValidation) {
                    setValid(customValidation(value))
                } else {
                    setValid(event.target.checkValidity())
                }
                if (valueFn) {
                    setValue(valueFn(event))
                } else {
                    setValue(value);
                }
            }
    };
}
