import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import Typography from "@material-ui/core/Typography";


export const CheckoutSuccess: FC = () => {
    const { t } = useTranslation();
    const breadcrumbs: Breadcrumb[] = [];

    breadcrumbs.push(
        {
            text: t("shopping-cart-breadcrumb-text"),
            to: `/shop/warenkorb.html`
        },
        {
            text: t("checkout-success-breadcrumb-text"),
            to: `/shop/abgeschlossen.html`
        }
    );

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("checkout-success-heading")}></ContentHeader>
            <Typography variant="body1">
                {t("checkout-success-text")}</Typography>
        </Fragment>
    );
};

export default CheckoutSuccess;
