import Typography from "@material-ui/core/Typography";
import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ContentHeader from "../app/component/ContentHeader";
import AppLink from "../app/component/theme/AppLink";


export const NotFoundErrorPage: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Fragment>
            <ContentHeader  header={t("error-404-heading")}></ContentHeader>

            <Typography paragraph variant="body1">
                {t("error-404-text1")}
            </Typography>

            <Typography paragraph variant="body2">
                {location.pathname}
            </Typography>

            <Typography paragraph variant="body1">
                {t("error-404-text2")}

                <br/>
                <AppLink color="secondary" to="/">{t("link-index-text")}</AppLink>
            </Typography>

            <Typography paragraph variant="body1">
                {t("error-404-text3")}

                <br/>
                <AppLink color="secondary" to="/kontakt.html">{t("link-contact-text")}</AppLink>
            </Typography>
        </Fragment>
    );
};

export default NotFoundErrorPage;
