import React, {FC, Fragment} from "react";
import {Trans, useTranslation} from "react-i18next";
import itemQuery from "../../state/returns/items/ItemQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import itemActions from "../../state/returns/items/ItemActions";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";

export const ReturnItemTable: FC = () => {
    const {t} = useTranslation();
    const items = itemQuery.useAll();
    const isLoading = itemQuery.useIsLoading();

    if (isLoading) {
        return (<CircularProgress/>);
    } else if (items.length) {
        return (<React.Fragment>
                <h4>{t('returns-item-label')}</h4>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("returns-item-label")}</TableCell>
                                <TableCell>{t("returns-item-name-label")}</TableCell>
                                <TableCell>{t("returns-item-count-label")}</TableCell>
                                <TableCell>{t("returns-reason-label")}</TableCell>
                                <TableCell>{t("returns-comment-label")}</TableCell>
                                <TableCell>{t("returns-invoice-label")}</TableCell>
                                <TableCell>{t("returns-added-at-label")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map(item => (
                                <Fragment>
                                    <TableRow key={item.id}>
                                        <TableCell>{item.itemNumber}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                        <TableCell>{t(`returns-type-${item.reason}`)}</TableCell>
                                        <TableCell style={{whiteSpace: 'pre-wrap'}}>{item.comment}</TableCell>
                                        <TableCell>{item.invoiceNumber} ({moment(item.invoiceDate).format(t("date-format"))})</TableCell>
                                        <TableCell>{moment(item.addedAt).format(t("date-time-format"))}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => itemActions.removeItem(item.id)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    {item.reason === 'QUERY' ?
                                        <TableRow>
                                            <TableCell colSpan={4}></TableCell>
                                            <TableCell colSpan={4}>
                                                <TableContainer>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>{t("returns-complaint-vehicle-info")}</TableCell>
                                                            <TableCell>{t("returns-complaint-vehicle-id")}</TableCell>
                                                            <TableCell>{t("returns-complaint-vehicle-mileage")}</TableCell>
                                                            <TableCell>{t("returns-complaint-details")}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableRow>
                                                        <TableCell>{item.complaintVehicleInfo}</TableCell>
                                                        <TableCell>{item.complaintVehicleIdNumber}</TableCell>
                                                        <TableCell>{item.complaintMileage}</TableCell>
                                                        <TableCell
                                                            style={{whiteSpace: 'pre-wrap'}}>{item.complaintDetails}</TableCell>
                                                    </TableRow>
                                                </TableContainer>
                                            </TableCell>
                                        </TableRow>
                                        : null}
                                </Fragment>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>);
    }
    return (
        <Alert severity="info">
            <span>
            <Trans i18nKey="returns-empty-hint"
                   components={[<strong/>]}
            />
            </span>
        </Alert>
    );
}

export default ReturnItemTable;