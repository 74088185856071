import Company, {CompanySlug} from "../../../model/Company";
import {VehicleGroupSlug} from "../../../model/VehicleGroup";
import companyService, {CompanyService} from "../../../service/CompanyService";
import companyStore, {CompanyStore} from "./CompanyStore";

export interface Dependencies {
    companyStore: CompanyStore;
    companyService: CompanyService;
}

export class CompanyActions {
    private readonly companyStore = companyStore;
    private readonly companyService = companyService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async load(group?: VehicleGroupSlug) {
        try {
            this.companyStore.update({
                loading: true,
                error: null
            });
            const result = await this.find(group);
            this.companyStore.set(result);
        } catch (error) {
            this.companyStore.setError(error);
        } finally {
            this.companyStore.setLoading(false);
        }
    }

    private find(group?: VehicleGroupSlug): Promise<Company[]> {
        if (group) {
            return this.companyService.findAllByGroup(group);
        } else {
            return this.companyService.findAll();
        }
    }

    public select(slug: CompanySlug) {
        this.companyStore.setActive(slug);
    }

    public deselect() {
        this.companyStore.setActive(null);
    }
}

export default new CompanyActions();
