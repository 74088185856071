import {from, Observable, of, Subject, Subscription} from "rxjs";
import {catchError, filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import Item from "../../model/item/Item";
import ItemSlug from "../../model/item/ItemSlug";
import watchListService, {WatchListService} from "../../service/WatchListService";
import authQuery from "../auth/AuthQuery";
import watchListStore, {ID, WatchListStore} from "./WatchListStore";

export interface Dependencies {
    store: WatchListStore;
    service: WatchListService;
    isLoggedIn: Observable<boolean>;
}

export enum ItemActionType {
    ADD = 'add',
    REMOVE = 'remove',
}

interface ItemAction {
    payload: ItemSlug;
    type: ItemActionType;
}

function AddItemAction(slug: ItemSlug): ItemAction {
    return {
        payload: slug,
        type: ItemActionType.ADD,
    }
}

function RemoveItemAction(slug: ItemSlug): ItemAction {
    return {
        payload: slug,
        type: ItemActionType.REMOVE,
    }
}

export class WatchListActions {
    private readonly store = watchListStore;
    private readonly service: WatchListService = watchListService;
    private readonly isLoggedIn = authQuery.isLoggedIn;

    private setError = (error: any): Observable<any> => {
        this.store.update({
            loading: false,
            error: error
        })
        return of();
    }
    private setLoading = (loading: boolean): void => {
        this.store.update({
            loading: loading,
            error: null
        })
    }
    private startLoading = (): void => {
        this.setLoading(true);
    }
    private endLoading = (): void => {
        this.setLoading(false);
    }


    public activateWatchListEffects(): Subscription[] {
        return [
            this.itemRemoveEffect$.subscribe(),
            this.itemAddEffect$.subscribe(),
            this.loadEffect$.subscribe(),
            this.loginEffect$.subscribe(),
            this.logoutEffect$.subscribe()
        ]
    }

    private readonly itemAction$ = new Subject<ItemAction>()
    private readonly itemRemoveEffect$ = this.itemAction$.pipe(
        filter(action => action.type === ItemActionType.REMOVE),
        withLatestFrom(this.isLoggedIn),
        filter(([_, isLoggedIn]) => isLoggedIn),
        switchMap(([action, _]) =>
            from(this.service.remove(action.payload)).pipe(
                tap(() => this.loadWatchList()),
                catchError(this.setError)
            )
        ),
    )

    private readonly itemAddEffect$ = this.itemAction$.pipe(
        filter(action => action.type === ItemActionType.ADD),
        withLatestFrom(this.isLoggedIn),
        filter(([_, isLoggedIn]) => isLoggedIn),
        switchMap(([action, _]) =>
            from(this.service.add(action.payload)).pipe(
                tap(() => this.loadWatchList()),
                catchError(this.setError)
            )
        ),
    )

    private readonly loadAction$ = new Subject<{}>()
    private readonly loadEffect$ = this.loadAction$.pipe(
        withLatestFrom(this.isLoggedIn),
        map(([_, isLoggedIn]) => isLoggedIn),

        filter((isLoggedIn) => isLoggedIn),
        tap(() => this.startLoading()),
        switchMap(() => from(this.service.find()).pipe(
            tap(it => this.store.set(it.watchListItems)),
            tap(() => this.endLoading()),
            catchError(this.setError)
        ))
    )

    private readonly loginEffect$ = this.isLoggedIn.pipe(
        filter(it => it),
        tap(it => this.loadWatchList())
    )

    private readonly logoutEffect$ = this.isLoggedIn.pipe(
        filter(it => !it),
        tap(() => this.store.set([]))
    )

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public loadWatchList(): void {
        this.loadAction$.next({});
    }


    public addItem(item: Item): void {
        const {slug} = item;
        const current = this.store.getValue().entities[slug];
        if (!current) {
            this.itemAction$.next(AddItemAction(slug))
        }
    }

    public removeItem(slug: ID): void {
        this.itemAction$.next(RemoveItemAction(slug))
    }
}

export default new WatchListActions();
