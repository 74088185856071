import {Store, StoreConfig} from "@datorama/akita";
import Config from "../../model/Config";

export interface State extends Config {
    init: boolean;
    loading: boolean;
    error: any;
}

export function createInitialState(): Partial<State> {
    return {
        init: false,
        loading: false,
        error: null
    };
}

@StoreConfig({
    name: "config"
})
export class ConfigStore extends Store<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new ConfigStore();
