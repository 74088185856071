import {transaction} from "@datorama/akita";
import itemService from "../../../service/ItemService";
import vehicleCategoryActions from "../../category/CategoryActions";
import categoryAttributeActions from "../../categoryPage/filter/attribute/category/CategoryAttributeActions";
import categoryActions from "../../categoryPage/filter/category/CategoryActions";
import subCategoriesActions from "../../categoryPage/subcategory/SubCategoriesActions";
import pageSize from "../../common/PageSize";
import sectionActions from "../../section/SectionActions";
import itemStore, {State} from "./ItemStore";

export class ItemActions {
    private readonly store = itemStore;
    private readonly service = itemService;
    private readonly categoryAttributeActions = categoryAttributeActions;
    private readonly categoryActions = categoryActions;
    private readonly vehicleCategoryActions = vehicleCategoryActions;
    private readonly sectionActions = sectionActions;
    private readonly subCategoriesActions = subCategoriesActions;
    private readonly pageSize = pageSize;

    @transaction()
    public async navigateToPage(category: string): Promise<void> {
        if (this.isSameCategory(category) && this.hasNoError()) {
            return;
        }

        this.store.resetFilter();
        this.store.updateFilterAttribute("category.slug", category);

        await Promise.all([
            this.loadCategories(category),
            this.subCategoriesActions.load(category)
        ]);
    }

    private isSameCategory(category: string) {
        return category === this.getCurrentCategory();
    }

    private getCurrentCategory() {
        const filter = this.getCurrentFilter();

        return filter["category.slug"];
    }

    private getCurrentFilter(): State["filter"] {
        return this.store.getValue().filter;
    }

    private hasNoError() {
        const current = this.store.getValue();

        return !current.error;
    }

    private async loadCategories(category: string): Promise<void> {
        const {slug, section, itemsAvailable} = await this.categoryActions.load(category);
        const sectionSlug = section.slug;

        this.vehicleCategoryActions.select(slug);
        await Promise.all([
            this.vehicleCategoryActions.load(sectionSlug),
            this.sectionActions.select(sectionSlug),
            itemsAvailable ? this.loadFilterData(category) : Promise.resolve()
        ]);
    }

    private async loadFilterData(category: string) {
        await Promise.all([
            this.categoryAttributeActions.load(category),
            this.loadCurrentFilter()
        ]);
    }

    @transaction()
    public async resetFilter(): Promise<void> {
        this.store.resetFilter();
        await this.loadCurrentFilter();
    }

    @transaction()
    public async setAttribute(attributeSlug: string, attributeValue: string | null): Promise<void> {
        this.store.updateFilterAttribute(attributeSlug, attributeValue);
        this.store.updateFilterAttribute("page", 0);
        await this.loadCurrentFilter();
    }

    @transaction()
    public async setAttributes(attributes: [string, string][]): Promise<void> {
        this.store.resetFilter();
        attributes.forEach(([attributeSlug, attributeValue]) => {
            this.store.updateFilterAttribute(attributeSlug, attributeValue);
        });
        this.store.updateFilterAttribute("page", 0);
        await this.loadCurrentFilter();
    }

    private async loadCurrentFilter() {
        const filter = {...this.getCurrentFilter()};
        this.store.update({
            loading: true,
            error: null
        });
        try {
            const result = await this.service.findAllByFilter(filter);
            if (this.isFilterEqual(filter, this.getCurrentFilter())) {
                this.store.set(result.items);
                this.store.update({totalItemsCount: result.count});
            }
        } catch (error) {
            if (this.isFilterEqual(filter, this.getCurrentFilter())) {
                this.store.setError(error);
                throw error;
            }
        } finally {
            if (this.isFilterEqual(filter, this.getCurrentFilter())) {
                this.store.setLoading(false);
            }
        }
    }

    private isFilterEqual(a: State["filter"], b: State["filter"]): boolean {
        return Object.entries(a).every(([key, value]) => b[key] === value)
    }

    @transaction()
    public async setPage(newPage: number) {
        this.store.updateFilterAttribute("page", newPage);
        await this.loadCurrentFilter();
    }

    @transaction()
    public sortByName() {
        this.store.updateFilterAttribute("sort", "sort.name");
        this.loadCurrentFilter();
    }

    @transaction()
    public async sortByNumber() {
        this.store.updateFilterAttribute("sort", "sort.itemNumber");
        this.loadCurrentFilter();
    }

    @transaction()
    public async setSize(size: number) {
        this.store.updateFilterAttribute("page", 0);
        this.store.updateFilterAttribute("size", size);
        this.pageSize.save(size);
        await this.loadCurrentFilter();
    }

    @transaction()
    public async setQuery(query: string) {
        this.store.updateFilterAttribute("query", query);
        this.store.updateFilterAttribute("page", 0);
        await this.loadCurrentFilter();
    }
}

export default new ItemActions();
