import {TextField} from "@material-ui/core";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";

const InvoiceNumberLength = 7;

export const InvoiceNumberField: FC<any> = ({value, onChange, pristine, ...props}) => {
    const error = useInvoiceNumberError(value);

    function onChangeInternal(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value || "";

        onChange && onChange(value.substr(0, 7));
    }

    return (
        <TextField
            value={value}
            helperText={pristine ? "" : error}
            onChange={onChangeInternal}
            {...props}
        />
    );
};

export function isInvoiceNumberInvalid(value: string): boolean {
    return getInvoiceNumberError(value) !== "";
}

function useInvoiceNumberError(value: string): string {
    const {t} = useTranslation();
    const error = getInvoiceNumberError(value);

    return error && t(error, {length: InvoiceNumberLength});
}

function getInvoiceNumberError(value: string): string {
    if (value.length !== InvoiceNumberLength) {
        return "form-invoice-number-length-error";
    }

    const invoiceYear = parseInvoiceNumberYear(value);
    if (Number.isNaN(invoiceYear)) {
        return "form-invoice-number-format-error";
    }

    const {toYear, fromYear} = getDateRange();
    if (invoiceYear < fromYear || toYear < invoiceYear) {
        return "form-invoice-number-to-old-error";
    }

    return "";
}

function parseInvoiceNumberYear(value: string): number {
    const baseYear = 2000;
    let inputYear;
    try {
        inputYear = parseInt(value.substr(0, 2)) + baseYear;
    } catch (err) {
        inputYear = NaN;
    }
    return inputYear;
}

function getDateRange() {
    const today = new Date();
    const month = today.getMonth();
    const offset = month <= 6 ? -1 : 0;
    const toYear = today.getFullYear();
    const fromYear = offset + today.getFullYear();

    return {toYear, fromYear};
}

export default InvoiceNumberField;
