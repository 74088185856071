import {useObservable} from "../../../common/useObservable";
import categoryQuery from "../../../state/category/CategoryQuery";
import sectionQuery from "../../../state/section/SectionQuery";

export function useCategorySelection() {
    const categories = useObservable(categoryQuery.all, []);
    const loading = useObservable(categoryQuery.isLoading, false);
    const section = sectionQuery.useActiveId();

    return {categories, loading, section};
}

export default useCategorySelection;