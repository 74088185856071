import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Path} from "history";
import React, {FC} from "react";
import AppLink from "./theme/AppLink";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useHistory} from "react-router-dom";
import windowAPI from "./hooks/windowAPI";

const useStyles = makeStyles(theme => ({
    listItem: {
        cursor: 'pointer',
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderRadius: "2px",
        padding: "0.5vw",
        marginBottom: "0.5vw",
        "&:hover": {
            backgroundColor: theme.palette.warning.main,
            borderColor: theme.palette.warning.contrastText,
            borderRadius: "5px",
            "& span": {
                color: theme.palette.warning.contrastText
            }
        }
    },
    listItemFilled: {
        backgroundColor: theme.palette.primary.main
    },
    listItemText: {
        color: theme.palette.secondary.dark
    },
    listItemFilledText: {
        color: theme.palette.secondary.contrastText
    },
    clearLinkStyle: {
        "&:hover": {
            textDecoration: 'none'
        }
    }
}));

export interface ListItemLinkProps {
    primary: string;
    path: Path;
    disabled?: boolean;
    filled?: boolean;
    hashLink?: boolean
}

export const ListItemLink: FC<ListItemLinkProps> = ({primary, path, filled, disabled, hashLink, ...props}) => {

    const classes = useStyles(undefined);
    const history = useHistory();

    const scrollTo = (element: HTMLElement) => {
        const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({top: yCoordinate + -200, behavior: 'smooth'});
    }

    let classNames = [classes.listItem];
    if(filled) {
        classNames.push(classes.listItemFilled);
    }

    const onClick = () => {
        if(hashLink && document.getElementById(path.split('#')[1])) {
            windowAPI.scrollToY(document.getElementById(path.split('#')[1])!.offsetTop)
            history.push(windowAPI.location())
        }
    }

    let text =<ListItem className={classNames.join(' ')} onClick={onClick}> <ListItemText className={filled ? classes.listItemFilledText : classes.listItemText} primary={primary} {...props} /></ListItem>;

    return disabled ? text :
        <AppLink
            scroll={hashLink ? scrollTo : () => {}}
            hashLink={hashLink}
            className={classes.clearLinkStyle}
            to={path}
            color="secondary">
            {text}
        </AppLink>;

};

export default ListItemLink;
