import Category, {CategorySlug} from "../model/Category";
import Reference from "../model/Reference";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class HttpCategoryService extends AuthorizedHttpService {
    public async findAllBySection(category: string): Promise<Reference<CategorySlug>[]> {
        return this.get(`/categories/`, {category});
    }

    public async findAllCategoriesBySectionAndVehicle(section: string, vehicle: string): Promise<Reference<CategorySlug>[]> {
        return this.get(`/categories/`, {section, vehicle});
    }

    public async findAllBySectionAndMotor(section: string, motor: string): Promise<Reference<CategorySlug>[]> {
        return this.get(`/categories/`, {motor, section});
    }

    public async findAllByParentForPreorder(category: string): Promise<Reference<CategorySlug>[]> {
        return this.get(`/categories/`, {category, preorder: true});
    }

    public async findOneBySlug(slug: string): Promise<Category> {
        return this.get(`/categories/${slug}/`);
    }

    public async findAllCategoriesByVehicleAndCategory(query: { vehicle: string, category: string }): Promise<Category[]> {
        return this.get(`/categories/`, query);
    }
}

export default new HttpCategoryService();
