import {transaction} from "@datorama/akita";
import {CategorySlug} from "../../../model/Category";
import Reference from "../../../model/Reference";
import categoryService from "../../../service/CategoryService";
import categoryQuery from "./SubCategoryQuery";
import categoryStore from "./SubCategoryStore";

export class SubCategoryActions {
    private readonly store = categoryStore;
    private readonly service = categoryService;
    private readonly query = categoryQuery;

    @transaction()
    public async load(category: string): Promise<Reference<CategorySlug>[]> {
        if (this.shouldSkipLoading(category)) {
            return this.query.getAll();
        }

        try {
            this.deselect();
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                category
            });
            const result = await this.service.findAllByParentForPreorder(category);
            this.store.set(result);
            return result;
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }


    private shouldSkipLoading(section: string) {
        return this.isFilterEqual(section) && this.hasNoError();
    }

    private hasNoError() {
        const current = this.query.getValue();

        return !current.error;
    }

    private isFilterEqual(next: string) {
        const current = this.query.getValue().category;

        return next === current;
    }

    public select(slug: string) {
        this.store.setActive(slug as CategorySlug);
    }

    public deselect() {
        this.store.setActive(null);
    }
}

export default new SubCategoryActions();
