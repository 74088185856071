import store, {LanguageStore} from "./LanguageStore";
import {changeUserLanguage, getUserLanguageAndTranslations, Language} from "./UserLanguage";
import userService, {UserService} from "../../service/UserService";

export interface Dependencies {
    store: LanguageStore;
    userService: UserService;
}

export class LanguageActions {
    private readonly store = store;
    private readonly userService = userService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public load = () => {
        const [language] = getUserLanguageAndTranslations();
        this.store.update({
           language
        });
    }

    public changeLanguage = async (language: Language, updateUser?: boolean) => {
        console.log('change language')
        await changeUserLanguage(language);
        if(updateUser) {
            await this.userService.updateLocale(language);
        }
        this.load();
    }
}

export default new LanguageActions();
