import PaymentType from "../model/checkout/PaymentType";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class PaymentTypeService extends AuthorizedHttpService {
    public async findForCurrentUser(): Promise<PaymentType[]> {
        return this.get("/payment-types/");
    }
}

export default new PaymentTypeService();
