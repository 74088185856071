import fetch from "isomorphic-unfetch";
import BadRequestError from "./error/BadRequestError";
import ConflictError from "./error/ConflictError";
import ForbiddenError from "./error/ForbiddenError";
import InternalServerError from "./error/InternalServerError";
import NotFoundError from "./error/NotFoundError";
import UnauthorizedError from "./error/UnauthorizedError";

export interface QueryParameters {
    [key: string]: undefined | string | number | boolean;
}

export interface RequestParams {
    method: string;
    path: string;
    query?: QueryParameters;
    body?: QueryParameters;
    headers?: HeadersInit;
}

export interface Dependencies {
    baseUrl: string;
}

export abstract class HttpService {
    private readonly baseUrl: string = "/api";

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    protected async get(path: string, query?: QueryParameters, body?: any): Promise<any> {
        return this.request({method: "GET", path, query, body});
    }

    protected createUrl(path: string, query: QueryParameters = {}) {
        const queryString = this.createQueryString(query);
        const querySeparator = queryString ? "?" : "";

        return `${this.baseUrl}${path}${querySeparator}${queryString}`;
    }

    private createQueryString(query: QueryParameters) {
        return Object.entries(query)
            .filter(([, value]) => value !== undefined)
            // @ts-ignore
            .map(([key, value]) => this.createQueryParam(key, value))
            .join("&");
    }

    private createQueryParam(key: string, value: string | number | boolean) {
        if (value === true) {
            return key;
        } else {
            return `${key}=${encodeURIComponent(value)}`;
        }
    }

    protected checkForError(response: Response): void {
        if (response.ok) {
            return;
        }
        switch (response.status) {
            case 400:
                throw new BadRequestError();
            case 401:
                throw new UnauthorizedError();
            case 403:
                throw new ForbiddenError();
            case 404:
                throw new NotFoundError();
            case 409:
                throw new ConflictError();
            case 500:
            default:
                throw new InternalServerError();
        }

    }

    protected async post(path: string, query?: QueryParameters, body?: any): Promise<any> {
        return this.request({method: "POST", path, query, body});
    }

    protected async put(path: string, query?: QueryParameters, body?: any): Promise<any> {
        return this.request({method: "PUT", path, query, body});
    }

    protected async delete(path: string, query?: QueryParameters, body?: any): Promise<any> {
        return this.request({method: "DELETE", path, query, body});
    }

    protected async request({method, path, query, body, headers}: RequestParams): Promise<any> {
        const url = this.createUrl(path, query);

        if (body) {
            headers = Object.assign({}, {
                "Content-Type": "application/json"
            }, headers);
        }

        const response = await fetch(url, {
            method,
            headers,
            body: body ? JSON.stringify(body) : null
        });

        this.checkForError(response);
        try {
            return await response.json();
        } catch (e) {
            return null;
        }
    }
}

export default HttpService;
