import Motor from "../model/motor/Motor";
import AuthorizedHttpService from "./AuthorizedHttpService";


export class MotorService extends AuthorizedHttpService {
    public async read(slug: string): Promise<Motor> {
        return this.get(`/motors/${slug}`);
    }

    public async findAllByCompany(company: string): Promise<Motor[]> {
        return this.get(`/motors/`, {company});
    }
}

export default new MotorService();
