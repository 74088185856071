import {Profile} from "../../model/user/Profile";
import React, {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";

interface ProfileTextFieldProps<P extends Profile, T extends keyof P> {
    onChange(property: T, value: P[T]): void;

    data: P;
    property: T;
    required?: boolean;
    multiline?: boolean;
    type?: string;
    error?: boolean;
    helperText?: string;
}

export function ProfileTextField<P extends Profile>(props: PropsWithChildren<ProfileTextFieldProps<P, keyof P>>) {
    const {t} = useTranslation();
    const propertyName = props.property as string;
    const data: P = props.data;
    const textFieldProps = {
        required: props.required,
        multiline: props.multiline,
        type: props.type,
        error: props.error,
        helperText: props.helperText
    };
    return (
        <TextField
            {...textFieldProps}
            fullWidth
            label={t(`user-profile-${propertyName}-label`)}
            name={propertyName}
            value={data[props.property] || ""}
            onChange={(event) => props.onChange(props.property, event.target.value as unknown as P[keyof P])}
        />
    );
}

export default ProfileTextField;