import JwtResponse, {AccessToken, RefreshToken} from "../model/auth/JwtResponse";
import {CurrencyResponse} from "../model/user/CurrencyResponse";
import HttpService from "./HttpService";

export class AuthService extends HttpService {
    public async login(username: string, password: string, keepLoggedIn: boolean): Promise<JwtResponse> {
        return this.post("/auth/token/", undefined, {username, password, keepLoggedIn});
    }

    public async refresh(refresh_token: RefreshToken): Promise<JwtResponse> {
        return this.post("/auth/token/refresh/", undefined, {refresh_token});
    }

    public async getCurrencyInfo(accessToken: AccessToken): Promise<CurrencyResponse> {
        return this.request({
            method: 'GET',
            path: '/user/currencySymbol',
            headers: {"Authorization": `Bearer ${accessToken}`}
        });
    }
}

export default new AuthService();
