import {CategorySlug} from "../model/Category";
import Item from "../model/item/Item";
import {Page} from "../model/user/OrderArchive";
import SaleCategory from "../sale/model/SaleCategory";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class ItemSaleService extends AuthorizedHttpService {

    public async getItemsOnSale(page?: number, size?: number, category?: CategorySlug): Promise<Page<Item>> {
        return this.get('/items/sale?page=' + (page ? page : 0) + '&size=' + (size ? size : 10) + (!!category ? '&category=' + category : ''));
    }

    public async getAllItemsOnSale(): Promise<SaleCategory[]> {
        return this.get('/items/sale2');
    }

    public async getAllItemsOnClearance(): Promise<SaleCategory[]> {
        return this.get('/items/clearance2');
    }
}

export default new ItemSaleService();
