import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import countries from "i18n-iso-countries";
import countriesDeLocal from "i18n-iso-countries/langs/de.json";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import AddressResponse from "../../model/checkout/AddressResponse";
import {Theme} from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>((theme) => ({
    box: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginLeft: theme.spacing(2)
    }
}));

export interface AddressDisplayProps {
    address: AddressResponse;
}

const AddressDisplay: FC<AddressDisplayProps> = ({address}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);

    function convertCountryCodeToName(code: string) {
        countries.registerLocale(countriesDeLocal);
        return countries.getName(code, "de");
    }

    return (
        <Box display="flex" className={classes.box}>
            <Typography variant="subtitle1" data-cy="address-data">
                {address.name}<br/>
                {address.subName}<br/>
                {address.street}<br/>
                {address.zip} {address.city}<br/>
                {convertCountryCodeToName(address.country)}
            </Typography>
            <Box className={classes.button}>
                <Button data-cy="change-address-button" variant="contained" color="default"
                        disableElevation
                        component={RouterLink} to={`/benutzer/adresse/${address.id}/edit`}
                >
                    {t("checkout-change-address")}
                </Button>
            </Box>
        </Box>
    );
};

export default AddressDisplay;
