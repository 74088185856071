import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import TurnedInNotSharp from "@material-ui/icons/TurnedInNotSharp";
import React, {FC, Fragment} from "react";
import {Link as RouterLink} from "react-router-dom";
import authQuery from "../../app/state/auth/AuthQuery";
import watchListQuery from "../../app/state/watchList/WatchListQuery";

export const WatchListAction: FC = () => {
    const isLoggedIn = authQuery.useIsLoggedIn();
    const hasError = watchListQuery.useHasError();
    const count = watchListQuery.useCount();

    if (!isLoggedIn) {
        return null;
    } else {
        return (
            <Fragment>
                <IconButton
                    style={{color: 'white'}}
                    data-cy="watchlist-icon"
                    color="secondary"
                    component={RouterLink}
                    to="/shop/merkliste.html"
                >
                    <Badge badgeContent={count} color={hasError ? "error" : "secondary"}>
                        <TurnedInNotSharp/>
                    </Badge>
                </IconButton>
            </Fragment>
        );
    }
};

export default WatchListAction;
