import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {ContentHeaderColor} from "../config/Config";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import AppBreadcrumbs from "./breadcrumb/AppBreadcrumbs";

export interface ContentHeaderProps {
    breadCrumbs?: Breadcrumb[];
    header: string
    subheader?: string
}

const useStyles = makeStyles(theme => ({
    header: {
        "& > p": {
            color: theme.palette.primary.main,
        },
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(1),
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        borderBottom: '4px solid ' + theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    },
    primary: {
        fontWeight: 'bolder'
    },
    subheader: {
        fontWeight: 'bold',
    }
}));

export const ContentHeader: FC<ContentHeaderProps> = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.header}>
            {props.breadCrumbs ? <AppBreadcrumbs breadcrumbs={props.breadCrumbs} /> : null}
            <Typography
            data-cy='page-title' color={ContentHeaderColor} className={classes.primary}  variant="h3" component="p">{props.header}</Typography>

            {props.subheader ?
                <Typography data-cy='page-subtitle' color={ContentHeaderColor} className={classes.subheader} variant="h6" component="p">{props.subheader}</Typography>
                : null
            }
        </div>
    );
};

export default ContentHeader;    
