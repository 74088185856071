import {transaction} from "@datorama/akita";
import Section, {SectionSlug} from "../../model/Section";
import sectionQuery, {SectionQuery} from "./SectionQuery";
import sectionStore, {SectionStore} from "./SectionStore";
import i18next from "i18next";

export interface Dependencies {
    sectionStore: SectionStore;
    sectionQuery: SectionQuery;
}

export class SectionActions {
    private readonly sectionStore = sectionStore;
    private readonly sectionQuery = sectionQuery;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public async select(slug: string): Promise<void> {
        const current = this.sectionQuery.getActiveId();

        if (current === slug) {
            return;
        }

        this.sectionStore.setActive(slug as any);
        await this.initialize();
    }

    private async initialize() {
        if (this.sectionQuery.isEmpty()) {
            await this.loadAll();
        }
    }

    private async loadAll(): Promise<void> {
        try {
            this.sectionStore.update({
                loading: true,
                error: null
            });
            const result: Section[] = [
                { name: i18next.t("header-menu-bicycle"), slug: "Zweirad" as SectionSlug },
                { name: i18next.t("header-menu-light-vehicle"), slug: "Mopedauto" as SectionSlug }
            ];
            this.sectionStore.set(result);
        } catch (error) {
            this.sectionStore.setError(error);
        } finally {
            this.sectionStore.setLoading(false);
        }
    }
}

export default new SectionActions();
