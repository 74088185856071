import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import profileQuery from "../../app/state/user/ProfileQuery";
import Grid from "@material-ui/core/Grid";
import {Profile} from "../../app/model/user/Profile";
import profileStore from "../../app/state/user/ProfileStore";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconCard from "./IconCard";
import RedGreenSwitch from "../../app/component/form/RedGreenSwitch";
import NotForTenant from "../../app/component/tenant/NotForTenant";


export const UserPage: FC = () => {
    const {t} = useTranslation();

    const classes = useStyles(undefined);

    const store = profileStore;
    useEffect(() => {
        profileStore.fetchProfile();
    }, [])
    const profile = profileQuery.useProfile();

    useEffect(() => {
        const subs = store.activateProfileEffects()
        store.fetchProfile();
        return () => {
            subs.forEach(sub => sub.unsubscribe());
        }
    }, [store]);

    function onChange<T extends keyof Profile>(property: T, value: Profile[T]) {
        store.updateProfile(Object.assign({}, profile, {[property]: value}));
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        }
    ];

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("user-page-title")}/>

            <Grid container spacing={6}>
                <Grid item container md={7}>
                    <Grid item xs={12}>
                        <Typography className={classes.categoryHeader}>{t("user-page-title")}</Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/benutzer/profile.html">
                            <IconCard title={t("action-user-profile-edit")}
                                      iconPath="/img/userprofile/user.png"/></Link>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/benutzer/password/">
                            <IconCard title={t("action-password-edit")} iconPath="/img/userprofile/key.png"/></Link>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/benutzer/adresse/">
                            <IconCard title={t("action-addresses-edit")}
                                      iconPath="/img/userprofile/location.png"/></Link>
                    </Grid>
                </Grid>


                <Grid item container md={5}>
                    <Grid item xs={12}>
                        <Typography className={classes.categoryHeader}>{t("footer-orders")}</Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/benutzer/rueckstaende/">
                            <IconCard title={t("action-show-backorders")}
                                      iconPath="/img/userprofile/parcel.png"/></Link>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/benutzer/bestellungen/">
                            <IconCard title={t("action-show-orders")} iconPath="/img/userprofile/parcel.png"/></Link>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/benutzer/rechnungen/">
                            <IconCard title={t("action-show-invoices")}
                                      iconPath="/img/userprofile/parcel.png"/></Link>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container md={12} spacing={6}>
                <Grid item container md={7}>
                    <Grid item xs={12}>
                        <Typography className={classes.categoryHeader}>{t("action-returns-heading")}</Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/ruecksendungen/add">
                            <IconCard title={t("action-returns-add")}
                                      iconPath="/img/userprofile/parcel_return.png"/>
                        </Link>
                    </Grid>
                    <Grid item md={4}>
                        <Link component={RouterLink}
                              color="secondary"
                              underline="none"
                              to="/ruecksendungen/">
                            <IconCard title={t("action-returns-old")}
                                      iconPath="/img/userprofile/archive.png"/></Link>
                    </Grid>
                </Grid>
                <NotForTenant>
                    <Grid item container md={5}>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.categoryHeader}>{t("user-display-prices-heading")}</Typography>
                        </Grid>

                        <Grid item xs={8}>
                            <div className={classes.controls}>
                                <Typography
                                    className={classes.switchLabel}>{t("user-profile-display-pricebox")}</Typography>
                                <RedGreenSwitch
                                    checked={profile.displayPriceBox}
                                    name="displayPriceBox"
                                    onChange={(event: { [p: string]: any }) => onChange("displayPriceBox", event.target.checked)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.controls}>
                                <Typography className={classes.switchLabel}>{t("user-profile-display-ek")}</Typography>
                                <RedGreenSwitch
                                    checked={profile.displayBuyingPrice}
                                    name="displayBuyingPrice"
                                    onChange={(event: { [p: string]: any }) => onChange("displayBuyingPrice", event.target.checked)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div className={classes.controls}>
                                <Typography className={classes.switchLabel}>{t("user-profile-display-vk")}</Typography>
                                <RedGreenSwitch
                                    checked={profile.displayRecommendedRetailPrice}
                                    name="displayRecommendedRetailPrice"
                                    onChange={(event: { [p: string]: any }) => onChange("displayRecommendedRetailPrice", event.target.checked)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </NotForTenant>
            </Grid>
        </Fragment>
    );
};

const useStyles = makeStyles(() => ({
    root: {},
    categoryHeader: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#999999",
        marginTop: "15px",
        marginBottom: "10px"
    },
    switchLabel: {
        color: "#999999",
        fontWeight: "bold",
        fontSize: "0.85rem",
        padding: "10px"
    },
    switchControl: {
        float: "right"
    },
    controls: {
        border: "1px solid #dedede",
        display: "flex",
        justifyContent: "space-between"
    }
}));

export default UserPage;
