import Link from "@material-ui/core/Link";
import React, {FC, ImgHTMLAttributes} from "react";
import {Link as RouterLink} from "react-router-dom";
import Company from "../model/Company";

export interface CompanyLogoProps extends ImgHTMLAttributes<HTMLImageElement> {
    company: Company;
    className: string;
}

export const CompanyLogo: FC<CompanyLogoProps> = ({company, ...props}) => {
    const {name, slug, image} = company;

    if (!image) {
        return null;
    }

    return (
        <Link
            component={RouterLink}
            to={`/marke/${slug}.html`}
        ><img src={image.locationDefault} alt={image.description || name} {...props}/>
        </Link>
    );
};

export default CompanyLogo;    
