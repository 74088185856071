import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import getItemPath from "../app/common/route/getItemPath";
import {useUniqueId} from "../app/common/useUniqueId";
import ContentHeader from "../app/component/ContentHeader";
import NumberField from "../app/component/form/NumberField";
import MapInteractionCSS from '../app/component/map/MapInteractionCSS';
import AppLink from "../app/component/theme/AppLink";
import {Box, BoxItem} from "../app/model/ExplodedView";
import NotFoundError from "../app/service/error/NotFoundError";
import explodedViewActions from "../app/state/explodedview/ExplodedViewActions";
import explodedViewQuery from "../app/state/explodedview/ExplodedViewQuery";
import shoppingCartActions from "../app/state/shoppingCart/ShoppingCartActions";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

interface Params {
    slug: string;
}

function useDimension(initialX: number, initialY: number): [[number, number], (x: number, y: number) => void] {
    const [x, setX] = useState(initialX);
    const [y, setY] = useState(initialY);

    function setDimension(x: number, y: number) {
        setX(x);
        setY(y);
    }

    return [[x, y], setDimension];
}

export const ExplodedViewsPage: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const {slug} = useParams<Params>();
    const error = explodedViewQuery.useError();
    const drawingName = explodedViewQuery.useName();
    const drawingImage = explodedViewQuery.useImage();
    const loading = explodedViewQuery.useIsLoading();
    const boxes = explodedViewQuery.useAll();
    const [image, setImage] = useDimension(1, 1);
    const [viewport, setViewport] = useDimension(1, 1);
    const mapId = useUniqueId("exploded-view-map")
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("explosion-drawings-title"),
            to: "/explosionszeichnungen.html"
        },
        {
            text: "",
            to: `/explosionszeichnung/${slug}.html`
        }
    ];

    useEffect(() => {
        explodedViewActions.load(slug).catch(error => console.log(error))
    }, [slug])

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={drawingName}></ContentHeader>
                <Grid container>
                    <Grid item md={12}>
                        <div
                            className={classes.viewport}
                            ref={(el: any) => {
                                if (!el) return;

                                setViewport(el.clientWidth, el.clientHeight);
                            }}
                        >
                            {drawingImage &&
                            <MapInteractionCSS
                                maxScale={1}
                                viewport={viewport}
                                scene={image}
                            >
                                <div
                                    className={classes.scene}
                                >
                                    <img
                                        alt={drawingImage.description}
                                        src={drawingImage.locationLarge}
                                        onLoad={(e) => {
                                            // @ts-ignore
                                            setImage(e.target.offsetWidth, e.target.offsetHeight);
                                        }}
                                        useMap={`#${mapId}`}
                                    />
                                    <map
                                        name={mapId}
                                    >
                                        {boxes.map(box => (
                                            <area
                                                className={classes.area}
                                                key={box.id}
                                                alt={`area-${box.id}`}
                                                shape="rect"
                                                coords={box.coords.join(",")}
                                                onMouseEnter={() => explodedViewActions.highlight(box.id)}
                                                onMouseLeave={() => explodedViewActions.dehighlight()}
                                                onClick={() => explodedViewActions.select(box.id)}
                                            />
                                        ))}
                                    </map>
                                    <MapHighlight/>
                                    <MapSelection/>
                                </div>
                            </MapInteractionCSS>
                            }
                        </div>
                    </Grid>
                    <Grid item md={12}>
                        <ItemList/>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
};

const useStyles = makeStyles((theme) => ({
    viewport: {
        height: theme.spacing(62),
        overflow: "hidden",
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        borderWidth: theme.spacing(1),
        borderStyle: "solid",
    },
    scene: {
        position: "relative",
        "& img": {
            display: "block"
        }
    },
    area: {
        cursor: "pointer"
    },
    highlight: {
        borderColor: theme.palette.secondary.dark,
        borderWidth: theme.spacing(0.5),
        backgroundColor: theme.palette.secondary.light,
        opacity: 0.3,
        borderStyle: "solid",
        position: "absolute",
        pointerEvents: "none"
    },
    image: {
        maxHeight: "100%",
        maxWidth: "100%"
    },
    listItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

const MapHighlight: FC = () => {
    const box = explodedViewQuery.useHighlight();

    if (!box) {
        return null;
    }

    return (<Highlight coords={box.coords}/>);
}

const MapSelection: FC = () => {
    const box = explodedViewQuery.useActive();

    if (!box) {
        return null;
    }

    return (<Highlight coords={box.coords}/>);
}

const Highlight: FC<any> = ({coords}: { coords: Box["coords"] }) => {
    const classes = useStyles(undefined);
    return (
        <div
            className={classes.highlight}
            style={{
                left: coords[0],
                top: coords[1],
                width: coords[2] - coords[0],
                height: coords[3] - coords[1]
            }}
        />
    );
}

const ItemList: FC<any> = () => {
    const items = explodedViewQuery.useItems();

    return (
        <List>
            {items.map((item) => (<ItemRow key={item.slug} item={item}/>))}
        </List>
    )
}

const ItemRow: FC<any> = ({item}: { item: BoxItem }) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const {name, itemNumber, image, slug, company, availability} = item;
    const [value, setValue] = useState(1);

    return (
        <ListItem className={classes.listItem}>
            <Grid container justify="center">
                {image &&
                <Grid item md={1} alignContent="center">
                    <img
                        className={classes.image}
                        src={image.locationSmall}
                        alt={image.description}
                    />
                </Grid>
                }
                <Grid item md component={AppLink} to={getItemPath(slug)}>
                    <ListItemText
                        data-cy="search-result"
                        primary={name}
                        secondary={t("result-item-item-item-number", {itemNumber})}
                    />
                </Grid>
                {company && company.image &&
                <Grid item md={1}>
                    <img className={classes.image}
                         src={company.image.locationSmall}
                         alt={company.image.description}/>
                </Grid>
                }
                <Grid container item md={3} spacing={1}>
                    <Grid item xs={4}>
                        <NumberField
                            value={value}
                            size="small"
                            variant="outlined"
                            fullWidth
                            label={t("order-amount-input-label")}
                            onChange={setValue}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            children={t("order-button-text")}
                            onClick={() => shoppingCartActions.addSlug(slug, value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {t(`order-enum-shop-availability-${availability}`)}
                    </Grid>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default ExplodedViewsPage;
