import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import useFilterBreadcrumbs from "../app/common/breadcrumb/useFilterBreadcrumbs";
import {useObservable} from "../app/common/useObservable";
import CategoryList from "../app/component/category/categoryselection/CategoryList";
import CategoryFilters, {FilterGridItem} from "../app/component/category/filter/CategoryFilters";
import CompanyFilter from "../app/component/category/filter/CompanyFilter";
import PagedItemList from "../app/component/category/PagedItemList";
import SubCategoryList from "../app/component/category/SubCategoryList";
import ContentHeader from "../app/component/ContentHeader";
import NotFoundError from "../app/service/error/NotFoundError";
import categoryQuery from "../app/state/categoryPage/filter/category/CategoryQuery";
import itemActions from "../app/state/categoryPage/item/ItemActions";
import itemQuery from "../app/state/categoryPage/item/ItemQuery";
import subCategoriesQuery from "../app/state/categoryPage/subcategory/SubCategoriesQuery";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import useSearchParams from "../app/common/useSearchParams";

interface Params {
    category: string;
}

export const CategoryFilterPage: FC = () => {
    const {category} = useParams<Params>();
    const searchParams = useSearchParams();
    const breadcrumbs = useFilterBreadcrumbs();
    const classes = useStyles(undefined);
    const {name, itemsAvailable} = categoryQuery.useCategory() || {name: "", itemsAvailable: false};
    const error = itemQuery.useError();
    const subCategoriesAvailable = subCategoriesQuery.useAllSubCategories().length > 0;
    const categoryLoading = useObservable(categoryQuery.selectLoading(), true);
    const subCategoriesLoading = useObservable(subCategoriesQuery.selectLoading(), true);
    const noPageContentAvailable = !itemsAvailable && !subCategoriesAvailable && !categoryLoading && !subCategoriesLoading;
    const {t} = useTranslation();
    const sort = itemQuery.useSort();
    const count = itemQuery.useCount();

    useEffect(() => {
        itemActions.navigateToPage(category).catch(error => console.log(error));
    }, [category]);

    useEffect(() => {
        const attributes = Object.keys(searchParams).map(key => ["attribute." + key, searchParams[key]] as [string, string]);
        itemActions.setAttributes(attributes).catch(console.error);
    }, [searchParams]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={name}/>
                <Grid container alignItems="flex-start">
                    <Grid item md={2} sm={6}>
                        <CategoryList/>
                    </Grid>
                    {
                        subCategoriesAvailable ? <Grid item md={2} sm={6}>
                            <SubCategoryList/>
                        </Grid> : null
                    }
                    <Grid item container md>
                        {
                            noPageContentAvailable &&
                            <Grid item xs={12}><Typography variant="body1" component="h2"
                                                           gutterBottom>{t("category-filter-page-empty")}</Typography></Grid>
                        }
                        {
                            itemsAvailable &&
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <Grid container alignItems="stretch">
                                        <FilterGridItem>
                                            <CompanyFilter data-cy="filter-category-companies" category={category}/>
                                        </FilterGridItem>
                                        <CategoryFilters/>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={8} sm={6} xs={12}>
                                    <SearchField/>
                                </Grid>
                                <Grid item xs className={classes.sortHint}>
                                    {t('category-filter-page-sort-after')}
                                </Grid>
                                <Grid item sm={2} xs={3} className={classes.sortButtonContainer}>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        disabled={sort === "sort.itemNumber"}
                                        onClick={() => itemActions.sortByNumber()}
                                        classes={{
                                            root: classes.sortButtonRoot,
                                            disabled: classes.sortButtonDisabled
                                        }}
                                        endIcon={sort === "sort.itemNumber" ? <ExpandMoreIcon/> : null}
                                    >{t('category-filter-page-sort-button-text')}</Button>
                                </Grid>
                                <Grid item sm={2} xs={3} className={classes.sortButtonContainer}>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        disabled={sort === "sort.name"}
                                        onClick={() => itemActions.sortByName()}
                                        classes={{
                                            root: classes.sortButtonRoot,
                                            disabled: classes.sortButtonDisabled
                                        }}
                                        endIcon={sort === "sort.name" ? <ExpandMoreIcon/> : null}
                                    >Name</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {count > 0
                                        ? <PagedItemList/>
                                        : <Typography
                                            variant="body1"
                                            component="h2"
                                            gutterBottom
                                            children={t("category-filter-page-empty")}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
};

const useStyles = makeStyles(theme => ({
    sortHint: {
        fontStyle: "italic",
        textAlign: "right",
        display: "flex",
        alignItems: "center"
    },
    sortButtonContainer: {
        display: "flex",
        alignItems: "center"
    },

    // see https://material-ui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
    sortButtonRoot: {
        "&$sortButtonDisabled": {
            color: theme.palette.primary.main,
            borderBottomWidth: "2px",
            borderBottomStyle: "solid"
        }
    },
    sortButtonDisabled: {}
}));

const SearchField: FC = () => {
    const {t} = useTranslation();
    const query = itemQuery.useFilterProperty("query");
    let endAdornment: JSX.Element | null = null;

    if (query) {
        endAdornment = <InputAdornment position="end">
            <IconButton
                onClick={() => itemActions.setQuery("")}
            >
                <ClearIcon/>
            </IconButton>
        </InputAdornment>;
    }

    return (
        <TextField
            fullWidth
            value={query}
            label={t("category-filter-page-search-label")}
            onChange={(event) => itemActions.setQuery(event.target.value)}
            InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                endAdornment
            }}
        />
    );
}

export default CategoryFilterPage;
