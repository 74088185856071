import React, {FC, useEffect} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
    chat: {
        position: "fixed",
        bottom: theme.spacing(8),
        right: theme.spacing(2),
        zIndex: 99999,
        "& > *": {
        "--call-us-form-header-background": "#373737",
        "--call-us-main-button-background":"#000000",
        "--call-us-client-text-color":"#d4d4d4",
        "--call-us-agent-text-color":"#00bebe",
        "--call-us-form-height":"330px"
        }
    }
}));

//todo bundle the script so page loads faster
export  const LiveChat: FC = () => {
    const classes = useStyles();

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cdn.3cx.com/livechat/v1/callus.js";
        //script.defer = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (<div className={classes.chat}><call-us
        id="wp-live-chat-by-3CX"
        channel-url="https://scootertech.3cx-hostprofis.at:28001"
        files-url="https://scootertech.3cx-hostprofis.at:28001"
        minimized="true"
        animation-style="none"
        party="willkommen"
        minimized-style="BubbleRight"
        allow-call="false"
        allow-video="false"
        allow-soundnotifications="true"
        enable-onmobile="true"
        offline-enabled="true"
        enable="true"
        ignore-queueownership="false"
        authentication="both"
        operator-name="ScooterTech"
        show-operator-actual-name="true"
        channel="phone"
        aknowledge-received="true"
        gdpr-enabled="false"
        gdpr-message="I agree that my personal data to be processed and for the use of cookies in order to engage in a chat processed by ScooterTech, for the purpose of Chat/Support for the time of  30 day(s) as per the GDPR."
        message-userinfo-format="both"
        message-dateformat="time"
        start-chat-button-text="Chat"
        window-title="Live Chat & Talk"
        button-icon-type="Default"
        invite-message="Hello! How can we help you today?"
        authentication-message="Could we have your name and email?"
        unavailable-message="We are away, leave us a message!"
        offline-finish-message="We received your message and we'll contact you soon."
        ending-message="Your session is over. Please feel free to contact us again!"
        greeting-visibility="none"
        greeting-offline-visibility="none"
        chat-delay="2000"
        offline-name-message="Could we have your name?"
        offline-email-message="Could we have your email?"
        offline-form-invalid-name="I'm sorry, the provided name is not valid."
        offline-form-maximum-characters-reached="Maximum characters reached"
        offline-form-invalid-email="I'm sorry, that doesn't look like an email address. Can you try again?"
        lang="de" /> </div>)
}

export default LiveChat;