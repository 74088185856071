import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import vehicleCategoryStore, {State} from "./VehicleCategoryStore";

@QueryConfig({})
export class VehicleCategoryQuery extends HookQueryEntity<State> {
    constructor(
        protected store = vehicleCategoryStore
    ) {
        super(store);
    }
}

export default new VehicleCategoryQuery();
