import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Company, {CompanySlug} from "../../../../model/Company";

export type Entity = Company;
export type ID = CompanySlug;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    section: string;
}

function createInitialState(): Partial<State> {
    return {section: ""};
}

@StoreConfig({
    name: "motor.selection.company",
    idKey: "slug"
})
export class CompanyStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new CompanyStore();
