import countries from "i18n-iso-countries";
import countriesDeLocal from "i18n-iso-countries/langs/de.json";

countries.registerLocale(countriesDeLocal);

export const CountryOptions: { key: string, name: string }[] = Object
    .entries(countries.getNames("de"))
    .map(([key, name]) => ({key, name}));
export const CountryPreferred = ["AT", "DE", "CH"];

function isPreferred(key: string) {
    return CountryPreferred.indexOf(key) >= 0;
}

export const preferredCountries = CountryOptions.filter(({key}) => isPreferred(key));
export const otherCountries = CountryOptions.filter(({key}) => !isPreferred(key));
