import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Motor, {MotorSlug} from "../../../../model/motor/Motor";

export type Entity = Motor;
export type ID = MotorSlug;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    company: string;
}

function createInitialState(): Partial<State> {
    return {company: ""};
}

@StoreConfig({
    name: "motor.selection.motor",
    idKey: "slug"
})
export class MotorStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new MotorStore();
