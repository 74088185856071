import {QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import PaymentType from "../../model/checkout/PaymentType";
import ShippingType from "../../model/checkout/ShippingType";
import ShoppingCartItem from "../../model/shoppingCart/ShoppingCartItem";
import {throttledLoading} from "../throttleLoading";
import shoppingCartStore, {ShoppingCartStore, State} from "./ShoppingCartStore";
import {Voucher} from "../../model/shoppingCart/Voucher";
import {ApplyVoucherResponse} from "../../service/ShoppingCartService";

@QueryConfig({})
export class ShoppingCartQuery extends QueryEntity<State> {
    public readonly isLoading = throttledLoading(this);
    private readonly error = this.selectError<Error | null>();
    private readonly all = this.selectAll();
    private readonly itemTotal = this.select("itemTotal");
    private readonly rebatedTotal = this.select("rebatedTotal");
    private readonly systemRebate = this.select("systemRebate");
    private readonly voucherRebate = this.select("voucherRebate");

    private readonly mmzValue = this.select("mmzValue");
    private readonly netTotal = this.select('netTotal');
    private readonly vatPrice = this.select("vatPrice");
    private readonly totalPriceWithoutShipping = this.select("totalPriceWithoutShipping");
    private readonly totalPrice = this.select("totalPrice");
    private readonly missingForFreeShipping = this.select("missingForFreeShipping");

    private readonly paymentType = this.select("paymentType");
    private readonly shippingType = this.select("shippingType");
    private readonly firstOrder = this.select("firstOrder");

    private readonly voucher = this.select("voucher");
    private readonly applyVoucherResponse = this.select("applyVoucherResponse");

    constructor(
        protected store: ShoppingCartStore = shoppingCartStore
    ) {
        super(store);
    }

    public useAll(): ShoppingCartItem[] {
        return useObservable(this.all, []);
    }

    public useIsEmpty(): boolean {
        return !this.useAll().length;
    }

    public useItemTotal(): number {
        const current = this.store.getValue().itemTotal;

        return useObservable(this.itemTotal, current);
    }

    public useRebatedTotal(): number {
        const current = this.store.getValue().rebatedTotal;

        return useObservable(this.rebatedTotal, current);
    }

    public useSystemRebate(): { discount: number, value: number, rebateSystemName: string} {
        const current = this.store.getValue().systemRebate;
        return useObservable(this.systemRebate, current);
    }

    public useVoucherRebate(): number {
        const current = this.store.getValue().voucherRebate;
        return useObservable(this.voucherRebate, current);
    }

    public useMmzValue(): number {
        const current = this.store.getValue().mmzValue;

        return useObservable(this.mmzValue, current);
    }

    public useNetTotal(): number {
        const current = this.store.getValue().netTotal;

        return useObservable(this.netTotal, current);
    }

    public useVatPrice() {
        const current = this.store.getValue().vatPrice;

        return useObservable(this.vatPrice, current);
    }

    public useTotalPrice() {
        const current = this.store.getValue().totalPrice;

        return useObservable(this.totalPrice, current);
    }

    public useMissingForFreeShipping() {
        const current = this.store.getValue().missingForFreeShipping;

        return useObservable(this.missingForFreeShipping, current);
    }

    public useError(): State["error"] {
        return useObservable(this.error, null);
    }

    public useHasError(): boolean {
        return !!this.useError();
    }

    public useCount(): number {
        return this.useAll().reduce((sum, {quantity}) => sum + quantity, 0);
    }

    public useTotalPriceWithoutShipping() {
        const current = this.store.getValue().totalPriceWithoutShipping;
        return useObservable(this.totalPriceWithoutShipping, current);
    }

    public usePaymentType(): PaymentType {
        const current = this.store.getValue().paymentType;
        return useObservable(this.paymentType, current);
    }

    public useShippingType(): ShippingType {
        const current = this.store.getValue().shippingType;

        return useObservable(this.shippingType, current);
    }

    public useFirstOrder(): boolean {
        const current = this.store.getValue().firstOrder;

        return useObservable(this.firstOrder, current);
    }

    public useIsPaymentDisabled(): (payment: PaymentType) => boolean {
        const shipping = this.useShippingType();
        return (payment: PaymentType) => payment === PaymentType.NACHNAHME && shipping === ShippingType.NOX;
    }

    public useIsShippingDisabled(): (shipping: ShippingType) => boolean {
        const payment = this.usePaymentType();
        return (shipping: ShippingType) => payment === PaymentType.NACHNAHME && shipping === ShippingType.NOX;
    }

    public useVoucher(): Voucher {
        const current = this.store.getValue().voucher;
        return useObservable(this.voucher, current);
    }

    public useApplyVoucherResponse(): ApplyVoucherResponse {
        const current = this.store.getValue().applyVoucherResponse;
        return useObservable(this.applyVoucherResponse, current);
    }

}

export default new ShoppingCartQuery();
