import AuthorizedHttpService from "./AuthorizedHttpService";
import Download from "../model/Download";

export class DownloadsService extends AuthorizedHttpService {
    public async getPublished(): Promise<Download[]> {
        return this.get(`/shop/downloads`);
    }
}

export default new DownloadsService();
