import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {SignupForm} from "../../model/SignupForm";

export interface FormField<T, K extends keyof T = keyof T> {
    key: K;
    value: T[K]
}

export type ID = keyof SignupForm;

export interface Entity {
    key: ID;
    value: SignupForm[ID];
    pristine: boolean;
}

export interface State extends EntityState<Entity, ID> {
    required: ID[];
    captcha: boolean;
    success: boolean;
}

export function createInitialState(): State {
    return {
        success: false,
        captcha: false,
        required: [
            "name", "ceoName", "uid", "license", "street", "zip", "city", "country", "email", "phone", "businessHours", "terms"
        ]
    };
}

@StoreConfig({
    name: "signup",
    resettable: true,
    idKey: "key"
})
export class SignupStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new SignupStore();
