import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CompareIcon from "@material-ui/icons/Compare";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import AppLink from "../../app/component/theme/AppLink";
import RoleName from "../../app/model/auth/RoleName";
import Download from "../../app/model/Download";
import downloadsService from "../../app/service/DownloadsService";
import authQuery from "../../app/state/auth/AuthQuery";
import configQuery from "../../app/state/config/ConfigQuery";
import {hasNewArticles, isDev} from "../../toggles";
import Copyright from "./Copyright";
import SocialMedia from "./SocialMedia";
import NotForTenant from "../../app/component/tenant/NotForTenant";
import {useTenantSwitch} from "../../app/common/useTenantSwitch";

const useStyles = makeStyles(theme => ({
    row: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    flexContainer: {
        display: "flex"
    },
    flexGrow: {
        flexGrow: 1,
        textAlign: "right"
    },
    footer: {
        height: theme.spacing(12)*5,
        "& > *": {
            zIndex: 51,
            color: theme.palette.secondary.contrastText,
        },
        backgroundColor: theme.palette.secondary.dark,
        zIndex: 50,
        width: '100%',
    }
}));

export const Row: FC = ({children}) => {
    const classes = useStyles(undefined);
    return (
        <Container className={classes.row}>
            <React.Fragment>
                {children}
            </React.Fragment>
        </Container>
    );
};

const FooterNavigation: FC = () => {
    const {t} = useTranslation();
    const isLoggedIn = authQuery.useIsLoggedIn();
    const showExplodedViews = isLoggedIn;
    const tenantSwitch = useTenantSwitch();

    const [downloads, setDownloads] = useState<Download[]>([]);
    useEffect(() =>
        tenantSwitch.doForBaseShop(() => {
            if(isLoggedIn) {
                downloadsService.getPublished()
                    .then((response: Download[]) => setDownloads(response))
                    .catch((err: any) => console.log(err));
            }
        }), [isLoggedIn, tenantSwitch]);

    return (
        <Grid container justify="flex-start" spacing={1}>
            <Grid item sm={3} xs={6} component="nav">
                <Typography variant="h6">{t("footer-nav-general-heading")}</Typography>
                <List dense>
                    <ListItem>
                        <AppLink to="/kontakt.html">{t("footer-contact")}</AppLink>
                    </ListItem>
                    <ListItem>
                        <AppLink to="/ueber-uns.html">{t("footer-about-us")}</AppLink>
                    </ListItem>
                    <ListItem>
                        <AppLink to="/agb.html">{t("footer-gtc")}</AppLink>
                    </ListItem>
                    <ListItem>
                        <AppLink to="/datenschutz.html">{t("footer-data-security")}</AppLink>
                    </ListItem>
                    <ListItem>
                        <AppLink to="/impressum.html">{t("footer-imprint")}</AppLink>
                    </ListItem>
                </List>
            </Grid>
            {
                isLoggedIn &&
                <Fragment>
                    <Grid item sm={3} xs={6} component="nav">
                        <Typography variant="h6">{t("footer-nav-customer-heading")}</Typography>
                        <List dense>
                            <ListItem>
                                <AppLink to="/benutzer/profile.html">{t("footer-user-profile")}</AppLink>
                            </ListItem>
                            <ListItem>
                                <AppLink to="/benutzer/adresse/">{t("footer-addresses")}</AppLink>
                            </ListItem>
                            <ListItem>
                                <AppLink to="/benutzer/bestellungen/">{t("footer-orders")}</AppLink>
                            </ListItem>
                            <ListItem>
                                <AppLink to="/ruecksendungen/">{t("footer-returns")}</AppLink>
                            </ListItem>
                            <ListItem>
                                <AppLink to="/ruecksendungen/add">{t("footer-add-returns")}</AppLink>
                            </ListItem>
                        </List>
                    </Grid>
                    <NotForTenant>
                        <Grid item sm={3} xs={6} component="nav">
                            <Typography variant="h6">Downloads</Typography>
                            <List dense>
                                <ListItem>
                                    <AppLink to="/pgo-malaguti.html">{t("footer-downloads-catalog")}</AppLink>
                                </ListItem>
                                {downloads.map(download =>
                                    <ListItem key={download.uri}>
                                        <Link href={download.uri} color="inherit" target="_blank">{download.name}</Link>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </NotForTenant>
                </Fragment>
            }

            <Grid item sm={3} xs={6} component="nav">
                {
                    isLoggedIn ?
                        <Typography variant="h6">{t("footer-nav-tools-heading")}</Typography> :
                        <NotForTenant><Typography variant="h6">{t("footer-nav-tools-heading")}</Typography></NotForTenant>
                }
                <List dense>
                    <NotForTenant>
                        <ListItem>
                            <AppLink to="/haendler/">{t("footer-store-locator")}</AppLink>
                        </ListItem>
                    </NotForTenant>
                    {
                        isLoggedIn && hasNewArticles() &&
                        <ListItem>
                            <AppLink to="/produkte/neu/aktuell.html">{t("footer-new-article")}</AppLink>
                        </ListItem>
                    }
                    {
                        isLoggedIn &&
                            <NotForTenant>
                                <ListItem>
                                    <AppLink to="/aktionen/abverkauf.html">{t('offer-clearance-label')}</AppLink>
                                </ListItem>
                            </NotForTenant>
                    }
                    {
                        showExplodedViews &&
                        <ListItem>
                            <AppLink to="/explosionszeichnungen.html">{t("footer-explosion-drawings")}</AppLink>
                        </ListItem>
                    }
                </List>
            </Grid>
        </Grid>
    );
};

export const Footer: FC = () => {
    const classes = useStyles(undefined);
    const {pathname} = useLocation();
    const url = `https://scootertech.at${pathname}`;
    const {t} = useTranslation();
    const showCompareIcon = isDev() || authQuery.useHasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_STAFF]);
    const shopName = configQuery.useConfig("shopName");
    const shopAddress = configQuery.useConfig("shopAddress");

    return (
        <div className={classes.footer}>
            <Row><FooterNavigation/></Row>
            <Divider/>
            <Row><SocialMedia/></Row>
            <Divider/>
            <Row>
                <Box display="flex" data-cy="footer-config-data">
                    <Copyright shopName={shopName} shopAddress={shopAddress}/>
                    <Box flexGrow={1} textAlign="right">{t("footer-error")}</Box>
                </Box>
                {
                    showCompareIcon &&
                    <Link target="scootertech" href={url} className={classes.fab}>
                        <Fab color="secondary" size="small">
                            <CompareIcon/>
                        </Fab>
                    </Link>
                }
            </Row>
        </div>
    );
};

export default Footer;
