import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {ExpandLessRounded, ExpandMoreRounded} from "@material-ui/icons";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import Alert from "@material-ui/lab/Alert";
import {Color} from "@material-ui/lab/Alert/Alert";
import React, {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {CSSTransition} from 'react-transition-group';
import useHistoryPushNew from "../../common/useHistoryPushNew";
import Item from "../../model/item/Item";
import {AdminPrice} from "../../model/shopprice/AdminPrice";
import {ShopAvailability} from "../../model/shopprice/ShopAvailability";
import {SpecialPrice} from "../../model/shopprice/ShopPrice";
import {ShopPriceInformation} from "../../model/shopprice/ShopPriceInformation";
import {Profile} from "../../model/user/Profile";
import shoppingCartActions from "../../state/shoppingCart/ShoppingCartActions";
import watchListActions from '../../state/watchList/WatchListActions';
import Currency from "../Currency";
import NumberField from "../form/NumberField";
import useTenantSwitch from "../../common/useTenantSwitch";
import authQuery from "../../state/auth/AuthQuery";
import RoleName from "../../model/auth/RoleName";
import {Link} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    largeText: {
        fontSize: 'larger'
    },
    enter: {
        maxHeight: 0,
    },
    leave: {
        maxHeight: '800px',
        overflow: 'hidden'
    },
    enterActive: {
        maxHeight: '800px',
        overflow: 'hidden',
        transition: 'max-height 200ms ease-out'
    },
    leaveActive: {
        maxHeight: 0,
        transition: 'max-height 200ms ease-out'
    },
    prices: {
        marginBottom: theme.spacing(4)
    },
    availability: {
        display: 'inline-block',
        flexBasis: 50,
        height: '100%',
        flexGrow: 0
    },
    availabilityAlert: {
        backgroundColor: 'transparent',
    },
    form: {},
    amountInput: {
        paddingTop: 2,
        paddingBottom: 2,
        flexGrow: 1,
        flexBasis: 50,
        display: 'inline-block',
        "& input": {
            backgroundColor: "white"
        }
    },
    orderButton: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        boxShadow: 'none',
        "&:hover": {
            backgroundColor: theme.palette.warning.light,
            boxShadow: 'none',
        },
        backgroundColor: theme.palette.warning.main
    },
    orderButtonIcon: {},
    bookmarkButton: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        marginTop: theme.spacing(2),
        boxShadow: 'none',
        "&:hover": {
            boxShadow: 'none',
        },
    },
    bookmarkButtonIcon: {},
    discounted: {
        textDecoration: "line-through"
    },
    flexbox: {
        "& > *": {
            marginLeft: 5,
            marginRight: 5,
        },
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row'
    }
}));

export interface OrderPanelProps {
    item: Item;
    userProfile: Profile;
}

export const SeverityStyles: { [key: string]: Color } = {
    [ShopAvailability.IN_STOCK]: "success",
    [ShopAvailability.ON_REQUEST]: "info",
    [ShopAvailability.OUT_OF_STOCK]: "error"
};

export const OrderPanel: FC<OrderPanelProps> = ({item, userProfile}) => {
    const {t} = useTranslation();
    const tenantSwitch = useTenantSwitch();
    const addToCartButtonText = t("order-button-text");
    const orderBookmarkButtonText = t("bookmark-button-text");
    const amountLabel = t("order-amount-input-label");
    const [quantity, setQuantity] = useState(1);
    const push = useHistoryPushNew();
    const price = item.price;
    const priceInformation = item.priceInformation;
    const hasDiscount: boolean = !!price && (!!price.offer || !!price.clearance);
    const classes = useStyles(undefined);
    const prices = item.prices || [];

    const [unfolded, setUnfolded] = useState(true);

    function onClickAddShoppingCart() {
        shoppingCartActions.addItem(item, quantity).catch(error => console.log(error));
        push("/shop/warenkorb.html");
    }

    function onClickBookmark() {
        watchListActions.addItem(item);
    }

    function onChangeQuantity(value: number) {
        setQuantity(value);
    }

    function toggleFold() {
        return setUnfolded(!unfolded)
    }

    if (!price) {
        return null;
    }

    const displayPriceBox = tenantSwitch.selectForBaseOrTenant(userProfile.displayPriceBox, true, userProfile.displayPriceBox);
    const displayBuyingPrice = tenantSwitch.selectForBaseOrTenant(userProfile.displayBuyingPrice, true, userProfile.displayBuyingPrice);
    const displayRecommendedRetailPrice = tenantSwitch.selectForBaseOrTenant(userProfile.displayRecommendedRetailPrice, false, userProfile.displayRecommendedRetailPrice);

    return (
        <div>
            {!displayPriceBox &&
            <div onClick={toggleFold} data-cy="price-box-toggle">
                {
                    unfolded && <ExpandLessRounded/>
                }
                {
                    !unfolded && <ExpandMoreRounded/>
                }
            </div>
            }

            <CSSTransition
                unmountOnExit
                timeout={300}
                in={unfolded}
                appear
                classNames={{
                    appearActive: classes.enterActive,
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    appear: classes.enter,
                    exit: classes.leave,
                    exitActive: classes.leaveActive
                }}>
                <div data-cy="collapsable-box">
                    <Grid
                        className={classes.prices}
                        container
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                    >

                        {displayBuyingPrice &&
                        <Fragment>
                            <Grid item xs={6}>
                                <Box fontWeight="fontWeightMedium">
                                    {tenantSwitch.selectForBaseOrTenant(t("item-price-wholesale-price"), t("item-price-wholesale-price-tenant"), t("item-price-wholesale-price"))}
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <Typography data-cy="wholesale-price" className={hasDiscount || price.rebatedPrice ? classes.discounted : ""}
                                            variant="h5"
                                            component="div"
                                            align="right">
                                    <Currency className={!price.offer ? classes.largeText : ''}
                                              value={price.wholesalePrice}/>
                                </Typography>
                                {
                                    price.rebatedPrice ?
                                        <Typography data-cy="rebated-price"
                                                    variant="h5"
                                                    component="div"
                                                    align="right">
                                            <Currency className={classes.largeText}
                                                      value={price.rebatedPrice}/>
                                        </Typography> : null
                                }
                            </Grid>
                            {
                                !!price.offer &&
                                <Fragment>
                                    <Grid item xs={8}>
                                        <Box fontWeight="fontWeightMedium">
                                            {t("item-price-offer")}
                                        </Box>
                                    </Grid>
                                    <Grid item lg>
                                        <Typography variant="h5" component="div" align="right">
                                            <Currency className={classes.largeText} value={price.offer}/>
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            }
                            {
                                !!price.clearance &&
                                <Fragment>
                                    <Grid item xs={8}>
                                        <Box fontWeight="fontWeightMedium">
                                            {t("item-price-clearance")}
                                        </Box>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h5" component="div" align="right">
                                            <Currency className={classes.largeText} value={price.clearance}/>
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            }
                            <VolumeDiscount items={price.volumeDiscounts}/>
                        </Fragment>
                        }
                        {displayRecommendedRetailPrice &&
                        <Fragment>
                            <Grid item xs={8}>
                                <Typography variant="subtitle2" component="div">
                                    {t("item-price-recommended-price")}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography data-cy="recommended-price" variant="subtitle2" component="div"
                                            align="right">
                                    <Currency value={price.recommendedRetailPriceVatIncluded}/>
                                </Typography>
                            </Grid>
                        </Fragment>
                        }

                        {prices.length > 0 &&
                        prices.map((price) => <PriceListEntry key={price.internalId} price={price}/>)
                        }
                        <PriceListInformationEntry priceInformation={priceInformation} itemId={item.id} />
                    </Grid>


                    <form className={classes.form} noValidate autoComplete="off">
                        <Grid
                            container
                            justify="space-between"
                            spacing={1}
                        >
                            <Grid className={classes.flexbox} item xs={12}>
                                <NumberField
                                    className={classes.amountInput}
                                    value={quantity}
                                    size={"small"}
                                    onChange={onChangeQuantity}
                                    variant="outlined"
                                    label={amountLabel}
                                />

                                <div className={classes.availability}>
                                    <Alert data-cy="item-availability" className={classes.availabilityAlert}
                                           severity={SeverityStyles[price.shopAvailability]}>
                                        {t(`order-enum-shop-availability-${price.shopAvailability}`)}</Alert>
                                </div>

                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-cy="item-order-button"
                                    fullWidth
                                    className={classes.orderButton}
                                    startIcon={<AddShoppingCartIcon/>}
                                    onClick={onClickAddShoppingCart}
                                >
                                    {addToCartButtonText}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    data-cy="item-bookmark-button"
                                    fullWidth
                                    className={classes.bookmarkButton}
                                    startIcon={<BookmarkIcon/>}
                                    onClick={onClickBookmark}
                                >
                                    {orderBookmarkButtonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </CSSTransition>
        </div>
    );
};

export default OrderPanel;

interface PriceListEntryProps {
    price: AdminPrice;
}

const PriceListEntry: FC<PriceListEntryProps> = ({price}) => {
    const {t} = useTranslation();
    const name = price.internalId;
    const value = price.value
    const volumeDiscounts = price.volumeDiscounts || [];

    if (value === undefined || value === null) {
        return null;
    }

    return (
        <Fragment>
            <Grid item xs={8}>
                <Typography variant="subtitle2" component="div">
                    {t("item-price-list-label", {name})}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="subtitle2" component="div" align="right">
                    <Currency value={value}/>
                </Typography>
            </Grid>
            <VolumeDiscount items={volumeDiscounts}/>
        </Fragment>
    );
};

interface VolumeDiscountProps {
    items: SpecialPrice[];
}

const VolumeDiscount: FC<VolumeDiscountProps> = ({items}) => {
    const {t} = useTranslation();
    if (!(items?.length)) {
        return null;
    }

    return (
        <Fragment>
            {items.map(({price, quantity}) =>
                <Fragment key={`price-${price}-quantity-${quantity}`}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div">
                            {t("item-price-volume-discount-heading")}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={7}>
                        <Typography variant="subtitle2" component="div">
                            {t("item-price-volume-discount", {quantity})}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" component="div" align="right">
                            <Currency value={price}/>
                        </Typography>
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    );
}

interface PriceListInformationEntryProps {
    priceInformation?: ShopPriceInformation;
    itemId: number;
}

const PriceListInformationEntry: FC<PriceListInformationEntryProps> = ({priceInformation, itemId}) => {
    const {t} = useTranslation();
    const showAdminLink = authQuery.useHasAnyRole([RoleName.ROLE_ADMIN, RoleName.ROLE_STAFF]);

    if (priceInformation === undefined || priceInformation === null) {
        return null;
    }

    return (
        <Fragment>
            <Grid item lg>
                <Typography variant="subtitle2" component="div">
                    {t("item-price-updatedAt")}
                </Typography>
            </Grid>
            {
                priceInformation.updatedAt ?
                    <Grid item lg={8}>
                        <Typography variant="subtitle2" component="div" align="right">
                            {new Intl.DateTimeFormat("de-De", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit"
                            }).format(new Date(priceInformation.updatedAt))}
                        </Typography>
                    </Grid>
                    : null
            }
            <Grid item lg={8}>
                <Typography variant="subtitle2" component="div">
                    {t("item-price-weight")}
                </Typography>
            </Grid>
            <Grid item lg>
                <Typography variant="subtitle2" component="div" align="right">
                    {new Intl.NumberFormat("de-De", {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                    }).format(priceInformation.weight)} kg
                </Typography>
            </Grid>
            <Grid item lg={8}>
                <Typography variant="subtitle2" component="div">
                    {t("item-price-itemgroup")}
                </Typography>
            </Grid>
            <Grid item lg>
                <Typography variant="subtitle2" component="div" align="right">
                    {priceInformation.itemgroup}
                </Typography>
            </Grid>
            <Grid item lg={8}>
                <Typography variant="subtitle2" component="div">
                    {t("item-price-stock")}
                </Typography>
            </Grid>
            <Grid item lg>
                <Typography variant="subtitle2" component="div" align="right">
                    {priceInformation.stock}
                </Typography>
            </Grid>
            {showAdminLink ? <Grid item lg={8}>
                <Link href={`/admin/item/edit/${itemId}`} target="_blank">
                    <Typography variant="subtitle2" component="div">Admin</Typography>
                </Link>
            </Grid> : null}

        </Fragment>
    );
};
