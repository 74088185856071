import {ReturnReason} from "../../model/returns/Reason";
import {toDate} from "../../TransformDate";
import AuthorizedHttpService from "../AuthorizedHttpService";

export interface ReturnItem {
    id: string;
    itemNumber: string;
    name: string;
    quantity: number;
    reason: ReturnReason;
    comment: string;
    invoiceNumber: string;
    invoiceDate: Date;
    addedAt: Date;
    complaintVehicleInfo?: string;
    complaintVehicleIdNumber?: string;
    complaintMileage?: number;
    complaintDetails?: string;
}

export interface AddItemRequest {
    itemNumber: string;
    count: number;
    reason: ReturnReason;
    comment: string;
    invoiceNumber: string;
    invoiceDate: Date;
    complaintVehicleInfo?: string;
    complaintVehicleIdNumber?: string;
    complaintMileage?: number;
    complaintDetails?: string;
}

export class ItemService extends AuthorizedHttpService {
    public async addItem({invoiceDate, ...rest}: AddItemRequest): Promise<void> {
        return this.post("/returns/items", undefined, {
            invoiceDate: invoiceDate.toISOString().substr(0, 10),
            ...rest
        });
    }

    public async removeItem(id: string): Promise<void> {
        return this.delete(`/returns/items/${id}`);
    }

    public async findCurrent(): Promise<ReturnItem[]> {
        const items: any[] = await this.get("/returns/items");
        return items.map(this.map);
    }

    private map({invoiceDate, addedAt, ...rest}: any) {
        return {
            invoiceDate: toDate(invoiceDate),
            addedAt: toDate(addedAt),
            ...rest
        };
    }

    public async submitForm(contact: string, email: string): Promise<void> {
        return this.post("/returns/", undefined, {contact, email});
    }

    public async findFormItems(id: string): Promise<ReturnItem[]> {
        const items: any[] = await this.get(`/returns/${id}/items`);
        return items.map(this.map);
    }
}

export default new ItemService();
