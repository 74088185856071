import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {CategorySlug} from "../../../model/Category";
import Reference from "../../../model/Reference";

export type ID = CategorySlug;
export type Entity = Reference<CategorySlug>;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    section: string;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        section: ""
    };
}

@StoreConfig({
    name: "preorder.category",
    idKey: "slug"
})
export class CategoryStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new CategoryStore();
