import {Query} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import profileStore, {createInitialState, ProfileState, ProfileStore} from "./ProfileStore";
import {Profile} from "../../model/user/Profile";

export class ProfileQuery extends Query<ProfileState> {
    public readonly error = this.selectError<Error | null>();
    public readonly profile = this.select("profile");
    public readonly loading = this.select("loading");

    constructor(protected store: ProfileStore = profileStore) {
        super(store);
    }

    public useError(): any {
        return useObservable(this.error, undefined);
    }

    public useProfile(): Profile {
        return useObservable(this.profile, createInitialState().profile);
    }

    public useLoading(): boolean {
        return useObservable(this.loading, createInitialState().loading);
    }
}

export default new ProfileQuery();
