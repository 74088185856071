import Address from "../model/checkout/Address";
import AddressResponse, {AddressId} from "../model/checkout/AddressResponse";
import AuthorizedHttpService from "./AuthorizedHttpService";

export class AddressService extends AuthorizedHttpService {
    public async findById(id: string): Promise<AddressResponse> {
        return this.get(`/addresses/findById/${id}`);
    }

    public async create(address: Address): Promise<AddressResponse> {
        return this.post("/addresses/", undefined, address);
    }

    public async findAll(): Promise<AddressResponse[]> {
        return this.get("/addresses/");
    }

    public async update(id: AddressId, address: Address): Promise<AddressResponse> {
        return this.put(`/addresses/${id}`, undefined, address);
    }

    public async remove(id: AddressId): Promise<void> {
        return this.delete(`/addresses/${id}`);
    }
}

export default new AddressService();
