import React, {FC, Fragment} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {Color} from "@material-ui/lab/Alert";

export interface StatusSnackbarProps {
    severity?: Color
    closeCallback?: () => void
    closeDelay?: number
    show: boolean
}

export const StatusSnackbar: FC<StatusSnackbarProps> = ({severity, closeCallback, closeDelay, show}: StatusSnackbarProps) => {
    return <Fragment>
        <Snackbar open={show} autoHideDuration={closeDelay} onClose={closeCallback}>
            <MuiAlert  severity={severity} elevation={5} variant="filled">
                { severity === "success" && "Import erfolgreich" }
                { severity === "warning" && "Import mit Fehlern abgeschlossen" }
                { severity === "error" && "Import konnte nicht durchgefuehrt werden" }
            </MuiAlert>
        </Snackbar>
    </Fragment>
}
