import ListItemText from "@material-ui/core/ListItemText";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {MotorSearchResult} from "../../model/search/MotorSearchResult";

export interface Props {
    value: MotorSearchResult;
}

export const MotorResultItem: FC<Props> = ({value}) => {
    const {t} = useTranslation();
    const {name, section} = value;

    return (
        <ListItemText
            data-cy="search-result-motor"
            primary={name} secondary={t("search-result-motor-secondary", {section: section.name})}/>
    )
};

export default MotorResultItem;
