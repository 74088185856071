import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useUniqueId} from "../../common/useUniqueId";
import PaymentType from "../../model/checkout/PaymentType";
import signupActions from "../../state/signup/SignupActions";
import signupQuery from "../../state/signup/SignupQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: "0.75vw",
        marginBottom: "0.75vw",
        fontSize: "0.875rem",
        "&::placeholder": {
            color: theme.palette.secondary.main
        }
    },
    label: {
        color: theme.palette.secondary.main
    },
    hint: {
        color: theme.palette.warning.main
    },
    labelContainer: {
        marginBottom: "0.5vw"
    }
}));

export interface Props {
    property: string;
    label: string;
}

function SignupPaymentField({property, label}: Props) {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const id = useUniqueId(property);
    const labelId = `${id}-label`;
    const value = signupQuery.useValue(property as any) || "";

    function onChange(event: any) {
        signupActions.update(property as any, event.target.value);
    }

    return (
        <FormControl>
            <div className={classes.labelContainer}>
                <InputLabel id={labelId} className={classes.label}>{t(label)}</InputLabel>
            </div>
            <Select
                labelId={labelId}
                id={id}
                value={value}
                onChange={onChange}
                variant="outlined"
                color="secondary"
                className={classes.textField}>
                <MenuItem value="">&nbsp;</MenuItem>
                {[
                    PaymentType.BANKEINZUG,
                    PaymentType.NACHNAHME,
                    PaymentType.SOFORT,
                    PaymentType.VORAUSZAHLUNG,
                    PaymentType.ZAHLSCHEIN
                ].map(paymentType =>
                    <MenuItem
                        key={paymentType}
                        value={paymentType}>
                        {t(`payment-type-${paymentType}`)}
                    </MenuItem>
                )}

            </Select>
            <FormHelperText>
                <Trans i18nKey="signup-paymentType-hint"
                       components={[<strong className={classes.hint}/>, <span className={classes.hint}/>]}
                />
            </FormHelperText>
        </FormControl>
    );
}

export default SignupPaymentField;
