import {transaction} from "@datorama/akita";
import {CompanySlug} from "../../../model/Company";
import {VehicleGroupSlug} from "../../../model/VehicleGroup";
import vehicleService, {VehicleService} from "../../../service/VehicleService";
import {CompanyActions} from "../company/CompanyActions";
import vehicleStore, {VehicleStore} from "./VehicleStore";

export interface Dependencies {
    vehicleStore: VehicleStore;
    vehicleService: VehicleService;
    companyActions: CompanyActions;
}

export class VehicleActions {
    private readonly vehicleStore = vehicleStore;
    private readonly vehicleService = vehicleService;


    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public async findAllByCompanyAndGroup(company: CompanySlug, group?: VehicleGroupSlug | null): Promise<void> {
        const filter = {company};
        if (group) {
            Object.assign(filter, {group});
        }

        try {
            this.vehicleStore.set([]);
            this.vehicleStore.update({
                loading: true,
                error: null
            });
            const result = await this.vehicleService.findAllByCompanyAndGroup(filter);
            this.vehicleStore.set(result);
        } catch (error) {
            this.vehicleStore.setError(error);
            throw error;
        } finally {
            this.vehicleStore.setLoading(false);
        }
    }

    @transaction()
    public clear(): void {
        this.vehicleStore.set([]);
        this.vehicleStore.setError(null);
    }

}

export default new VehicleActions();
