import Theme from "../model/Theme";
import HttpService from "./HttpService";

export class ThemeService extends HttpService {
    public async load(): Promise<Theme> {
        return this.get("/themes/current");
    }
}

export default new ThemeService();
