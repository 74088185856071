import {TextField} from "@material-ui/core";
import React, {FC} from "react";

export const NumberField: FC<any> = ({min = 1, onChange, ...props}) => {
    function onChangeInternal(event: React.ChangeEvent<HTMLInputElement>) {
        const quantity = parseInt(event.target.value, 10);
        if (Number.isInteger(quantity) && quantity >= min) {
            onChange && onChange(quantity);
        }
    }

    return (
        <TextField
            data-cy="shopping-cart-quantity"
            type="number"
            onChange={onChangeInternal}
            {...props}
        />
    );
};

export default NumberField;
