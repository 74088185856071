import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import React, {FC, Fragment, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import Preorder from "../../app/model/preorder/Preorder";
import ContentService from "../../app/service/ContentService";
import NotFoundError from "../../app/service/error/NotFoundError";
import preorderService from "../../app/service/PreorderService";
import configQuery from "../../app/state/config/ConfigQuery";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";

export const CodePage: FC = () => {
    const {t} = useTranslation();
    const [error, setError] = useState<any>(null);
    const [preorder, setPreorder] = useState<Preorder | null>(null);
    const hasOpenPreorder = preorder && !preorder.orderedAt;
    const hasDonePreorder = preorder && !!preorder.orderedAt;
    const year = moment().format(t("year-format"));
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("preorder-code-page-title", {year}),
            to: "/vor-teile-wochen/code.html"
        }
    ];
    useEffect(() => {
        preorderService.findCurrent()
            .catch(error => {
                if (error instanceof NotFoundError) {
                    return null
                } else {
                    throw error;
                }
            })
            .then(setPreorder)
            .catch(setError)
    }, [])

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    if (hasOpenPreorder) {
        return (<Redirect to="/vor-teile-wochen/warenkorb.html"/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("preorder-code-page-title", {year})}></ContentHeader>
            <Grid container>
                {hasDonePreorder &&
                <Grid item xs={12}>
                    <Alert severity="info">
                        {t("preorder-code-done")}
                    </Alert>
                </Grid>
                }
                {!hasDonePreorder &&
                <Fragment>
                    <Grid item xs={12}>
                        <CodeForm/>
                    </Grid>
                    <Grid item xs={12}>
                        <Note/>
                    </Grid>
                    <Grid item xs={12}>
                        <Content/>
                    </Grid>
                </Fragment>
                }
            </Grid>
        </Fragment>
    );
};

const CodeForm: FC = () => {
    const {t} = useTranslation();
    const [code, setCode] = useState("");
    const [error, setError] = useState<any>(null);
    const [success, setSuccess] = useState(false);
    const isInvalid = !code;

    function onSubmit() {
        preorderService.create(code)
            .then(() => setSuccess(true))
            .catch(setError)
    }

    if (success) {
        return (<Redirect to="/vor-teile-wochen/Zweirad.html"/>);
    }

    return (
        <Grid container component="form"
              onSubmit={(event: any) => {
                  event.preventDefault();
                  onSubmit();
              }}
        >
            {error &&
            <Grid item sm={8} xs={12}>
                <Alert severity="error">
                    {t("preorder-code-error")}
                </Alert>
            </Grid>
            }
            <Grid item sm={8} xs={12}>
                <TextField
                    required
                    fullWidth
                    name="code"
                    label="Code"
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                />
            </Grid>
            <Grid item sm={4} xs={12}/>
            <Grid item md={2} sm={3}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disableElevation
                    disabled={isInvalid}
                    children={t("action-send")}
                />
            </Grid>
        </Grid>
    );
};
const Note: FC = () => {
    const phone = configQuery.useConfig("shopPhone");
    const email = configQuery.useConfig("shopEMail");

    return (
        <Alert severity="info">
            <div>
                <Trans i18nKey="preorder-code-note"
                       values={{phone, email}}
                       components={[<br/>]}
                />
            </div>
        </Alert>
    );
};

const Content: FC = () => {
    const [content, setContent] = useState("");
    const [error, setError] = useState<any>(null);
    const classes = useStyles(undefined);

    useEffect(() => {
        ContentService.find("preorder")
            .then(({text}) => text)
            .then(setContent)
            .catch(setError);
    }, []);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <div
            className={classes.content}
            dangerouslySetInnerHTML={{__html: content}}
        />
    );
};

const useStyles = makeStyles((theme) => ({
    content: {
        "& a": {
            color: theme.palette.secondary.dark,
            textDecoration: "none"
        },
        "& a:hover": {
            textDecoration: "underline"
        },
        textAlign: "justify"
    }
}));


export default CodePage;
