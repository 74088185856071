import React, {FC} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import WatchListAction from "./WatchListAction";
import ShoppingCartAction from "./ShoppingCartAction";
import AccountAction from "./AccountAction";
import LanguageToggleAction from "./LanguageToggleAction";
import languageQuery from "../../app/state/language/LanguageQuery";
import {Language} from "../../app/state/language/UserLanguage";
import languageActions from "../../app/state/language/LanguageActions";
import authQuery from "../../app/state/auth/AuthQuery";

const useStyles = makeStyles(theme => ({
    sectionActions: {
        transform: 'skew(30deg)',
    },
    accentBar: {
        paddingLeft: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '40vw',
        zIndex: 25,
        position: 'fixed',
        right: '-5vw',
        top: 0,
        height: theme.spacing(12)-theme.spacing(1),
        transform: 'skew(-30deg)',
        backgroundColor: theme.palette.primary.main
    }
}));

export const ActionsHeader: FC = () => {
    const classes = useStyles(undefined);
    const isLoggedIn = authQuery.useIsLoggedIn();
    const language = languageQuery.useValue().language;
    const changeLanguage = (newLanguage: Language) => languageActions.changeLanguage(newLanguage, isLoggedIn);

    return <div className={classes.accentBar}>
        <div className={classes.sectionActions}>
            <WatchListAction/>
            <ShoppingCartAction/>
            <AccountAction/>
            <LanguageToggleAction currentLanguage={language} changeLanguage={changeLanguage}/>
        </div>
    </div>
}