import ms from "ms";

export const CompanyLogoBaseUrl = `https://medien.scootertech.at/original`;
export const StandardImageBaseUrl = `https://medien.scootertech.at/standard`;
export const SmallImageBaseUrl = `https://medien.scootertech.at/small`;
export const FileBaseUrl = `https://medien.scootertech.at/files`;
export const CatalogueBaseUrl = `/media/v1/pgo`;

export const ContentHeaderColor = "primary";
export const ContentSubHeaderColor = "primary";
export const MinimumSearchInputLength = 3;
export const CompanyName = "ScooterTech";
export const GridDefaultSpacing = 2;
// also see https://www.nngroup.com/articles/response-times-3-important-limits/
export const PerceptionInMilliSeconds = 100;
export const DefaultLoadingIndicatorThrottleTimeoutInMilliSeconds = PerceptionInMilliSeconds;
export const TokenRefreshLeadTime = ms("10 s");

export const StripePublishableApiKey = 'pk_test_51IGmxSJVK1Pi0IPXiq35FROwwJTxDyHXMEUBqZbV7bf2B9LpkzfBBBSDqgRfQTMFL3YQamD43B8Rrs5e9xVSBLhx00nsBiCwRH';
