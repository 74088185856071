import DateAdapter from '@date-io/moment';
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import React, {FC, useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "typeface-open-sans";
import AuthGuard from "./app/component/auth/AuthGuard";
import ScrollToTop from "./app/component/ScrollToTop";
import authActions from './app/state/auth/AuthActions'
import authQuery from './app/state/auth/AuthQuery'
import configActions from "./app/state/config/ConfigActions";
import languageActions from './app/state/language/LanguageActions';
import languageQuery from './app/state/language/LanguageQuery';
import {SaleType} from "./app/state/sale/SaleStore";
import NotFoundErrorPage from "./errors/404";
import InternalServerErrorPage from "./errors/500";
import Layout from "./layout/Layout";
import IndexPage from "./pages";
import AboutPage from "./pages/about";
import AddressIndexPage from "./pages/address/AddressIndexPage";
import AddressCreatePage from "./pages/address/create";
import AddressEditPage from "./pages/address/edit";
import CatalogPage from "./pages/catalog/CataloguePage";
import CategoryFilterPage from "./pages/CategoryFilterPage";
import CategorySelectionPage from "./pages/CategorySelectionPage";
import Checkout from "./pages/Checkout";
import CheckoutCancellation from "./pages/CheckoutCancellation";
import CheckoutConfirmation from "./pages/CheckoutConfirmation";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import ContactPage from "./pages/contact";
import ExplodedViewPage from "./pages/ExplodedViewPage";
import ExplodedViewsPage from "./pages/ExplodedViewsPage";
import KeywordPage from "./pages/keyword/KeywordPage";
import LatestItemsPage from "./pages/latest/LatestItemsPage";
import {CompanyPage} from "./pages/marke";
import MotorPage from "./pages/motor";
import MotorItemsPage from "./pages/motor/MotorItemsPage";
import MotorSelectionPage from "./pages/motoren";
import CartPage from "./pages/preorder/CartPage";
import CategoryPage from "./pages/preorder/CategoryPage";
import CheckoutPage from "./pages/preorder/CheckoutPage";
import CodePage from "./pages/preorder/CodePage";
import FinishedPage from "./pages/preorder/FinishedPage";
import SectionPage from "./pages/preorder/SectionPage";
import ProductPage from "./pages/produkt";
import AddReturnsPage from "./pages/returns/AddReturnsPage";
import ReturnPage from "./pages/returns/ReturnPage";
import ReturnsArchivePage from "./pages/returns/ReturnsArchivePage";
import {SalePage} from "./pages/sale";
import SearchPage from "./pages/search";
import ShoppingCart from "./pages/ShoppingCart";
import SignupPage from "./pages/signup/SignupPage";
import StaticContentPage from "./pages/StaticContentPage";
import StoreLocator from "./pages/StoreLocator";
import ChangePasswordPage from "./pages/user/ChangePasswordPage";
import ConfirmResetPasswordPage from "./pages/user/ConfirmResetPasswordPage";
import {OrderArchivePage} from "./pages/user/OrderArchivePage";
import OrderPage from "./pages/user/OrderPage";
import ProfilePage from "./pages/user/ProfilePage";
import ResetPasswordPage from "./pages/user/ResetPasswordPage";
import UserPage from "./pages/user/UserPage";
import VehiclePage from "./pages/vehicle";
import VehicleItemPage from "./pages/VehicleItemPage";
import VehicleItemsPage from "./pages/VehicleItemsPage";
import VehicleSelectionPage from "./pages/VehicleSelectionPage";
import WatchList from "./pages/WatchList";
import defaultTheme, {createTheme} from "./theme";
import TenantSwitch from "./app/component/tenant/TenantSwitch";
import TenantSignupPage from "./pages/signup/TenantSignupPage";
import tenantStore from "./app/state/tenant/TenantStore";
import themeService from "./app/service/ThemeService";
import profileQuery from "./app/state/user/ProfileQuery";
import profileStore from "./app/state/user/ProfileStore";
import {Language, mapLocaleForLanguageUse} from "./app/state/language/UserLanguage";
import {printPromiseError} from "./app/common/util/errorHelper";
import {InvoiceListPage} from "./pages/user/InvoiceListPage";
import {InvoicePage} from "./pages/user/InvoicePage";
import shoppingCartActions from "./app/state/shoppingCart/ShoppingCartActions";
import {BackorderPage} from "./pages/user/BackorderPage";
import watchListActions from "./app/state/watchList/WatchListActions";

const App: FC = () => {
    const isLoggedIn = authQuery.useIsLoggedIn();
    languageQuery.useValue(); // We only want to listen to the state value here to trigger re-renderings on change.
    const [theme, setTheme] = useState(defaultTheme);
    const profile = profileQuery.useProfile();

    useEffect(() => {
        if (isLoggedIn) {
            authActions.setupCurrency();
            profileStore.fetchProfile();
        }
    }, [isLoggedIn])

    useEffect(() => {
        configActions.load().catch(printPromiseError);
        themeService.load()
            .then(createTheme)
            .then(setTheme);
    }, [])

    useEffect(() => {
        languageActions.load();
    }, []);

    useEffect(() => {
         const sub = shoppingCartActions.createLoadRequests().subscribe();
         return () => {
             sub.unsubscribe();
         }
    }, []);

    useEffect(() => {
        if(profile?.locale && profile.locale !== Language.NOT_SET) {
            languageActions.changeLanguage(mapLocaleForLanguageUse(profile.locale), false).catch(printPromiseError);
        }
    }, [profile]);

    useEffect(() => {
        const subs = tenantStore.activateEffects();
        tenantStore.getTenantState();
        return () => {
            subs.forEach(sub => sub.unsubscribe());
        }
    }, [])

    useEffect(() => {
        const subs = watchListActions.activateWatchListEffects();
        watchListActions.loadWatchList();

        return () => {
            subs.forEach(it => it.unsubscribe());
        }
    }, [])


    return (
        <MuiPickersUtilsProvider utils={DateAdapter}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <Layout>
                        <ScrollToTop />
                        <Switch>
                            <Route exact path="/">
                                <IndexPage/>
                            </Route>
                            <Route exact path="/ueber-uns.html">
                                <AboutPage/>
                            </Route>
                            <Route exact path="/kontakt.html">
                                <ContactPage/>
                            </Route>
                            <Route exact path="/pgo-malaguti.html">
                                <TenantSwitch forBase={<AuthGuard><CatalogPage/></AuthGuard>} forTenant={<NotFoundErrorPage />} />
                            </Route>
                            <Route path="/explosionszeichnungen.html">
                                <AuthGuard><ExplodedViewsPage/></AuthGuard>
                            </Route>
                            <Route path="/explosionszeichnung/:slug.html">
                                <AuthGuard><ExplodedViewPage/></AuthGuard>
                            </Route>
                            <Route path="/aktionen/angebote.html">
                                <AuthGuard><SalePage type={SaleType.OFFER}/></AuthGuard>
                            </Route>
                            <Route path="/aktionen/abverkauf.html">
                                <AuthGuard><SalePage type={SaleType.CLEARANCE}/></AuthGuard>
                            </Route>
                            <Route exact path="/:slug.html">
                                <StaticContentPage/>
                            </Route>
                            <Route path="/produkt/:slug.html">
                                <ProductPage/>
                            </Route>
                            <Route path="/marke/:slug.html">
                                <CompanyPage/>
                            </Route>
                            <Route path="/:section/fahrzeug/:slug.html">
                                <VehiclePage/>
                            </Route>
                            <Route path="/:section/fahrzeug/:vehicle/:item.html">
                                <VehicleItemPage/>
                            </Route>
                            <Route path="/:section/fahrzeug/:vehicle/:category/">
                                <VehicleItemsPage/>
                            </Route>
                            <Route path="/:section/motor/:slug/:category/">
                                <MotorItemsPage/>
                            </Route>
                            <Route path="/:section/motor/:slug">
                                <MotorPage/>
                            </Route>
                            <Route path="/motor/:slug">
                                <MotorPage/>
                            </Route>
                            <Route path="/:section/motoren/:company">
                                <MotorSelectionPage/>
                            </Route>
                            <Route path="/:section/motoren">
                                <MotorSelectionPage/>
                            </Route>
                            <Route path="/search">
                                <SearchPage/>
                            </Route>
                            <Route path="/:section/fahrzeuge/:vehicleGroup/:company">
                                <VehicleSelectionPage/>
                            </Route>
                            <Route path="/:section/fahrzeuge/:vehicleGroup/">
                                <VehicleSelectionPage/>
                            </Route>
                            <Route path="/:section/fahrzeuge/">
                                <VehicleSelectionPage/>
                            </Route>
                            <Route path="/:section/stichwortverzeichnis.html">
                                <KeywordPage/>
                            </Route>
                            <Route path="/:section/produkte/:category/">
                                <CategoryFilterPage/>
                            </Route>
                            <Route path="/produkte/k/:category/">
                                <CategoryFilterPage/>
                            </Route>
                            <Route path="/:section/produkte">
                                <CategorySelectionPage/>
                            </Route>
                            <Route path="/produkte/neu/aktuell.html">
                                <AuthGuard><LatestItemsPage/></AuthGuard>
                            </Route>
                            <Route path="/produkte/neu/:year/:month.html">
                                <AuthGuard><LatestItemsPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/adresse/create">
                                <AuthGuard><AddressCreatePage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/adresse/:id/edit">
                                <AuthGuard><AddressEditPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/adresse/">
                                <AuthGuard><AddressIndexPage/></AuthGuard>
                            </Route>
                            <Route path="/haendler/">
                                <TenantSwitch forBase={<StoreLocator/>} forTenant={<NotFoundErrorPage />} />
                            </Route>
                            <Route path="/benutzer/bestellungen/:id">
                                <AuthGuard><OrderPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/bestellungen/">
                                <AuthGuard><OrderArchivePage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/rueckstaende/">
                                <AuthGuard><BackorderPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/rechnungen/:number">
                                <AuthGuard><InvoicePage /></AuthGuard>
                            </Route>
                            <Route path="/benutzer/rechnungen/">
                                <AuthGuard><InvoiceListPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/password/reset/:token">
                                <ConfirmResetPasswordPage/>
                            </Route>
                            <Route path="/benutzer/password/reset/">
                                <ResetPasswordPage/>
                            </Route>
                            <Route path="/benutzer/password/">
                                <AuthGuard><ChangePasswordPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/registrieren/">
                                <TenantSwitch forBase={<SignupPage/>} forTenant={<TenantSignupPage/>}/>
                            </Route>
                            <Route path="/benutzer/index.html">
                                <AuthGuard><UserPage/></AuthGuard>
                            </Route>
                            <Route path="/benutzer/profile.html">
                                <AuthGuard><ProfilePage/></AuthGuard>
                            </Route>
                            <Route path="/shop/warenkorb.html">
                                <AuthGuard><ShoppingCart/></AuthGuard>
                            </Route>
                            <Route path="/shop/merkliste.html">
                                <AuthGuard><WatchList/></AuthGuard>
                            </Route>
                            <Route path="/shop/versand.html">
                                <AuthGuard><Checkout/></AuthGuard>
                            </Route>
                            <Route path="/shop/bestaetigen.html">
                                <AuthGuard><CheckoutConfirmation/></AuthGuard>
                            </Route>
                            <Route path="/shop/abgeschlossen.html">
                                <CheckoutSuccess/>
                            </Route>
                            <Route path="/shop/abgebrochen.html">
                                <CheckoutCancellation/>
                            </Route>
                            <Route path="/ruecksendungen/add">
                                <AuthGuard><AddReturnsPage/></AuthGuard>
                            </Route>
                            <Route path="/ruecksendungen/:id">
                                <AuthGuard><ReturnPage/></AuthGuard>
                            </Route>
                            <Route path="/ruecksendungen/">
                                <AuthGuard><ReturnsArchivePage/></AuthGuard>
                            </Route>
                            <Route path="/vor-teile-wochen/code.html">
                                <AuthGuard><CodePage/></AuthGuard>
                            </Route>
                            <Route path="/vor-teile-wochen/warenkorb.html">
                                <AuthGuard><CartPage/></AuthGuard>
                            </Route>
                            <Route path="/vor-teile-wochen/bestaetigung.html">
                                <AuthGuard><CheckoutPage/></AuthGuard>
                            </Route>
                            <Route path="/vor-teile-wochen/fertig.html">
                                <AuthGuard><FinishedPage/></AuthGuard>
                            </Route>
                            <Route path="/vor-teile-wochen/:section.html">

                                    <AuthGuard><SectionPage/></AuthGuard>
                            </Route>
                            <Route path="/vor-teile-wochen/:section/:category.html">
                                    <AuthGuard><CategoryPage/></AuthGuard>
                            </Route>
                            <Route exact path="/error/404.html">
                                <NotFoundErrorPage/>
                            </Route>
                            <Route exact path="/error/500.html">
                                <InternalServerErrorPage/>
                            </Route>
                            <Route path="*">
                                <NotFoundErrorPage/>
                            </Route>
                        </Switch>
                    </Layout>
                </Router>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
