import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const SocialMedia: FC = () => {
    const { t } = useTranslation();
    const facebook = "https://www.facebook.com/scootertechhandelsgmbh/";
    const instagram = "https://www.instagram.com/scootertech_/";

    return (
        <Grid container direction="row" alignItems="center">
            <Grid item>{t("footer-social-media-text")}</Grid>
            <Grid item><Link color="inherit" href={facebook}><FacebookIcon fontSize="large"/></Link></Grid>
            <Grid item><Link color="inherit" href={instagram}><InstagramIcon fontSize="large"/></Link></Grid>
        </Grid>
    );
};

export default SocialMedia;    
