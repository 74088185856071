import React from "react";
import Image from "./Image";
import ImagePreviewOverlay from "./ImagePreviewOverlay";
import styles from "./styles";
import utils from "./utils";

// @ts-ignore
const SideBySideRenderer = props => {
    const {
        itemPosition,
        active,
        elementDimensions,
        elementOffset,
        itemDimensions,
        imageSrc,
        largeImageSrc,
        imageAlt,
        itemRef,
        overlayOpacity,
        overlayBoxOpacity,
        overlayBackgroundColor,
        overlayBoxColor,
        overlayBoxImage,
        overlayBoxImageSize,
        transitionSpeed,
        transitionSpeedInPlace,
        renderOverlay,
        cursorStyle,
        onImageLoad,
        onLargeImageLoad,
        onLoadRefresh,
        fillAvailableSpace,
        fillAlignTop,
        fillGapLeft,
        fillGapRight,
        fillGapTop,
        fillGapBottom,
        zoomContainerBorder,
        zoomContainerBoxShadow,
        scale
    } = props;
    const zoomContainerDimensions = {
        width: elementDimensions.width,
        height: elementDimensions.height
    };
    const zoomContainerStyle = {};

    let availableWidth = 0;
    let availableHeight = 0;

    const zoomGapVertical = fillGapTop + fillGapBottom;
    const zoomGapHorizontal = fillGapLeft + fillGapRight;

    try {
        const {clientWidth, clientHeight} = document.documentElement;
        availableWidth = clientWidth;
        availableHeight = clientHeight;
    } catch (e) {
    }

    let inPlace = false;

    if (fillAvailableSpace) {
        const left = elementDimensions.width + elementOffset.left;

        if (fillAlignTop) {
            zoomContainerDimensions.height = Math.min(
                itemDimensions.height,
                availableHeight - elementOffset.top - zoomGapVertical + fillGapTop
            );
            // @ts-ignore
            zoomContainerDimensions.top = fillGapTop;
        } else {
            zoomContainerDimensions.height = Math.min(
                itemDimensions.height,
                availableHeight - zoomGapVertical
            );

            const offsetTop = fillGapTop - elementOffset.top;

            const maxOffsetTop =
                availableHeight -
                elementOffset.top -
                (zoomContainerDimensions.height + fillGapBottom);

            // @ts-ignore
            zoomContainerDimensions.top = Math.max(offsetTop, maxOffsetTop);
        }

        // @ts-ignore
        zoomContainerDimensions.top = Math.min(zoomContainerDimensions.top, 0);
        // @ts-ignore
        zoomContainerStyle.top = `${zoomContainerDimensions.top}px`;

        zoomContainerDimensions.width = Math.min(
            itemDimensions.width,
            availableWidth - left - zoomGapHorizontal
        );
        // @ts-ignore
        zoomContainerDimensions.left = elementDimensions.width + fillGapLeft;
        // @ts-ignore
        zoomContainerStyle.left = `${zoomContainerDimensions.left}px`;
    } else {
        inPlace = elementDimensions.width * 2 + elementOffset.left > availableWidth;
    }

    const legalSize = itemDimensions.width > elementDimensions.width;
    const isActive = legalSize && active;
    const transSpeed = inPlace ? transitionSpeedInPlace : transitionSpeed;

    const smallImageSize = {
        width: elementDimensions.width,
        height: elementDimensions.height
    };

    const previewSize = {
        width: Math.floor(
            smallImageSize.width *
            (zoomContainerDimensions.width / itemDimensions.width)
        ),
        height: Math.floor(
            smallImageSize.height *
            (zoomContainerDimensions.height / itemDimensions.height)
        )
    };

    let position = {x: 0, y: 0};
    const itemPositionAdj = {...itemPosition};

    const previewOffset = {
        x: inPlace ? 0 : previewSize.width / 2,
        y: inPlace ? 0 : previewSize.height / 2
    };

    itemPositionAdj.x = Math.max(previewOffset.x, itemPositionAdj.x);
    itemPositionAdj.x = Math.min(
        smallImageSize.width - previewOffset.x,
        itemPositionAdj.x
    );
    itemPositionAdj.y = Math.max(previewOffset.y, itemPositionAdj.y);
    itemPositionAdj.y = Math.min(
        smallImageSize.height - previewOffset.y,
        itemPositionAdj.y
    );

    position = {...itemPositionAdj};

    const zoomContainerSize = inPlace ? smallImageSize : zoomContainerDimensions;

    position.x = utils.convertRange(
        previewOffset.x,
        smallImageSize.width - previewOffset.x,
        zoomContainerSize.width - itemDimensions.width,
        0,
        position.x
    );
    position.y = utils.convertRange(
        previewOffset.y,
        smallImageSize.height - previewOffset.y,
        zoomContainerSize.height - itemDimensions.height,
        0,
        position.y
    );

    position.x = utils.invertNumber(
        zoomContainerSize.width - itemDimensions.width,
        0,
        position.x
    );
    position.y = utils.invertNumber(
        zoomContainerSize.height - itemDimensions.height,
        0,
        position.y
    );

    // @ts-ignore
    previewSize.left = Math.floor(itemPositionAdj.x - previewOffset.x) || 0;
    // @ts-ignore
    previewSize.right = Math.floor(itemPositionAdj.x + previewOffset.x) || 0;
    // @ts-ignore
    previewSize.top = Math.floor(itemPositionAdj.y - previewOffset.y) || 0;
    // @ts-ignore
    previewSize.bottom = Math.floor(itemPositionAdj.y + previewOffset.y) || 0;

    return (
        <div style={{position: "relative"}}>
            <Image
                // @ts-ignore
                style={{
                    width: "100%",
                    display: "block",
                    cursor: legalSize ? cursorStyle : "default"
                }}
                src={imageSrc}
                alt={imageAlt}
                onImageLoad={onImageLoad}
                onLoadRefresh={onLoadRefresh}
            />
            <div
                // @ts-ignore
                style={{
                    // @ts-ignore
                    ...styles.getZoomContainerStyle(
                        zoomContainerSize.width,
                        zoomContainerSize.height,
                        inPlace
                    ),
                    opacity: isActive ? "1" : "0",
                    transition: `opacity ${transSpeed}s ease`,
                    // @ts-ignore
                    zIndex: "100",
                    ...zoomContainerStyle,
                    border: zoomContainerBorder,
                    boxShadow: zoomContainerBoxShadow
                }}
            >
                <Image
                    // @ts-ignore
                    style={styles.getLargeImageStyle(position.x, position.y, true, itemDimensions, scale)}
                    src={largeImageSrc || imageSrc}
                    alt={imageAlt}
                    ref={itemRef}
                    onImageLoad={onLargeImageLoad}
                    onLoadRefresh={onLoadRefresh}
                />
            </div>
            <ImagePreviewOverlay
                previewWidth={previewSize.width}
                previewHeight={previewSize.height}
                // @ts-ignore
                previewPosLeft={previewSize.left}
                // @ts-ignore
                previewPosRight={previewSize.right}
                // @ts-ignore
                previewPosTop={previewSize.top}
                // @ts-ignore
                previewPosBottom={previewSize.bottom}
                imageWidth={smallImageSize.width}
                imageHeight={smallImageSize.height}
                overlayOpacity={overlayOpacity}
                overlayBoxOpacity={overlayBoxOpacity}
                overlayBackgroundColor={overlayBackgroundColor}
                overlayBoxColor={overlayBoxColor}
                overlayBoxImage={overlayBoxImage}
                overlayBoxImageSize={overlayBoxImageSize}
                active={isActive && !inPlace}
                transitionSpeed={transSpeed}
            />
            {renderOverlay ? renderOverlay(active) : null}
        </div>
    );
};

export default SideBySideRenderer;
