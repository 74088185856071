import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {AddItemRequest, ReturnItem} from "../../../service/returns/ItemService";

export type Entity = ReturnItem;
export type ID = string;

export interface State extends EntityState<Entity, ID>, AddItemRequest {
    pristine: boolean;
    success: boolean;
}

export function createInitialState(): Partial<State> {
    return {
        itemNumber: "",
        count: 1,
        reason: undefined,
        comment: "",
        invoiceNumber: "",
        invoiceDate: undefined,
        pristine: true,
        success: false
    };
}

@StoreConfig({
    name: "returns.items"
})
export class ItemStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new ItemStore();
