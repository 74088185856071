import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {ContentSubHeaderColor} from "../config/Config";

export const ContentSubHeader: FC = (props) => {
    return (
        <Typography color={ContentSubHeaderColor} variant="h4" component="h2" {...props} />
    );
};

export default ContentSubHeader;
