import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {Box, ExplodedView} from "../../model/ExplodedView";

export type ID = Box["id"];
export type Entity = Box;

export interface State extends EntityState<Entity, ID>, ActiveState<ID>, Omit<ExplodedView, "boxes"> {
    highlight?: ID;
}

export function createInitialState(): Partial<State> {
    return {
        slug: ""
    };
}

@StoreConfig({
    name: "explodedview",
    idKey: "id"
})
export class ExplodedViewStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new ExplodedViewStore();
