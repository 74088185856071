import React, {FC} from "react";
import {VehicleGroup} from "../../model/VehicleGroup";
import {ImageLink} from "../ImageLink";

export interface VehicleGroupProps {
    group: VehicleGroup;
}

export const VehicleGroupImageLink: FC<VehicleGroupProps> = ({group}) => {
    return (
        <ImageLink
            path={`${group.slug}/`}
            name={group.name}
            imageUrl={group.image ? group.image.locationDefault : `/img/fahrzeugauswahl/${group.slug}.jpg`}
        />
    );
};

export default VehicleGroupImageLink;
