import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";

export interface StringListProps {
    heading?: string;
    items: string[];
}

export const StringList: FC<StringListProps> = ({heading, items}) => {
    return (
        <Fragment>
            {heading && <Typography variant="h5" component="h3">{heading}</Typography>}
            <List dense>
                {
                    items.map((item) => (
                        <ListItem key={item}>
                            <ListItemText>{item}</ListItemText>
                        </ListItem>
                    ))
                }
            </List>
        </Fragment>
    );
};

export default StringList;
