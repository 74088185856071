import {transaction} from "@datorama/akita";
import categoryService from "../../service/CategoryService";
import vehicleCategoryQuery from "./VehicleCategoryQuery";
import vehicleStore, {Filter,} from "./VehicleCategoryStore";

export class VehicleCategoryActions {
    private readonly store = vehicleStore;
    private readonly query = vehicleCategoryQuery;
    private readonly service = categoryService;

    @transaction()
    public async findAll(vehicle: string, category: string): Promise<void> {
        const filter: Filter = {vehicle, category};
        if (this.isFilterEqual(filter)) {
            return;
        }
        try {
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.service.findAllCategoriesByVehicleAndCategory(filter);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private isFilterEqual(next: Filter) {
        const current = this.query.getValue().filter;
        // @ts-ignore
        return Object.entries(current).every(([key, value]) => next[key] === value)
    }

    public select(slug: string): void {
        this.store.setActive(slug);
    }

    public deselect(): void {
        this.store.setActive(null);
    }
}

export default new VehicleCategoryActions();
