import clientStorage from "../../common/ClientStorage";

export class PageSize {
    private readonly SIZE_KEY = "item.filter.size";
    private readonly DEFAULT_SIZE = 10;
    private readonly storage = clientStorage;

    public load(): number {
        let size = this.DEFAULT_SIZE;
        try {
            const value = this.storage.getItem(this.SIZE_KEY);
            if (value) {
                size = parseInt(value);
            }
        } catch (err) {
            this.storage.removeItem(this.SIZE_KEY);
        }

        return size;
    }

    public save(size: number): void {
        this.storage.setItem(this.SIZE_KEY, `${size}`);
    }
}

export default new PageSize()