import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, {FC, useState} from "react";

const PasswordField: FC<TextFieldProps> = (props) => {
    const [isVisible, setVisibility] = useState(false);

    function toggleVisibility() {
        setVisibility(!isVisible);
    }

    return (
        <TextField
            {...props}
            type={isVisible ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={toggleVisibility}
                            onMouseDown={event => event.preventDefault()}
                        >
                            {isVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        ></TextField>
    );
};

export default PasswordField;
