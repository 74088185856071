import React, {FC} from "react";
import Content from "../app/component/Content";

export const About: FC = () => {
    return (
        <Content name="about"/>
    );
};

export default About;
