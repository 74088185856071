import AuthorizedHttpService from "./AuthorizedHttpService";
import {Order, OrderMetadata, Page} from "../model/user/OrderArchive";

export class OrderArchiveService extends AuthorizedHttpService {

    public getPage(page: number = 0, size: number = 10, direction: string = 'desc'): Promise<Page<OrderMetadata>> {
        const query = `?page=${page}&size=${size}&sortOrder=${direction}`
        return this.get(`/orders${query}`);
    }

    public getOrder(transactionId: string): Promise<Order> {
        return this.get(`/orders/${transactionId}`);
    }
}

export default new OrderArchiveService();
