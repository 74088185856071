import {Query} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import Address from "../../../model/checkout/Address";
import addressEditStore, {EditAddressStore, State} from "./EditAddressStore";

export class EditAddressQuery extends Query<State> {
    public readonly isLoading = this.selectLoading();
    public readonly error = this.selectError<Error | null>();
    public readonly address = this.select("address");
    public readonly isCompanyAddress = this.select("isCompanyAddress");

    constructor(
        protected store: EditAddressStore = addressEditStore
    ) {
        super(store);
    }

    public useIsCompanyAddress(): boolean {
        const current = this.store.getValue().isCompanyAddress;

        return useObservable(this.isCompanyAddress, current);
    }

    public useAddress(): Address {
        const current = this.store.getValue().address;

        return useObservable(this.address, current);
    }

    public useError(): State["error"] {
        const current = this.store.getValue().error;

        return useObservable(this.error, current);
    }
}

export default new EditAddressQuery();
