import {Query, QueryConfig} from "@datorama/akita";
import {map} from "rxjs/operators";
import {useObservable} from "../../common/useObservable";
import Config from "../../model/Config";
import {throttledLoading} from "../throttleLoading";
import configStore, {ConfigStore, State} from "./ConfigStore";

@QueryConfig({})
export class ConfigQuery extends Query<State> {
    private readonly loading = throttledLoading(this);
    private readonly error = this.selectError<Error | null>();
    private readonly value = this.select();

    constructor(
        protected store: ConfigStore = configStore
    ) {
        super(store);
    }

    public useConfig<K extends keyof Config>(key: K): Config[K] {
        const mapper = (config: Config) => config[key];

        return useObservable(this.value.pipe(map(mapper)), mapper(this.store.getValue()));
    }

    public useValue(): State {
        return useObservable(this.value, this.getValue());
    }

    public useLoading(): State["loading"] {
        return useObservable(this.loading, this.store.getValue().loading);
    }

    public useError(): State["error"] {
        return useObservable(this.error, this.store.getValue().error);
    }
}

export default new ConfigQuery();
