import motorService from "../../service/MotorService";
import companyActions from "../company/CompanyActions";
import motorQuery from "./MotorQuery";
import motorStore, {FilterState} from "./MotorStore";

export class MotorActions {
    private readonly service = motorService;
    private readonly store = motorStore;
    private readonly query = motorQuery;
    private readonly companyActions = companyActions;

    public async findAllByCompanyAndGroup(company: string): Promise<void> {
        const filter: FilterState = {company};
        if (this.isFilterEqual(filter)) {
            return;
        }
        this.companyActions.select(company);
        try {
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            // const result = await this.service.findAllByCompanyAndGroup(filter);
            // this.store.set(result);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private isFilterEqual(next: FilterState) {
        const current = this.store.getValue().filter;

        return next.company === current.company;
    }

    public select(slug: string): void {
        if (this.query.getActiveId() !== slug) {
            this.store.setActive(slug);
        }
    }

    public deselect(): void {
        this.store.setActive(null);
    }
}

export default new MotorActions();
