import {QueryConfig} from "@datorama/akita";
import {map} from "rxjs/operators";
import {useObservable} from "../../common/useObservable";
import {isInvalidEmail} from "../../common/validation";
import {HookQueryEntity} from "../HookQueryEntity";
import signupStore, {ID, SignupStore as Store, State} from "./SignupStore";

@QueryConfig({})
export class SignupQuery extends HookQueryEntity<State> {
    private readonly value = this.select();
    private readonly success = this.value.pipe(map(this.getSuccess));

    constructor(
        protected store: Store = signupStore
    ) {
        super(store);
    }

    public useValue<T>(key: ID, initialValue?: T): T {
        const entity = this.getEntity(key) || {value: initialValue};
        // @ts-ignore
        return useObservable(this.selectEntity(key, "value"), entity.value);
    }

    public useShowEmailError(): boolean {
        const id = "email";
        const {value, pristine} = useObservable(this.selectEntity(id), this.getEntity(id)) || {
            value: "",
            pristine: true
        };

        // @ts-ignore
        return !pristine && isInvalidEmail(value);
    }

    public useIsInvalid(): boolean {
        const {captcha, required, entities = {}} = useObservable(this.value, this.store.getValue());

        function isEmpty(id: ID) {
            return !entities[id] || !entities[id].value;
        }

        return !captcha || required.some(isEmpty) || !entities.email || isInvalidEmail(entities.email.value as string);
    }

    public useSuccess(): boolean {
        return useObservable(this.success, this.getSuccess(this.store.getValue()));
    }

    private getSuccess(state: State): State["success"] {
        return state.success;
    }
}

export default new SignupQuery();
