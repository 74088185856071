import {Store, StoreConfig} from "@datorama/akita";
import {from, Subject, Subscription} from "rxjs";
import {catchError, filter, switchMap, tap} from "rxjs/operators";
import tenantService from "../../service/TenantService";

export interface State {
    initialized: boolean
    tenant: boolean;
    errorFlag: boolean;
}

export interface GetIsTenantAction {}


export function createInitialState(): Partial<State> {
    return {
        initialized: false,
        tenant: false,
        errorFlag: false
    };
}

@StoreConfig({
    name: 'tenant'
})
export class TenantStore extends Store<State> {

    private readonly service = tenantService

    private readonly getIsTenantAction$: Subject<GetIsTenantAction> = new Subject<GetIsTenantAction>()

    private readonly getIsTenantEffect$ = this.getIsTenantAction$.pipe(
        filter(() => !this.getValue().initialized),
        switchMap(() => from(this.service.isTenant()).pipe(
            tap(it => this.update({tenant: it, errorFlag: false, initialized: true})),
            catchError((err) => this.update({tenant: false, errorFlag: true, initialized: true}))
            )
        )
    )

    public getTenantState(): void {
        this.getIsTenantAction$.next({});
    }

    public activateEffects(): Subscription[] {
        return [this.getIsTenantEffect$.subscribe()]
    }

    constructor() {
        super(createInitialState());
    }
}

export default new TenantStore();
