import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import companyStore, {CompanyStore, State} from "./CompanyStore";

@QueryConfig({})
export class CompanyQuery extends HookQueryEntity<State> {
    constructor(
        protected store: CompanyStore = companyStore
    ) {
        super(store);
    }
}

export default new CompanyQuery();
