import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, memo} from "react";
import Vehicle from "../../../model/Vehicle";
import sectionQuery from "../../../state/section/SectionQuery";
import vehicleQuery from "../../../state/vehicle/VehicleQuery";
import {VehicleModelList} from "./VehicleModelList";

export const VehicleGroupingList: FC = () => {
    const loading = vehicleQuery.useIsLoading();
    const vehicles = vehicleQuery.useAll();
    const section = sectionQuery.useActiveId() || "";

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <VehicleGroupingListPure
                section={section}
                vehicles={vehicles}
            />
        );
    }
};

export default VehicleGroupingList;

interface VehicleModelListPureProps {
    section: string;
    vehicles: Vehicle[];
}

interface VehicleGroupingMap {
    [groupingName: string]: Vehicle[];
}


const useStyles = makeStyles(theme => ({
    margin: {
        marginBottom: "15px"
    },
}));

export const VehicleGroupingListPure: FC<VehicleModelListPureProps> = memo(({section, vehicles}) => {
    const vehicleGroupingMap: VehicleGroupingMap = createVehicleGrouping(vehicles);
    const classes = useStyles(undefined);

    return (
        <List component="div">
            {
                Object.keys(vehicleGroupingMap).sort().map((groupingName: string) =>
                    <Fragment key={groupingName}>
                        <Typography data-cy="vehicle-model-group-name"
                                    variant="subtitle2">{groupingName}</Typography>
                        <List component="div" className={classes.margin}>
                            {vehicleGroupingMap[groupingName].map((currentVehicle: Vehicle) => (
                                <VehicleModelList key={currentVehicle.slug} section={section}
                                                  currentVehicle={currentVehicle}/>
                            ))}
                        </List>
                    </Fragment>)
            }
        </List>
    );
});

function createVehicleGrouping(vehicles: Vehicle[]): VehicleGroupingMap {
    let map: VehicleGroupingMap = {};
    vehicles.forEach((vehicle) => {
        let grouping: string = vehicle.grouping || "";
        let vehicles: Vehicle[] = map[grouping] || [];
        vehicles.push(vehicle);
        map[grouping] = vehicles;
    });
    return map;
}

