import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import useHistoryPushNew from "../../common/useHistoryPushNew";
import ConfirmationCart from "../../model/shoppingCart/ConfirmationCart";
import ShoppingCartItem from "../../model/shoppingCart/ShoppingCartItem";
import configQuery from "../../state/config/ConfigQuery";
import OrderCartItemRow from "./OrderCartItemRow";
import NotForTenant from "../tenant/NotForTenant";
import {AddingRow, SubtractingRow, SumRow} from "../../shoppingcart/SummaryRow";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.secondary.dark,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > *": {
            color: theme.palette.secondary.contrastText,
        }
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    table: {
        marginBottom: 10
    }
}));

export interface OrderCartProps {
    confirmationCart: ConfirmationCart;
}

export const OrderCart: FC<OrderCartProps> = ({confirmationCart}) => {
        const {t} = useTranslation();
        const classes = useStyles(undefined);
        const push = useHistoryPushNew();
        const stockedItems: ShoppingCartItem[] = confirmationCart.stockedItems;
        const defaultShippingText = confirmationCart.shippingDescription;
        const shippingText: string = t(`shipping-name-${confirmationCart.shippingName}`, defaultShippingText) + " " + extractShippingTypeDetail();
        const mmzLimitPrice = configQuery.useConfig('mmzLimitPrice');

        function extractShippingTypeDetail() {
            if (confirmationCart.numberOfPackages === 1) {
                return t("order-cart-shipping-weight", {value: confirmationCart.packageWeight});
            }
            return t("order-cart-packages", {value: confirmationCart.numberOfPackages});
        }

        return (
            <Fragment>
                <TableContainer className={classes.table}>
                    <Paper className={classes.paper} variant="outlined">
                        <Table>
                            <TableBody>
                                <TableRow className={classes.header}>
                                    <TableCell>{t("shopping-cart-header-cell-product")}</TableCell>
                                    <TableCell align="right">{t("shopping-cart-header-cell-unit-price")}</TableCell>
                                    <TableCell align="right">{t("shopping-cart-header-cell-amount")}</TableCell>
                                    <TableCell colSpan={3}
                                               align="right">{t("shopping-cart-header-cell-total-price")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                {
                                    stockedItems.map(item => <OrderCartItemRow key={item.slug} item={item}
                                                                               onPushHistory={push}/>)
                                }

                                <SumRow label={t("shopping-cart-subtotal")} value={confirmationCart.itemTotal}/>
                                {confirmationCart.mmzPrice ?
                                    <AddingRow cypressLabel={'order-cart-Mindermengenzuschlag'}
                                               warningText={t("shopping-cart-min-value", {mmz: mmzLimitPrice})}
                                               label={t("shopping-cart-mmz")} value={confirmationCart.mmzPrice}/> : null}
                                {confirmationCart.shippingName ?
                                    <AddingRow label={shippingText} cypressLabel={'order-cart-shipping'}
                                               value={confirmationCart.shippingPrice}/> : null}
                                <NotForTenant>
                                    <SumRow cypressLabel={'order-cart-Nettosumme'} label={t("shopping-cart-net-sum")}
                                            value={confirmationCart.netTotalPrice}/>
                                </NotForTenant>

                                {confirmationCart.systemRebate?.discount ? <SubtractingRow
                                    label={t("shopping-cart-systemRebate", {
                                        discount: confirmationCart.systemRebate.discount,
                                        systemName: confirmationCart.systemRebate.rebateSystemName
                                    })} value={confirmationCart.systemRebate.value}/> : null}
                                {confirmationCart.voucherRebate ? <SubtractingRow
                                    label={t("voucher-cart-row", {voucherCode: confirmationCart.voucher.code})}
                                    value={confirmationCart.voucherRebate}/> : null}
                                {confirmationCart.systemRebate?.discount || confirmationCart.voucherRebate ?
                                    <SumRow cypressLabel={'order-cart-rebated-total'} label={t("rebated-total")}
                                            value={confirmationCart.rebatedTotal}/> : null}

                                <NotForTenant>
                                    {!!confirmationCart.vatAmount &&
                                    <AddingRow cypressLabel={'order-cart-vat'} label={t("shopping-cart-ust")}
                                               value={confirmationCart.vatAmount}/>
                                    }
                                </NotForTenant>
                                <SumRow cypressLabel={'order-cart-overall-sum'} label={t("order-cart-overall-sum")}
                                        value={confirmationCart.totalPrice}/>
                            </TableBody>
                        </Table>
                    </Paper>
                </TableContainer>
            </Fragment>
        );
    }
;

export default OrderCart;
