import motorService from "../../../../service/MotorService";
import motorStore from "./MotorStore";

export class MotorActions {
    private readonly store = motorStore;
    private readonly service = motorService;

    public async load(company: string) {
        if (this.shouldSkipLoading(company)) {
            return
        }

        try {
            this.store.update({
                loading: true,
                error: null
            });
            const result = await this.service.findAllByCompany(company);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(next: string) {
        const {company, error} = this.store.getValue();

        return company === next && !error;
    }
}

export default new MotorActions();
