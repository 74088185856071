import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import AppLink from "../theme/AppLink";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    link: {
        position: "relative",
        display: "block",
        color: theme.palette.secondary.dark,
        background: theme.palette.secondary.main,
        padding: "5px 10px 5px 5px",
        transform: "skew(-0deg, 0deg)",
        transformOrigin: "bottom left",
        "&:hover": {
            textDecoration: "none",
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
        }
    },
    transform: {
        fontWeight: "bold",
        transform: "skew(-30deg, 0deg)",
        borderLeftStyle: "solid",
        borderLeftColor: theme.palette.secondary.light,
        borderRightColor: theme.palette.secondary.light,
        borderLeftWidth: "1px",
        borderRightWidth: "1px"

    }
}));

export interface BreadCrumbItemProps {
    to: string;
    active: boolean;
}

export const BreadCrumbItem: FC<BreadCrumbItemProps> = ({to, active, ...props}) => {
    const classes = useStyles(props);
    return active ? (
        <div className={classes.transform}>
            <AppLink color="secondary" to={to} {...props} className={classes.link}/>
        </div>
    ) : (
        <div className={classes.transform}>
            <Typography component={'span'} color="textPrimary" {...props}/>
        </div>
    );
};

export default BreadCrumbItem;
