import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Vehicle from "../../model/Vehicle";
import {VehicleFilter} from "../../service/VehicleService";

export type FilterState = VehicleFilter | {
    company: null;
    group: null;
}

export type ID = string;
export type Entity = Vehicle;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    filter: FilterState
}

export function createInitialFilterState(): FilterState {
    return {
        company: null,
        group: null
    };
}

@StoreConfig({
    name: "vehicle",
    idKey: "slug"
})
export class VehicleStore extends EntityStore<State> {
    constructor() {
        super({filter: createInitialFilterState()});
    }
}

export default new VehicleStore();
