import {QueryConfig} from "@datorama/akita";
import {useMemo} from "react";
import {useObservable} from "../../../common/useObservable";
import {HookQueryEntity} from "../../HookQueryEntity";
import returnFilesStore, {ReturnFilesState} from "./ReturnFilesStore";

@QueryConfig({})
export class ReturnFilesQuery extends HookQueryEntity<ReturnFilesState> {
    private readonly files = this.select('files');

    constructor() {
        super(returnFilesStore);
    }

    public useValue<K extends keyof ReturnFilesState>(key: K): ReturnFilesState[K] {
        const mapper = useMemo(() => (state: ReturnFilesState) => state[key], [key]);
        const observable = useMemo(() => this.select(mapper), [mapper]);
        const current = useMemo(() => mapper(this.getValue()), [mapper]);
        return useObservable(observable, current);
    }

    public getFiles(): File[] {
        const current = this.getValue().files;
        return useObservable(this.files, current);
    }
}

export default new ReturnFilesQuery();
