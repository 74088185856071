import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import React, {FC, Fragment} from "react";
import itemActions from "../../state/categoryPage/item/ItemActions";
import itemQuery from "../../state/categoryPage/item/ItemQuery";
import {Entity} from "../../state/categoryPage/item/ItemStore";
import PaginationActions from "./PaginationActions";
import ItemRow from "../../common/ItemRow";

export const PagedItemList: FC = () => {
    const loading = itemQuery.useIsLoading();
    const {page, size} = itemQuery.useItemFilter();
    const items = itemQuery.useAll();
    const count = itemQuery.useCount();

    function setPage(newPage: number) {
        itemActions.setPage(newPage).catch(error => console.log(error));
    }

    function setSize(newSize: number) {
        itemActions.setSize(newSize).catch(error => console.log(error));
    }

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <PagedItemListPure
                items={items}
                page={page}
                size={size}
                count={count}
                setPage={setPage}
                setSize={setSize}
            />
        );
    }
};

export default PagedItemList;

const useStyles = makeStyles(theme => ({
    fillWidth: {
        width: "100%"
    },
    table: {
        marginTop: theme.spacing(1),
    },
    paginationContainer: {
        display: "flex",
        alignItems: "left",
        padding: "0.25vw",
        "& button": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "0.3vw",
            margin: "0.25vw"
        }
    },

}));

export interface PagedItemProps {
    items: Entity[];
    page: number;
    size: number;
    count: number;
    setPage: (newPage: number) => void;
    setSize: (newSize: number) => void;
}

export const PagedItemListPure: FC<PagedItemProps> = ({items, page, size, count, setPage, setSize}) => {
    const classes = useStyles(undefined);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setSize(parseInt(event.target.value, 10));
    };

    return (
        <Fragment>
            <TableContainer component={Paper} className={classes.table}>
                <Table className={classes.fillWidth}>
                    <TableBody className={classes.fillWidth}>
                        {items.map(item => <ItemRow key={item.slug} item={item}/>)}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PaginationActions}
                className={classes.paginationContainer}
            />
        </Fragment>
    );
};


