import AuthorizedHttpService from "../../service/AuthorizedHttpService";

export class TurnoverService extends AuthorizedHttpService {
    public async getTurnover(): Promise<{turnover: number}> {
        console.log('lel!');
        return this.get(`/turnover`);
    }

    public async getDiffToNextRebateGroup(): Promise<{diff: number, systemAssigned: boolean}> {
        return this.get(`/turnover/rebate/levels/next`);
    }
}

export default new TurnoverService()
