import React, {FC} from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface TurnoverComponentProps {
    turnover: number,
    currencySymbol: string;
    label: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        minWidth: "100%",
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    label: {
        fontSize: "large",
    },
    value: {
        fontSize: "large",
        color: theme.palette.primary.main,
    }
}))

export const CartTurnoverInfoComponent: FC<TurnoverComponentProps> = ({turnover, currencySymbol, label}) => {
    const classes = useStyles();

    return <div className={classes.container}>
        {<Typography className={classes.label} component="span">{label}</Typography>}
        <span className={classes.value} data-cy="turnover-value">{turnover.toFixed(2)}&nbsp;{currencySymbol}</span>
    </div>
}