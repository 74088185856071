import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import ContentSubHeader from "../app/component/ContentSubHeader";
import Item from "../app/component/item/Item";
import ProductCategoryList from "../app/component/vehicle/ProductCategoryList";
import Vehicle from "../app/model/Vehicle";
import NotFoundError from "../app/service/error/NotFoundError";
import vehicleService from "../app/service/VehicleService";
import categoryActions from "../app/state/category/CategoryActions";
import {useItem} from "../app/state/categoryPage/item/useItem";
import cartQuery from "../app/state/preorder/cart/CartQuery";
import sectionActions from "../app/state/section/SectionActions";
import vehicleActions from "../app/state/vehicle/VehicleActions";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";
import AppLink from "../app/component/theme/AppLink";
import getItemPath from "../app/common/route/getItemPath";

interface Params {
    section: string;
    vehicle: string;
    item: string;
}

export const VehicleItemsPage: FC = () => {
    const {t} = useTranslation();
    const {section, vehicle, item} = useParams<Params>();
    const [vehicleData, setVehicleData] = useState<Vehicle | null>(null);
    const [itemData, error] = useItem(item);
    const breadcrumbs: Breadcrumb[] = [];
    const isPreorderOpen = cartQuery.useIsOpen();

    if (vehicleData && itemData) {
        let sectionPath = "";
        const vehiclesPrefix = "fahrzeuge";
        const vehicleGroup = vehicleData.vehicleGroup;
        const section = vehicleGroup.section;
        if (section) {
            sectionPath = `/${section.slug}`;
            breadcrumbs.push({
                text: t("vehicle-group-breadcrumb-text"),
                to: `${sectionPath}/${vehiclesPrefix}/`
            })

        }
        breadcrumbs.push(
            {
                text: vehicleGroup.name,
                to: `${sectionPath}/${vehiclesPrefix}/${vehicleGroup.slug}/`
            },
            {
                text: vehicleData.company.name,
                to: `${sectionPath}/${vehiclesPrefix}/${vehicleGroup.slug}/${vehicleData.company.slug}`
            },
            {
                text: vehicleData.name,
                to: `${sectionPath}/fahrzeug/${vehicleData.slug}.html`
            },
            {
                text: itemData.itemNumber,
                to: `${sectionPath}/fahrzeug/${vehicleData.slug}/${itemData.slug}.html`
            }
        );
    }

    useEffect(() => {
        vehicleService.read(vehicle)
            .then(setVehicleData)
            .catch(() => {
            })
        ;
    }, [vehicle]);

    useEffect(() => {
        categoryActions.loadForVehicle(section, vehicle).catch(error => console.log(error));
    }, [section, vehicle]);


    useEffect(() => {
        vehicleActions.select(vehicle).catch(error => console.log(error));
    }, [vehicle]);

    useEffect(() => {
        sectionActions.select(section).catch(error => console.log(error));
    }, [section]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs}
                               data-cy="vehicle-title" header={vehicleData ? `${vehicleData.company.name} ${vehicleData.name}` : ''}></ContentHeader>
                <Grid container>
                    <Grid item md={3}>
                        <ProductCategoryList/>
                    </Grid>
                    <Grid item md>
                        {itemData ?
                            <Fragment>
                                <ContentSubHeader>{itemData.company?.name} <AppLink style={{fontWeight: 'bold'}} to={getItemPath(itemData.slug)}>{itemData.itemNumber}</AppLink></ContentSubHeader>
                                <ContentSubHeader data-cy="page-subtitle">{itemData.name}</ContentSubHeader>
                                <Item item={itemData} preorder={isPreorderOpen}/>
                            </Fragment>
                            : <CircularProgress/>
                        }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
};

export default VehicleItemsPage;
