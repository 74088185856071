import React, {FC} from "react";
import VehicleItem from "../../../model/VehicleItem";
import ItemRow from "../../../common/ItemRow";
import {CompanySlug} from "../../../model/Company";

export interface VehicleListItemProps {
    item: VehicleItem;
    getLink: (item: VehicleItem) => string
}

export const VehicleListItem: FC<VehicleListItemProps> = ({item, getLink}) => {
    return (
        <ItemRow
            altLinkTarget={getLink(item)}
            item={{...item, company: {slug: item.companyName as CompanySlug, name: item.companyName? item.companyName: ''}}}/>
    );
};

export default VehicleListItem;
