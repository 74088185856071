import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export const useSearchParams = (): { [key: string]: string; } => {
    const location = useLocation();
    return useMemo(() => {
        const searchParams = new URLSearchParams(location.search)
        const result: { [key: string]: string; } = {};
        searchParams.forEach((value, key) => result[key] = value);
        return result;
    }, [location.search]);
}

export default useSearchParams;