import React, {FC, Fragment, useState} from "react";
import AddingForm from "./AddingForm";
import CsvForm from "./CsvForm";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button} from "@material-ui/core";
import {ArrowLeftRounded, ArrowRightRounded} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    collapsed: {
        display: "none"
    },
    visible: {
        display: "block"
    },
    container: {
        margin: "5px"
    }
})

export const  AddToCartMethods: FC = () => {
    const classes = useStyles(undefined);

    const [formVisible, setFormVisible] = useState(false)
    const [csvVisible, setCsvVisible] = useState(false)

    const toggleForm = () => {
        setCsvVisible(false);
        setFormVisible(!formVisible);
    }
    const toggleCsv = () => {
        setFormVisible(false);
        setCsvVisible(!csvVisible);
    }

    return <Fragment>
        <Grid direction="column"
              className={classes.container}
              container
              spacing={4}>

            <Grid container direction="row">
                <Grid item md={4}>
                    <Button onClick={toggleForm} id={"button-toggle-form"}>
                        {
                            formVisible &&
                            <Fragment><Typography component={"h1"} variant={"h6"} color={"secondary"}>Eingabe via
                                Formular</Typography> <ArrowLeftRounded color={"secondary"}/></Fragment>
                        }
                        {
                            !formVisible &&
                            <Fragment><Typography component={"h1"} variant={"h6"} color={"primary"}>Eingabe via
                                Formular</Typography> <ArrowRightRounded color={"secondary"}/></Fragment>
                        }
                    </Button>
                </Grid>
                <Grid item className={formVisible ? classes.visible : classes.collapsed}>
                    <AddingForm/>
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item md={4}>
                    <Button onClick={toggleCsv} id={"button-toggle-form"}>
                        {
                            csvVisible && <Fragment><Typography component={"h1"} variant={"h6"} color={"secondary"}>CSV
                                Import</Typography> <ArrowLeftRounded color={"secondary"}/></Fragment>
                        }
                        {
                            !csvVisible && <Fragment><Typography component={"h1"} variant={"h6"} color={"primary"}>CSV
                                Import</Typography> <ArrowRightRounded color={"secondary"}/></Fragment>
                        }
                    </Button>
                </Grid>

                <Grid item className={csvVisible ? classes.visible : classes.collapsed}>
                    <CsvForm/>
                </Grid>
            </Grid>
        </Grid>
    </Fragment>
}

export default AddToCartMethods;
