import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {ReturnFile} from "../../../service/returns/ReturnFilesService";

export type Entity = ReturnFile;
export type ID = number;

export interface ReturnFilesState extends EntityState<Entity, ID> {
    pristine: boolean;
    success: boolean;
    files: File[];
}

export function createReturnFilesInitialState(): Partial<ReturnFilesState> {
    return {
        pristine: true,
        success: false,
        files: []
    };
}

@StoreConfig({
    name: "returns.files"
})
export class ReturnFilesStore extends EntityStore<ReturnFilesState> {
    constructor() {
        super(createReturnFilesInitialState());
    }
}

export default new ReturnFilesStore();
