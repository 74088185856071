import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import returnFilesQuery from "../../../app/state/returns/files/ReturnFilesQuery"
import returnFilesActions from "../../../app/state/returns/files/ReturnFilesActions"

export const ReturnItemTable: FC = () => {
    const {t} = useTranslation();
    const files = returnFilesQuery.useAll();
    const isLoading = returnFilesQuery.useIsLoading();

    if (isLoading) {
        return (<CircularProgress/>);
    } else if (files.length) {
        return (<React.Fragment>
            <h4>{t('returns-files-label')}</h4>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("returns-filename-label")}</TableCell>
                            <TableCell>{t("returns-added-at-label")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map(file => (
                            <Fragment>
                                <TableRow key={file.id}>
                                    <TableCell>{file.name}</TableCell>
                                    <TableCell>{moment(file.addedAt).format(t("date-time-format"))}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => returnFilesActions.removeFile(file.id)}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </Fragment>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>);
    }
    return null;
}

export default ReturnItemTable;