import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import ProductCategoryList from "../../app/component/motor/ProductCategoryList";
import VehicleItemTree from "../../app/component/vehicle/itemtree/VehicleItemTree";
import {GridDefaultSpacing} from "../../app/config/Config";
import Motor from "../../app/model/motor/Motor";
import NotFoundError from "../../app/service/error/NotFoundError";
import motorService from "../../app/service/MotorService";
import categoryActions from "../../app/state/category/CategoryActions";
import categoryQuery from "../../app/state/category/CategoryQuery";
import motorActions from "../../app/state/motor/MotorActions";
import motorItemActions from "../../app/state/motoritem/MotorItemActions"
import motorItemQuery from "../../app/state/motoritem/MotorItemQuery"
import sectionActions from "../../app/state/section/SectionActions";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";
import {useRememberScrollY} from "../../app/component/hooks/RememberScrollY";

interface Params {
    section: string;
    slug: string;
    category: string;
}

export const MotorItemsPage: FC = () => {
    const {t} = useTranslation();
    const {section, slug, category} = useParams<Params>();
    const items = motorItemQuery.useAll();
    const loading = motorItemQuery.useIsLoading();
    const error = motorItemQuery.useError();
    const [motor, setMotor] = useState<Motor | null>(null);
    const categoryData = categoryQuery.useActive();
    const [breadcrumbs, setBreadCrumbs] = useState<Breadcrumb[]>([]);
    const isEmpty = items.length === 0;

    useEffect(() => {
        if(category && !categoryData) {
            categoryActions.loadForMotor(section, slug).then(() => categoryActions.select(category));
        }
    }, [category, section, slug, categoryData]);

    // Build breadcrumbs
    useEffect(() => {
        if(!motor || !categoryData) {
            return;
        }
        const section = motor.section;
        const company = motor.company;
        setBreadCrumbs( [
            {
                text: section.name,
                to: `/${section.slug}/motoren/`
            },
            {
                text: t("motor-breadcrumb-text"),
                to: `/${section.slug}/motoren/`
            },
            {
                text: company.name,
                to: `/${section.slug}/motoren/${company.slug}`
            },
            {
                text: motor.name,
                to: `/${section.slug}/motor/${motor.slug}/`
            },
            {
                text: categoryData.name,
                to: `/${section.slug}/motor/${motor.slug}/${categoryData.slug}/`
            }]
        );
    }, [motor, categoryData, t]);


    useEffect(() => {
        motorItemActions.findAll(slug, category).catch(error => console.log(error));
    }, [slug, category]);

    useEffect(() => {
        motorService.read(slug)
            .then(setMotor)
            .catch(() => {
            })
        ;
    }, [slug]);

    useEffect(() => {
        if (motor) {
            sectionActions.select(motor.section.slug).catch(error => console.log(error));
        }
    }, [motor]);

    useEffect(() => {
        if (motor) {
            categoryActions.loadForMotor(motor.section.slug, motor.slug).catch(error => console.log(error));
        }
    }, [motor]);

    useEffect(() => {
        categoryActions.select(category);
    }, [category]);

    useEffect(() => {
        motorActions.select(slug);
    }, [slug]);

    useRememberScrollY(!loading && !!breadcrumbs.length && !!items && !!items.length)

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs}
                               data-cy="page-title" header={motor ? `${motor.company.name} ${motor.name}` : ''}></ContentHeader>
                <Grid container spacing={GridDefaultSpacing}>
                    <Grid item md={3}>
                        <ProductCategoryList/>
                    </Grid>
                    <Grid item md>
                        <Typography
                            variant="h4"
                            component="h2"
                            gutterBottom
                        >{categoryData?.name}</Typography>
                        {loading ? <CircularProgress/> :
                            <Fragment>
                                {isEmpty && <span>{t("vehicle-items-list-empty")}</span>}
                                {!isEmpty && <VehicleItemTree items={items} categories={[]}/>}
                            </Fragment>
                        }
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
};

export default MotorItemsPage;
