import List from "@material-ui/core/List";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../app/component/ContentHeader";
import ListItemLink from "../app/component/ListItemLink";
import Reference from "../app/model/Reference";
import NotFoundError from "../app/service/error/NotFoundError";
import explodedViewService from "../app/service/ExplosionDrawingService";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

export const ExplodedViewsPage: FC = () => {
    const {t} = useTranslation();
    const [error, setError] = useState<any>();
    const [drawings, setDrawings] = useState<Reference[]>([]);
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("explosion-drawings-title"),
            to: "/explosionszeichnungen.html"
        }
    ];

    useEffect(() => {
        explodedViewService.findAll()
            .then(setDrawings)
            .catch(setError)

    }, [])


    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("explosion-drawings-title")}></ContentHeader>
                <List>
                    {drawings.map(({name, slug}) => (
                        <ListItemLink
                            data-cy="drawing-item"
                            key={slug}
                            primary={name}
                            path={`/explosionszeichnung/${slug}.html`}
                            disabled={false}
                        />
                    ))}
                </List>
            </Fragment>
        );
    }
};

export default ExplodedViewsPage;
