import {EntityState, EntityStore, StoreConfig, transaction} from "@datorama/akita";
import SearchResult from "../../model/search/SearchResult";

export type ID = string;

export type Entity = SearchResult<ID>;

export interface State extends EntityState<Entity, ID> {
    query: string;
    loading: boolean;
}

export function createInitialState(): Partial<State> {
    return {
        query: "",
        loading: false
    };
}

@StoreConfig({name: "search"})
export class SearchStore extends EntityStore<State> {

    constructor() {
        super(createInitialState());
    }

    akitaPreAddEntity(entity: Entity): Entity {
        return {
            // @ts-ignore
            id: `id-${entity.type}-${entity.slug}`,
            ...entity
        };
    }

    @transaction()
    public loading(query: string): void {
        this.setLoading(true);
        this.setError(null);
        this.update({query});
    }

    @transaction()
    public success(query: string, result: SearchResult[]): void {
        if (this.isCurrentQuery(query)) {
            this.setLoading(false);
            this.set(result);
        }
    }

    @transaction()
    public fail<T>(query: string, error: T): void {
        if (this.isCurrentQuery(query)) {
            this.setLoading(false);
            this.setError(error);
            this.set([])
        }
    }

    public isCurrentQuery(query: string): boolean {
        return this.getValue().query === query;
    }
}

export default new SearchStore();
