import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import VehicleGroup, {VehicleGroupSlug} from "../../../model/VehicleGroup";

export interface VehicleGroupState extends EntityState<VehicleGroup, VehicleGroupSlug>, ActiveState<VehicleGroupSlug> {
}

@StoreConfig({
    name: "vehicleselectionshortcut.vehiclegroup",
    idKey: "slug"
})
export class VehicleGroupStore extends EntityStore<VehicleGroupState> {
}

export default new VehicleGroupStore();
