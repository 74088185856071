import {Store, StoreConfig} from "@datorama/akita";

export interface AttributeValueState {
    [key: string]: string[]
}

export function createInitialState(): Partial<AttributeValueState> {
    return {};
}

@StoreConfig({ name: "filter.attribute.values" })
export class AttributeValueStore extends Store<AttributeValueState> {
    constructor() {
        super(createInitialState());
    }
}

export default new AttributeValueStore();
