import {QueryEntity} from "@datorama/akita";
import {EntityStore} from "@datorama/akita/lib/entityStore";
import {EntityState, getEntityType, getIDType} from "@datorama/akita/lib/types";
import {useObservable} from "../common/useObservable";
import {throttledLoading} from "./throttleLoading";

export abstract class HookQueryEntity<S extends EntityState, EntityType = getEntityType<S>, IDType = getIDType<S>> extends QueryEntity<S, EntityType, IDType> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();
    public readonly activeId = this.selectActiveId();
    public readonly active = this.selectActive();
    public readonly state = this.select();

    constructor(
        protected store: EntityStore<S>
    ) {
        super(store);
    }

    public useAll(): EntityType[] {
        return useObservable(this.all, this.getAll());
    }

    public useActiveId(): S["active"] | undefined {
        return useObservable(this.activeId, this.getActiveId());
    }

    public useActive(): EntityType | null {
        // @ts-ignore
        return useObservable(this.active, this.getActive());
    }

    public useIsLoading(): S["loading"] {
        return useObservable(this.isLoading, this.getValue().loading);
    }

    public useError(): S["error"] {
        return useObservable(this.error, this.getValue().error);
    }

    public useState(): S {
        return useObservable(this.state, this.getValue());
    }
}
