import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import moment, {Moment} from "moment";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ContentHeader from "../../app/component/ContentHeader";
import {PagedItemListPure} from "../../app/component/latest/PagedItemList";
import ListItemLink from "../../app/component/ListItemLink";
import FilterResult from "../../app/model/FilterResult";
import Item from "../../app/model/item/Item";
import NotFoundError from "../../app/service/error/NotFoundError";
import itemService from "../../app/service/ItemService";
import pageSize from "../../app/state/common/PageSize";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";

interface Params {
    year?: string;
    month?: string;
}

export const LatestItemsPage: FC = () => {
    const {year, month} = useParams<Params>();
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const [result, setResult] = useState<FilterResult<Item>>();
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(pageSize.load());
    const [sort, setSort] = useState<string>("name");
    const [error, setError] = useState<any>();
    const selected = getMoment(year, month);
    const monthName = selected.format("MMMM");

    useEffect(() => {
        const montToLoad = getMoment(year, month);
        setResult(undefined);
        itemService.findForMonth(montToLoad.toDate(), page, size, sort)
            .then(setResult)
            .catch(setError)
        ;
    }, [year, month, page, size, sort]);

    useEffect(() => {
        pageSize.save(size);
    }, [size])

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return <Fragment>
            <ContentHeader data-cy="page-title" header={t("latest-items-page-title", {monthName})}></ContentHeader>
            <Grid container alignItems="flex-start">
                <Grid item md={3}>
                    <Months/>
                </Grid>
                <Grid container item md>
                    <Grid container item xs={12} alignItems="center">
                        <Grid item sm xs={12} className={classes.sortHint}>
                            Sortierung:
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <FilterButton
                                sort={sort}
                                property="name"
                                onClick={setSort}
                                children="Bezeichnung"
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <FilterButton
                                sort={sort}
                                property="itemNumber"
                                onClick={setSort}
                                children="Artikelnummer"
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <FilterButton
                                sort={sort}
                                property="publishedAt,desc"
                                onClick={setSort}
                                children="Datum"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!result &&
                        <CircularProgress/>
                        }
                        {result &&
                        <Fragment>
                            {result.count === 0 &&
                            <Typography
                                variant="body1"
                                component="h2"
                                gutterBottom
                            >
                                {t("category-filter-page-empty")}
                            </Typography>
                            }
                            {result.count > 0 &&
                            <PagedItemListPure
                                items={result.items}
                                count={result.count}
                                page={page}
                                size={size}
                                setPage={setPage}
                                setSize={setSize}
                            />
                            }
                        </Fragment>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>;
    }
};

function getMoment(year: string | undefined, month: string | undefined): Moment {
    let selected = moment.utc();
    if (year && month) {
        try {
            selected = moment.utc().year(Number.parseInt(year)).month(Number.parseInt(month) - 1);
        } catch (e) {
        }
    }
    return selected.startOf("month");
}

const useStyles = makeStyles(theme => ({
    sortHint: {
        fontStyle: "italic"
    }
}));

const Months: FC = () => {
    const {t} = useTranslation();
    const MonthCount = 6;
    const months = lastMonths(MonthCount);
    return (
        <Fragment>
            <Typography variant="h5" component="h2">{t("latest-items-nav-title")}</Typography>
            <List>
                {months.map((month: Moment) => (
                    <ListItemLink
                        key={month.format("MMMM YYYY")}
                        primary={month.format("MMMM YYYY")}
                        disabled={false}
                        path={month.format("[/produkte/neu/]YYYY/MM[.html]")}
                    />
                ))}
            </List>
        </Fragment>
    );
}

function lastMonths(count: number) {
    const months: Moment[] = []

    for (let i = 0; i < count; ++i) {
        months.push(moment.utc().subtract(i, "months").startOf("month"))
    }

    return months;
}

const FilterButton: FC<any> = ({sort, property, onClick, ...props}) => {
    return (
        <Button
            variant="contained"
            color={sort === property ? "primary" : "default"}
            fullWidth
            onClick={() => onClick(property)}
            {...props}
        />
    );
}

export default LatestItemsPage;
