import {SCROLL_CONTAINER} from "../../../layout/Layout";

export function storeY(page: string) {
    localStorage.setItem(page, '' +  document.getElementById(SCROLL_CONTAINER)!.scrollTop)
}

export function location(): string {
    return window.location.toString();
}

export function scrollToYForPage(page: string) {
    const tmp = localStorage.getItem(page)
    document.getElementById(SCROLL_CONTAINER)!.scrollTo({left: 0, top: tmp ? +tmp : 0, behavior: 'smooth'})
}

export function scrollToY(y: number) {
    document.getElementById(SCROLL_CONTAINER)!.scrollTo({top: y ? y : 0, behavior: 'smooth'})
}

export default {
    scrollToYForPage,
    scrollToY,
    storeY,
    location
}
