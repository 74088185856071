import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";
import ContentHeader from "../../app/component/ContentHeader";
import {PreorderCategoryList} from "../../app/component/preorder/PreorderCategoryList";
import PreorderPagedItemList from "../../app/component/preorder/PreorderPagedItemList";
import PreorderSubCategoryList from "../../app/component/preorder/PreorderSubCategoryList";
import PreorderSubSubCategoryList from "../../app/component/preorder/PreorderSubSubCategoryList";
import NotFoundError from "../../app/service/error/NotFoundError";
import preorderService from "../../app/service/PreorderService";
import categoryQuery from "../../app/state/preorder/category/CategoryQuery";
import preorderActions from "../../app/state/preorder/PreorderActions";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";
import useTenantSwitch from "../../app/common/useTenantSwitch";

export interface Params {
    section: string;
    category: string;
}

export const CategoryPage: FC = () => {
    const {t} = useTranslation();
    const tenantSwitch = useTenantSwitch();
    const {section, category} = useParams<Params>();
    const [error, setError] = useState<any>(null);
    const push = useHistoryPushNew();
    const year = moment().format(t("year-format"));
    const pageTitle = t("preorder-page-title", {year});
    const activeCategory = categoryQuery.useActive();
    const {hash} = useLocation() || "#";
    const subcategory = hash.substr(1);
    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("preorder-main-breadcrumb"),
            to: `/vor-teile-wochen/${section}.html`,
        },
        {
            text: section,
            to: `/vor-teile-wochen/${section}.html`,
        }
    ];

    if (activeCategory) {
        breadcrumbs.push({
            text: activeCategory.name,
            to: `/vor-teile-wochen/${section}/${activeCategory.slug}.html`
        })
    }

    useEffect(() => {
        tenantSwitch.doForBaseShop(() =>
            preorderService.findOpen()
                .catch(error => {
                    if (error instanceof NotFoundError) {
                        push("/vor-teile-wochen/code.html");
                    } else {
                        throw error;
                    }
                })
                .catch(setError)
        )
    }, [push, tenantSwitch])

    useEffect(() => {
        if (subcategory) {
            preorderActions.navigateToSubCategory(section, category, subcategory).catch(setError);
        } else {
            preorderActions.navigateToCategory(section, category).catch(setError);
        }
    }, [section, category, subcategory]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={pageTitle}></ContentHeader>
            <Grid container>
                <Grid item md={3} lg={2}>
                    <PreorderCategoryList/>
                </Grid>

                <Grid item md={3} lg={2}>
                    <PreorderSubCategoryList/>
                </Grid>
                <Grid item md>
                    <PreorderSubSubCategoryList/>
                    <PreorderPagedItemList/>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default CategoryPage;
