import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {CategorySlug} from "../../../model/Category";
import Reference from "../../../model/Reference";
import {SectionSlug} from "../../../model/Section";
import categoryQuery from "../../../state/categoryPage/filter/category/CategoryQuery";
import ListItemLink from "../../ListItemLink";
import useCategorySelection from "./useCategorySelection";

export const CategoryList: FC<any> = (props) => {
    const {categories, loading, section} = useCategorySelection();
    const {slug} = categoryQuery.useCategory() || {slug: null};

    function getPath(category: string, section?: string | null) {
        return `/${section || ""}/produkte/${category}/`;
    }

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <CategoryListPure
                section={section}
                current={slug}
                categories={categories}
                getPath={getPath}
                {...props}
            />
        );
    }
};

export default CategoryList;

export interface CategoryListProps {
    section?: SectionSlug | null;
    current: CategorySlug | null;
    categories: Reference[]

    getPath(category: string, section?: string | null): string;
}

export const CategoryListPure: FC<CategoryListProps> = ({categories, current, section, getPath}) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <Typography variant="h6">{t("category-list-title")}</Typography>
            <List>
                {categories.map(({name, slug}) => (
                    <ListItemLink
                        data-cy="category-title"
                        key={slug}
                        primary={name}
                        disabled={slug === current}
                        path={getPath(slug, section)}
                    />
                ))}
            </List>
        </Fragment>
    );
};

