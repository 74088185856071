import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../app/common/breadcrumb/Breadcrumb";
import getItemPath from "../app/common/route/getItemPath";
import useHistoryPushNew from "../app/common/useHistoryPushNew";
import ContentHeader from "../app/component/ContentHeader";
import Currency from "../app/component/Currency";
import Item from "../app/model/item/Item";
import ItemSlug from "../app/model/item/ItemSlug";
import ShopPrice from "../app/model/shopprice/ShopPrice";
import WatchListItem from "../app/model/watchLIst/WatchListItem";
import ForbiddenError from "../app/service/error/ForbiddenError";
import NotFoundError from "../app/service/error/NotFoundError";
import shoppingCartActions from "../app/state/shoppingCart/ShoppingCartActions";
import watchListActions from "../app/state/watchList/WatchListActions";
import watchListQuery from "../app/state/watchList/WatchListQuery";
import PageForbidden from "../errors/403";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    header: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    grid: {
        "&:hover": {
            background: "#efefef"
        },
        padding: theme.spacing(2)
    },
    item: {
        cursor: "pointer"
    },
    deleteIcon: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    },
    table: {
        marginBottom: 10
    },
    notAvailable: {
        color: theme.palette.text.disabled
    },
    minValue: {
        color: theme.palette.error.light
    },
    image: {
        minWidth: theme.spacing(14),
        cursor: "pointer"
    },
    box: {
        minWidth: theme.spacing(54),
    },
    discounted: {
        textDecoration: "line-through"
    }
}));


export const WatchList: FC = () => {
    const {t} = useTranslation();
    const error = watchListQuery.useError();
    const breadcrumbs: Breadcrumb[] = [];
    const classes = useStyles(undefined);
    const items: WatchListItem[] = watchListQuery.useAll();
    const isEmpty = watchListQuery.useIsEmpty();

    breadcrumbs.push(
        {
            text: t("bookmarks-breadcrumb-text"),
            to: `/shop/merkliste.html`
        }
    );

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error instanceof ForbiddenError) {
        return (<PageForbidden/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("bookmarks-title")}></ContentHeader>
                {isEmpty &&
                <span data-cy="shopping-cart-empty">{t("bookmarks-empty-text")}</span>
                }
                {!isEmpty &&
                <Fragment>
                    <TableContainer component={Paper} className={classes.table}>
                        <Table>
                            <TableBody>
                                <TableRow className={classes.header}>
                                    <TableCell colSpan={2}>{t("shopping-cart-header-cell-product")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-availability")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-unit-price")}</TableCell>
                                    <TableCell>{t("bookmarks-header-cell-actions")}</TableCell>
                                </TableRow>
                                {
                                    items.map((item) => (
                                        <WatchListRow
                                            key={item.slug}
                                            item={item.item}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
                }
            </Fragment>
        );
    }
};

interface WatchListRowProps {
    key: string;
    item: Item;

}

export function getItemPrice(price: ShopPrice, quantity: number) {
    const special = (price.volumeDiscounts || [])
        .filter((special) => quantity >= special.quantity)
        .map(({price}) => price)[0]
    ;
    return special || price.offer || price.clearance || price.wholesalePrice;
}

const WatchListRow: FC<WatchListRowProps> = ({item}: WatchListRowProps) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();
    const {price} = item;
    const offerPrice = price ? getItemPrice(price, 1) : 0;

    function onClickRemoveFromWatchList(slug: ItemSlug) {
        watchListActions.removeItem(slug);
    }

    function onClickOrderFromWatchList(slug: ItemSlug) {
        shoppingCartActions.addItem(item).catch(error => console.log(error))
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           data-cy="shopping-cart-image" className={classes.image}>
                    {
                        item.images && item.images.length > 0 &&
                        <img src={item.images[0].locationSmall}
                             alt={item.images[0].description}/>
                    }
                </TableCell>
                <TableCell onClick={() => push(getItemPath(item.slug))}
                           className={classes.item} data-cy="shopping-cart-text">
                    <Box fontWeight="fontWeightBold" fontSize="h6.fontSize"
                         className={classes.box}>
                        {item.company && item.company.name + " "}
                        <Typography color="secondary" component="span"
                                    variant="inherit"
                                    data-cy="shopping-cart-item-number"
                                    onClick={() => push(getItemPath(item.slug))}>{item.itemNumber}</Typography>
                    </Box>
                    {item.name}
                </TableCell>
                <TableCell data-cy="shopping-cart-availability">
                    {price &&
                    <Typography color="primary" component="p"
                                variant="inherit"
                                data-cy="shopping-cart-item-availability">{t(`order-enum-shop-availability-${price.shopAvailability}`)}</Typography>
                    }
                </TableCell>
                <TableCell className={classes.price} data-cy="shopping-cart-price">
                    {price &&
                    <Fragment>
                        {offerPrice !== price.wholesalePrice &&
                        <Typography className={classes.discounted} variant="subtitle2" component="div">
                            <Currency value={price.wholesalePrice}/>
                        </Typography>
                        }
                        <Typography variant="subtitle2" component="div">
                            <Currency value={offerPrice}/>
                        </Typography>
                        {(price.volumeDiscounts || [])
                            .map(({price, quantity}) =>
                                <Typography key={quantity} variant="subtitle2" component="div">
                                    {t("item-price-volume-discount", {quantity})} <Currency value={price}/>
                                </Typography>
                            )}
                    </Fragment>
                    }
                </TableCell>
                <TableCell data-cy="watchlist-actions">
                    {
                        price?.shopAvailability === 'IN_STOCK' &&
                        <AddShoppingCartIcon onClick={() => onClickOrderFromWatchList(item.slug)} className={classes.deleteIcon}/>
                    }
                    <DeleteIcon data-cy="delete-watchlist-item-icon" onClick={() => onClickRemoveFromWatchList(item.slug)}
                                className={classes.deleteIcon}/>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export default WatchList;
