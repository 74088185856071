import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import companyActions from "../../../state/categoryPage/filter/companies/CompanyActions";
import companyQuery from "../../../state/categoryPage/filter/companies/CompanyQuery";
import itemActions from "../../../state/categoryPage/item/ItemActions";
import itemQuery from "../../../state/categoryPage/item/ItemQuery";
import AutocompleteFilter from "./AutocompleteFilter";

export interface CompanyFilterProps {
    category: string;
}

export const CompanyFilter: FC<CompanyFilterProps> = ({category, ...props}) => {
    const {t} = useTranslation();
    const property = "company.slug";
    const slug = itemQuery.useFilterProperty(property) || null;
    const items = companyQuery.useAllCompanies();
    const value = items.find(item => item.slug === slug) || null;

    useEffect(() => {
        companyActions.load(category).catch(error => console.log(error));
    }, [category]);

    const updateFilter = (value: string | null, type: string) => {
        console.log('filter company: ' + value +  " type: " + type)
        itemActions.setAttribute(type, value).catch(error => console.log(error));
    }

    return <AutocompleteFilter {...props}
                               value={value} options={items}
                               onUpdateFilter={newValue => updateFilter(newValue && newValue.slug, property)}
                               label={t("tires-filter-companies")}
                               getOptionLabel={option => option.name}
    />;
};

export default CompanyFilter;
