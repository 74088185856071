import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React from "react";
import {useTranslation} from "react-i18next";
import {useUniqueId} from "../../common/useUniqueId";
import signupActions from "../../state/signup/SignupActions";
import signupQuery from "../../state/signup/SignupQuery";

export interface Props {
    property: string;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    accept?: string;
}

const useStyles = makeStyles((theme) => ({
    inputFile: {
        display: "none"
    },
    button: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
    }
}));

function SignupFileField({property, label, ...props}: Props) {
    const {t} = useTranslation();
    const id = useUniqueId(property);
    const classes = useStyles(props);
    // @ts-ignore
    const {name} = signupQuery.useValue(property, "") || {name: ""};

    function onChange(event: any) {
        if (event.target.files) {
            // @ts-ignore
            signupActions.update(property, event.target.files[0]);
        }
    }

    return (
        <label htmlFor={id}>
            <input
                {...props}
                id={id}
                className={classes.inputFile}
                name={property}
                type="file"
                onChange={onChange}
            />
            <Button
                className={classes.button}
                variant="contained"
                color="default"
                component="span"
                startIcon={<CloudUploadIcon/>}
            >{t(label)}</Button>
            <Box m={1} component="span">
                <Typography variant="caption">{name}</Typography>
            </Box>
        </label>

    );
}

export default SignupFileField;
