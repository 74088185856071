import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import React, {FC} from "react";
import VehicleGroupModel from "../../model/VehicleGroup";
import sectionQuery from "../../state/section/SectionQuery";
import vehicleGroupQuery from "../../state/vehiclegroup/VehicleGroupQuery";
import ListItemLink from "../ListItemLink";

export const VehicleGroupList: FC = () => {
    const loading = vehicleGroupQuery.useIsLoading();
    const vehicleGroups = vehicleGroupQuery.useAll();
    const currentVehicleGroupSlug = vehicleGroupQuery.useActiveId();
    const currentSectionSlug = sectionQuery.useActiveId();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <VehicleGroupListPure
                currentSectionSlug={currentSectionSlug}
                currentVehicleGroupSlug={currentVehicleGroupSlug}
                vehicleGroups={vehicleGroups}
            />
        );
    }
};

export default VehicleGroupList;

export interface VehicleGroupListProps {
    currentSectionSlug?: string | null;
    currentVehicleGroupSlug?: string | null;
    vehicleGroups: VehicleGroupModel[];
}

export const VehicleGroupListPure: FC<VehicleGroupListProps> = ({currentSectionSlug, currentVehicleGroupSlug, vehicleGroups}) => {
    return (
        <List>
            {vehicleGroups.map(({name, slug}) => (
                <ListItemLink data-cy="vehicle-group-title"
                    key={slug}
                    primary={name}
                    path={`/${currentSectionSlug}/fahrzeuge/${slug}/`}
                    disabled={slug === currentVehicleGroupSlug}
                />
            ))}
        </List>
    );
};
