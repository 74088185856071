import makeStyles from "@material-ui/core/styles/makeStyles";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import React, {FC, useEffect, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {useObservable} from "../../common/useObservable";
import Item from "../../model/item/Item";
import latestItemActions from "../../state/latestItems/LatestItemsActions";
import latestItemQuery from "../../state/latestItems/LatestItemsQuery";
import itemStore from "../../state/latestItems/LatestItemsStore";
import PaginationActions from "../category/PaginationActions";
import LatestItem from "./LatestItem";

export const LatestItemList: FC = () => {
    const initialState = itemStore.getValue();
    const page = useObservable(latestItemQuery.page, initialState.page);
    const size = useObservable(latestItemQuery.size, initialState.size);
    const count = useObservable(latestItemQuery.count, -1);
    const items = useObservable(latestItemQuery.all, []);
    const error = useObservable(latestItemQuery.error, null);

    useEffect(() => {
        latestItemActions.loadLatestItemsForCurrentPage().catch(error => console.log(error));
    }, []);

    return (
        <LatestItemListPure
            items={items}
            page={page}
            size={size}
            count={count}
            error={error}
        />
    );
};

export default LatestItemList;

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(1),
        width: "100%",
        marginTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        color: theme.palette.warning.main,
        fontWeight: 'bolder',
        fontStyle: 'italic',
        fontSize: 'xxx-large',
    },
    footer: {
        width: "100%"
    }
}));

export interface PagedLatestItemsProps {
    items: Item[];
    page: number;
    size: number;
    count: number;
    error: any;
}

export interface ItemPair {
    first: Item;
    second: Item;
}

export const LatestItemListPure: FC<PagedLatestItemsProps> = ({items, page, size, count, error}) => {
    const classes = useStyles(undefined);
    const {t} = useTranslation();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        latestItemActions.setPage(newPage).catch(error => console.log(error));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        latestItemActions.setSize(parseInt(event.target.value, 10)).catch(error => console.log(error));
    };

    return (
        <Fragment>
            <Typography className={classes.header} data-cy={"item-new-item-title"}
                        variant="h6" component="h2">{t("item-new-items-header")}
            </Typography>
            {
                items.map(it => <LatestItem item={it}/>)
            }
            <TablePagination
                className={classes.footer}
                rowsPerPageOptions={[10]}
                component="div"
                count={count}
                rowsPerPage={size}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PaginationActions}
            />
        </Fragment>
    );
};

