import {List} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Reference from "../../model/Reference";
import categoryQuery from "../../state/preorder/category/CategoryQuery";
import subCategoryQuery from "../../state/preorder/subsubcategory/SubCategoryQuery";
import sectionQuery from "../../state/section/SectionQuery";
import ListItemLink from "../ListItemLink";

export const PreorderSubSubCategoryList: FC = () => {
    const categories = subCategoryQuery.useAll();
    const subcategory = subCategoryQuery.useActiveId() || "";
    const loading = subCategoryQuery.useIsLoading();
    const section = sectionQuery.useActiveId() || "";
    const category = categoryQuery.useActiveId() || "";

    if (loading) {
        return (<CircularProgress/>);
    }

    if (!categories.length) {
        return null;
    }

    return (
        <SubCategoryListPure
            section={section}
            category={category}
            categories={categories}
            subcategory={subcategory}
        />
    );
};

export default PreorderSubSubCategoryList;

export interface SubCategoryListProps {
    section: string;
    category: string;
    subcategory: string;
    categories: Reference[];
}

export const SubCategoryListPure: FC<SubCategoryListProps> = ({subcategory, categories, category, section}) => {
    const {t} = useTranslation();
    return (
        <Grid item xs={12}>
            <Typography variant="h5">{t("subcategory-title")}</Typography>
            <List>
                {categories.map(({name, slug}) => (
                    <ListItemLink
                        data-cy="sub-category-title"
                        key={slug}
                        disabled={subcategory === slug}
                        primary={name}
                        path={`/vor-teile-wochen/${section}/${category}.html#${slug}`}
                    />
                ))}
            </List>
        </Grid>
    );
};

