// @ts-ignore
function getLargeImageStyle(positionX, positionY, active, {height, width}, scale) {
  return {
    position: "absolute",
    boxSizing: "border-box",
    display: "block",
    top: 0,
    left: 0,
    transform: `translate(-${width / (scale * 2)}px, -${height / (scale * 2)}px) scale(${scale}) translate(${width / (scale * 2)}px, ${height / (scale * 2)}px) translate(${positionX / scale}px, ${positionY / scale}px)`,
    zIndex: "1",
    visibility: !active ? "hidden" : "visible",
    width: "auto"
  };
}

// @ts-ignore
function getZoomContainerStyle(width, height, inPlace) {
  const style = {
    position: "absolute",
    boxSizing: "border-box",
    pointerEvents: "none",
    width: `${width}px`,
    height: `${height}px`,
    top: "0",
    overflow: "hidden"
  };

  if (inPlace) {
    // @ts-ignore
    style.left = "0px";
  } else {
    // @ts-ignore
    style.left = `${width}px`;
  }

  return style;
}

// @ts-ignore
function getOverlayCenterStyle(width, height, left, top, opacity, transitionSpeed, color, backgroundImage, backgroundImageSize) {
  const backgroundStyle = {};

  if (backgroundImage) {
    // @ts-ignore
    backgroundStyle.backgroundImage = `url("${backgroundImage}")`;
  }

  if (backgroundImageSize) {
    // @ts-ignore
    backgroundStyle.backgroundSize = backgroundImageSize;
  }

  return {
    position: "absolute",
    width: `${width}px`,
    height: `${height}px`,
    left: 0,
    top: 0,
    boxSizing: "border-box",
    transform: `translate(${left}px, ${top}px)`,
    border: `1px solid ${color}`,
    opacity: opacity,
    transition: `opacity ${transitionSpeed}s ease`,
    zIndex: "15",
    pointerEvents: "none",
    ...backgroundStyle
  };
}

// @ts-ignore
function getOverlayTopStyle(width, height, opacity, transitionSpeed, backgroundColor) {
  return {
    backgroundColor: backgroundColor,
    position: "absolute",
    boxSizing: "border-box",
    top: 0,
    left: 0,
    width: `${width}px`,
    height: `${height}px`,
    zIndex: "10",
    transition: `opacity ${transitionSpeed}s ease`,
    opacity: opacity,
    transform: "scale3d(1,1,1)",
    pointerEvents: "none"
  };
}

// @ts-ignore
function getOverlayLeftStyle(width, height, top, opacity, transitionSpeed, backgroundColor) {
  return {
    backgroundColor: backgroundColor,
    position: "absolute",
    boxSizing: "border-box",
    width: `${width}px`,
    top: `${top}px`,
    left: 0,
    height: `${height}px`,
    zIndex: "10",
    transition: `opacity ${transitionSpeed}s ease`,
    opacity: opacity,
    transform: "scale3d(1,1,1)",
    pointerEvents: "none"
  };
}

// @ts-ignore
function getOverlayRightStyle(width, height, top, opacity, transitionSpeed, backgroundColor) {
  return {
    backgroundColor: backgroundColor,
    position: "absolute",
    boxSizing: "border-box",
    top: `${top}px`,
    right: 0,
    width: `${width}px`,
    height: `${height}px`,
    zIndex: "10",
    transition: `opacity ${transitionSpeed}s ease`,
    opacity: opacity,
    transform: "scale3d(1,1,1)",
    pointerEvents: "none"
  };
}

// @ts-ignore
function getOverlayBottomStyle(width, height, top, opacity, transitionSpeed, backgroundColor) {
  return {
    backgroundColor: backgroundColor,
    position: "absolute",
    boxSizing: "border-box",
    top: `${top}px`,
    width: `${width}px`,
    height: `${height}px`,
    zIndex: "10",
    transition: `opacity ${transitionSpeed}s ease`,
    opacity: opacity,
    transform: "scale3d(1,1,1)",
    pointerEvents: "none"
  };
}

export default {
  getLargeImageStyle,
  getZoomContainerStyle,
  getOverlayCenterStyle,
  getOverlayTopStyle,
  getOverlayLeftStyle,
  getOverlayRightStyle,
  getOverlayBottomStyle
};
