import {QueryConfig} from "@datorama/akita";
import {useMemo} from "react";
import {useObservable} from "../../../common/useObservable";
import {isInvoiceNumberInvalid} from "../../../component/form/InvoiceNumberField";
import {AddItemRequest} from "../../../service/returns/ItemService";
import {HookQueryEntity} from "../../HookQueryEntity";
import itemStore, {ItemStore as Store, State} from "./ItemStore";

@QueryConfig({})
export class ItemQuery extends HookQueryEntity<State> {
    constructor(
        protected store: Store = itemStore
    ) {
        super(store);
    }

    public useValue<K extends keyof State>(key: K): State[K] {
        const mapper = useMemo(() => (state: State) => state[key], [key]);
        const observable = useMemo(() => this.select(mapper), [mapper]);
        const current = useMemo(() => mapper(this.getValue()), [mapper]);

        return useObservable(observable, current);
    }

    public getItem(): AddItemRequest {
        const {itemNumber, count, reason, comment, invoiceNumber, invoiceDate, complaintVehicleInfo, complaintVehicleIdNumber, complaintMileage, complaintDetails} = this.getValue();
        return {
            itemNumber, count, reason, comment, invoiceNumber, invoiceDate, complaintVehicleInfo, complaintVehicleIdNumber, complaintMileage, complaintDetails
        }
    }

    public useIsInvalid(): boolean {
        const mapper = this.isInvalid;
        const observable = useMemo(() => this.select(mapper), [mapper]);
        const current = useMemo(() => mapper(this.getValue()), [mapper]);

        return useObservable(observable, current);
    }

    public isInvalid({itemNumber, count, reason, invoiceNumber, invoiceDate, complaintMileage, complaintDetails, complaintVehicleIdNumber, complaintVehicleInfo}: State): boolean {
        return !itemNumber || !count || !reason || isInvoiceNumberInvalid(invoiceNumber) || !invoiceDate
            || (reason === 'QUERY' && (!complaintMileage || !complaintMileage || !complaintDetails || !complaintVehicleIdNumber ));
    }
}

export default new ItemQuery();
