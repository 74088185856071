import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import Vehicle from "../../../model/Vehicle";


export interface VehicleState extends EntityState<Vehicle, string>, ActiveState<string> {
}

@StoreConfig({
    name: "vehicleselectionshortcut.vehicle",
    idKey: "slug"
})
export class VehicleStore extends EntityStore<VehicleState> {
}

export default new VehicleStore();
