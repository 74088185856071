import {transaction} from "@datorama/akita";
import {MinimumSearchInputLength} from "../../config/Config";
import searchService, {SearchService} from "../../service/SearchService";
import searchStore, {SearchStore} from "./SearchStore";

export interface Dependencies {
    searchStore: SearchStore;
    searchService: SearchService;
}

export class Search {
    private readonly minSearchInput = MinimumSearchInputLength;
    private readonly searchStore = searchStore;
    private readonly searchService: SearchService = searchService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public clear(): void {
        this.searchStore.update({
            query: ""
        });
        this.searchStore.set([]);
    }

    @transaction()
    public async input(query: string): Promise<void> {
        this.searchStore.update({
            query
        });

        if (query.length < this.minSearchInput) {
            this.searchStore.set([]);

            return;
        }

        await this.find(query);
    }

    public async find(query: string): Promise<void> {
        try {
            this.searchStore.loading(query);
            const result = await this.searchService.find(query);
            this.searchStore.success(query, result);
        } catch (error) {
            this.searchStore.fail(query, error);
            throw error;
        } finally {
            this.searchStore.setLoading(false);
        }
    }
}

export default new Search();
