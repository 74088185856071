import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import getItemPath from "../../app/common/route/getItemPath";
import useHistoryPushNew from "../../app/common/useHistoryPushNew";
import ContentHeader from "../../app/component/ContentHeader";
import ContentSubHeader from "../../app/component/ContentSubHeader";
import Currency from "../../app/component/Currency";
import {GridDefaultSpacing} from "../../app/config/Config";
import PaymentType from "../../app/model/checkout/PaymentType";
import ShippingType from "../../app/model/checkout/ShippingType";
import {getEnumValue, Order, OrderItem, ShopOrderStatusEnum} from "../../app/model/user/OrderArchive";
import orderArchiveQuery from "../../app/state/orderArchive/OrderArchiveQuery"
import orderArchiveStore from "../../app/state/orderArchive/OrderArchiveStore"

const useStyles = makeStyles(theme => ({
    verticalTop: {
        verticalAlign: "top"
    },
    header: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    grid: {
        "&:hover": {
            background: "#efefef"
        },
        padding: theme.spacing(2)
    },
    item: {
        cursor: "pointer"
    },
    deleteIcon: {
        cursor: "pointer"
    },
    price: {
        whiteSpace: "nowrap"
    },
    table: {
        marginBottom: 10
    },
    notAvailable: {
        color: theme.palette.text.disabled
    },
    minValue: {
        color: theme.palette.error.light
    },
    image: {
        minWidth: theme.spacing(14),
        cursor: "pointer"
    },
    box: {
        minWidth: theme.spacing(54),
    },
    discounted: {
        textDecoration: "line-through"
    },
    link: {
        cursor: "pointer"
    },
}));

interface Params {
    id: string;
}

export const OrderPage: FC = () => {
    const {id} = useParams<Params>();
    const {t} = useTranslation();
    const currentOrder = orderArchiveQuery.useCurrent();

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("action-show-orders"),
            to: "/benutzer/bestellungen/",
        },
        {
            text: id,
            to: "/benutzer/bestellungen/" + id
        }
    ];

    useEffect(() => {
        const subs = orderArchiveStore.activateOrderDetailEffects()
        orderArchiveStore.getOrder(id);
        return () => {
            subs.forEach(it => it.unsubscribe())
        }
    }, [id]);

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"
                           header={t("order-page-title", {orderId: id})}/>
            {currentOrder &&
            <Grid container direction="column">
                <OrderHeader currentOrder={currentOrder}/>
                <Grid item sm={12}>
                    {currentOrder &&
                    <OrderItemTable order={currentOrder} title="Artikel"/>
                    }
                    {!currentOrder &&
                    <CircularProgress variant="indeterminate"/>
                    }
                </Grid>
            </Grid>
            }
        </Fragment>
    )
};

interface OrderProps {
    currentOrder: Order
}

const OrderHeader: FC<OrderProps> = ({currentOrder}: OrderProps) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <OrderHeaderRow label={t("order-archive-date-header")} value={currentOrder?.orderedAt}/>
            <OrderHeaderRow label={t("order-archive-shippingaddress-header")} value={currentOrder?.shippingAddress}/>
            {
                currentOrder && currentOrder.billingAddress !== currentOrder.shippingAddress &&
                <OrderHeaderRow label={t("order-archive-billingaddress-header")} value={currentOrder?.billingAddress}/>
            }
            <OrderHeaderRow label={t("order-shipping-type-header")}
                            value={getEnumValue(ShippingType, '' + currentOrder?.shippingType)}/>
            <OrderHeaderRow label={t("order-archive-payment-type-header")}
                            value={getEnumValue(PaymentType, '' + currentOrder?.paymentType)}/>
            <OrderHeaderRow label={t("order-archive-status-header")}
                            value={getEnumValue(ShopOrderStatusEnum, '' + currentOrder?.status)}/>
        </Fragment>
    )
}


interface OrderHeaderRowProps {
    label: string;
    value?: any;
}

const OrderHeaderRow: FC<OrderHeaderRowProps> = ({label, value}: OrderHeaderRowProps) => {
    return (
        <Grid container md={12} sm={12} direction="row" spacing={GridDefaultSpacing}>
            <Grid item sm={12} md={3}>
                <Typography color="secondary" component="div">{label}</Typography>
            </Grid>
            <Grid item sm={12} md={3}>
                <Typography color="textPrimary" component="div">{value}</Typography>
            </Grid>
        </Grid>

    )
}

interface OrderItemRowProps {
    item: OrderItem
}

const OrderItemRow: FC<OrderItemRowProps> = ({item}: OrderItemRowProps) => {
    const classes = useStyles(undefined);
    const push = useHistoryPushNew();

    return (
        <Fragment>
            <TableRow hover className={classes.link} onClick={() => push(getItemPath(item.item.slug))}>
                <TableCell className={classes.image}>
                    {
                        item.item.images && item.item.images.length > 0 &&
                        <img src={item.item.images[0].locationSmall}
                             alt={item.item.images[0].description}/>
                    }
                </TableCell>
                <TableCell>
                    <Typography component="span">{item.item.itemNumber}</Typography>
                </TableCell>
                <TableCell>
                    <Typography color="textPrimary" component="span">{item.item.name}</Typography>
                </TableCell>
                {item.unavailable &&
                <Fragment>
                    <TableCell colSpan={3}><Typography color="error" component="span">Artikel war zum Zeitpunkt der
                        Bestellung nicht lieferbar</Typography></TableCell>
                </Fragment>
                }
                {
                    !item.unavailable &&
                    <Fragment>
                        <TableCell>
                            <Typography color="textPrimary" component="span">{item.quantity}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="textPrimary" component="span"> <Currency
                                value={item.activePrice}/></Typography>
                        </TableCell>
                        <TableCell>
                            <Typography color="textPrimary" component="span"><Currency
                                value={item.activePrice * item.quantity}/></Typography>
                        </TableCell>
                    </Fragment>
                }

            </TableRow>
        </Fragment>
    )
}


interface OrderItemTableProps {
    order: Order
    title: string

}

const OrderItemTable: FC<OrderItemTableProps> = ({order, title}: OrderItemTableProps) => {
    const classes = useStyles(undefined);
    const {t} = useTranslation();
    return (
        <Fragment>
            <ContentSubHeader>{title}</ContentSubHeader>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableBody>
                        <TableRow className={classes.header}>
                            <TableCell/>
                            <TableCell>Artikelnummer</TableCell>
                            <TableCell>Bezeichnung</TableCell>
                            <TableCell>Menge</TableCell>
                            <TableCell>Stueckpreis</TableCell>
                            <TableCell>Gesamt</TableCell>
                        </TableRow>
                        {[...order.stockedItems, ...order.unavailableItems.map(item => ({
                            ...item,
                            unavailable: true
                        }))].map(orderItem =>
                            <OrderItemRow
                                key={orderItem.item.slug}
                                item={orderItem}
                            />)
                        }
                        {
                            order.mmzValue > 0 &&
                            <TableRow>
                                <TableCell colSpan={4}/>
                                <TableCell> {t('shopping-cart-mmz')} </TableCell>
                                <TableCell><Currency value={order.mmzValue}/></TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell colSpan={4}/>
                            <TableCell> Versand </TableCell>
                            <TableCell><Currency value={order.shipping}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4}/>
                            <TableCell> USt. </TableCell>
                            <TableCell><Currency value={order.vatPrice}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4}/>
                            <TableCell><b>Bestellwert</b></TableCell>
                            <TableCell><Currency value={order.totalPrice}/></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}


export default OrderPage;
