import {useTranslation} from "react-i18next";
import Motor from "../../model/motor/Motor";
import Breadcrumb from "./Breadcrumb";

export function useMotorBreadcrumbs(motor?: Motor | null) {
    const {t} = useTranslation();
    const breadcrumbs: Breadcrumb[] = [];

    if (motor) {
        let sectionPath = "";
        const sectionSubPath = "motoren";
        const company = motor.company;
        const section = motor.section;
        if (section) {
            sectionPath = `/${section.slug}`;
            breadcrumbs.push(
                {
                    text: section.name,
                    to: `${sectionPath}/motoren/`
                }
            )
        }
        breadcrumbs.push(
            {
                text: t("motor-breadcrumb-text"),
                to: `${sectionPath}/${sectionSubPath}/`,
            }
        );

        if (company) {
            breadcrumbs.push(
                {
                    text: company.name,
                    to: `${sectionPath}/${sectionSubPath}/${company.slug}/`,
                }
            );
        }

        breadcrumbs.push(
            {
                text: motor.name,
                to: `${sectionPath}/motor/${motor.slug}/`
            }
        );
    }

    return breadcrumbs;
}

export default useMotorBreadcrumbs;
