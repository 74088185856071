import {transaction} from "@datorama/akita";
import returnFilesStore, {createReturnFilesInitialState, ReturnFilesStore} from "./ReturnFilesStore";
import returnFilesService, {ReturnFilesService} from "../../../service/returns/ReturnFilesService";
import returnFilesQuery, {ReturnFilesQuery} from "./ReturnFilesQuery";
import {fileToBase64} from "../../../common/util/fileToBase64";

export class ReturnFileActions {
    private readonly store: ReturnFilesStore = returnFilesStore;
    private readonly query: ReturnFilesQuery = returnFilesQuery;
    private readonly service: ReturnFilesService = returnFilesService;

    @transaction()
    public async load(): Promise<void> {
        this.clear();
        this.startRequest();
        await this.findCurrent();
    }

    private async findCurrent() {
        try {
            const result = await this.service.findCurrent();
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private startRequest() {
        this.store.update({
            loading: true,
            error: null,
            success: false
        });
    }

    @transaction()
    public async addFiles(): Promise<void> {
        this.startRequest();
        try {
            for (const file of this.query.getValue().files) {
                const encodedFileContent = await fileToBase64(file);
                await this.service.addFile({
                    name: file.name,
                    contentBase64: encodedFileContent
                });
            }
            await this.load();
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    @transaction()
    public async removeFile(id: number): Promise<void> {
        this.startRequest();
        try {
            await this.service.removeFile(id);
            this.store.remove(id);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private clear() {
        this.store.update(createReturnFilesInitialState());
    }

    public setFiles(files: File[]): void {
        const update = {
            files
        }
        this.store.update(update);
    }

}

export default new ReturnFileActions();
