import i18n from "i18next";
import moment from "moment";
import "moment/locale/de";
import numeral from "numeral";
import {initReactI18next} from "react-i18next";
import de from "./i18n/de.json";
import en from "./i18n/en.json";
import {getUserLanguageAndTranslations, Language} from "./app/state/language/UserLanguage";

export async function setupTranslations() {
    const [language] = getUserLanguageAndTranslations();

    // Register a German locale for numeral.
    numeral.register("locale", Language.GERMAN, {
        // @ts-ignore
        ...(de["numeral-format"]),
        ordinal(number) {
            return `${number}`;
        }
    });

    // Set locales for moment and numeral.
    moment.locale(language)
    numeral.locale(language);
    i18n.on("languageChanged", function (lng) {
        numeral.locale(lng);
        moment.locale(lng)
    });

    // Init the i18next instance.
    await i18n.use(initReactI18next)
        .init({
            resources: {
                de: {translation: de},
                en: {translation: en}
            },
            lng: language,
            fallbackLng: Language.GERMAN,
            interpolation: {
                escapeValue: false
            }
        });
}
