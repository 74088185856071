import fetch from "isomorphic-unfetch";
import {Profile} from "../model/user/Profile";
import AuthorizedHttpService from "./AuthorizedHttpService";


export class UserService extends AuthorizedHttpService {
    public async getProfile(): Promise<Profile> {
        return this.get("/user/", undefined, undefined);
    }

    public async updateProfile(profile: Profile): Promise<Profile> {
        return this.put("/user/", undefined, profile);
    }

    public async updateLocale(newLocale: string): Promise<void> {
        return this.put("/user/locale", undefined, {newLocale});
    }

    public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
        return this.put("/user/password/", undefined, {oldPassword, newPassword});
    }

    public async createPasswordReset(user: string): Promise<void> {
        return this.post("/user/password/reset/", undefined, {user});
    }

    public async resetPassword(token: string, newPassword: string): Promise<void> {
        return this.put("/user/password/reset/", undefined, {token, newPassword});
    }

    public async signup(data: FormData): Promise<void> {
        const path = "/user/";
        const body = data;
        const url = this.createUrl(path);

        const response = await fetch(url, {
            method: "POST",
            body
        });

        this.checkForError(response);

        try {
            await response.json();
        } catch (e) {
        }
    }
}

export default new UserService();
