import React, {FC, useEffect} from "react";
import {useObservable} from "../../../../common/useObservable";
import {AttributeSlug} from "../../../../model/attribute/Attribute";
import attributeValueActions from "../../../../state/categoryPage/filter/attribute/values/AttributeValueActions";
import attributeValueQuery from "../../../../state/categoryPage/filter/attribute/values/AttributeValueQuery";
import itemActions from "../../../../state/categoryPage/item/ItemActions";
import itemQuery from "../../../../state/categoryPage/item/ItemQuery";
import AutocompleteFilter from "../AutocompleteFilter";

export type OptionLabelMapper = (value: string) => string;

export interface StringFilterProps {
    name: string;
    slug: AttributeSlug;
    getOptionLabel?: OptionLabelMapper;
}

export const StringFilter: FC<StringFilterProps> = ({name, slug, ...props}) => {
    const category = itemQuery.useCategory();
    const filter = itemQuery.useItemFilter();
    const property = `attribute.${slug}`;
    const value: string | null = itemQuery.useFilterProperty(property)?.toString() || null;
    const items = useObservable(attributeValueQuery.select(`${category}/${slug}`)) || [];

    useEffect(() => {
        attributeValueActions.load(slug, filter).catch(error => console.log(error));
    }, [slug, category, filter]);

    const updateFilter = (value: string | null, type: string) => {
        itemActions.setAttribute(type, value).catch(error => console.log(error));
    }

    return <AutocompleteFilter
        {...props}
        value={value}
        options={items}
        onUpdateFilter={newValue => updateFilter(newValue, property)}
        label={name}
    />;
};

export default StringFilter;
