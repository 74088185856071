import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import VehicleItem from "../../model/VehicleItem";

export interface Filter {
    motor: string;
    category: string;
}

export type FilterState = Partial<Filter>;

export interface State extends EntityState<VehicleItem, string>, ActiveState<string> {
    filter: FilterState
}

export function createInitialState(): Partial<State> {
    return {
        filter: {}
    };
}

@StoreConfig({
    name: "motor.item",
    idKey: "slug"
})
export class MotorItemStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new MotorItemStore();
