import PaymentType from "../checkout/PaymentType";
import ShippingType from "../checkout/ShippingType";
import Item from "../item/Item";

export enum ShopOrderStatusEnum {
    NEW = 'neu',
    LOCKED = 'gesperrt',
    COMPLETED = 'abgeschlossen',
    ABORTED = 'abgebrochen'
}


export function getEnumValue(enumDefinition: any, value: string) {
    if (value === 'EMPTY' || value === 'NONE' || value === 'ROLE_ANONYMOUS') {
        return null;
    }

    return Object.keys(enumDefinition)
        .filter(key => key === value)
        .map(key => enumDefinition[key])[0];
}

export interface OrderItem {
    item: Item;
    quantity: number;
    activePrice: number;
    unavailable: boolean;
    comment: string;
}

export interface OrderMetadata {
    id: number;
    mmzValue: number;
    orderedAt: string;
    transactionId: string;
    billingAddress: string;
    shippingAddress: string;
    totalPrice: number;
    paymentType: PaymentType;
    shippingType: ShippingType;
    status: string;
}

export interface Order {
    id: number;
    mmzValue: number;
    shipping: number;
    status: ShopOrderStatusEnum;
    orderedAt: string;
    transactionId: string;
    billingAddress: string;
    shippingAddress: string;
    paymentType: PaymentType;
    shippingType: ShippingType;
    totalPrice: number;
    vatPrice: number;
    netTotal: number;
    itemTotal: number;
    stockedItems: OrderItem[];
    unavailableItems: OrderItem[];
}

export interface Page<T> {
    content: T[];
    totalElements: number;
    number: number;
    size: number;
    numberOfElements: number;
    totalPages: number;
}
