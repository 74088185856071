import VehicleGroup from "../model/VehicleGroup";
import AuthorizedHttpService from "./AuthorizedHttpService";

export interface VehicleGroupService {
    read(slug: string): Promise<VehicleGroup[]>;
}

export class HttpVehicleGroupService extends AuthorizedHttpService implements VehicleGroupService {
    public async read(section?: string): Promise<VehicleGroup[]> {
        if(!section) {
            return this.get(`/vehiclegroups/`);
        }
        return this.get(`/vehiclegroups/`, { section });
    }
}

export default new HttpVehicleGroupService();
