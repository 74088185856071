import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, {FC} from "react";


export interface CopyrightProps {
    shopName: string;
    shopAddress: string;
}

export const Copyright: FC<CopyrightProps> = ({shopName, shopAddress}) => (

    <Typography variant="body2">
        {"© "}
        {new Date().getFullYear()}
        {" "}
        <Link color="inherit" href="https://scootertech.at/">
           {shopName} | {shopAddress}
        </Link>
    </Typography>
);

export default Copyright;
