import Company, {CompanySlug} from "../model/Company";
import Reference from "../model/Reference";
import AuthorizedHttpService from "./AuthorizedHttpService";

export interface CompanyService {
    findBySlug(slug: string): Promise<Company>;

    findAll(): Promise<Company[]>;

    findAllByGroup(vehicleGroup: string): Promise<Company[]>;

    findByCategorySlug(category: string): Promise<Reference<CompanySlug>[]>;
}

export class HttpCompanyService extends AuthorizedHttpService implements CompanyService {
    public async findAll(): Promise<Company[]> {
        return this.get(`/companies/`);
    }

    public async findBySlug(slug: string): Promise<Company> {
        return this.get(`/companies/${slug}`);
    }

    public async findAllByGroup(group: string): Promise<Company[]> {
        return this.get(`/companies/`, {vehicleGroup: group});
    }

    public async findAllForMotors(section: string): Promise<Company[]> {
        return this.get(`/companies/motor`, {section});
    }

    public async findByCategorySlug(category: string): Promise<Reference<CompanySlug>[]> {
        return this.get(`/companies/`, {category});
    }

}

export default new HttpCompanyService();
