import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import InternalServerErrorPage from "../../../errors/500";
import AddressResponse from "../../model/checkout/AddressResponse";
import addressService from "../../service/AddressService";
import AddressDisplay from "../address/AddressDisplay";

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: theme.spacing(2)
    },
    title: {
        marginTop: theme.spacing(2),
        fontWeight: "bold"
    }
}));

const Address: FC = () => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    const [addressOptions, setAddressOptions] = useState<AddressResponse[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const companyAddress = addressOptions.find(address => address.companyAddress);
    const firstAddress = addressOptions[0];
    const deliveryAddress = addressOptions.find(address => address.delivery) || companyAddress || firstAddress;
    const billingAddress = addressOptions.find(address => address.billing) || deliveryAddress;
    const hasAddress = addressOptions.length > 0;
    const hasBillingAddress = deliveryAddress !== billingAddress;

    useEffect(() => {
        addressService.findAll()
            .then(setAddressOptions)
            .catch(setError);
    }, []);

    return (
        <Fragment>
            {
                error && <InternalServerErrorPage/>
            }
            {
                !hasAddress &&
                <Fragment>
                    {t("address-missing")}
                    <IconButton
                        component={RouterLink}
                        to="/benutzer/adresse/create"
                    >
                        <AddIcon/>
                    </IconButton>
                </Fragment>
            }
            {
                hasAddress &&
                <Grid container data-cy="address-title">
                    <Grid item md={6}>
                        <Typography variant="subtitle1" className={classes.title}>
                            {hasBillingAddress ? t("checkout-address-delivery") : t("checkout-address")}</Typography>
                        <AddressDisplay address={deliveryAddress}/>
                    </Grid>
                    {
                        hasBillingAddress &&
                        <Grid item md={6}>
                            <Fragment>
                                <Typography variant="subtitle1" className={classes.title}>
                                    {t("checkout-address-billing")}</Typography>
                                <AddressDisplay address={billingAddress}/>
                            </Fragment>
                        </Grid>
                    }
                </Grid>
            }
        </Fragment>
    );
};

export default Address;
