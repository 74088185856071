import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ContentHeader from "../app/component/ContentHeader";
import ContentService from "../app/service/ContentService";
import NotFoundError from "../app/service/error/NotFoundError";
import PageNotFound from "../errors/404";
import PageInternalServerError from "../errors/500";

const useStyles = makeStyles((theme) => ({
    content: {
        "& a": {
            color: theme.palette.secondary.dark,
            textDecoration: "none"
        },
        "& a:hover": {
            textDecoration: "underline"
        },
        textAlign: "justify",
        "& td": {}
    },
    dummy: {
        display: "none"
    }
}));

interface Params {
    slug: string;
}

export const StaticContentPage: FC = () => {
    const { slug } = useParams<Params>();
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [error, setError] = useState<any>(null);
    const classes = useStyles(undefined);

    useEffect(() => {
        ContentService.find(slug)
            .then(res => {
                setContent(res.text);
                setTitle(res.description);
            })
            .catch(setError);
    }, [slug]);

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    } else {
        return (
            <Fragment>
                <ContentHeader  header={title}></ContentHeader>
                <div className={classes.content} dangerouslySetInnerHTML={{ __html: content }}/>
                {/* render dumy table to load styling of material ui table */}
                <Table className={classes.dummy}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
        );
    }
};

export default StaticContentPage;
