import {useObservable} from "../../common/useObservable";
import {HookQueryEntity} from "../HookQueryEntity";
import searchStore, {SearchStore, State} from "./SearchStore";

export class SearchQuery extends HookQueryEntity<State> {
    private readonly query = this.select("query");

    constructor(
        protected store: SearchStore = searchStore
    ) {
        super(store);
    }

    public useQuery(): State["query"] {
        return useObservable(this.query, this.getValue().query);
    }
}

export default new SearchQuery();
