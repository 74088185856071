import makeStyles from "@material-ui/core/styles/makeStyles";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import React, {PropsWithChildren} from "react";

const useStyles = makeStyles(theme => ({
    dropdown: {
        borderRadius: "5px",
        "& label": {
            color: theme.palette.secondary.main,
        }
    }
}));

interface AutocompleteFilterProps<T> {
    value: T | null;
    options: Array<T>;
    onUpdateFilter: (newValue: T | null) => void;
    label: string;
    getOptionLabel?: (option: T) => string;
}

export const AutocompleteFilter = <T, >(props: PropsWithChildren<AutocompleteFilterProps<T>>) => {
    const classes = useStyles(props);
    const {value} = props;
    return <Autocomplete
        {...props}
        value={value}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        renderInput={params => <TextField {...params}
                                          label={props.label}
                                          variant="outlined"
                                          fullWidth
        />}
        onChange={(event, newValue) => {
            props.onUpdateFilter(newValue!!);
        }}
        className={classes.dropdown}
    />
}

export default AutocompleteFilter;
