import {Order, QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../../common/useObservable";
import Vehicle from "../../../model/Vehicle";
import {throttledLoading} from "../../throttleLoading";
import vehicleStore, {VehicleState, VehicleStore} from "./VehicleStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class VehicleQuery extends QueryEntity<VehicleState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();
    public readonly all = this.selectAll();
    public readonly current = this.selectActive();
    public readonly currentSlug = this.selectActiveId();

    constructor(
        protected store: VehicleStore = vehicleStore
    ) {
        super(store);
    }

    public useAll(): Vehicle[] {
        return useObservable(this.all, this.getAll());
    }
}

export default new VehicleQuery();
