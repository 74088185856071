import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import getItemPath from "../../common/route/getItemPath";
import ItemReference from "../../model/item/ItemReference";
import AppLink from "../theme/AppLink";

export interface ItemReferenceListProps {
    title: string;
    items: ItemReference[];
}

export const ItemReferenceList: FC<ItemReferenceListProps> = ({ title, items, ...props }) => {
    return (
        <Fragment>
            <Typography variant="h5" component="h3">{title}</Typography>
            <List dense {...props}>
                {
                    items.map(({ slug, itemNumber, name }) => (
                        <ListItem key={slug}>
                            <AppLink to={getItemPath(slug)}>
                                <Typography color="secondary" component="span">
                                    {itemNumber}
                                </Typography>
                                {" "}
                                {name}
                            </AppLink>
                        </ListItem>
                    ))
                }
            </List>
        </Fragment>
    );
};

export default ItemReferenceList;
