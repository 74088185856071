import React, {FC, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Prediction from "./Prediction";
import MapsService from "./MapsService";
import {Store} from "./Store";
import {Location} from "./Location";

interface MapsAutocompleteFieldProps {
    callback: (center: Location, dealers: Store[]) => void
    error: (err: any) => void
}

const MapsAutocompleteField: FC<MapsAutocompleteFieldProps> = ({callback, error}: MapsAutocompleteFieldProps) => {

    const [place, setPlace] = useState<Prediction>({description: "", placeId: ""})
    const [predictions, setPredictions] = useState<Prediction[]>([])

    function onChangeInternal(event: React.ChangeEvent<HTMLInputElement>) {
        if (!!event.target.value) {
            MapsService.predict(event.target.value)
                .then(result => setPredictions(result))
                .catch(err => console.log(err));
        }
    }

    function onPressReturn(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.keyCode === 13) {
            onSelect(e, place)
        }
    }

    function onSelect(event: any, newValue: Prediction | null) {
        const value = !!newValue?.placeId ? newValue : predictions[0]
        if (!value.placeId) {
           return
        }

        setPlace(value);

        const center = MapsService.getLocationForPlace(value.placeId)
        const stores = MapsService.getNearStores(value.placeId)

        Promise.all([center, stores])
            .then(([location, stores]) => callback(location, stores))
            .catch(err => error(err));
    }

    function onBlur(event: React.FocusEvent<HTMLInputElement>) {
        if (!predictions || predictions.length === 0) {
            return;
        }
        if (!place.placeId) {
            onSelect(event, predictions[0]);
        }
    }

    return (
        <Autocomplete
            onChange={(event, newValue) => onSelect(event, newValue as Prediction)}
            options={predictions}
            autoSelect
            getOptionLabel={(option) => option.description}
            freeSolo
            renderInput={(params) =>
                <TextField
                    {...params}
                    InputProps={{...params.InputProps, fullWidth: true}}
                    onChange={onChangeInternal}
                    onBlur={onBlur}
                    onKeyDown={onPressReturn}
                    label="Adresse"
                    fullWidth
                    variant="outlined"/>
            }
        />
    );
}

export default MapsAutocompleteField;
