import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import VehicleModel from "../../model/Vehicle";
import AppLink from "../theme/AppLink";
import ReferenceList from "../item/ReferenceList";
import {getTireSearchLinkFromTireDimensions} from "../../common/util/tireDimensions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";

export interface VehicleProps {
    vehicle: VehicleModel;
}


const useStyles = makeStyles(theme => ({
    vehicleImage: {
        maxHeight: '50vh',
    }
}));

export const Vehicle: FC<VehicleProps> = ({vehicle}) => {
    const classes = useStyles();
    const {
        chassisNumber,
        displacement,
        engineNumber,
        motor,
        buildYearFrom,
        buildYearUntil,
        tireBack,
        tireFront,
        explodedViews,
        images,
    } = vehicle;
    const headingComponent = "h6";

    const {t} = useTranslation();
    const displayTireLinks = vehicle?.vehicleGroup?.slug !== 'mopedauto';
    return (
        <Grid container xs={12} direction={"row-reverse"} wrap={"wrap"}>
            {
                images?.length ?
                    <Grid item xs={12} md={4}>
                        <img src={images[0].image.locationDefault} alt={images[0].image.description}
                                       className={classes.vehicleImage}/>
                    </Grid>
                    : null
            }
            <Grid item xs={12} md={8}>
                {
                    motor &&
                    <Fragment>
                        <Typography variant={headingComponent} component="h2">{t("vehicle-motor")}</Typography>
                        <Typography paragraph variant="body1">
                            <AppLink
                                color="secondary"
                                to={`/motor/${motor.slug}/`}>
                                {motor.name}
                            </AppLink>
                        </Typography>
                    </Fragment>
                }
                {
                    explodedViews?.length > 0 &&
                    <Fragment>
                        <Typography variant={headingComponent} component="h2">{t("exploded-views-heading")}</Typography>
                        <ReferenceList
                            items={explodedViews}
                            to={(slug) => `/explosionszeichnung/${slug}.html`}/>
                    </Fragment>
                }

                {
                    displacement &&
                    <Fragment>
                        <Typography variant={headingComponent} component="h2">{t("vehicle-displacement")}</Typography>
                        <Typography paragraph
                                    variant="body1">{t("vehicle-displacement-value", {data: displacement})}</Typography>
                    </Fragment>
                }

                {
                    chassisNumber &&
                    <Fragment>
                        <Typography variant={headingComponent} component="h2">{t("vehicle-chassis-number")}</Typography>
                        <Typography paragraph variant="body1">{chassisNumber}</Typography>
                    </Fragment>
                }

                {
                    engineNumber &&
                    <Fragment>
                        <Typography variant="h5" component="h2">{t("vehicle-engine-number")}</Typography>
                        <Typography paragraph variant="body1">{engineNumber}</Typography>
                    </Fragment>
                }

                {
                    (buildYearFrom || buildYearUntil) &&
                    <Fragment>
                        <Typography variant={headingComponent} component="h2">{t("vehicle-build-year")}</Typography>

                        {
                            buildYearFrom &&
                            <Typography paragraph={!buildYearUntil}
                                        variant="body1">{t("vehicle-build-year-from", {year: buildYearFrom})}</Typography>
                        }
                        {
                            buildYearUntil &&
                            <Typography paragraph
                                        variant="body1">{t("vehicle-build-year-until", {year: buildYearUntil})}</Typography>
                        }
                    </Fragment>
                }

                {
                    (tireFront || tireBack) && displayTireLinks &&
                    <Fragment>
                        <Typography variant={headingComponent} component="h2">{t("vehicle-tires")}</Typography>
                        <Typography paragraph variant="body1">
                            <AppLink
                                color="secondary"
                                to={getTireSearchLinkFromTireDimensions(tireFront)}
                                data-cy="vehicle-front-tire-search-link">
                                {t("vehicle-front", {data: tireFront})}
                            </AppLink>
                        </Typography>
                        <Typography paragraph variant="body1">
                            <AppLink
                                color="secondary"
                                to={getTireSearchLinkFromTireDimensions(tireBack)}
                                data-cy="vehicle-back-tire-search-link">
                                {t("vehicle-back", {data: tireBack})}
                            </AppLink>
                        </Typography>
                    </Fragment>
                }
            </Grid>
        </Grid>
    );
};

export default Vehicle;
