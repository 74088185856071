import React, {FC} from "react";
import AppLink from "../../app/component/theme/AppLink";
import {CompanyName} from "../../app/config/Config";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    logo: {
        height: theme.spacing(12),
        padding: theme.spacing(1, 1, 1, 1)
    },
    logoBar: {
        paddingLeft: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        minWidth: 500,
        width: '60vw',
        zIndex: 11,
        position: 'fixed',
        top: 0,
        height: theme.spacing(12)-theme.spacing(1),
        transform: 'skew(-30deg)',
        backgroundColor: theme.palette.secondary.dark
    },
    counterSkew: {
        transform: 'skew(30deg)',
    }
}));

export const LogoHeader: FC = () => {
    const classes = useStyles(undefined);

    return <div className={classes.logoBar}>
        <div className={classes.counterSkew}>
            <AppLink to="/">
                <img alt={CompanyName} className={classes.logo} src="/theme/logo.png"/>
            </AppLink>
        </div>
    </div>
}