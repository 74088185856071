import {transaction} from "@datorama/akita";
import clientStorage, {ClientStorage} from "../../common/ClientStorage";
import {TokenRefreshLeadTime} from "../../config/Config";
import JwtResponse from "../../model/auth/JwtResponse";
import authService, {AuthService} from "../../service/AuthService";
import authQuery, {AuthQuery} from "./AuthQuery";
import authStore, {AuthStore, defaultCurrencyRate, defaultCurrencySymbol} from "./AuthStore";

export interface Dependencies {
    store: AuthStore;
    service: AuthService;
    sessionStorage: ClientStorage;
    query: AuthQuery;
}

export class AuthActions {
    private readonly store = authStore;
    private readonly service = authService;
    private readonly query = authQuery;
    private readonly sessionStorage = clientStorage;
    private readonly tokenRefreshLeadTime = TokenRefreshLeadTime;
    private tokenRefreshTimer: number | null = null;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);

        this.query.accessTokenExpiration.subscribe((timeout) => {
            this.clearTokenRefreshTimer();

            if (timeout) {
                const refresh = (timeout * 1000) - Date.now() - this.tokenRefreshLeadTime;
                this.tokenRefreshTimer = setTimeout(() => this.refresh(), refresh) as any;
            }
        });

        const name = "AuthActions";
        // @ts-ignore
        if (window.Cypress) {
            // @ts-ignore
            window[name] = this;
        } else {
            // @ts-ignore
            delete window[name];
        }
    }

    private clearTokenRefreshTimer() {
        if (this.tokenRefreshTimer) {
            clearTimeout(this.tokenRefreshTimer);
            this.tokenRefreshTimer = null;
        }
    }

    public openLoginDialog() {
        this.store.update({
            "ui.login.open": true
        });
    }

    public closeLoginDialog() {
        this.store.update({
            "ui.login.open": false
        });
    }

    public async login(username: string, password: string, keepLoggedIn: boolean): Promise<void> {
        try {
            this.store.update({
                loading: true,
                error: null
            });
            const session = await this.service.login(username, password, keepLoggedIn);
            this.store.update({
                "ui.login.open": false
            });
            this.store.setSession(session);
            this.saveSession(session);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private saveSession({access_token, refresh_token}: JwtResponse) {
        this.sessionStorage.setItem("access_token", access_token);
        this.sessionStorage.setItem("refresh_token", refresh_token);
    }

    public logout() {
        this.store.setSession(null);
        this.sessionStorage.removeItem("access_token");
        this.sessionStorage.removeItem("refresh_token");
    }

    @transaction()
    public async refresh(): Promise<void> {
        const refreshToken = this.store.getRefreshToken();
        if (!refreshToken) {
            return;
        }

        try {
            this.store.update({
                loading: true,
                error: null
            });
            const session = await this.service.refresh(refreshToken);
            this.store.setSession(session);
            this.saveSession(session);
        } catch (error) {
            this.logout();
        } finally {
            this.store.setLoading(false);
        }
    }

    public setupCurrency() {
        const token = this.store.getAccessToken();
        if (token) {
            this.service.getCurrencyInfo(token).then(
                response => this.store.setCurrencyInfo(response),
                () => this.store.setCurrencyInfo({
                    symbol: defaultCurrencySymbol,
                    rateToEuro: defaultCurrencyRate,
                }));
            return
        }
        this.store.setCurrencyInfo({
            symbol: defaultCurrencySymbol,
            rateToEuro: defaultCurrencyRate,
        })
    }
}

export default new AuthActions();
