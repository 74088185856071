import React, {FC, Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Currency from "../Currency";
import {ApplyVoucherResponse, ApplyVoucherResponseCode, VoucherType} from "../../service/ShoppingCartService";

export interface ShippingThresholdInfoProps {
    missingForFreeShipping: number;
    applyVoucherResponse: ApplyVoucherResponse;
    warningClassName: string;
    successClassName: string;
}

export const ShippingThresholdInfo: FC<ShippingThresholdInfoProps> = ({
                                                                          missingForFreeShipping,
                                                                          applyVoucherResponse,
                                                                          warningClassName,
                                                                          successClassName
                                                                      }) => {
    const isFreeShippingVoucher = applyVoucherResponse?.voucherType === VoucherType.FREE_SHIPPING && applyVoucherResponse?.responseCode === ApplyVoucherResponseCode.VALID;
    const isFreeShipping = missingForFreeShipping === 0 || isFreeShippingVoucher;
    return <Fragment>
        {!isFreeShipping ?
            <TableRow data-cy="shipping-info-missing">
                <TableCell colSpan={6} align="center">
                    <Typography className={warningClassName} variant="subtitle2" component="span">nur
                        noch </Typography>
                    <Currency className={warningClassName} value={missingForFreeShipping}/>
                    <Typography className={warningClassName} variant="subtitle2"
                                component="span"> bis zu kostenlosen Versand!</Typography>
                </TableCell>
            </TableRow>
            :
            <TableRow data-cy="shipping-info-past">
                <TableCell colSpan={6} align="center">
                    {
                        !isFreeShippingVoucher ?
                            <Typography className={successClassName} variant="subtitle2" component="span">
                                Versand kostenlos da der Mindestbestellwert für kostenlosen Versand erreicht wurde!
                            </Typography> :
                            <Typography className={successClassName} variant="subtitle2" component="span">
                                Versand kostenlos da der
                                Gutschein <strong>{applyVoucherResponse.voucherCode}</strong> eingelöst wurde!
                            </Typography>
                    }
                </TableCell>
            </TableRow>
        }</Fragment>

}

export default ShippingThresholdInfo
