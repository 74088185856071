import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {Fragment, useEffect, useState} from "react";
import ContentService from "../service/ContentService";
import ContentHeader from "./ContentHeader";

const useStyles = makeStyles((theme) => ({
    content: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        "& a": {
            color: theme.palette.secondary.dark,
            textDecoration: "none"
        },
        "& a:hover": {
            textDecoration: "underline"
        },
        textAlign: "justify"
    }
}));

export interface ContentProps {
    name: string;
}

const Content: React.FC<ContentProps> = ({ name }) => {
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const classes = useStyles(undefined);

    useEffect(() => {
        ContentService.find(name)
            .then(res => {
                setContent(res.text);
                setTitle(res.description);
            });
    }, [name]);

    return (
        <Fragment>
            <ContentHeader header={title}></ContentHeader>
            <div className={classes.content} dangerouslySetInnerHTML={{ __html: content }}/>
        </Fragment>
    );
};

export default Content;
