import React, {FC, Fragment, useCallback} from "react";
import signupActions from "../../../state/signup/SignupActions";
import signupQuery from "../../../state/signup/SignupQuery";
import CountrySelection from "../../address/CountrySelection";
import SignupTextField from "../SignupTextField";

export const LocationFields: FC = () => {
    const country = signupQuery.useValue("country", "AT");
    const onChangeCountry = useCallback((event: any) => signupActions.update("country", event.target.value), []);

    return (
        <Fragment>
            <SignupTextField
                property="street"
                label="form-address-location-street-label"
                required
            />
            <SignupTextField
                property="zip"
                label="form-address-location-zip-label"
                maxLength={10}
                required
            />
            <SignupTextField
                property="city"
                label="form-address-location-city-label"
                required
            />
            <CountrySelection
                value={country}
                required
                onChange={onChangeCountry}
            />
        </Fragment>
    );
};

export default LocationFields;
