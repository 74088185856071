import {Store, StoreConfig} from "@datorama/akita";
import {Page} from "../../model/user/OrderArchive";
import {from, of, Subject, Subscription} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators"
import invoiceService, {Invoice, InvoiceListItem} from "../../service/InvoiceService"

export interface InvoiceState {
    error?: any;
    loading: boolean;
    page: Page<InvoiceListItem>;
    current?: Invoice;
}

export const EMPTYPAGE: Page<InvoiceListItem> = {
    content: [],
    numberOfElements: 0,
    number: 0,
    totalElements: 0,
    size: 10,
    totalPages: 0
};

export function createInitialState(): InvoiceState {
    return {
        loading: false,
        page: EMPTYPAGE,
    };
}

interface GetPageAction {
    size?: number;
    offset?: number;
}

interface GetInvoiceAction {
    invoiceNumber: string
}

@StoreConfig({name: "orderArchive"})
export class InvoiceStore extends Store<InvoiceState> {

    private invoiceService = invoiceService;

    constructor() {
        super(createInitialState());
    }

    private readonly getPageAction$: Subject<GetPageAction> = new Subject<GetPageAction>()
    private readonly getPageEffect$ = this.getPageAction$.pipe(
        switchMap(action => from(this.invoiceService.getPage(action.offset, action.size)).pipe(
            tap(result => this.update({page: result, loading: false})),
            catchError(error => {
                console.log(error)
                this.update({error: error, page: createInitialState().page, loading: false})
                return of();
            })
            )
        )
    )

    private readonly getInvoiceDetailsAction$: Subject<GetInvoiceAction> = new Subject<GetInvoiceAction>()
    private readonly getInvoiceDetailsEffect$ = this.getInvoiceDetailsAction$.pipe(
        switchMap(action => from(this.invoiceService.getByNumber(action.invoiceNumber)).pipe(
            tap(response => this.update({loading: false, current: response})),
            catchError(error => {
                console.log(error)
                this.update({error: error, current: undefined, loading: false})
                return of();
            })
            )
        )
    )

    public activateInvoiceDetailEffects(): Subscription[] {
        return [
            this.getInvoiceDetailsEffect$.subscribe(),
        ]
    }

    public activatePageEffects(): Subscription[] {
        return [
            this.getPageEffect$.subscribe()
        ]
    }

    public getPage(action: GetPageAction): void {
        const {size, number} = this.getValue().page;
        this.getPageAction$.next({ size, offset: number, ...action })
    }

    public getInvoice(invoiceNumber: string): void {
        this.getInvoiceDetailsAction$.next({invoiceNumber})
    }
}

export default new InvoiceStore();
