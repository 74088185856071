import PaymentType from "../model/checkout/PaymentType";
import ShippingType from "../model/checkout/ShippingType";
import CheckoutCart from "../model/shoppingCart/CheckoutCart";
import ConfirmationCart from "../model/shoppingCart/ConfirmationCart";
import ShopOrder from "../model/shoppingCart/ShopOrder";
import AuthorizedHttpService from "./AuthorizedHttpService";

export interface ShoppingCartItemRequest {
    slug: string;
    quantity: number;
    comment?: string;
}

export interface CheckoutRequest {
    comment: string;
    successUrl: string;
    abortUrl: string;
}

export interface ApplyVoucherRequest {
    voucher: string;
}

export enum VoucherType {
    FREE_SHIPPING = 'FREE_SHIPPING',
    RELATIVE_REBATE = 'RELATIVE_REBATE',
    ABSOLUTE_REBATE = 'ABSOLUTE_REBATE'
}

export enum ApplyVoucherResponseCode {
    VALID = "VALID",
    INVALID = "INVALID",
    NOT_VALID_YET = "NOT_VALID_YET",
    NOT_VALID_ANYMORE = "NOT_VALID_ANYMORE",
    BELOW_MINIMUM_ORDER_VALUE = "BELOW_MINIMUM_ORDER_VALUE",
}

export interface ApplyVoucherResponse {
    responseCode: ApplyVoucherResponseCode;
    voucherCode: string;
    voucherType: VoucherType;
    minimumOrderValue?: number;
    rebateValue?: number;
}

export class ShoppingCartService extends AuthorizedHttpService {
    public async find(): Promise<ShopOrder> {
        return this.get("/cart/");
    }

    public async addItem(content: ShoppingCartItemRequest): Promise<ShopOrder> {
        return this.post("/cart/item", undefined, content);
    }

    public async setItem(content: ShoppingCartItemRequest): Promise<ShopOrder> {
        return this.put("/cart/item", undefined, content);
    }

    public async removeItem(slug: string): Promise<ShopOrder> {
        return this.delete(`/cart/item/${slug}`);
    }

    public async updateSelectedPaymentType(payment: PaymentType): Promise<Response> {
        return this.put("/cart/payment-method/", undefined, {payment});
    }

    public async updateSelectedShippingType(shipping: ShippingType): Promise<Response> {
        return this.put("/cart/shipping-method/", undefined, {shipping});
    }

    public async updateShippingDetails(): Promise<ConfirmationCart> {
        return this.put("/cart/shipping/");
    }

    public async checkout(content: CheckoutRequest): Promise<CheckoutCart> {
        return this.put("/cart/checkout/", undefined, content);
    }

    public async abort(orderId: string, timestamp: string, signature: string): Promise<ShopOrder> {
        return this.post("/cart/abort/", {orderId, timestamp, signature});
    }

    public async applyVoucher(voucher: string): Promise<ApplyVoucherResponse> {
        return this.post("/cart/voucher/", undefined, {
            voucher
        } as ApplyVoucherRequest);
    }

    public async removeVoucher(): Promise<void> {
        return this.delete("/cart/voucher/");
    }

}

export default new ShoppingCartService();
