import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Reference from "../../model/Reference";
import categoryQuery from "../../state/category/CategoryQuery";
import companyQuery from "../../state/company/CompanyQuery";
import motorQuery from "../../state/motor/MotorQuery";
import sectionQuery from "../../state/section/SectionQuery";
import ListItemLink from "../ListItemLink";

export const ProductCategoryList: FC = () => {
    const section = sectionQuery.useActiveId();
    const motor = motorQuery.useActiveId();
    const currentCategorySlug = categoryQuery.useActiveId();
    const categories = categoryQuery.useAll();
    const loading = companyQuery.useIsLoading();
    const path = `/${section}/motor/${motor}`;

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <ProductCategoryListPure
                activeSlug={currentCategorySlug}
                path={path}
                categories={categories}
            />
        );
    }
};

export default ProductCategoryList;

export interface ProductCategoryListProps {
    activeSlug?: string | null;
    path: string;
    categories: Reference[]
}

export const ProductCategoryListPure: FC<ProductCategoryListProps> = (props) => {
    const {
        activeSlug,
        categories,
        path
    } = props;
    const {t} = useTranslation();

    return (

        <Fragment>
            <Typography variant="h6" component="h2">{t("vehicle-matching-product-categories")}</Typography>
            <List>
                {categories.map(({name, slug}) => (
                    <ListItemLink
                        data-cy="vehicle-product-category-title"
                        key={slug}
                        primary={name}
                        path={`${path}/${slug}/`}
                        disabled={slug === activeSlug}
                    />
                ))}
            </List>
        </Fragment>
    );
};
