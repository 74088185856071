import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import getItemPath from "../../common/route/getItemPath";
import useHistoryPushNew from "../../common/useHistoryPushNew";
import ShoppingCartItem from "../../model/shoppingCart/ShoppingCartItem";
import Currency from "../Currency";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        marginRight: "200px"
    },
    pointer: {
        cursor: "pointer"
    },
    table: {
        marginBottom: 10
    }
}));

export interface BackordersProps {
    items: ShoppingCartItem[];
    immediatePayment: boolean;
}


export const Backorders: FC<BackordersProps> = ({ immediatePayment, items }) => {
        const { t } = useTranslation();
        const classes = useStyles(undefined);
        const push = useHistoryPushNew();
        const header: string = immediatePayment ? t("checkout-confirmation-delivery-time-request") : t("checkout-confirmation-backorder");

        return (
            <Fragment>
                <Typography variant="h5" component="h5" gutterBottom>{header}</Typography>

                {immediatePayment &&
                <Typography variant="inherit" component="p">{t("checkout-confirmation-delivery-info")}</Typography>
                }

                <TableContainer className={classes.table}>
                    <Paper className={classes.paper} variant="outlined">
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>{t("shopping-cart-header-cell-product")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-unit-price")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-amount")}</TableCell>
                                    <TableCell>{t("shopping-cart-header-cell-total-price")}</TableCell>
                                </TableRow>
                                {
                                    items.map(({ item, quantity }) => (
                                        <TableRow key={item.slug}>
                                            <TableCell className={classes.pointer}
                                                       onClick={() => push(getItemPath(item.slug))}>

                                                {item.company && item.company.name + " "}
                                                <Typography color="secondary" component="span"
                                                            variant="inherit"
                                                            onClick={() => push(getItemPath(item.slug))}>{item.itemNumber}</Typography>

                                                <br/>{item.name}
                                            </TableCell>
                                            <TableCell data-cy="order-cart-price" align="right">
                                                {item.price &&
                                                <Currency
                                                    value={item.price.wholesalePrice}/>
                                                }
                                            </TableCell>
                                            <TableCell data-cy="order-cart-amount" align="right">
                                                {quantity}
                                            </TableCell>
                                            <TableCell data-cy="order-cart-wholePrice" align="right">
                                                {item.price &&
                                                <Currency
                                                    value={item.price.wholesalePrice * quantity}/>
                                                }
                                            </TableCell>
                                        </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </TableContainer>
            </Fragment>
        );
    }
;

export default Backorders;