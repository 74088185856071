import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import PreorderItem from "../../../model/item/PreorderItem";
import pageSize from "../../common/PageSize";

export type Entity = PreorderItem;
export type ID = string;

export interface State extends EntityState<Entity, ID>, ActiveState<ID> {
    category: string;
    page: number;
    size: number;
    count: number;
}

export function createInitialState(): Partial<State> {
    return {
        loading: false,
        category: "",
        page: 0,
        size: pageSize.load(),
        count: 0
    };
}

@StoreConfig({
    name: "preorder.item",
    idKey: "slug"
})
export class ItemStore extends EntityStore<State> {
    constructor() {
        super(createInitialState());
    }
}

export default new ItemStore();
