import {QueryConfig, QueryEntity} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import {throttledLoading} from "../throttleLoading";
import watchListStore, {State, WatchListStore} from "./WatchListStore";
import WatchListItem from "../../model/watchLIst/WatchListItem";


@QueryConfig({})
export class WatchListQuery extends QueryEntity<State> {
    public readonly isLoading = throttledLoading(this);
    private readonly error = this.selectError<Error | null>();
    private readonly all = this.selectAll();

    constructor(
        protected store: WatchListStore = watchListStore
    ) {
        super(store);
    }

    public useAll(): WatchListItem[] {
        return useObservable(this.all, []);
    }

    public useIsEmpty(): boolean {
        return !this.useAll().length;
    }

    public useError(): State["error"] {
        return useObservable(this.error, null);
    }

    public useHasError(): boolean {
        return !!this.useError();
    }

    public useCount(): number {
        return this.useAll().length;
    }
}

export default new WatchListQuery();
