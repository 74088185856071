export enum PaymentType {
    BARZAHLUNG = "BARZAHLUNG",
    BANKEINZUG = "BANKEINZUG",
    NACHNAHME = "NACHNAHME",
    SOFORT = "SOFORT",
    VORAUSZAHLUNG = "VORAUSZAHLUNG",
    ZAHLSCHEIN = "ZAHLSCHEIN",
    STRIPE = "STRIPE"
}

export default PaymentType;