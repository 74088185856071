import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ShoppingCartRow from "./ShoppingCartRow";
import React, {Fragment, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Currency from "../component/Currency";
import ShippingThresholdInfo from "../component/shoppingcart/shippingThresholdInfo";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import ShoppingCartItem from "../model/shoppingCart/ShoppingCartItem";
import TenantSwitch from "../component/tenant/TenantSwitch";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {ApplyVoucherResponse, ApplyVoucherResponseCode} from "../service/ShoppingCartService";
import authQuery from "../state/auth/AuthQuery";
import {AddingRow, SubtractingRow, SumRow} from "./SummaryRow";
import {isNotNullOrUndefined} from "../common/util/nullHelper";

const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.secondary.dark,
        "& > *": {
            color: theme.palette.secondary.contrastText,
        },
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    minValue: {
        color: theme.palette.error.light
    },
    red: {
        color: theme.palette.error.main,
        fontSize: 'larger'
    },
    green: {
        color: theme.palette.primary.main,
        fontSize: 'larger'
    },
    voucherSuccess: {
        color: theme.palette.primary.main,
        fontSize: 'larger'
    },
    voucherFailure: {
        color: theme.palette.error.main,
        fontSize: 'larger'
    }
}));

interface ShoppingCartTableProps {
    items: ShoppingCartItem[];
    showMinValueWarning: boolean;
    mmzValue: number;
    systemRebate: { discount: number, value: number, rebateSystemName: string };
    voucherRebate: number;
    rebatedTotal: number;
    mmzLimitPrice: number;
    itemTotal: number;
    netTotal: number;
    vatPrice: number;
    totalPrice: number;
    missingForFreeShipping: number;
    voucherCode: string;
    onVoucherCodeApply: (voucher: string) => void;
    applyVoucherResponse: ApplyVoucherResponse;
}

export const ShoppingCartTable: React.FC<ShoppingCartTableProps> = props => {
    return <TenantSwitch forBase={<BaseShoppingCartTable {...props} />}
                         forTenant={<TenantShoppingCartTable {...props} />}/>;
};


export default ShoppingCartTable;

const BaseShoppingCartTable: React.FC<ShoppingCartTableProps> = (props) => {
    const classes = useStyles(undefined);
    const currencySymbol = authQuery.useCurrencySymbol();
    const {t} = useTranslation();
    const [voucher, setVoucher] = useState<string>(props.voucherCode);

    useEffect(() => {
        setVoucher(props.voucherCode);
    }, [props.voucherCode])

    return (
        <Table>
            <TableBody>
                <TableRow className={classes.header}>
                    <TableCell colSpan={2}>{t("shopping-cart-header-cell-product")}</TableCell>
                    <TableCell>{t("shopping-cart-header-cell-availability")}</TableCell>
                    <TableCell>{t("shopping-cart-header-cell-unit-price")}</TableCell>
                    <TableCell>{t("shopping-cart-header-cell-amount")}</TableCell>
                    <TableCell align="right">{t("shopping-cart-header-cell-total-price")}</TableCell>
                    <TableCell/>
                </TableRow>
                {
                    props.items.map(({item, quantity, comment}) => (
                        <ShoppingCartRow
                            key={item.slug}
                            item={item}
                            quantity={quantity}
                            comment={comment}
                        />
                    ))
                }
                {
                    <TableRow>
                        {
                            props.applyVoucherResponse ?
                                <TableCell
                                    className={props.applyVoucherResponse.responseCode === ApplyVoucherResponseCode.VALID ? classes.green : classes.red}
                                    colSpan={2} align="right">
                                    {
                                        t(`voucher-response-message-${props.applyVoucherResponse.responseCode}`, {
                                            ...props.applyVoucherResponse,
                                            currencySymbol
                                        })
                                    }{
                                    props.applyVoucherResponse.responseCode === ApplyVoucherResponseCode.VALID ?
                                        (': ' + t(`voucher-type-${props.applyVoucherResponse.voucherType}`, {
                                            ...props.applyVoucherResponse,
                                            currencySymbol
                                        }) + '!')
                                        : ''
                                }
                                </TableCell> : <TableCell colSpan={2}/>
                        }

                        <TableCell colSpan={2}>
                            <TextField
                                label={t('voucher-label')}
                                size="small"
                                fullWidth
                                onChange={ev => setVoucher(ev.target.value)}
                                value={voucher}
                            />
                        </TableCell>
                        <TableCell colSpan={1} align="right">
                            <Button variant="contained" color="primary" fullWidth
                                    disableElevation onClick={() => props.onVoucherCodeApply(voucher)}>
                                {t('voucher-apply-button')}
                            </Button>
                        </TableCell>
                        {props.applyVoucherResponse?.responseCode === ApplyVoucherResponseCode.VALID ?
                            <TableCell colSpan={1} align="right">
                                <Button variant="contained" color="primary" fullWidth
                                        disableElevation onClick={() => props.onVoucherCodeApply('')}>
                                    {t('voucher-remove-button')}
                                </Button>
                            </TableCell> : null}
                    </TableRow>
                }

                {props.itemTotal !== props.netTotal ?
                    <SumRow label={t("shopping-cart-subtotal")} value={props.itemTotal}/> : null}

                {props.showMinValueWarning &&
                <AddingRow cypressLabel={'order-cart-Mindermengenzuschlag'} label={t("shopping-cart-mmz")}
                           value={props.mmzValue}
                           warningText={t("shopping-cart-min-value", {mmz: props.mmzLimitPrice})}/>
                }

                <SumRow cypressLabel={'order-cart-Nettosumme'} label={t("shopping-cart-net-sum")}
                        value={props.netTotal}/>

                {props.systemRebate && props.systemRebate.discount ? <SubtractingRow
                    label={t("shopping-cart-systemRebate", {
                        discount: props.systemRebate.discount,
                        systemName: props.systemRebate.rebateSystemName
                    })} value={props.systemRebate.value}/> : null}
                {props.voucherRebate && props.applyVoucherResponse?.responseCode === ApplyVoucherResponseCode.VALID ?
                    <SubtractingRow label={t("voucher-cart-row", {voucherCode: props.voucherCode})}
                                    value={props.voucherRebate}/> : null}
                {isNotNullOrUndefined(props.rebatedTotal) && props.rebatedTotal !== props.netTotal ?
                    <SumRow cypressLabel={'order-cart-rebated-total'} label={t("rebated-total")}
                            value={props.rebatedTotal}/> : null}

                {isNotNullOrUndefined(props.vatPrice) ?
                    <AddingRow cypressLabel={'order-cart-vat'} label={t("shopping-cart-ust")}
                               value={props.vatPrice}/> : null
                }
                <SumRow cypressLabel={'order-cart-overall-sum'} showBottomSeparator={true}
                        label={t("shopping-cart-overall-sum")} value={props.totalPrice}/>

                <ShippingThresholdInfo missingForFreeShipping={props.missingForFreeShipping}
                                       applyVoucherResponse={props.applyVoucherResponse}
                                       warningClassName={classes.red} successClassName={classes.green}/>
            </TableBody>
        </Table>
    );
};

const TenantShoppingCartTable: React.FC<ShoppingCartTableProps> = props => {
    const classes = useStyles(undefined);
    const {t} = useTranslation();
    return (
        <Table>
            <TableBody>
                <TableRow className={classes.header}>
                    <TableCell colSpan={2}>{t("shopping-cart-header-cell-product")}</TableCell>
                    <TableCell>{t("shopping-cart-header-cell-availability")}</TableCell>
                    <TableCell>{t("shopping-cart-header-cell-unit-price")}</TableCell>
                    <TableCell>{t("shopping-cart-header-cell-amount")}</TableCell>
                    <TableCell align="right">{t("shopping-cart-header-cell-total-price")}</TableCell>
                    <TableCell/>
                </TableRow>
                {
                    props.items.map(({item, quantity, comment}) => (
                        <ShoppingCartRow
                            key={item.slug}
                            item={item}
                            quantity={quantity}
                            comment={comment}
                        />
                    ))
                }
                {props.showMinValueWarning &&
                <Fragment>
                    <TableRow>
                        <TableCell className={classes.minValue} colSpan={2}
                                   data-cy="shopping-cart-mmz-warning">{t("shopping-cart-min-value", {mmz: props.mmzLimitPrice})}
                        </TableCell>
                        <TableCell colSpan={3} align="right">{t("shopping-cart-mmz")}
                        </TableCell>
                        <TableCell colSpan={1} data-cy="shopping-cart-mmz" align="right"><Typography
                            variant="subtitle2" component="div">
                            <Currency value={props.mmzValue}/>
                        </Typography>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </Fragment>
                }
                <TableRow>
                    <TableCell rowSpan={3} colSpan={2}/>
                    <TableCell colSpan={3} align="right">{t("shopping-cart-item-sum-with-vat")}</TableCell>
                    <TableCell colSpan={1} data-cy="shopping-cart-item-sum-with-vat" align="right">
                        <Typography variant="subtitle2" component="div">
                            <Currency value={props.itemTotal}/>
                        </Typography>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={3} align="right">{t("shopping-cart-overall-sum")}</TableCell>
                    <TableCell colSpan={1} data-cy="shopping-cart-total-price" align="right">
                        <Typography variant="subtitle2" component="div">
                            <Currency value={props.netTotal}/>
                        </Typography>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
                <ShippingThresholdInfo missingForFreeShipping={props.missingForFreeShipping}
                                       applyVoucherResponse={props.applyVoucherResponse}
                                       warningClassName={classes.red} successClassName={classes.green}/>
            </TableBody>
        </Table>
    );
};