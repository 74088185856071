import {QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../../HookQueryEntity";
import categoryStore, {State, SubCategoryStore} from "./SubCategoryStore";

@QueryConfig({})
export class SubCategoryQuery extends HookQueryEntity<State> {
    constructor(
        protected store: SubCategoryStore = categoryStore
    ) {
        super(store);
    }
}

export default new SubCategoryQuery();
