import ItemSlug from "../../model/item/ItemSlug";

export const EMPTY_SLUG = '' as ItemSlug
export const SUCCESS_STATUS = "success";
export const PARTIAL_SUCCESS_STATUS = "warning";
export const BACKEND_ERROR_STATUS = "error";

export interface AddingFormRow {
    slug: ItemSlug,
    quantity: number,
    error?: boolean
}

export function parseCsv(text: string, seperator: string = ';', linebreak: RegExp = /\r?\n/) : AddingFormRow[] {
    return text.split(linebreak)
        .filter(line => !!line.trim().length)
        .map(line => line.split(seperator))
        .filter(kv => kv.length >= 2)
        .filter(kv => !isNaN(+kv[1]))
        .map(kv => ({slug: kv[0] as ItemSlug, quantity: isNaN(+kv[1]) ? 1 : +kv[1]}))
}

export function filterBySlugs(original: AddingFormRow[] , dropList: ItemSlug[]): AddingFormRow[] {
    return original.filter(item => dropList.findIndex(resultItem => item.slug === resultItem) === -1)
}
