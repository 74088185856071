import companyService, {CompanyService} from "../../service/CompanyService";
import companyStore, {CompanyStore, FilterState, ID} from "./CompanyStore";

export interface Dependencies {
    companyStore: CompanyStore;
    companyService: CompanyService;
}

export class CompanyActions {
    private readonly companyStore = companyStore;
    private readonly companyService: CompanyService = companyService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async loadSingle(slug: string): Promise<void> {
        try {
            this.companyStore.set([]);
            this.companyStore.update({
                loading: true,
                error: null,
            });
            const result = await this.companyService.findBySlug(slug);
            this.companyStore.set([result]);
            this.companyStore.setActive(result.slug)
        } catch (error) {
            this.companyStore.setError(error);
            throw error;
        } finally {
            this.companyStore.setLoading(false);
        }

        return;

    }

    public async loadGroup(group: string): Promise<ID[]> {
        const filter: FilterState = {group};
        if (this.shouldSkipLoading(filter)) {
            return this.companyStore.getValue().ids || [];
        }

        try {
            this.companyStore.set([]);
            this.companyStore.update({
                loading: true,
                error: null,
                filter
            });
            const result = await this.companyService.findAllByGroup(group);
            this.companyStore.set(result);
            return this.companyStore.getValue().ids || [];
        } catch (error) {
            this.companyStore.setError(error);
            throw error;
        } finally {
            this.companyStore.setLoading(false);
        }
    }

    private shouldSkipLoading(next: FilterState) {
        return this.isFilterEqual(next) && this.hasNoError();
    }

    private hasNoError() {
        const current = this.companyStore.getValue();

        return !current.error;
    }

    private isFilterEqual(next: FilterState) {
        const current = this.companyStore.getValue().filter;

        return next.group === current.group;
    }

    public select(slug: string) {
        this.companyStore.setActive(slug);
    }

    public deselect() {
        this.companyStore.setActive(null);
    }
}

export default new CompanyActions();
