import {CompanySlug} from "../../model/Company";
import {VehicleGroupSlug} from "../../model/VehicleGroup";
import companyActions from "./company/CompanyActions";
import companyQuery from "./company/CompanyQuery";
import vehicleActions from "./vehicle/VehicleActions";
import vehicleGroupActions from "./vehiclegroup/VehicleGroupActions";
import vehicleGroupQuery from "./vehiclegroup/VehicleGroupQuery";
import {isNotNullOrUndefined} from "../../common/util/nullHelper";

export class ShortcutActions {
    private readonly vehicleGroupActions = vehicleGroupActions;
    private readonly companyActions = companyActions;
    private readonly vehicleActions = vehicleActions;
    private readonly vehicleGroupQuery = vehicleGroupQuery;
    private readonly companyQuery = companyQuery;

    public changeGroup(slug: VehicleGroupSlug | undefined) {
        if (isNotNullOrUndefined(slug)) {
            this.vehicleGroupActions.select(slug!);

            this.loadVehicles();
        } else {
            this.vehicleGroupActions.deselect();
        }
        this.companyActions.load(slug);
    }

    public changeCompany(slug: CompanySlug | undefined) {
        if (isNotNullOrUndefined(slug)) {
            this.companyActions.select(slug!);
            this.loadVehicles();
        } else {
            this.companyActions.deselect();
            this.vehicleActions.clear();
        }
    }

    private async loadVehicles(): Promise<void> {
        const company = this.companyQuery.getActiveId();
        const group = this.vehicleGroupQuery.getActiveId();
        if (company || group) {
            await this.vehicleActions.findAllByCompanyAndGroup(company ? company : "" as CompanySlug, group ? group : "" as VehicleGroupSlug);
        } else {
            this.vehicleActions.clear();
        }
    }
}

export default new ShortcutActions();
