import React from "react";
import {List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import AppLink from "../theme/AppLink";
import VehicleItem from "../../model/VehicleItem";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import windowAPI from "../hooks/windowAPI";

const useStyles = makeStyles(theme => ({
    jumpTo: {
        paddingLeft: theme.spacing(2)
    },
    jumpToItem: {
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    }
}));

interface VehicleItemsShortcutNavigationProps {
    items: VehicleItem[]
}

export const VehicleItemsShortcutNavigation: React.FC<VehicleItemsShortcutNavigationProps> = props => {
    const {t} = useTranslation();
    const classes = useStyles(props);

    const getAnchorLink = (name: string) => {
        return `${window.location.pathname}#${name.replace(/\W/g, '')}`;
    }

    const scrollClick = (name: string) => {
        const path =getAnchorLink(name)
        if(document.getElementById(path.split('#')[1])) {
            windowAPI.scrollToY(document.getElementById(path.split('#')[1])!.offsetTop)
        }
    }

    const names = props.items.map(item => item.categoryNames[0]).filter(item => !!item);
    const uniqueNames = Array.from(new Set(names));
    uniqueNames.sort((s1, s2) => {
        return s1.localeCompare(s2);
    });

    if (uniqueNames.length > 0) {
        return <React.Fragment>
            <Box className={classes.jumpTo} fontWeight='fontWeightBold' fontSize='18px'>{t('jump-to')}</Box>
            <List className={classes.jumpToItem}>
                {
                    uniqueNames.map(name => <ListItem key={name} >
                        <AppLink to={getAnchorLink(name)} hashLink={true} onClick={() => scrollClick(name)}>
                            <ListItemText>
                                {name}
                            </ListItemText>
                        </AppLink>
                    </ListItem>)
                }
            </List>
        </React.Fragment>
    } else {
        return <React.Fragment></React.Fragment>
    }
}

export default VehicleItemsShortcutNavigation;