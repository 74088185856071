import React from "react";
import {useTranslation} from "react-i18next";
import signupActions from "../../state/signup/SignupActions";
import signupQuery from "../../state/signup/SignupQuery";
import NumberField from "../form/NumberField";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: "0.75vw",
        marginBottom: "0.75vw",
        fontSize: "0.875rem",
        "&::placeholder": {
            color: theme.palette.secondary.main
        }
    },
    label: {
        color: theme.palette.secondary.main
    }
}));

export interface SignupNumberFieldProps {
    min?: number;
    initialValue?: number;
    property: string;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
}

const SignupNumberField: React.FC<SignupNumberFieldProps> = ({initialValue, property, label, ...props}) => {
    const {t} = useTranslation();
    const classes = useStyles(props);
    const value = signupQuery.useValue(property as any, initialValue);

    const onChange = (newValue: number) => {
        signupActions.update(property as any, newValue);
    }

    return (
        <NumberField
            {...props}
            fullWidth
            label={t(label)}
            name={`${property}`}
            value={value !== undefined ? value : ""}
            onChange={onChange}
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.textField}
            InputLabelProps={{className: classes.label}}
        />
    );
}

export default SignupNumberField;
