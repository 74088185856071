import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, memo} from "react";
import {useTranslation} from "react-i18next";
import {Breadcrumb} from "../../common/breadcrumb/Breadcrumb";
import AppLink from "../theme/AppLink";
import BreadCrumbItem from "./BreadCrumbItem";
import HomeIcon from '@material-ui/icons/Home';
import "./BreadCrumb.css";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    link: {
        display: "flex",
        color: theme.palette.secondary.dark,
    },
    transformBottom: {
        transformOrigin: "bottom left",
    },
    transformTop: {
        transformOrigin: "top left"
    },
    text: {
        transform: "skew(+30deg, 0deg)",
    },
    last: {
        transform: "skew(+30deg, 0deg)",
        position: "relative",
        padding: "5px 10px 5px 5px",
        color: "white",
        background: theme.palette.primary.main,
        fontWeight: "bold",
        '&::after': {
            content: '" "',
            position: "absolute",
            display: "block",
            width: "100%;",
            height: "100%",
            top: 0,
            left: '-3px',
            zIndex: -1,
            background: theme.palette.primary.main,
            transform: "skew(-30deg, 0deg)",
            transformOrigin: "top left",
        },
    },
    homelink: {
        display: "flex",
        transform: "skew(+30deg, 0deg)",
        position: "relative",
        padding: "5px 10px 5px 5px",
        color: theme.palette.secondary.dark,
        background: theme.palette.secondary.main,
        '&::after': {
            content: '" "',
            position: "absolute",
            display: "block",
            width: "100%;",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
            background: theme.palette.secondary.main,
            transform: "skew(-30deg, 0deg)",
            transformOrigin: "bottom left",
        },"&:hover": {
            '&::after': {
                color: theme.palette.warning.contrastText,
                backgroundColor: theme.palette.warning.main
            },
            textDecoration: "none",
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main,
        }
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    transform: {
        fontWeight: "bold",
        transform: "skew(-30deg, 0deg)",
        borderLeftColor:  theme.palette.secondary.light,
        borderRightColor: theme.palette.secondary.light,
        borderLeftWidth: "1px",
        borderRightWidth: "1px"

    }
}));

export interface AppBreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

export const AppBreadcrumbs: FC<AppBreadcrumbsProps> = ({breadcrumbs}) => {
    const {t} = useTranslation();
    const classes = useStyles(undefined);
    return (
        <Breadcrumbs aria-label="breadcrumb" className={classes.root} separator="">
            <div className={classes.transform}>
                <AppLink color="secondary" to="/" className={classes.homelink}>
                    <HomeIcon className={classes.icon}/>
                    {t("route-home")}
                </AppLink>
            </div>
            {breadcrumbs.map(({text, to, disable = false}, index, all) =>
                (
                    <BreadCrumbItem
                        data-cy="breadcrumb"
                        active={index !== all.length - 1 && !disable}
                        key={to}
                        to={to}
                    ><div className={(index === all.length - 1 ? classes.last : classes.text)}>{text}</div></BreadCrumbItem>
                ))}
        </Breadcrumbs>
    );
};
export default memo(AppBreadcrumbs);
