import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InternalServerErrorPage from "../../../errors/500";
import PaymentType from "../../model/checkout/PaymentType";
import paymentTypeService from "../../service/PaymentTypeService";
import shoppingCartActions from "../../state/shoppingCart/ShoppingCartActions";
import shoppingCartQuery from "../../state/shoppingCart/ShoppingCartQuery";

const useStyles = makeStyles((theme) => ({
    radioGroup: {
        padding: theme.spacing(1)
    },
    grid: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        fontWeight: "bold"
    },
    paper: {
        cursor: 'pointer',
        padding: theme.spacing(2),
        margin: theme.spacing(1)
    },
    label: {
        textTransform: "capitalize"
    },
    firstOrder: {
        paddingRight: theme.spacing(4)
    }
}));


const PaymentOptions: FC = () => {
    const { t } = useTranslation();
    const classes = useStyles(undefined);
    const paymentType: PaymentType = shoppingCartQuery.usePaymentType();
    const isPaymentDisabled = shoppingCartQuery.useIsPaymentDisabled();
    const isFirstOrder: boolean = shoppingCartQuery.useFirstOrder();
    const [options, setOptions] = useState<PaymentType[]>([]);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        paymentTypeService.findForCurrentUser()
            .then(setOptions)
            .catch(setError);
    }, []);

    function onChange(value: PaymentType) {
        shoppingCartActions.setPaymentType(value).catch(error => console.log(error));
    }

    const getPaymentTypeLabel = (option: PaymentType) => {
        switch (option) {
            case PaymentType.SOFORT:
                return <img src="/img/payment/Sofort.png"
                            alt={t("checkout-payment-instant")}
                />;
            case PaymentType.STRIPE:
                return <img src="/img/payment/Stripe.png"
                            alt={t("checkout-payment-instant")}
                />;
            default:
                return option.toLowerCase();
        }
    }

    return (
        <Fragment>
            {
                error && <InternalServerErrorPage/>
            }
            <FormControl component="fieldset">
                <Typography variant="subtitle1" className={classes.grid} data-cy="payment-title">
                    {t("checkout-payment-options")}</Typography>
                <RadioGroup className={classes.radioGroup} aria-label="payment-options" name="payment-options" data-cy="payment-options"
                            value={paymentType}
                            onChange={event => onChange(event.target.value as PaymentType)}>
                    {
                        options.map((option: PaymentType) => (
                            <Paper onClick={() => onChange(option)} key={option} className={classes.paper} variant="outlined">
                                <FormControlLabel
                                    className={classes.label}
                                    value={option}
                                    control={<Radio/>}
                                    disabled={isPaymentDisabled(option)}
                                    label={getPaymentTypeLabel(option)}/>
                                {option === PaymentType.NACHNAHME && isPaymentDisabled(option) &&
                                <FormHelperText
                                    children={t("checkout-nox-hint")}
                                />
                                }
                            </Paper>
                        ))
                    }
                </RadioGroup>
            </FormControl>
            { isFirstOrder &&
            <div className={classes.firstOrder}>
                <strong>{t("checkout-payment-hint")}</strong>
                {t("checkout-payment-first-order")}
            </div>
            }
        </Fragment>
    );
};
export default PaymentOptions;
