import {transaction} from "@datorama/akita";
import itemService, {ItemService} from "../../service/ItemService";
import vehicleStore, {FilterState, VehicleItemStore} from "./VehicleItemStore";
import VehicleItem from "../../model/VehicleItem";

export interface Dependencies {
    store: VehicleItemStore;
    service: ItemService;
}

export class VehicleItemActions {
    private readonly store = vehicleStore;
    private readonly service = itemService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    @transaction()
    public async findAll(vehicle: string, category: string): Promise<VehicleItem[]> {
        const filter: FilterState = {vehicle, category};
        if (this.isFilterEqual(filter)) {
            return [];
        }
        try {
            this.store.update({
                loading: true,
                error: null,
                filter
            });
            var result = await this.service.findAllByVehicleAndCategory(vehicle, category);
            this.store.set(result);
            return result;
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }

    private isFilterEqual(next: FilterState) {
        const current = this.store.getValue().filter;

        return next.vehicle === current.vehicle && next.category === current.category;
    }

    public async select(slug: string): Promise<void> {
        const current = this.store.getValue();

        if (current.active !== slug) {
            this.store.setActive(slug);
        }
    }

    public async deselect(): Promise<void> {
        this.store.setActive(null);
    }

    public async set(items: VehicleItem[]): Promise<void> {
        console.log('setting items: ', items)
        this.store.set(items);
    }
}

export default new VehicleItemActions();
