import {useTranslation} from "react-i18next";
import Breadcrumb from "./Breadcrumb";

export function useCategoryBreadcrumbs(section?: string | null) {
    const { t } = useTranslation();
    const breadcrumbs: Breadcrumb[] = [];

    if (section) {
        breadcrumbs.push(
            {
                text: section,
                to: `/${section}/fahrzeuge/`
            },
            {
                text: t("category-breadcrumb-text"),
                to: `/${section}/produkte/`,
                disable: true
            },
        );
    }
    return breadcrumbs;
}

export default useCategoryBreadcrumbs;
