import {EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import {CompanySlug} from "../../../../model/Company";
import Reference from "../../../../model/Reference";

export type EntityType = Reference<CompanySlug>;

export interface CompanyState extends EntityState<EntityType, CompanySlug> {
    category: string;
}

export function createInitialState(): Partial<CompanyState> {
    return {
        loading: false,
        category: ""
    };
}

@StoreConfig({
    name: "filter.companies",
    idKey: "slug"
})
export class CompanyStore extends EntityStore<CompanyState> {
    constructor() {
        super(createInitialState());
    }
}

export default new CompanyStore();
