import Image from "../Image";
import ItemSlug from "../item/ItemSlug";
import SearchResult from "./SearchResult";
import {SearchResultType} from "./SearchResultType";

export interface ItemSearchResult extends SearchResult<ItemSlug> {
    type: SearchResultType.ITEM;
    itemNumber: string;
    image: Image | null;
}

export function isItemSearchResult(searchResult: SearchResult): searchResult is ItemSearchResult {
    return searchResult.type === SearchResultType.ITEM;
}
