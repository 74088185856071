import {ActiveState, EntityState, EntityStore, StoreConfig} from "@datorama/akita";
import VehicleItem from "../../model/VehicleItem";

export interface VehicleItemFilter {
    vehicle: string;
    category: string;
}

export type FilterState = VehicleItemFilter | {
    vehicle: null;
    category: null;
}

export interface State extends EntityState<VehicleItem, string>, ActiveState<string> {
    filter: FilterState
}

export function createInitialFilterState(): FilterState {
    return {
        vehicle: null,
        category: null
    };
}

/*
it is possible for an item to belong to multiple categories.
the store cannot use the slug as a definitive key in that case, because it would overwrite the state of one with the other
 */
@StoreConfig({
    name: "vehicle.item",
    idKey: "_treeKey"
})
export class VehicleItemStore extends EntityStore<State> {
    constructor() {
        super({filter: createInitialFilterState()});
    }

    akitaPreAddEntity(x: Readonly<VehicleItem>): VehicleItem & {_treeKey: string;} {
        return {
            ...x,
            _treeKey: x.categoryNames.join(".") + "." + x.slug
        };
    }
}

export default new VehicleItemStore();
