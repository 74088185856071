import {transaction} from "@datorama/akita";
import {CategorySlug} from "../../../model/Category";
import categoryService from "../../../service/CategoryService";
import categoryStore from "./CategoryStore";

export class CategoryActions {
    private readonly store = categoryStore;
    private readonly service = categoryService;

    @transaction()
    public async load(section: string): Promise<void> {
        if (this.shouldSkipLoading(section)) {
            return;
        }

        try {
            this.deselect();
            this.store.set([]);
            this.store.update({
                loading: true,
                error: null,
                section
            });
            const result = await this.service.findAllByParentForPreorder(section);
            this.store.set(result);
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }


    private shouldSkipLoading(section: string) {
        return this.isFilterEqual(section) && this.hasNoError();
    }

    private hasNoError() {
        const current = this.store.getValue();

        return !current.error;
    }

    private isFilterEqual(next: string) {
        const {section} = this.store.getValue();

        return next === section;
    }

    public select(slug: string) {
        this.store.setActive(slug as CategorySlug);
    }

    public deselect() {
        this.store.setActive(null);
    }
}

export default new CategoryActions();
