import React, {FC, useEffect, useState} from "react";
import getItemPath from "../../../common/route/getItemPath";
import Category from "../../../model/Category";
import VehicleItem from "../../../model/VehicleItem";
import Node, {createTree} from "./Node";

export interface VehicleItemTreeProps {
    items: VehicleItem[];
    categories: Category[];
    getLink?: (item: VehicleItem) => string
    mini?: boolean
}

export const VehicleItemTree: FC<VehicleItemTreeProps> = ({items, categories, getLink, mini}) => {
    const [tree, setTree] = useState(createTree([], []))
    useEffect(() => {
       setTree(createTree(items, categories))
    }, [items, categories])

    function getItemLink(item: VehicleItem) {
        return getItemPath(item.slug);
    }

    return <Node mini={mini} node={tree} getLink={getLink || getItemLink} rootNode/>;
};

export default VehicleItemTree;
