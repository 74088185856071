import {useEffect, useState} from "react";
import turnOverService  from "./turnover.service";

export const useTurnoverForRebateGroups = () : {rebateSystemAssigned: boolean, turnover: number, diffToNextRebateGroup: number} => {
    const turnoverService = turnOverService
    const [turnover, setTurnOver] =  useState(0.00);
    const [diffToNextRebateGroup, setDiffToNextRebateGroup] =  useState(0.00);
    const [rebateSystemAssigned, setRebateSystemAssigned] = useState(false);

    useEffect(() =>{
        turnoverService.getTurnover()
            .then(it => setTurnOver(it.turnover))
            .catch((err) => {
                console.log(err);
                setTurnOver(0);
            })
    }, [turnoverService])

    useEffect(() =>{
        turnoverService.getDiffToNextRebateGroup()
            .then(it => {
                setDiffToNextRebateGroup(it.diff);
                setRebateSystemAssigned(it.systemAssigned)
            })
            .catch((err) => {
                console.log(err);
                setDiffToNextRebateGroup(0);
                setRebateSystemAssigned(false);
            })
    }, [turnoverService])

    return {rebateSystemAssigned, turnover, diffToNextRebateGroup}
}