import {deDE} from "@material-ui/core/locale";
import {createMuiTheme} from "@material-ui/core/styles";
import Theme from "./app/model/Theme";
import themeCalculator from './color-calculator'

const cyan = '#2bb6b7'
const orange = '#f75e1b'
const grey = '#999999'
const green = '#39b54a'
const red = '#ff0000'

export default createTheme({
    primaryColor: cyan,
    secondaryColor: grey,
    accentColor: orange

});

export function createTheme({primaryColor, secondaryColor, accentColor}: Theme) {

    let secondaryScheme = themeCalculator.getThemeColors(secondaryColor)
    const warning = accentColor ? accentColor : themeCalculator.getComplement(primaryColor)


    return createMuiTheme({
        palette: {
            text: {
                primary: secondaryScheme.dark,
                secondary: primaryColor,
            },
            primary: {
                main: primaryColor,
                contrastText: themeCalculator.getContrastText(primaryColor)
            },
            warning: {
                main: warning,
                contrastText: themeCalculator.getContrastText(warning)
            },
            secondary: {
                dark: secondaryScheme.dark,
                light: secondaryScheme.light,
                main: secondaryScheme.base,
                contrastText: themeCalculator.getContrastText(secondaryScheme.dark)
            },
            error: {
                main: red
            },
            success: {
                main: green
            }
        },
        props: {
            MuiContainer: {
                maxWidth: "lg"
            },
            MuiGrid: {
                spacing: 2
            }
        },
        overrides: {
            MuiListItem: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        }
    }, deDE)
}
