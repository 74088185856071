import makeStyles from "@material-ui/core/styles/makeStyles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import React, {FC} from "react";
import windowAPI from "./hooks/windowAPI";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 2100
    }
}));

export interface ScrollTopProps {
    children: React.ReactElement;
}

export const ScrollTop: FC<ScrollTopProps> = (props) => {
    const { children } = props;
    const classes = useStyles(props);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
            windowAPI.scrollToY(0)
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
};

export default ScrollTop;    
