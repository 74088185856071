import makeStyles from "@material-ui/core/styles/makeStyles";
import numeral from "numeral";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import authQuery from "../state/auth/AuthQuery"

const useStyles = makeStyles(theme => ({
    root: {
        whiteSpace: "nowrap",
        color: theme.palette.primary.main,
        fontWeight: 'bolder',
    },
}));

export interface CurrencyProps {
    value: number;
    className?: string;
}

export const Currency: FC<CurrencyProps> = ({value, className}) => {
    const currencySymbol = authQuery.useCurrencySymbol();

    const {t} = useTranslation();
    const currencyFormat = t("currency-format");
    const classes = useStyles();

    return (
        <span className={`${classes.root} ${className}`}>
            {numeral(value).format(currencyFormat) + ' ' + currencySymbol}
        </span>
    );
};

export default Currency;    
