import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useParams} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import NotFoundError from "../../app/service/error/NotFoundError";
import itemService, {ReturnItem} from "../../app/service/returns/ItemService";
import PageNotFound from "../../errors/404";
import PageInternalServerError from "../../errors/500";

export interface Params {
    id: string;
}

export const ReturnPage: FC = () => {
    const {t} = useTranslation();
    const {id} = useParams<Params>();
    const breadcrumbs: Breadcrumb[] = [];
    const [items, setItems] = useState<ReturnItem[]>([]);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        itemService.findFormItems(id).then(setItems).catch(setError)
    }, [id]);

    breadcrumbs.push(
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html"
        },
        {
            text: t("returns-archive-page-title"),
            to: "/ruecksendungen/"
        },
        {
            text: t("returns-id-page-title"),
            to: `/ruecksendungen/${id}`
        }
    );

    if (error instanceof NotFoundError) {
        return (<PageNotFound/>);
    } else if (error) {
        return (<PageInternalServerError/>);
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title"  header={t("returns-id-page-title")}></ContentHeader>
            <Grid container>
                <Grid item md={12}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("returns-item-label")}</TableCell>
                                    <TableCell>{t("returns-item-name-label")}</TableCell>
                                    <TableCell>{t("returns-item-count-label")}</TableCell>
                                    <TableCell>{t("returns-reason-label")}</TableCell>
                                    <TableCell>{t("returns-comment-label")}</TableCell>
                                    <TableCell>{t("returns-invoice-label")}</TableCell>
                                    <TableCell>{t("returns-added-at-label")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.itemNumber}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                        <TableCell>{t(`returns-type-${item.reason}`)}</TableCell>
                                        <TableCell><span style={{whiteSpace: 'pre-wrap'}}>{item.comment}</span></TableCell>
                                        <TableCell>{item.invoiceNumber} ({moment(item.invoiceDate).format(t("date-format"))})</TableCell>
                                        <TableCell>{moment(item.addedAt).format(t("date-time-format"))}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item md={2} sm={3}>
                    <Button variant="contained" color="default" fullWidth
                            disableElevation
                            component={RouterLink}
                            to="/ruecksendungen/"
                            children={t("back")}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ReturnPage;
