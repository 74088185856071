import configService, {ConfigService} from "../../service/ConfigService";
import store, {ConfigStore} from "./ConfigStore";

export interface Dependencies {
    store: ConfigStore;
    service: ConfigService;
}

export class ConfigActions {
    private readonly store = store;
    private readonly service = configService;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
    }

    public async load() {
        if (this.shouldSkipLoading()) {
            return;
        }

        try {
            this.store.update({
                loading: true,
                error: null
            });
            const result = await this.service.getConfig();
            this.store.update({...result, init: true});
        } catch (error) {
            this.store.setError(error);
        } finally {
            this.store.setLoading(false);
        }
    }

    private shouldSkipLoading(): boolean {
        const {init, loading} = this.store.getValue();
        return init || loading;
    }
}

export default new ConfigActions();
