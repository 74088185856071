import Grid from "@material-ui/core/Grid";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {ItemSearchResult} from "../../model/search/ItemSearchResult";

export interface ResultItemProps {
    value: ItemSearchResult;
}

const useStyles = makeStyles(theme => ({
    image: {
        maxHeight: theme.spacing(5),
        maxWidth: theme.spacing(5)
    }
}));


export const ItemResultItem: FC<ResultItemProps> = ({value, ...props}) => {
    const {t} = useTranslation();
    const {name, itemNumber, image} = value;
    const classes = useStyles(props);

    return (
        <Fragment>
            {
                image &&
                <ListItemAvatar>
                    <Grid container justify="center">
                        <img className={classes.image}
                             src={image.locationSmall}
                             alt={image.description}/>
                    </Grid>
                </ListItemAvatar>
            }
            <ListItemText
                data-cy="search-result"
                primary={name}
                secondary={t("result-item-item-item-number", {itemNumber})}
                {...props}
            />
        </Fragment>
    );
};

export default ItemResultItem;
