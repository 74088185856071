import {QueryConfig} from "@datorama/akita";
import {useMemo} from "react";
import {useObservable} from "../../../common/useObservable";
import {ItemFilter} from "../../../service/ItemService";
import {HookQueryEntity} from "../../HookQueryEntity";
import itemStore, {ItemStore, State} from "./ItemStore";

@QueryConfig({})
export class ItemQuery extends HookQueryEntity<State> {
    private readonly filter = this.select("filter");
    private readonly count = this.select("totalItemsCount");
    private readonly sort = this.select(state => state.filter.sort);
    private readonly category = this.select(state => state.filter["category.slug"]);

    constructor(
        protected store: ItemStore = itemStore
    ) {
        super(store);
    }

    public useItemFilter(): State["filter"] {
        return useObservable(this.filter, this.getValue().filter);
    }

    public useFilterProperty<K extends keyof ItemFilter>(key: K): ItemFilter[K] {
        const mapper = useMemo(() => (state: State) => state.filter[key], [key]);
        const observable = useMemo(() => this.select(mapper), [mapper]);
        const current = useMemo(() => mapper(this.getValue()), [mapper]);

        return useObservable(observable, current);
    }

    public useCategory(): ItemFilter["category.slug"] {
        return useObservable(this.category, this.getValue().filter["category.slug"]);
    }

    public useSort(): ItemFilter["sort"] {
        return useObservable(this.sort, this.getValue().filter.sort);
    }

    public useCount(): State["totalItemsCount"] {
        return useObservable(this.count, this.getValue().totalItemsCount);
    }
}

export default new ItemQuery();
