import {Store} from "./Store";
import React, {FC} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {MailRounded, PhoneIphoneRounded, PhoneRounded} from "@material-ui/icons";

const useStyles = makeStyles({
    container: {
        width: '100%',
        fontSize: "8pt",
    },
    icon: {
        fontSize: "10pt",
    },
    row: {
        display: "block"
    },
    rowPadded: {
        display: "block",
        marginTop: "2pt"
    },
    header: {
        display: "block",
        fontSize: "10pt",
        marginBottom: '5px'
    },
})

interface StorePopUpProps {
    store: Store
}

const StorePopUp: FC<StorePopUpProps> = ({store}: StorePopUpProps) => {
    const classes = useStyles(undefined);

    return (<div className={classes.container}>
        <span className={classes.header}>{store.companyName}</span>
        <span className={classes.rowPadded}>{store.street}, {store.zip} {store.city}</span>
        <span className={classes.row}>{store.hours}</span>
        <span className={classes.rowPadded}><PhoneRounded className={classes.icon}/> {store.phone}</span>
        <span className={classes.row}><PhoneIphoneRounded className={classes.icon}/> {store.mobile}</span>
        <span className={classes.row}><MailRounded className={classes.icon}/> <a
            href={'mailto://' + store.mail}>{store.mail}</a></span>
        <span className={classes.rowPadded}><a href={store.homepage}>{store.homepage}</a></span>
    </div>)
}

export default StorePopUp;
