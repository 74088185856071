import HttpService from "./HttpService";

export interface ContactData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

export class ContactService extends HttpService {
    public async create(data: ContactData): Promise<void> {
        return this.post("/contact/", undefined, data);
    }
}

export default new ContactService();
