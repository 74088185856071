import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import React, {FC, Fragment, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumb from "../../app/common/breadcrumb/Breadcrumb";
import ContentHeader from "../../app/component/ContentHeader";
import userService from "../../app/service/UserService";
import InternalServerErrorPage from "../../errors/500";

export const ResetPasswordPage: FC = () => {
    const {t} = useTranslation();
    const [error, setError] = useState<any>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [user, setUser] = useState<string>("");
    const isInvalid: boolean = !user;

    function clear() {
        setSuccess(false);
        setError(null);
    }

    function onSubmit() {
        clear();
        userService.createPasswordReset(user)
            .then(() => setSuccess(true))
            .catch(setError)
    }

    const breadcrumbs: Breadcrumb[] = [
        {
            text: t("user-breadcrumb"),
            to: "/benutzer/index.html",
        },
        {
            text: t("password-reset-title"),
            to: "/benutzer/password/reset/",
        }
    ];

    if (error) {
        return <InternalServerErrorPage/>
    }

    return (
        <Fragment>
            <ContentHeader breadCrumbs={breadcrumbs} data-cy="page-title" header={t("password-reset-title")}></ContentHeader>
            {
                success &&
                <span data-cy="alert">
                    <Trans i18nKey="password-reset-email-sent"
                           values={{email: user}}
                           components={[<strong/>, <Link component={RouterLink} to="/kontakt/"/>]}
                    />
                </span>
            }
            <Grid
                container
                spacing={4}
                component="form"
                onSubmit={(event: any) => {
                    event.preventDefault();
                    onSubmit();
                }}
            >
                <Grid item container md={12}>
                    <Grid item md={7}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            label={t(`password-reset-user-label`)}
                            name="user"
                            value={user}
                            onChange={(event) => {
                                clear();
                                setUser(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item md={3}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="default"
                        disabled={isInvalid}
                        disableElevation>{t("password-reset-save-button")}</Button>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ResetPasswordPage;
