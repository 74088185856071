import {Store, StoreConfig} from "@datorama/akita";
import Category from "../../../../model/Category";

export interface CategoryState {
    loading: boolean;
    category: Category | null;
    error: Error | null;
}

export function createInitialState(): Partial<CategoryState> {
    return {
        loading: false,
        error: null,
        category: null
    };
}

@StoreConfig({
    name: "filter.category"
})
export class CategoryStore extends Store<CategoryState> {
    constructor() {
        super(createInitialState());
    }
}

export default new CategoryStore();
