import React from "react";
import {Language} from "../../app/state/language/UserLanguage";
import {IconButton} from "@material-ui/core";
import ImageIcon from "../../app/component/ImageIcon";

export interface LanguageToggleActionProps {
    currentLanguage: Language;
    changeLanguage: (language: Language) => void;
}

export const LanguageToggleAction: React.FC<LanguageToggleActionProps> = props => {
    const toggle = () => props.changeLanguage(props.currentLanguage === Language.GERMAN ? Language.ENGLISH : Language.GERMAN);
    return (<IconButton onClick={() => {
        toggle();
        window.location.reload(false);
    }} aria-label="toggle language">
        <ImageIcon src={"/img/language/" + props.currentLanguage + ".svg"}/>
    </IconButton>);
};

export default LanguageToggleAction;