import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, ReactNode} from "react";
import { SimplePaletteColorOptions, Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";

const useStyles = (props: PanelProps) => makeStyles(theme => ({
    content: {
        backgroundColor: props.background ? props.background.light : 'transparent',
        padding: theme.spacing(2)
    },
    wrapper: {
        marginBottom: theme.spacing(2),
        borderBottom: props.heading ? '5px solid ' + theme.palette.primary.main : 'none',
    },
    heading: {
        fontWeight: 'bold',
        textAlign: "center",
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText
    }
}));

export interface PanelProps {
    background?: SimplePaletteColorOptions
    children: ReactNode
    heading?: string
}

export const Panel: FC<PanelProps> = (props: PanelProps) => {
    const classes = useStyles(props)(props);

    return (
        <Paper variant="outlined" className={classes.wrapper}>
            {
                props.heading &&
                <Typography className={classes.heading} component={"h1"} variant={"h6"}>{props.heading}</Typography>

            }
            <CardContent className={classes.content}>
                    {props.children}
            </CardContent>
        </Paper>
    );
};
