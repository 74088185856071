import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {useObservable} from "../../common/useObservable";
import authActions from "../../state/auth/AuthActions";
import authQuery from "../../state/auth/AuthQuery";
import LoginForm from "./LoginForm";

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: theme.spacing(3),
        maxWidth: 420
    },
    dialogPaperScrollPaper: {
        maxHeight: "none"
    },
    dialogContent: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

export const LoginDialog: FC = () => {
    const classes = useStyles(undefined);
    const {t} = useTranslation();
    const loginOpen = useObservable(authQuery.isLoginOpen, false);

    function onClose() {
        authActions.closeLoginDialog();
    }

    return (
        <Dialog
            open={loginOpen}
            onClose={onClose}
            classes={{
                paper: classes.dialogPaper,
                paperScrollPaper: classes.dialogPaperScrollPaper
            }}
        >
            <DialogTitle
                style={{
                    width: "100%"
                }}
                disableTypography
            >
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">{t("auth-login-dialog-title")}</Typography>
                    <IconButton
                        onClick={onClose}
                        style={{marginRight: -12, marginTop: -10}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <LoginForm/>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;
