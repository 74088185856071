import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import React, {FC, useEffect, useState} from "react";
import Image from "../../model/Image";
import SideBySideMagnifier from "../magnifier/SideBySideMagnifier";
import {AspectRatio} from "./AspectRatio";

export interface ImagesProps {
    images: Image[];
}

const useStyles = makeStyles(theme => ({
    magnifier: {
        width: "100%",
        paddingTop: "100%",
        position: "relative",
        "& > div": {
            position: "absolute !important" as any,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            "& > div": {
                zIndex: "2001 !important" as any
            },
            "& > img": {
                width: "unset !important" as any,
                maxWidth: "100%",
                maxHeight: "100%",
                margin: "auto"
            }
        }
    },
    button: {
        padding: 0
    }
}));

export const Images: FC<ImagesProps> = ({images}) => {
    const classes = useStyles(undefined);
    const theme = useTheme();
    const [active, setActive] = useState(0);
    const [scale, setScale] = useState(1);
    const current = images[active];

    useEffect(() => {
        const current: any = document.getElementById("images-link");

        if (current) {
            const onWheel = (e: any) => {
                if (e.deltaY < 0) {
                    setScale(s => s * 1.1);
                } else {
                    setScale(s => Math.max(1, s * 0.9));
                }
                e.preventDefault();
            };
            current.addEventListener("wheel", onWheel);

            return () => {
                current.removeEventListener("wheel", onWheel);
            }
        }
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <a href={current.locationLarge} id="images-link">
                    <SideBySideMagnifier
                        className={classes.magnifier}
                        data-cy="item-image"
                        fillAvailableSpace={true}
                        fillGapLeft={theme.spacing(4)}
                        overlayOpacity={0.5}
                        imageSrc={current.locationDefault}
                        largeImageSrc={current.locationLarge}
                        imageAlt={current.description}
                        // @ts-ignore
                        scale={scale}
                    />
                </a>
            </Grid>
            {
                images.length > 1 &&
                images.map((image, index) => (
                    <Grid item
                          md={4}
                          key={image.locationLarge}
                    >
                        <Button
                            className={classes.button}
                            disabled={index === active}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            onClick={() => setActive(index)}
                        >
                            <AspectRatio>
                                <img
                                    data-cy="item-image-thumbnail"
                                    src={image.locationSmall}
                                    alt={image.description}
                                />
                            </AspectRatio>
                        </Button>
                    </Grid>
                ))}
        </Grid>
    );
};

export default Images;
