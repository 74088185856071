import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, useEffect, useState, Fragment} from "react";
import InternalServerErrorPage from "../../../errors/500";
import News from "../../model/News";
import newsService from "../../service/NewsService";
import authQuery from "../../state/auth/AuthQuery";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(1),
        width: "100%",
        marginTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        color: theme.palette.warning.main,
        fontWeight: 'bolder',
        fontStyle: 'italic',
        fontSize: 'xxx-large',
    },
    grid: {
        marginBottom: theme.spacing(2),
        backgroundColor: "white",
        borderRadius: '2px',
        border: '2px solid ' + theme.palette.primary.main  + '22',
        cursor: 'pointer',
        overflow: 'hidden',
        padding: theme.spacing(2),
        minWidth: '400px',
        flexBasis: '48%',
        boxShadow: '0 2pt 5pt 0pt ' + theme.palette.secondary.main + 'dd',
        "& img": {
          width: '100%',
            height: 'auto'
        },
        "&:hover": {
            background: "#efefef",
            textDecoration: 'none',
        }
    }
}));

export const NewsList: FC = () => {
    const classes = useStyles(undefined);
    const [news, setNews] = useState<News[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const roles = authQuery.useRoles();
    const {t} = useTranslation();

    useEffect(() => {
        newsService.getAllCurrentNews()
            .then(setNews)
            .catch(setError)
        ;
    }, [roles]);

    if (!news?.length) {
        return null;
    }
    return <Fragment>
        {error ? <InternalServerErrorPage/> :
            <Fragment>
                <Typography className={classes.header} data-cy={"item-news-title"}
                            variant="h6" component="h2">{t("news-current-title").toUpperCase()}
                </Typography>
                {
                news.filter(it => !!it.content).map(({title, content, slug}) => (
                <Grid item className={classes.grid} key={slug} data-cy={"news-current-content"} dangerouslySetInnerHTML={{__html: content!!}} />))
                }
            </Fragment>
        }
    </Fragment>
};

export default NewsList;
