import React from "react";
import TenantSwitch from "./TenantSwitch";

const NotForTenant: React.FC = props => {
    const forBase = <React.Fragment>{props.children}</React.Fragment>;
    return <TenantSwitch forBase={forBase} />
}

export default NotForTenant;

