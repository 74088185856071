import {Order, QueryConfig} from "@datorama/akita";
import {HookQueryEntity} from "../HookQueryEntity";
import vehicleStore, {MotorStore, State} from "./MotorStore";

@QueryConfig({
    sortBy: "name",
    sortByOrder: Order.ASC
})
export class MotorQuery extends HookQueryEntity<State> {
    constructor(
        protected store: MotorStore = vehicleStore
    ) {
        super(store);
    }
}

export default new MotorQuery();
