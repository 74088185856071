import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import React, {FC} from "react";
import {GridDefaultSpacing} from "../../config/Config";
import VehicleGroupModel from "../../model/VehicleGroup";
import vehicleGroupQuery from "../../state/vehiclegroup/VehicleGroupQuery";
import VehicleGroupImageLink from "./VehicleGroupImageLink";

export const VehicleGroupImages: FC = () => {
    const loading = vehicleGroupQuery.useIsLoading();
    const vehicleGroups = vehicleGroupQuery.useAll();

    if (loading) {
        return (<CircularProgress/>);
    } else {
        return (
            <VehicleGroupImagesPure groups={vehicleGroups}/>
        );
    }
};


export default VehicleGroupImages;

export interface VehicleGroupImagesProps {
    groups: VehicleGroupModel[];
}

export const VehicleGroupImagesPure: FC<VehicleGroupImagesProps> = ({groups}) => {
    return (
        <Grid container spacing={GridDefaultSpacing}>
            {groups.map((group) => (
                <Grid item key={group.slug} md={4}>
                    <VehicleGroupImageLink group={group}/>
                </Grid>
            ))}
        </Grid>
    );
};
