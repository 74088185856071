import {QueryConfig} from "@datorama/akita";
import {useObservable} from "../../common/useObservable";
import {HookQueryEntity} from "../HookQueryEntity";
import explodedViewStore, {Entity, State} from "./ExplodedViewStore";

@QueryConfig({})
export class ExplodedViewQuery extends HookQueryEntity<State> {
    private readonly name = this.select("name");
    private readonly image = this.select("image");
    private readonly items = this.selectActive(box => box.items);
    private readonly highlight = this.select(this.getHighlight)

    private getHighlight({highlight, entities}: State): Entity | undefined {
        if (highlight) {
            return entities?.[highlight];
        }
    }

    constructor(
        protected store = explodedViewStore
    ) {
        super(store);
    }

    public getSlug(): State["slug"] {
        return this.getValue().slug || "";
    }

    public useName(): State["name"] {
        return useObservable(this.name, this.getValue().name) || "";
    }

    public useImage(): State["image"] | undefined {
        return useObservable(this.image, this.getValue().image);
    }

    public useHighlight(): Entity | undefined {
        const mapper = this.getHighlight

        return useObservable(this.highlight, mapper(this.getValue()));
    }

    public useItems(): Entity["items"] {
        return useObservable(this.items, this.getActive()?.items) || [];
    }
}

export default new ExplodedViewQuery();
