import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import {InfoOutlined} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    box: {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        padding: "1vw",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    icon: {
        color: theme.palette.warning.contrastText,
        marginRight: "0.5vw"
    }
}));

export interface InfoMessageProps {
}

const InfoMessage: React.FC<InfoMessageProps> = (props) => {
    const classes = useStyles(props);
    return <Paper className={classes.box}>
        <InfoOutlined className={classes.icon}/>
        {props.children}
    </Paper>
};

export default InfoMessage;