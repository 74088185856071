import {Query, QueryConfig} from "@datorama/akita";
import {useObservable} from "../../../../common/useObservable";
import Category from "../../../../model/Category";
import {throttledLoading} from "../../../throttleLoading";
import store from "./CategoryStore";
import categoryStore, {CategoryState, CategoryStore} from "./CategoryStore";

@QueryConfig({})
export class CategoryQuery extends Query<CategoryState> {
    public readonly isLoading = throttledLoading(this);
    public readonly error = this.selectError<Error | null>();

    constructor(
        protected store: CategoryStore = categoryStore
    ) {
        super(store);
    }

    public useCategory(): Category | null {
        return useObservable(this.select("category"), store.getValue().category);
    }
}

export default new CategoryQuery();
