import {CategorySlug} from "../Category";
import Reference from "../Reference";
import SearchResult from "./SearchResult";
import {SearchResultType} from "./SearchResultType";

export interface CategorySearchResult extends SearchResult<CategorySlug> {
    type: SearchResultType.CATEGORY;
    section: Reference;
    linage: Reference[];
}

export function isCategorySearchResult(searchResult: SearchResult): searchResult is CategorySearchResult {
    return searchResult.type === SearchResultType.CATEGORY;
}
