import React, {FC, useEffect, useState} from "react";
import {DropzoneArea} from "material-ui-dropzone";
import {useTranslation} from "react-i18next";
import returnFilesQuery from "../../state/returns/files/ReturnFilesQuery";
import returnFilesActions from "../../state/returns/files/ReturnFilesActions";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const FILE_NUMBER_LIMIT = 1000;
const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MiB
const FILE_SIZE_LIMIT_STRING = '10 MiB'

export const AddReturnFilesForm: FC = () => {
    const {t} = useTranslation();
    const files = returnFilesQuery.getFiles();

    // We need to unmount the dropzone area when the files array changed to clear it. For that we change the key when files change.
    // This hack is necessary: https://github.com/Yuvaleros/material-ui-dropzone/issues/9
    const [dropzoneAreaKey, setDropzoneAreaKey] = useState<number>(0);
    const numFiles = files?.length;
    useEffect(() => {
        setDropzoneAreaKey(prev => prev + 1);
    }, [numFiles]);

    return <Grid container component="form"
                 onSubmit={(event: any) => {
                     event.preventDefault();
                     returnFilesActions.addFiles().catch(/* It seems like we don't care */);
                 }}
    >
        <Grid item xs={12}>
            <DropzoneArea
                key={dropzoneAreaKey}
                initialFiles={files}
                onChange={files => returnFilesActions.setFiles(files)}
                filesLimit={FILE_NUMBER_LIMIT}
                maxFileSize={FILE_SIZE_LIMIT}
                showAlerts={true}
                showFileNames={true}
                getFileAddedMessage={fileName => t('returns-dropzone-file-added', {fileName})}
                getFileRemovedMessage={fileName => t('returns-dropzone-file-removed', {fileName})}
                getDropRejectMessage={file => t('returns-dropzone-file-too-big', {
                    fileName: file.name,
                    sizeLimit: FILE_SIZE_LIMIT_STRING
                })}
                dropzoneText={t('returns-dropzone-text')}
            />
        </Grid>
        <Grid item md={2}>
            <Button
                type="submit"
                fullWidth
                disabled={files?.length === 0}
                variant="contained"
                color="primary"
                children={t("returns-add-files-button")}
            />
        </Grid>
    </Grid>;
}