import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {FC, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import Category from "../../../model/Category";
import VehicleItem from "../../../model/VehicleItem";
import AppLink from "../../theme/AppLink";
import VehicleListItem from "./VehicleListItem";
import IconButton from "@material-ui/core/IconButton";
import {
    KeyboardArrowDown, KeyboardArrowUp,
} from "@material-ui/icons";
import {useParams} from "react-router";

export interface TreeNode {
    name?: string;
    children: {
        [key: string]: TreeNode
    };
    items: VehicleItem[];
    categories: Category[];
    level: number;
}

const useStyles = makeStyles((theme) => ({
    itemNumber: {
        color: theme.palette.secondary.dark
    },
    nested: {
        paddingLeft: theme.spacing(2)
    },
    notNested: {
        paddingLeft: 0
    },
    withMargin: {
        marginTop: "10px",
        marginBottom: "10px"
    }
}));


export function createTree(items: VehicleItem[], categories: Category[]): TreeNode {
    const root = createTreeNode(0);
    items.forEach(item => insertItem(root, item));
    categories.forEach(category => insertCategory(root, category))
    return root;
}

export function createTreeNode(level: number, name?: string): TreeNode {
    return {
        name,
        children: {},
        items: [],
        categories: [],
        level
    };
}

export function insertItem(tree: TreeNode, item: VehicleItem) {
    const path = item.categoryNames;
    const branch = createBranch(tree, path);
    branch.items.push(item);
}

function createBranch(tree: TreeNode, path: string[]) {
    let branch = tree;
    path.forEach((key, index) => {
        const node = branch.children[key] || createTreeNode(index, key);
        branch.children[key] = node;
        branch = node;
    });
    return branch;
}

function insertCategory(tree: TreeNode, category: Category) {
    const linage = category.linage;
    const path = linage.slice(0, linage.length - 3).reverse().map(({name}) => name);
    const branch = createBranch(tree, path);

    branch.categories.push(category);
}

export interface NodeProps {
    node: TreeNode;
    getLink: (item: VehicleItem) => string;
    rootNode?: boolean;
    mini?: boolean
}

interface Params {
    category: string;
}

export const Node: FC<NodeProps> = ({node, getLink, rootNode = false, mini}) => {
    const largeCategories = ['motorteile', 'vergaser-kraftstoffzufuhr']
    const {t} = useTranslation();
    const params = useParams<Params>();
    const classes = useStyles(undefined);
    const {items, categories, name, level} = node;
    const collator = new Intl.Collator('de');
    const childNodes = getSortedChildNodes(node, collator);
    const [expanded, setExpanded] = useState(
        rootNode  //root is always expanded
        || (mini && name?.includes("root")) // expand sub-'root' nodes on mini lists (company)
        || (!mini && !largeCategories.includes(params.category)) //expand non mini lists, except for known ' large categories' (defined in old shop)
    );

    const fontSizes = [18, 16, 14, 14];
    const fonts = ["fontWeightBold", "fontWeightMedium", "fontWeightMedium", "fontWeightMedium"];

    return (
        <Box marginBottom={level === 0 ? 3 : 0}>
            {
                name &&
                <ListItem dense id={name.replace(/\W/g, '')}>
                    <ListItemText>
                        <Box fontWeight={fonts[level]} fontSize={fontSizes[level]}>
                            {name}
                            <IconButton style={{display: "inline"}} onClick={() => setExpanded(!expanded)}>{expanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}</IconButton>
                        </Box>
                    </ListItemText>
                </ListItem>
            }

            {expanded ?
                <Fragment>
                    {categories.length ?
                        categories.map(category =>
                            <ListItem dense key={category.slug}>
                                <AppLink
                                    to={`/${category.section.slug}/produkte/${category.slug}/`}
                                    color="secondary"
                                    children={t("vehicle-items-category-text")}
                                />
                            </ListItem>
                        ) : null
                    }
                    <List className={rootNode ? classes.notNested : classes.nested} disablePadding>
                        {childNodes.map(child => <Node mini={mini} key={child.name} node={child} getLink={getLink}/>)}
                        {items.sort((a, b) => collator.compare(a.name, b.name)).map(item => (
                            <VehicleListItem key={item.categoryNames.join(".") + "." + item.slug} item={item}
                                             getLink={getLink}/>
                        ))}
                    </List>
                </Fragment>
                : null
            }
        </Box>
    );
};

function getSortedChildNodes({children}: TreeNode, collator: Intl.Collator) {
    return Object.keys(children).sort((a, b) => {
        return collator.compare(a, b);
    }).map(key => children[key]);
}

export default Node;
