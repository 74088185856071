import FilterResult from "../model/FilterResult";
import FilteredItem from "../model/item/FilteredItem";
import Item from "../model/item/Item";
import PreorderItem from "../model/item/PreorderItem";
import VehicleItem from "../model/VehicleItem";
import AuthorizedHttpService from "./AuthorizedHttpService";
import PageRequest from "./PageRequest";

export interface ItemFilter extends PageRequest {
    query: string;
    "category.slug": string;

    [key: string]: string | number;
}

export class ItemService extends AuthorizedHttpService {
    public async read(itemSlug: string): Promise<Item> {
        return this.get(`/items/${itemSlug}`);
    }

    public async findAllByVehicleAndCategory(vehicle: string, category: string): Promise<VehicleItem[]> {
        return this.get("/items/", {vehicle, category});
    }

    public async findAllByCompany(company: string): Promise<VehicleItem[]> {
        return this.get("/items/", {company});
    }

    public async findAllByMotorAndCategory(motor: string, category: string): Promise<VehicleItem[]> {
        return this.get("/items/", {motor, category});
    }

    //MOCK
    public async findAllByFilter(filter: ItemFilter): Promise<FilterResult<FilteredItem>> {
        return this.get("/items/filter/", filter)
    }

    public async findAllPreorderByCategory(category: string, page: number, size: number): Promise<FilterResult<PreorderItem>> {
        return this.get("/items/preorder/", {category, page, size, sort: "category.sort.depth,desc"});
    }

    public async findLatestItems(page: number, size: number): Promise<FilterResult<Item>> {
        return this.get("/items/latest/", {page, size});
    }

    public async findForMonth(month: Date, page: number, size: number, sort: string | undefined): Promise<FilterResult<Item>> {
        return this.get("/items/latest/", {
            month: month.toISOString().substr(0, 10),
            page, size, sort
        });
    }
}

export default new ItemService();
