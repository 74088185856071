import Typography from "@material-ui/core/Typography";
import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../app/component/ContentHeader";

export const InternalServerErrorPage: FC = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <ContentHeader  header={t("error-500-heading")}></ContentHeader>


            <Typography paragraph variant="body1">
                {t("error-500-text1")}
            </Typography>
        </Fragment>
    );
};

export default InternalServerErrorPage;
