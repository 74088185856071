import {transaction} from "@datorama/akita";
import {Observable} from "rxjs";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import ItemSlug from "../../../model/item/ItemSlug";
import PreorderItem from "../../../model/item/PreorderItem";
import Preorder from "../../../model/preorder/Preorder";
import preorderService from "../../../service/PreorderService";
import authQuery from "../../auth/AuthQuery";
import {Dependencies} from "../../shoppingCart/ShoppingCartActions";
import cartQuery from "./CartQuery";
import cartStore, {ID} from "./CartStore";

export class CartActions {
    private readonly store = cartStore;
    private readonly query = cartQuery;
    private readonly service = preorderService;
    private readonly isLoggedIn = authQuery.isLoggedIn;

    constructor(options: Partial<Dependencies> = {}) {
        Object.assign(this, options);
        this.createLoadRequests().subscribe();
    }

    private createLoadRequests(): Observable<Promise<void>> {
        return (
            this.isLoggedIn
                .pipe(distinctUntilChanged())
                .pipe(filter((isLoggedIn) => isLoggedIn))
                .pipe(map(() => this.load()))
        );
    }

    @transaction()
    public async load(): Promise<void> {
        const request = this.service.findOpen();
        await this.updateCart(request);
    }

    @transaction()
    public async addItem(item: PreorderItem, quantity: number = 1): Promise<void> {
        const slug = item.slug;
        const current = this.query.getEntity(slug);
        if (current) {
            this.store.update(slug, {quantity: quantity + current.quantity});
        } else {
            this.store.add({...item, quantity});
        }
        const request = this.service.addItem(item.slug, quantity);
        await this.updateCart(request);
    }

    @transaction()
    public async addSlug(slug: ItemSlug, quantity: number = 1): Promise<void> {
        const current = this.query.getEntity(slug);
        if (current) {
            this.store.update(slug, {quantity: quantity + current.quantity});
        }
        const request = this.service.addItem(slug, quantity);
        await this.updateCart(request);
    }

    @transaction()
    public async setItem(item: PreorderItem, quantity: number = 1): Promise<void> {
        const slug = item.slug;
        const current = this.query.getEntity(slug);
        if (current) {
            this.store.update(slug, {quantity});
        } else {
            this.store.add({...item, quantity});
        }
        const request = this.service.setItem(item.slug, quantity);
        await this.updateCart(request);
    }

    private currentTimestamp(): number {
        const {timestamp} = this.store.getValue();

        return timestamp;
    }

    @transaction()
    public async removeItem(slug: ID): Promise<void> {
        if (this.query.hasEntity(slug)) {
            this.store.remove(slug);
            const request = this.service.removeItem(slug);
            await this.updateCart(request);
        }
    }

    @transaction()
    public async setQuantity(slug: ID, quantity: number): Promise<void> {
        const entity = this.query.getEntity(slug);
        if (entity) {
            this.store.update(slug, {quantity});
            const request = this.service.setItem(slug, quantity);
            await this.updateCart(request);
        }
    }

    private async updateCart(request: Promise<Preorder>) {
        const timestamp = Date.now();
        this.store.update({
            loading: true,
            error: null,
            timestamp
        });
        try {
            const result = await request;
            if (timestamp >= this.currentTimestamp()) {
                this.store.setCart(result);
            }
        } catch (error) {
            if (timestamp >= this.currentTimestamp()) {
                this.store.setError(error);
            }
        } finally {
            if (timestamp >= this.currentTimestamp()) {
                this.store.setLoading(false);
            }
        }
    }

    public async checkout(date: string, comment: string) {
        try {
            await this.service.checkout(date, comment);
        } catch (error) {
            this.store.setError(error);
            throw error;
        } finally {
            this.store.setLoading(false);
        }
    }
}

export default new CartActions();
