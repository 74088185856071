import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import React, {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {CategorySlug} from "../../model/Category";
import Reference from "../../model/Reference";
import {SectionSlug} from "../../model/Section";
import categoryQuery from "../../state/preorder/category/CategoryQuery";
import sectionQuery from "../../state/section/SectionQuery";
import ListItemLink from "../ListItemLink";

export const PreorderCategoryList: FC = () => {
    const slug = categoryQuery.useActiveId() || null;
    const categories = categoryQuery.useAll();
    const loading = categoryQuery.useIsLoading();
    const section = sectionQuery.useActiveId() || null;

    if (loading || !section) {
        return (<CircularProgress/>);
    } else {
        return (
            <CategoryListPure
                section={section}
                current={slug}
                categories={categories}
            />
        );
    }
}

export interface Props {
    section: SectionSlug;
    current: CategorySlug | null;
    categories: Reference[]
}

export const CategoryListPure: FC<Props> = ({categories, current, section}) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <Typography variant="h5" component="h2">{t("category-list-title")}</Typography>
            <List>
                {categories.map(({name, slug}) => (
                    <ListItemLink
                        data-cy="category-title"
                        key={slug}
                        primary={name}
                        disabled={slug === current}
                        path={`/vor-teile-wochen/${section}/${slug}.html`}
                    />
                ))}
            </List>
        </Fragment>
    );
};

